import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DiffKey } from '../classes/diffKey';
import { HttpParams } from '../classes/httpParams';
import { deserializationJacksonKey } from '../classes/jacksonUtils';
import { FileUpload } from '../models/fileUpload';
import { FileUploadHistory } from '../models/fileUploadHistory';
import { ProfileService } from './profile.service';
import { BaseProjectService } from './base-project.service';
import { AlertService } from './alert.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService extends BaseProjectService {
  constructor(http: HttpClient,
              alertService: AlertService,
              private profileService: ProfileService) {
    super(http, alertService);
  }

  public uploadFile = (file: File): Observable<any> => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return fromPromise(this.executePost('v2/files', formData));
  }

  public deleteFile(id: number, comment: string, fromSuggestion: boolean): Promise<FileUpload> {
    const params: HttpParams = {
      fromSuggestion: fromSuggestion.toString(),
    };
    if (comment) {
      params.comment = comment;
    }
    return this.deleteWithCheck<FileUpload>(`files/${id}`, {params})
      .then((data) => deserializationJacksonKey(FileUpload, data));
  }

  public accept(id: number, comment: string, fromSuggestion: boolean): Promise<FileUpload> {
    const params: HttpParams = {
      fromSuggestion: fromSuggestion.toString(),
    };
    if (comment) {
      params.comment = comment;
    }

    return this.putWithCheck(`files/${id}/actions/accept`, null, {params})
      .then((data) => deserializationJacksonKey(FileUpload, data));
  }

  public decline(id: number, comment: string, fromSuggestion: boolean): Promise<FileUpload> {
    const params: HttpParams = {
      fromSuggestion: fromSuggestion.toString(),
    };
    if (comment) {
      params.comment = comment;
    }
    return this.executePut(`files/${id}/actions/decline`, null, {params}).toPromise()
      .then((data) => deserializationJacksonKey(FileUpload, data));
  }

  public loadHistory(id: number, fromSuggestion: boolean): Observable<FileUploadHistory[]> {
    const params: HttpParams = {
      fromSuggestion: fromSuggestion.toString(),
    };
    return this.executeGet<FileUploadHistory[]>(`files/${id}/actions/history`)
      .pipe(map((data) => deserializationJacksonKey(FileUploadHistory, data, {params})));
  }

  public rename(id: number, name: string, fromSuggestion: boolean): Promise<FileUpload> {
    const params: HttpParams = {
      fromSuggestion: fromSuggestion.toString(),
    };
    return this.putWithCheck(`files/${id}/actions/rename`, name, {params})
      .then((data) => deserializationJacksonKey(FileUpload, data));
  }

  public fixHistory(file: FileUpload, diffKey: DiffKey) {
    if (file && diffKey && file.histories.length > 0) {
      const lastHistory = file.histories[file.histories.length - 1];
      if (lastHistory.isModify() && lastHistory.owner.id === this.profileService.currentProfile.id
        && lastHistory.suggestionId === 0) {
        lastHistory.suggestionId = diffKey.suggestionId;
        lastHistory.packageVersion = diffKey.packageVersion;
      }
    }
  }
}
