import {Stream} from 'openvidu-browser';
import {WebRtcParticipantStatus} from './enums';

export class WebRtcParticipant {

  public static getStatusMsg(status: WebRtcParticipantStatus): string {
    switch (status) {
      case WebRtcParticipantStatus.NONE:
        return '';
      case WebRtcParticipantStatus.CALLING:
        return 'RTC.STATUS.CALLING';
      case WebRtcParticipantStatus.CONNECTING:
        return 'RTC.STATUS.CONNECTING';
      case WebRtcParticipantStatus.REJECT:
        return 'RTC.STATUS.REJECT';
      case WebRtcParticipantStatus.COMMUNICATING:
        return '';
    }
  }

  public hash: number;
  public status: WebRtcParticipantStatus;
  public fullscreen: boolean;
  public video: HTMLVideoElement;
  public stream: Stream = null;
  public statusMsg = '';
  public fullName = '';

  constructor(hash: number, status: WebRtcParticipantStatus, fullscreen: boolean, video: HTMLVideoElement, fullName) {
    this.hash = hash;
    this.status = status;
    this.fullscreen = fullscreen;
    this.video = video;
    this.fullName = fullName;
    this.statusMsg = WebRtcParticipant.getStatusMsg(this.status);
  }
}
