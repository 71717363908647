import {Type} from 'class-transformer';
import {ContentType} from '../classes/enums';
import {FileUploadHistory} from './fileUploadHistory';
import {IChangeable} from './iChangeable';
import {SimpleProfile} from './profile/simpleProfile';

export class FileUpload implements IChangeable<FileUploadHistory> {
  public id: number;
  public name: string;
  public extension: string;
  public size: number;
  public contentType: ContentType;
  public contentId: number;

  @Type(() => SimpleProfile)
  public uploader: SimpleProfile;
  public changeVersion: number;

  @Type(() => Date)
  public modifiedAt: Date;

  @Type(() => FileUploadHistory)
  public histories: FileUploadHistory[];

  public isHistoryShown = false;

  public uuid: string;
}
