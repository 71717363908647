import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { VideoElementEvent } from 'openvidu-browser';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../helpers/rxjs/take-until-destroyed';
import { WebRtcCallState, WebRtcParticipantStatus, WebRtcSource } from '../../models/webrtc/enums';
import { ParticipantStatusChangedMessage } from '../../models/webrtc/messages/participantStatusChangedMessage';
import { StreamCreatedMessage } from '../../models/webrtc/messages/streamCreatedMessage';
import { StreamDestroyedMessage } from '../../models/webrtc/messages/streamDestroyedMessage';
import { WebRtcParticipant } from '../../models/webrtc/webRtcParticipant';
import { AlertService } from '../../services/alert.service';
import { ProfileService } from '../../services/profile.service';
import { WebRtcService } from '../../services/webrtc.service';
import { TranslateService } from '@ngx-translate/core';

@DecorateUntilDestroy()
@Component({
  selector: 'rtc',
  templateUrl: './rtc.component.html',
  styleUrls: [ './rtc.component.scss' ],
})
export class RtcComponent implements OnInit, OnDestroy {

  @ViewChild('dialog')
  public dialog: ElementRef;

  @ViewChild('remote')
  public remote: ElementRef;

  @ViewChild('local')
  public local: ElementRef;

  public WebRtcCallState = WebRtcCallState;
  public WebRtcSource = WebRtcSource;
  public WebRtcService = WebRtcService;
  public WebRtcParticipantStatus = WebRtcParticipantStatus;

  public incomingPeer = '';
  public incomingRoom = '';

  public remoteParticipants: WebRtcParticipant[] = [];
  public fullscreenMode = false;

  public isScreenSharingPossible = false;

  constructor(
    public profileService: ProfileService,
    public translate: TranslateService,
    public webRtcService: WebRtcService,
    public alertService: AlertService,
    public renderer: Renderer2,
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.resizeVideos();
  }

  public ngOnInit(): void {
    this.init();

    this.webRtcService.streamCreated$.pipe(
      takeUntilDestroyed(this),
    ).subscribe((message: StreamCreatedMessage) => {
      if (message) {
        this.onNewStreamCreated(message);
      }
    });

    this.webRtcService.streamDestroyed$.pipe(
      takeUntilDestroyed(this),
    ).subscribe((message: StreamDestroyedMessage) => {
      if (message) {
        this.onStreamDestroyed(message);
      }
    });

    this.webRtcService.participantStatusChanged$.pipe(
      takeUntilDestroyed(this),
    ).subscribe((message: ParticipantStatusChangedMessage) => {
      if (message) {
        this.onParticipantStatusChanged(message);
      }
    });

    this.webRtcService.participantStatusChangedOnUserLeave$.pipe(
      takeUntilDestroyed(this),
    ).subscribe((message: ParticipantStatusChangedMessage) => {
      if (message) {
        this.onParticipantStatusChangedOnUserLeave(message);
      }
    });
  }

  public ngOnDestroy(): void { }

  public init() {
    this.incomingPeer = '';
    this.incomingRoom = '';
    this.remoteParticipants = [];
  }

  public acceptCall(): void {
    this.init();
    this.webRtcService.acceptIncomingCall();
  }

  public rejectCall(): void {
    this.init();
    this.webRtcService.rejectIncomingCall();
  }

  public withdrawInviteUser(hash: number): void {
    this.webRtcService.withdrawInviteUser(hash);
    this.init();
    this.webRtcService.leaveRoom();
  }

  public stop(): void {
    this.init();
    this.webRtcService.leaveRoom();
  }

  public switchVideoTo(source: WebRtcSource): void {
    if (source === WebRtcSource.SCREEN) {
      this.getScreenSharingPossibility((possible) => {
        if (possible) {
          this.webRtcService.switchVideoTo(source);
        } else {
          this.alertService.error({
            message: 'RTC_COMPONENT.SCREEN_SHARE_ERROR_NO_COMPONENT_INSTALLED',
            params: {text: this.WebRtcService.CHROME_EXTENSION_HREF}
          });
        }
      });
    } else {
      this.webRtcService.switchVideoTo(source);
    }
  }

  public getScreenSharingPossibility(responseFunc): void {
    return responseFunc(true);
  }

  public onNewStreamCreated(message: StreamCreatedMessage): void {
    const peerData = JSON.parse(message.stream.connection.data);
    this.onParticipantStatusChanged(new ParticipantStatusChangedMessage(peerData.userHash,
      WebRtcParticipantStatus.COMMUNICATING,
      peerData.userFullName,
      WebRtcParticipant.getStatusMsg(WebRtcParticipantStatus.COMMUNICATING)),
    );

    // time for DOM to rerender
    setTimeout(() => {
      const subscriber = message.session.subscribe(message.stream, 'participant_' + peerData.userHash);
      subscriber.on('videoElementCreated', (event) => {
        const videoEvent = event  as VideoElementEvent;
        const participant: WebRtcParticipant = this.remoteParticipants.find((item) => item.hash === peerData.userHash);
        participant.stream = message.stream;
        this.initParticipantVideo(participant, videoEvent.element);
      });
    }, 1000);

  }

  public onStreamDestroyed(message: StreamDestroyedMessage): void {
    const peerData = JSON.parse(message.stream.connection.data);
    const status = peerData.userFullName ? WebRtcParticipantStatus.COMMUNICATING : WebRtcParticipantStatus.NONE;
    this.onParticipantStatusChanged(new ParticipantStatusChangedMessage(peerData.userHash,
      status,
      peerData.userFullName,
      WebRtcParticipant.getStatusMsg(status)),
    );
  }

  public onParticipantStatusChanged(message: ParticipantStatusChangedMessage): void {
    const userHash: number = message.userHash;
    const status: WebRtcParticipantStatus = message.status;
    let participantIndex = this.remoteParticipants.findIndex((item) => item.hash === userHash);
    if (participantIndex === -1) {
      const participant = new WebRtcParticipant(userHash, status, false, null, message.userFullName);
      this.remoteParticipants.push(participant);
      participantIndex = this.remoteParticipants.length - 1;
    } else {
      this.remoteParticipants[participantIndex].status = status;
      this.remoteParticipants[participantIndex].statusMsg = message.message;
    }

    const showMessageDuration = 6000;
    if (status === WebRtcParticipantStatus.REJECT) {
      if (message.message) {
        this.remoteParticipants[participantIndex].statusMsg = message.message;
      }
      setTimeout(() => {
        this.remoteParticipants.splice(participantIndex, 1);
        if (this.remoteParticipants.length == 0) {
          this.webRtcService.leaveRoom();
        }
      }, showMessageDuration);
    }

    if (status === WebRtcParticipantStatus.NONE) {
      this.remoteParticipants.splice(participantIndex, 1);
    }

    this.resizeVideos();
  }

  public onParticipantStatusChangedOnUserLeave(message: ParticipantStatusChangedMessage): void {
    const userHash: number = message.userHash;
    const status: WebRtcParticipantStatus = message.status;
    let participantIndex = this.remoteParticipants.findIndex((item) => item.hash === userHash);
    if (participantIndex === -1) {
      const participant = new WebRtcParticipant(userHash, status, false, null, message.userFullName);
      this.remoteParticipants.push(participant);
      participantIndex = this.remoteParticipants.length - 1;
    } else {
      this.remoteParticipants[participantIndex].status = status;

      this.remoteParticipants[participantIndex].statusMsg = message.message;
    }
    const showMessageDuration = 6000;

    if (status === WebRtcParticipantStatus.NONE) {
      this.remoteParticipants.splice(participantIndex, 1);
    }

    setTimeout(() => {
      if (this.remoteParticipants.length == 0) {
         this.webRtcService.leaveRoom();
      }
    }, showMessageDuration / 2);
    this.resizeVideos();
  }

  private resizeVideos(): void {
    // It means dialog is showing.
    // TODO Make isShowing component flag
    if (this.webRtcService.callState === WebRtcCallState.CALL) {
      this.remoteParticipants.forEach((participant) => {
        this.resizeVideo(participant);
      });
    }
  }

  private resizeVideo(participant: WebRtcParticipant): void {
    if (participant.video && participant.stream.streamManager.videos.length > 0) {
      const element = participant.video;
      const aspectRatio = participant.stream.streamManager.videos[0].video.videoHeight / participant.stream.streamManager.videos[0].video.videoWidth;
      const dialogWidth = this.dialog.nativeElement.clientWidth;
      const potentialWidth = (dialogWidth - (participant.fullscreen ? 0 : 100)) / (participant.fullscreen ? 1 : this.remoteParticipants.length);
      element.height = Math.ceil(Math.min(potentialWidth * aspectRatio, this.remote.nativeElement.clientHeight - (participant.fullscreen ? 0 : 40)));
      element.width = Math.min(potentialWidth, element.height / aspectRatio);
    }
  }

  private initParticipantVideo(participant: WebRtcParticipant, element: HTMLVideoElement): void {
    participant.video = element;

    this.renderer.listen(element, 'loadedmetadata', () => {
      this.resizeVideo(participant);
    });

    this.renderer.listen(element, 'click', () => {
      participant.fullscreen = !participant.fullscreen;
      this.fullscreenMode = participant.fullscreen;
      setTimeout(() => this.resizeVideo(participant), 50);
    });
  }

}
