import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { ProfileManagementService } from '../../services/profile-management.service';
import { AlertService } from '../../services/alert.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  template: ''
})

export class ConfirmComponent implements OnInit {
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private profileService: ProfileService,
              private profileManagementService: ProfileManagementService,
              private alertService: AlertService) {
  }

  public ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let path = [];
      let query: NavigationExtras;
      let promise: Promise<any> = null;
      if (params.source) {
        switch (params.source) {
          case 'restore':
            query = {queryParams: {token: params.token}};
            path = ['/forgotPassword'];
            break;
          case 'email':
            promise = this.profileManagementService.validateEmail(params.token);
            path = this.profileService?.currentProfile ? ['/settings/security'] : ['/login'];
            break;
          case 'contact':
            promise = this.profileManagementService.validateContactEmail(params.token);
            path = ['/profile'];
            query = {queryParams: {tab: 'contacts'}};
            break;
          case 'register':
            promise = this.profileManagementService.confirmRegistration(params.token);
            path = ['/login'];
            break;
        }
      } else {
        // confirm/connect route, processing cannot pass ?source=connect as back url
        if (params.code && params.state) {
          promise = this.profileManagementService.confirmConnect(params.code, params.state);
          path = ['/finance/accountWithdraw'];
        }
      }
      if (params.source === 'restore') {
        this.router.navigate(path, query);
      }
      if (promise) {
        promise.then((resp) => {
          if (resp && resp.error) {
            this.alertService.error('SERVER_ERROR.INTERNAL_SERVER_ERROR');
          } else {
            if (params.source === 'register') {
              this.router.navigate(['/confirm-success']);
            } else {
              this.alertService.success('CONFIRM_COMPONENT.CONFIRMATION_SUCCESSFUL');
              this.router.navigate(path, query);
            }
          }
        }).catch((error) => {
          if (error.text === 'invalidToken') {
            this.router.navigate(['/login']);
            return;
          }

          this.alertService.error(error);
        });
      }

    });
  }
}
