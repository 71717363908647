<section *ngIf="mode === SubscriptionMode.UNSUBSCRIBE_REASON" class="subscription__unsubscribe">
    <div class="subscription__inner">
        <p><strong>{{'SUBSCRIPTION.UNSUBSCRIBE_TITLE1' | translate}}</strong></p>
        <p>{{'SUBSCRIPTION.UNSUBSCRIBE_TITLE2' | translate}}</p>

        <form (ngSubmit)="onClickSubmit()" [formGroup]="subscriptionForm">
            <mat-form-field class="subscription__max-field">
                <input
                    #email
                    class="subscription__input"
                    formControlName="email"
                    matInput
                    placeholder="{{'SUBSCRIPTION.FIELDS.EMAIL' | translate}}"
                    required
                />
                <mat-hint *ngIf="email.value.length > 0" align="start">{{'SUBSCRIPTION.VALIDATION.MAX_CHAR' | translate}}</mat-hint>
                <mat-hint *ngIf="email.value.length > 0" align="end">{{ email.value.length }} / 50</mat-hint>
                <mat-error *ngIf="subscriptionForm.controls.email.errors?.email">{{'SUBSCRIPTION.VALIDATION.INVALID_EMAIL' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="subscription__max-field">
                <textarea
                    class="subscription__textarea"
                    formControlName="reason"
                    matInput
                    matTextareaAutosize="false"
                    maxlength="5000"
                    placeholder="{{'SUBSCRIPTION.FIELDS.REASON' | translate}}"
                ></textarea>
            </mat-form-field>

            <mat-radio-group class="subscription__radio-group subscription__column" formControlName="unsubscribe">
                <mat-radio-button [value]="true" class="subscription__radio" color="primary">
                  {{'SUBSCRIPTION.UNSUBSCRIBE_TEXT' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false" class="subscription__radio" color="primary">
                    {{'SUBSCRIPTION.KEEP_ME_SUBSCRIBED' | translate}}
                </mat-radio-button>
            </mat-radio-group>

            <div>
                <button
                    [disabled]="
                        loading || !subscriptionForm.valid || subscriptionForm.controls.unsubscribe.value === false
                    "
                    [ngClass]="{
                        subscription__button_disabled:
                            loading || !subscriptionForm.valid || subscriptionForm.controls.unsubscribe.value === false
                    }"
                    class="subscription__button"
                    color="primary"
                    button
                    mat-stroked-button
                    mat-button
                    type="submit"
                >
                  {{'SUBSCRIPTION.SUBMIT' | translate}}
                </button>
            </div>
        </form>
    </div>
</section>

<section *ngIf="mode === SubscriptionMode.UNSUBSCRIBE_SUCCESS" class="subscription__unsubscribed">
    <div class="subscription__sad">{{'SUBSCRIPTION.WE_WILL_MISS1' | translate}}</div>
    <div class="subscription__sad">{{'SUBSCRIPTION.WE_WILL_MISS2' | translate}}</div>
</section>
<section *ngIf="mode === SubscriptionMode.ALREADY_UNSUBSCRIBED" class="subscription__unsubscribed">
    <div class="subscription__sad">{{'SUBSCRIPTION.YOU_ARE_NOT_SUBSCRIBED' | translate}}</div>
</section>
