import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { CurrencySymbol } from '../../../../models/currency.enum';
import { ProjectApiService } from '../../../../services/project.api.service';
import { SnackbarNotificationsService } from '../../../../services/snackbar-notifications.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';

@Component({
  selector: 'tpt-generate-invoice-dialog',
  templateUrl: './generate-invoice-dialog.component.html',
  styleUrls: ['./generate-invoice-dialog.component.scss']
})
export class GenerateInvoiceDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output()
  public updateContract = new EventEmitter<void>();

  public project;

  public currencySymbol = CurrencySymbol;

  private className = 'tpt-generate-invoice-dialog';

  private config: MatDialogConfig = {
    width: '520px',
  };

  constructor(private projectApiService: ProjectApiService,
              private spinner: SpinnerService,
              private snack: SnackbarNotificationsService) { }

  public open(project): void {
    this.project = project;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public generateInvoice(): void {
    this.spinner.startSpinner();
    this.projectApiService.createInvoice(this.project.id).subscribe(() => {
      this.spinner.stopSpinner();
      this.updateContract.emit();
      this.close();
    }, () => {
      this.spinner.stopSpinner();
      this.snack.showSmthWentWrong();
    });

  }

}
