import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { FutureExpenseModel } from '../../../finance/models/future-expense.model';
import { PaymentPeriodEnum } from '../../../employer/types/payment-period.enum';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { FailureDialogComponent } from '../failure-dialog/failure-dialog.component';
import { DepositFundsDialogComponent } from '../deposit-funds-dialog/deposit-funds-dialog.component';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { ProjectApiService } from '../../../../services/project.api.service';

@Component({
  selector: 'tpt-stop-auto-payment-dialog',
  templateUrl: './stop-auto-payment-dialog.component.html',
  styleUrls: ['./stop-auto-payment-dialog.component.scss']
})
export class StopAutoPaymentDialogComponent {
  public svgIconsEnum = SvgIconsEnum;
  public info: FutureExpenseModel;

  @ViewChild(DialogComponent) public dialog: DialogComponent;
  @ViewChild(SuccessDialogComponent)
  public successDialog: SuccessDialogComponent;

  @ViewChild(FailureDialogComponent)
  public failureDialog: FailureDialogComponent;

  @ViewChild(DepositFundsDialogComponent)
  public depositFundsDialog: DepositFundsDialogComponent;
  private className = 'tpt-stop-auto-payment-dialog';

  private config: MatDialogConfig = {
    width: '729px',
  };

  constructor(private spinnerService: SpinnerService,
              public projectApiService: ProjectApiService,
  ) {
  }

  get paymentPeriod() {
    return PaymentPeriodEnum;
  }

  public open(data): void {
    this.info = data;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public openDepositFundsDialog(): void {
    this.depositFundsDialog.open();
    this.failureDialog.close();
  }

  public close(): void {
    this.dialog.close();
  }

  public confirm(): void {
    this.spinnerService.startSpinner();
    this.projectApiService.stopAutoPayment(this.info.job.id).subscribe(this.handlePaymentSuccessful, this.handlePaymentFailure);
  }

  public depositIsSuccess(): void {
    this.depositFundsDialog.close();
    this.open(this.info);
    this.confirm();
  }

  private handlePaymentSuccessful = (): void => {
    this.spinnerService.stopSpinner();
    this.successDialog.open();
    this.close();
  }

  private handlePaymentFailure = (): void => {
    this.spinnerService.stopSpinner();
    this.failureDialog.open();
    this.close();
  }
}
