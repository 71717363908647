import { Type } from 'class-transformer';
import { CommissionValue } from './commissionValue';

export class InvoiceData {
  public amount: number = null;

  @Type(() => Date)
  public holdDate: Date = null;

  @Type(() => CommissionValue)
  public commission?: CommissionValue = null;

  @Type(() => CommissionValue)
  public arbitrage?: CommissionValue = null;

  @Type(() => Date)
  public tillTransferDate: Date = null;

  public freelancerPenaltySum: number = null;
}
