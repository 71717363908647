<div class="container"
     tptDnd (fileDropped)="onFileDropped($event)"
     [ngStyle]="{'display': invisible ? 'none' : 'flex'}"
     [class.small]="size === 'small'">
  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target?.files)" />
  <mat-icon [svgIcon]="svgIconsEnum.DocUpload" class="icon-upload"></mat-icon>

  <div class="right-block">
    <ng-container *ngIf="size !== 'small'">
      <div class="upload-message mb-4">{{'CREATE_PROJECT.DRAG_N_DROP_MESSAGE' | translate}}</div>

      <tpt-button type="accent" class="upload-btn mb-8" (onClick)="fileDropRef.click()">
        {{'FILE_UPLOADER_COMPONENT.UPLOAD' | translate}}
      </tpt-button>
    </ng-container>
    <ng-container *ngIf="size === 'small'">
      <div class="upload-message">{{'CREATE_PROJECT.DRAG_N_DROP_MESSAGE_SMALL' | translate}}
        <span class="upload-link">{{'FILE_UPLOADER_COMPONENT.UPLOAD' | translate}}</span>
      </div>
    </ng-container>

    <div class="bottom-message">
      {{'CREATE_PROJECT.SIZE_INFO' | translate}}
    </div>
  </div>
</div>
<div class="files-list" [ngStyle]="{'display': invisible ? 'none' : 'flex'}">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <div class="close-size">
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
        <mat-icon [svgIcon]="svgIconsEnum.Close" class="delete" (click)="deleteFile(i, file.id)"></mat-icon>
      </div>
    </div>
    <div class="progress-cont" *ngIf="file?.progress < 100">
      <div class="progress" [style.width]="file?.progress + '%'">
      </div>
    </div>
  </div>
</div>
