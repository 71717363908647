import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { NotificationV2 } from '../../models/notification';
import { NotificationType } from '../../classes/enums';
import { RouteHelper } from '../../helpers/route.helper';
import { ProfileService } from '../../services/profile.service';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { NotificationService } from '../../services/notification.service';
import { CurrencySymbol } from '../../models/currency.enum';

@Component({
  selector: 'tpt-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss'],
})

export class NotificationContentComponent {
  @Input() public notifications: {
    today: NotificationV2[],
    yesterday: NotificationV2[],
    older: NotificationV2[]
  };

  @Output() public clear = new EventEmitter();

  @Output() public ack: EventEmitter<NotificationV2> = new EventEmitter<NotificationV2>();

  @Output() public close: EventEmitter<void> = new EventEmitter<void>();

  @Output() public delete: EventEmitter<NotificationV2> = new EventEmitter<NotificationV2>();

  @Output() public markAllAsReadEvent: EventEmitter<void> = new EventEmitter<void>();

  public svgIconsEnum = SvgIconsEnum;

  public redirectToFinance: string[] = [
    NotificationType.EMPLOYER_MILESTONE_INSUFFICIENT_FUNDS,
    NotificationType.EMPLOYER_PROJECT_INSUFFICIENT_FUNDS,
    NotificationType.FREELANCER_GOT_PAID,
  ];

  public redirectToFinanceHistory: string[] = [
    NotificationType.EMPLOYER_RUBLE_BALANCE_REPLENISHED,
  ];

  public redirectToChatsArray: string[] = [
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED,
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_RUBLE_NEED_PAY_AN_INVOICE,
    NotificationType.EMPLOYER_RUBLE_NEED_CREATE_AN_INVOICE,
  ];

  public redirectToProposalsArray: string[] = [
    NotificationType.FREELANCER_FREELANCER_INVITED,
    NotificationType.EMPLOYER_FREELANCER_PROPOSAL,
    NotificationType.EMPLOYER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_FREELANCER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_CHANGE_PROJECT,
    NotificationType.EMPLOYER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_CHANGES,
  ];

  public redirectToTaskTracker: string[] = [
    NotificationType.EMPLOYER_FREELANCER_OPEN_DISPUTE,
    NotificationType.FREELANCER_FREELANCER_OPEN_DISPUTE,
    NotificationType.EMPLOYER_EMPLOYER_OPEN_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_OPEN_DISPUTE,
  ];

  public redirectFinanceDetails: string[] = [
    NotificationType.CARD_ATTACHED,
    NotificationType.CARD_DETACHED,
    NotificationType.PAYONEER_DETACHED,
  ];

  public redirectToProjects: string[] = [
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_ALL_TASKS,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_ALL_TASKS
  ];

  public redirectToProjectPage: string[] = [
    NotificationType.EMPLOYER_FREELANCER_LEFT_COMMENT,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_REVIEW,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_TO_DO,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_IN_PROGRESS,
    NotificationType.EMPLOYER_FREELANCER_REWORK_TASK,
    NotificationType.EMPLOYER_FREELANCER_STARTED_TASK,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_TASK,
    NotificationType.FREELANCER_EMPLOYER_LEFT_COMMENT,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_TASK,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_TASK,
    NotificationType.FREELANCER_EMPLOYER_CREATED_TASK,
    NotificationType.FREELANCER_EMPLOYER_EDITED_TASK,
    NotificationType.EMPLOYER_REVIEW_DEADLINE_IS_SOON,
    NotificationType.EMPLOYER_AUTO_ACCEPT,
    NotificationType.EMPLOYER_FREELANCER_STARTED_ARBITRAGE,
    NotificationType.FREELANCER_EMPLOYER_STARTED_ARBITRAGE,
  ];

  public showFreelancerAvatar: string[] = [
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED,
    NotificationType.EMPLOYER_FREELANCER_PROPOSAL,
    NotificationType.FREELANCER_FREELANCER_PROPOSAL,
    NotificationType.EMPLOYER_FREELANCER_OPEN_DISPUTE,
    NotificationType.FREELANCER_FREELANCER_OPEN_DISPUTE,
    NotificationType.EMPLOYER_FREELANCER_DECLINE_SELF,
    NotificationType.FREELANCER_FREELANCER_DECLINE_SELF,
    NotificationType.EMPLOYER_FREELANCER_LEFT_COMMENT,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_REVIEW,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_TO_DO,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_IN_PROGRESS,
    NotificationType.EMPLOYER_FREELANCER_REWORK_TASK,
    NotificationType.EMPLOYER_FREELANCER_STARTED_TASK,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_TASK,
    NotificationType.EMPLOYER_FREELANCER_DECLINED,
    NotificationType.FREELANCER_FREELANCER_DECLINED,
    NotificationType.EMPLOYER_FREELANCER_CLOSED_DISPUTE,
    NotificationType.EMPLOYER_FREELANCER_CHANGE_PROJECT,
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_CHANGE_PROJECT,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_REVOKE_OWN_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_STARTED_ARBITRAGE,
    NotificationType.EMPLOYER_REVIEW_ABOUT_FREELANCER_IS_ALLOWED,
  ];

  public showEmployerAvatar: string[] = [
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED,
    NotificationType.EMPLOYER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_ALL_TASKS,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_ALL_TASKS,
    NotificationType.FREELANCER_FREELANCER_INVITED,
    NotificationType.EMPLOYER_FREELANCER_INVITED,
    NotificationType.EMPLOYER_EMPLOYER_DECLINED,
    NotificationType.FREELANCER_EMPLOYER_DECLINED,
    NotificationType.EMPLOYER_EMPLOYER_DECLINE_SELF,
    NotificationType.FREELANCER_EMPLOYER_DECLINE_SELF,
    NotificationType.FREELANCER_EMPLOYER_LEFT_COMMENT,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_TASK,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_TASK,
    NotificationType.FREELANCER_EMPLOYER_CREATED_TASK,
    NotificationType.FREELANCER_EMPLOYER_EDITED_TASK,
    NotificationType.FREELANCER_EMPLOYER_CLOSED_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.FREELANCER_EMPLOYER_REVOKE_OWN_CHANGES,
    NotificationType.EMPLOYER_EMPLOYER_OPEN_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_OPEN_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_STARTED_ARBITRAGE,
    NotificationType.FREELANCER_REVIEW_ABOUT_EMPLOYER_IS_ALLOWED,
  ];

  public showErrorIcon: string[] = [
    NotificationType.EMPLOYER_MILESTONE_INSUFFICIENT_FUNDS,
    NotificationType.EMPLOYER_PROJECT_INSUFFICIENT_FUNDS,
  ];

  public showSuccessIcon: string[] = [
    NotificationType.FREELANCER_GOT_PAID,
    NotificationType.CARD_DETACHED,
    NotificationType.CARD_ATTACHED,
    NotificationType.PAYONEER_DETACHED,
    NotificationType.EMPLOYER_REGISTERED,
    NotificationType.FREELANCER_REGISTERED,
    NotificationType.EMPLOYER_RUBLE_BALANCE_REPLENISHED,
  ];

  public infoIcon: string[] = [
    NotificationType.FREELANCER_OTHER_FREELANCER_SELECTED,
    NotificationType.EMPLOYER_REVIEW_DEADLINE_IS_SOON,
    NotificationType.EMPLOYER_AUTO_ACCEPT,
    NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER,
    NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER,
    NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER,
    NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER,
    NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_PROPORTION,
    NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_PROPORTION,
    NotificationType.EMPLOYER_RUBLE_NEED_PAY_AN_INVOICE,
    NotificationType.EMPLOYER_RUBLE_NEED_CREATE_AN_INVOICE,
  ];

  private subscription: Subscription;
  private result: string;

  constructor(private translateService: TranslateService,
              private cd: ChangeDetectorRef,
              private profile: ProfileService,
              private router: Router,
              private notificationService: NotificationService,
              public routeHelper: RouteHelper,
              private ngZone: NgZone) {
  }

  get showMarkAll(): boolean {
    return this.notifications.today.some(item => !item.viewed) ||
    this.notifications.yesterday.some(item => !item.viewed) ||
    this.notifications.older.some(item => !item.viewed);
  }

  public translate(value: NotificationV2): string {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const { code, data } = value;
    const type = data?.type;
    this.subscription = this.translateService.get(code, {
      emp: data?.owner && `${data.owner.firstName} ${data.owner.lastName}` || '',
      fr: data?.freelancer && `${data.freelancer.firstName} ${data.freelancer.lastName}` || '',
      project: type === 'project' ? data?.name : data?.project?.name ||
        data?.task?.project?.name || data?.task?.job?.name || data?.job?.name || '',
      task: data && data.name || '',
      deadline: data && typeof data.reviewDaysLeft === 'number' ? data.reviewDaysLeft : '',
      empSum: data?.employerRefund || '',
      frSum: data?.freelancerRefund || '',
      currency: CurrencySymbol[data?.job?.currency?.code] || CurrencySymbol[data?.task?.job?.currency?.code] || '',
    })
      .subscribe((result) => {
        this.result = result;
        this.ngZone.run(() => this.cd.markForCheck());
      });
    return this.result;
  }

  public markAllAsRead(): void {
    this.notifications.today.forEach(item => item.viewed = true);
    this.notifications.yesterday.forEach(item => item.viewed = true);
    this.notifications.older.forEach(item => item.viewed = true);
    this.markAllAsReadEvent.emit();
  }

  public redirectTo(notification: NotificationV2): void {
    const isEmployer = this.profile.currentProfile.isEmployer();
    const chatId = notification?.data?.id;
    const projectId = notification?.data?.project?.id || notification?.data?.task?.project?.id ||
      notification?.data?.job?.projectId || notification?.data?.task?.job?.projectId;

    if (!notification.viewed) {
      this.markOneAsRead(notification);
    }

    if (this.redirectToChatsArray.includes(notification.code)) {
      this.router.navigate(isEmployer ? this.routeHelper.contractsChatsUrl(chatId) :
        this.routeHelper.frContractsChatsUrl(chatId));
      this.close.emit();
      return;
    }

    if (this.redirectToProposalsArray.includes(notification.code)) {
      this.router.navigate(isEmployer ? this.routeHelper.contractsProposalsUrl(chatId) :
        this.routeHelper.frContractsProposalsUrl(chatId));
      this.close.emit();
      return;
    }

    if (this.redirectToFinance.includes(notification.code)) {
      this.router.navigate(this.routeHelper.financesCommon);
      this.close.emit();
      return;
    }

    if (this.redirectToFinanceHistory.includes(notification.code)) {
      this.router.navigate(this.routeHelper.financesTransactions);
      this.close.emit();
      return;
    }

    if (this.redirectToTaskTracker.includes(notification.code)) {
      this.router.navigate(isEmployer ? this.routeHelper.employerProjectPage(projectId) :
        this.routeHelper.freelancerProjectPage(projectId));
      this.close.emit();
      return;
    }

    if (this.redirectToProjects.includes(notification.code)) {
      this.router.navigate(isEmployer ? this.routeHelper.employeerProjectsCompleted :
        this.routeHelper.freelancerProjectsCompleted);
      this.close.emit();
      return;
    }

    if (this.redirectFinanceDetails.includes(notification.code)) {
      this.router.navigate(this.routeHelper.financesPaymentDetails);
      this.close.emit();
      return;
    }

    if (this.redirectToProjectPage.includes(notification.code)) {
      this.router.navigate(isEmployer ? this.routeHelper.employerProjectPage(projectId) :
        this.routeHelper.freelancerProjectPage(projectId));
      this.close.emit();
      return;
    }
  }

  public isActiveRow(notification: NotificationV2): boolean {
    return this.redirectToChatsArray.includes(notification.code) ||
      this.redirectToProposalsArray.includes(notification.code) ||
      this.redirectToTaskTracker.includes(notification.code) ||
      this.redirectToFinance.includes(notification.code) ||
      this.redirectToFinanceHistory.includes(notification.code) ||
      this.redirectToProjects.includes(notification.code) ||
      this.redirectToProjectPage.includes(notification.code) ||
      this.redirectFinanceDetails.includes(notification.code);
  }

  public get noNotifications(): boolean {
    return !this.notifications.today.length && !this.notifications.yesterday.length && !this.notifications.older.length;
  }

  public deleteNotification(notification): void {
    this.notificationService.deleteNotification(notification.id).subscribe(
      () => {
        this.notifications.today = this.notifications.today.filter(item => item.id !== notification.id);
        this.notifications.yesterday = this.notifications.yesterday.filter(item => item.id !== notification.id);
        this.notifications.older = this.notifications.older.filter(item => item.id !== notification.id);

        this.delete.emit(notification);
        if (!notification.viewed) {
          this.ack.emit();
        }
      }
    );
  }

  public markOneAsRead(notification: NotificationV2): void {
    notification.viewed = true;
    this.notificationService.markOneAsRead(notification.id).subscribe(() => {
      this.ack.emit();
    });
  }
}
