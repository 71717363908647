import { TptControlDirective } from '../../directives/tpt-control.directive';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StripeCardModel } from '../../../../models/financev2/stripe-card.model';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-cards-select',
  templateUrl: './cards-select-control.component.html',
  styleUrls: [ './cards-select-control.component.scss' ],
})
export class CardsSelectControlComponent extends TptControlDirective {

  @Input()
  public stripeCards: StripeCardModel[];

  @Output() addPaymentMethod: EventEmitter<void> = new EventEmitter();

  public svgIconsEnum = SvgIconsEnum;

  public get errorState(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  public getSelectedCard(): StripeCardModel {
    if (!this.stripeCards || !this.stripeCards.length) { return; }
    return this.stripeCards.find((card: StripeCardModel) => card.id === this.control.value);
  }

  public getCardBrandIcon(stripeCard: StripeCardModel): SvgIconsEnum {
    return stripeCard.card.brand === 'visa' ? SvgIconsEnum.VisaGrey : SvgIconsEnum.MastercardGrey;
  }

}
