import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root',
})
export class IdleService {

  public DEFAULT_IDLE_TIMEOUT = 15; // seconds
  public DEFAULT_SESSION_EXPIRATION_TIMEOUT = 60; // minutes

  constructor(private idle: Idle) {
    this.idle.setIdleName('default');
  }

  public startIdle(timeout) {
    this.stopIdle();
    this.idle.setIdle(this.DEFAULT_IDLE_TIMEOUT); // how much seconds of idle have to be passed before timeout counting starts
    this.idle.setTimeout((timeout ? timeout : this.DEFAULT_SESSION_EXPIRATION_TIMEOUT) * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => {
      this.logout();
    });
    this.idle.watch();
  }

  public stopIdle() {
    this.idle.stop();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
  }

  private logout() {
    LogoutService.logout();
  }
}
