import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { FinanceServiceV2 } from '../../services/finance.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { AccountModel } from '../../../../models/financev2/account.model';
import { DepositFundsForm } from '../../forms/deposit-funds.form';
import { DecorateUntilDestroy } from '../../../../helpers/rxjs/take-until-destroyed';
import { AddPaymentMethodDialogComponent } from '../add-payment-method-dialog/add-payment-method-dialog.component';
import { timer } from 'rxjs';
import { MatDialogConfig } from '@angular/material/dialog';
import { NewWsService } from '../../../../services/new-ws.service';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { TxTypeEnum } from '../../../finance/models/transaction.model';

enum PaymentStateEnum {
  Initial,
  RequestInProgress,
  DepositInProgress,
  Success,
  Error,
}

@DecorateUntilDestroy()
@Component({
  selector: 'tpt-deposit-funds-dialog',
  templateUrl: './deposit-funds-dialog.component.html',
  styleUrls: [ './deposit-funds-dialog.component.scss' ],
})
export class DepositFundsDialogComponent implements OnDestroy {

  @Input() isPaymentFlow: boolean;

  @Output() depositIsSuccess: EventEmitter<void> = new EventEmitter();
  @Output() depositFailed: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly dialogClose = new EventEmitter<void>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(AddPaymentMethodDialogComponent)
  public addPaymentMethodDialog: AddPaymentMethodDialogComponent;

  public accounts: AccountModel[];

  public usdAccount: AccountModel;

  public svgIconsEnum = SvgIconsEnum;

  public depositFundsForm: DepositFundsForm;

  public paymentStateEnum = PaymentStateEnum;

  public paymentState: PaymentStateEnum = PaymentStateEnum.Initial;

  public jobId: number;

  public transactionId: number;

  public transactionIdFromSocket: number;

  public canBeClosed: boolean;

  private className = 'tpt-deposit-funds-dialog';

  private config: MatDialogConfig = {
    width: '520px',
    height: '360px',
  };

  constructor(
    private financeServiceV2: FinanceServiceV2,
    private spinnerService: SpinnerService,
    private ws: NewWsService,
  ) { }

  ngOnDestroy(): void {
  }

  public open(jobId?: number): void {
    this.paymentState = PaymentStateEnum.Initial;
    if (!this.financeServiceV2.accounts) {
      this.spinnerService.startSpinner();
      this.financeServiceV2.getAccount().subscribe(this.handleAccounts);
    } else {
      this.accounts = this.financeServiceV2.accounts;
      this.usdAccount = this.financeServiceV2.usdAccount;
      this.jobId = jobId;
      this.depositFundsForm = DepositFundsForm.createForm(this.usdAccount.id);
      this.dialog.config = this.config;
      this.dialog.open(this.className);
    }

    this.ws.operationSuccess.subscribe((res) => {
      if (res?.length) {
        const tx = res.find(item => item.tx?.id === this.transactionId);

        if (tx?.tx?.status === 'success') {
          this.transactionIdFromSocket = tx.id;
          this.paymentState = PaymentStateEnum.Success;
        }
      }
    });
  }

  public close = (): void => {
    this.dialogClose.emit();
    this.dialog.close();
  }

  public closeIfSuccess = (): void => {
    this.close();

    if (this.isPaymentFlow) {
      this.depositIsSuccess.emit();
    }
  }

  public depositFunds = (): void => {
    if (!this.depositFundsForm.validate()) {
      return;
    }

    this.paymentState = PaymentStateEnum.RequestInProgress;

    this.financeServiceV2.depositFunds(this.depositFundsForm, this.jobId).subscribe((res) => {
        if (res.txType === TxTypeEnum.DEPOSIT) {
          this.transactionId = res.id;
        }
        this.paymentState = PaymentStateEnum.DepositInProgress;

        setTimeout(() => {
          if (this.paymentState === PaymentStateEnum.DepositInProgress) {
            this.canBeClosed = true;

            this.financeServiceV2.checkTransaction(res.id).subscribe(response => {
              if (response?.length) {
                const tx = response.find(item => item.tx?.id === this.transactionId);

                if (tx?.tx?.status === 'success') {
                  this.transactionIdFromSocket = tx.id;
                  this.paymentState = PaymentStateEnum.Success;
                }
              }
            });
          }
        }, 10000);
      },
      () => { this.handleDepositFailure(); });
  }

  public openAddPaymentMethod(): void {
    this.addPaymentMethodDialog.open();
  }

  public handleCardAdded(): void {
    this.spinnerService.startSpinner();
    // this timer was added so that the Stripe info could be updated
    timer(2000).subscribe(() => {
      this.financeServiceV2.getAccount().subscribe((accounts: AccountModel[]) => {
        this.spinnerService.stopSpinner();
        this.accounts = accounts;
        this.usdAccount = this.financeServiceV2.usdAccount;
      });
    });
  }

  private handleAccounts = (accounts: AccountModel[]): void => {
    this.accounts = accounts;
    this.usdAccount = this.financeServiceV2.usdAccount;
    this.depositFundsForm = DepositFundsForm.createForm(this.usdAccount.id);
    this.spinnerService.stopSpinner();
    this.dialog.open(this.className);
  }

  private handleDepositFailure = (): void => {
    this.paymentState = PaymentStateEnum.Error;
  }

}
