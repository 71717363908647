import {Type} from 'class-transformer';
import {RResource} from './rResource';

export class RProject {
  public id: number;
  public name: string;

  @Type(() => RResource)
  public rResources: RResource[] = [];

}
