import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../../dialog/components/dialog/dialog.component';
import { ProjectSearchItemModel } from '../../../../../models/search/projectSearchItem.model';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { ProfileService } from '../../../../../services/profile.service';
import { SpinnerService } from '../../../../tpt-ui/services/spinner.service';
import { SearchService } from '../../../../../services/search.service';
import { ChatApiService } from '../../../../../services/chat.api.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../../services/language.service';
import { Language } from '../../../../../models/language';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'project-details-dialog',
  templateUrl: './project-details-dialog.component.html',
  styleUrls: ['./project-details-dialog.component.scss']
})
export class ProjectDetailsDialogComponent implements OnInit, OnDestroy {
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public project: ProjectSearchItemModel;

  public svgIconsEnum = SvgIconsEnum;

  public languagesArray: Array<Language> = [];

  private className = 'project-details-dialog';

  private readonly destroy$ = new Subject();

  private config: MatDialogConfig = {
    width: '1000px',
    minHeight: '200px',
    maxHeight: '100vh',
  };

  constructor(private profileService: ProfileService,
              private spinner: SpinnerService,
              private searchService: SearchService,
              private chatApiService: ChatApiService,
              private translate: TranslateService,
              private languageService: LanguageService,
  ) {
  }

  get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  public get placeholderIcon(): string {
    return `/assets/icons/${SvgIconsEnum.AssigneePlaceholder}.svg`;
  }

  ngOnInit() {
    this.languageService.getLanguagesV2().pipe(takeUntil(this.destroy$))
      .subscribe((langs: Language[]) => this.languagesArray = langs);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public open(project): void {
    this.project = project;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public sendProposal(project: ProjectSearchItemModel) {
    this.spinner.startSpinner();
    this.searchService.sendProposal(project.id)
      .then(res => {
        if (res) {
          project.currentUserState = 'PROPOSAL_SUBMITTED';
          project.chatId = res.id;
          this.spinner.stopSpinner();
        }
      })
      .catch(this.spinner.stopSpinner);
  }

  public cancelProposal(project: ProjectSearchItemModel) {
    this.spinner.startSpinner();
    if (!project.chatId) { return; }

    this.chatApiService.decline(project.chatId).subscribe(
      () => {
        project.currentUserState = '';
        this.spinner.stopSpinner();
      },
      this.spinner.stopSpinner
    );
  }

  public getLangByCode(lang): string {
    const language = this.languagesArray?.find(item => item.code === lang.code);
    return this.getTranslation(language);
  }

  public getTranslation(item): string {
    if (!item) {return; }
    if (this.translate.currentLang === 'ru') {
      return item?.nameRu;
    }
    return item?.name;
  }
}
