import { Injectable } from '@angular/core';
import { FinancesApiService } from '../../../services/finances.api.service';
import { AccountModel } from '../../../models/financev2/account.model';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, share, switchMap, tap } from 'rxjs/operators';
import { SetupIntentResponseModel } from '../../../models/financev2/setup-intent-response.model';
import { ProviderEnum } from '../../../types/provider.enum';
import { ProviderInfoModel } from '../../../models/financev2/provider-info.model';
import { DepositFundsForm } from '../forms/deposit-funds.form';
import { FinanceCommonInfoModel } from '../../finance/models/finance-common-info.model';
import { WithdrawFundsForm } from '../forms/withdraw-funds.form';
import { FinanceHistoryListFilterModel } from '../../finance/models/finance-history-list-filter.model';
import { FinanceHistoryListItemModel } from '../../finance/models/finance-history-list-item.model';
import * as moment from 'moment';
import { IPayoneerReleasePayee } from '../../../models/payoneer/payoneer-release-payee.interface';
import { CardDetachRequestModel } from '../../finance/models/card-detach-request.model';
import { IPayoneerRegLink } from '../../../models/payoneer/payoneer-reg-link.interface';
import { SpinnerService } from '../../tpt-ui/services/spinner.service';
import { CurrencyEnum } from 'src/app/models/currency.enum';
import { TransactionModel } from '../../finance/models/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class FinanceServiceV2 {
  public subject = new BehaviorSubject<void>(null);

  public financeCommonInfo: Observable<FinanceCommonInfoModel[]>;
  public balanceUpdated$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public isLoading = false;

  private financeData$: Observable<any>;

  public get accounts(): AccountModel[] | null {
    return this._accounts;
  }

  public get usdAccount(): AccountModel {
    return this._accounts && this.accounts.filter(it => it.currency.code === CurrencyEnum.USD)[0];
  }

  private _accounts: AccountModel[];

  constructor(
    private financesApiService: FinancesApiService,
    private spinnerService: SpinnerService,

  ) {
    this.financeCommonInfo = this.subject.asObservable().pipe(
      tap(() => this.isLoading = true),
      tap(() => this.balanceUpdated$.next()),
      switchMap(this.getCommonInfo),
      tap(() => this.isLoading = false),
      catchError((error) => {
        this.spinnerService.stopSpinner();
        this.isLoading = false;
        return throwError(error);
      })
    );
  }

  public getAccount = (): Observable<AccountModel[]> => {
    if (this.financeData$) {
      return this.financeData$;
    } else {
      this.financeData$ = this.financesApiService.getFinances().pipe(
        tap((accounts: AccountModel[]) => this._accounts = accounts),
        share()
      );
      return this.financeData$;
    }
  }

  public getCommonInfo = (): Observable<FinanceCommonInfoModel[]> => {
    return this.financesApiService.getCommonInfo();
  }

  public getHistoryOperations = (filter?: FinanceHistoryListFilterModel): Observable<FinanceHistoryListItemModel[]> => {
    if (filter && filter.from) {
      filter.from = moment(filter.from).format('YYYY-MM-DD');
    }

    if (filter && filter.to) {
      filter.to = moment(filter.to).format('YYYY-MM-DD');
    }
    return this.financesApiService.getHistoryOperations(filter);
  }

  public getProviderInfo = (providerId: ProviderEnum): Observable<ProviderInfoModel> => {
    return this.financesApiService.getProviderInfo(providerId);
  }

  public setupIntent = (accountId: number): Observable<SetupIntentResponseModel> => {
    return this.financesApiService.setupIntent(accountId);
  }

  public setMainAccount = (accountId: number): Observable<any> => {
    return this.financesApiService.setMainAccount(accountId);
  }

  public depositFunds = (form: DepositFundsForm, jobId?: number): Observable<TransactionModel> => {
    const model = form.getFormData();
    model.amount = Number(model.amount);

    if (jobId) {
      model.jobId = jobId;
    }
    return this.financesApiService.depositFunds(model);
  }

  public checkTransaction = (id: number): Observable<any> => {
    return this.financesApiService.checkTransaction(id);
  }

  public withdrawFunds = (form: WithdrawFundsForm): Observable<any> => {
    const model = form.getFormData();
    model.amount = Number(model.amount);
    return this.financesApiService.withdrawFunds(model);
  }

  public releasePayoneerPayee(accountId: number): Observable<IPayoneerReleasePayee> {
    return this.financesApiService.releasePayoneerPayee(accountId);
  }

  public cardDetach = (accountId: number, cardId: string): Observable<SetupIntentResponseModel> => {
    const model = new CardDetachRequestModel(accountId, cardId);
    return this.financesApiService.cardDetach(model);
  }

  public requestPayoneerRegLink = (accountId: number): Observable<IPayoneerRegLink> => {
    return this.financesApiService.requestPayoneerRegLink(accountId);
  }

  public cardAdded = (): Observable<any> => {
    return this.financesApiService.cardAdded();
  }
}
