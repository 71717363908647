<div *ngIf="count > 0" class="file-viewer">

  <div class="file-viewer__container" [class.centered]="!displayUploadedElements">
    <div class="file-viewer__header">
      <mat-icon class="file-viewer__header-icon">attach_file</mat-icon>
      <span class="file-viewer__header-text">Attached files ({{ count }})</span>
    </div>
    <div class="file-viewer__file-container-wrapper">
      <ng-container *ngIf="displayUploadedElements">
        <ng-container *ngFor="let file of uploadedFiles; let i = index">
          <xln-file *ngIf="!hidden[i]"
                    [file]="file"
                    [allowDelete]="allowDelete"
                    [allowAddVersion]="allowAddVersion"
                    [allowRename]="allowRename"
                    [showDate]="showDate"
                    [showVersion]="showVersion"
                    [showFrom]="showFrom"
                    [showHistory]="showHistory"
                    [diffKey]="diffKey"
                    (fileChange)="onFileChange($event, i)">
          </xln-file>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
