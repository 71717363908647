import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SimpleProjectHourlyPaymentModel } from '../../employer/models/simple-project-hourly-payment.model';
import { ProjectSearchItemModel } from '../../../models/search/projectSearchItem.model';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { CurrencySymbol } from '../../../models/currency.enum';
import { CategoryService } from '../../../services/category.service';
import { CategoryV2Model } from '../../../models/category-v2.model';
import { Language } from '../../../models/language';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'project-details-card',
  templateUrl: './project-details-card.component.html',
  styleUrls: ['./project-details-card.component.scss']
})
export class ProjectDetailsCardComponent implements OnInit {

  @Input() project: ProjectSearchItemModel;
  public svgIconsEnum = SvgIconsEnum;

  public currencySymbol = CurrencySymbol;

  public languagesArray: Array<Language> = [];

  private categories: CategoryV2Model[] = [];

  constructor(private translate: TranslateService,
              private languageService: LanguageService,
              private categoriesService: CategoryService) {
  }

  async ngOnInit() {
    await this.getCategories();
    await this.getLanguages();
  }

  async getCategories(): Promise<void> {
    this.categories = await this.categoriesService.getCategoriesV2().toPromise();
  }

  async getLanguages(): Promise<void> {
    this.languagesArray = await this.languageService.getLanguagesV2().toPromise();
  }

  public getTranslation(item): string {
    if (!item) {
      return;
    }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public getProjectCategory() {
    if (!this.project || !this.categories.length) {
      return;
    }
    const category = this.categories.find(c => c.id === this.project.parentCategoryId);
    this.project.category = category;
    return `${this.getTranslation(category)}/`;
  }

  public getProjectSubCategory() {
    if (!this.project || !this.categories.length) {
      return;
    }
    const category = this.categories.find(c => c.id === this.project.parentCategoryId);
    const subCategory = category.children.find(c => c.id === this.project.subCategoryId);
    this.project.subCategory = subCategory;
    return this.getTranslation(subCategory);
  }

  public get hourlyProjectTotalCost(): string {
    if (!this.project) {
      return '';
    }

    const { hoursPerWeek, hourlyRate } = this.project.details as SimpleProjectHourlyPaymentModel;
    return (hoursPerWeek * hourlyRate).toString(10);
  }

  public getLangByCode(lang): string {
    const language = this.languagesArray?.find(item => item.code === lang.code);
    return this.getTranslation(language);
  }
}
