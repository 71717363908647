import { Directive, ElementRef, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../../helpers/rxjs/take-until-destroyed';

@DecorateUntilDestroy()
@Directive({
  selector: '[tptInputMin]'
})
export class InputMinValidatorDirective implements OnInit, OnDestroy {

  @Input()
  public min = 1;

  private keyPressSubscription: Subscription;

  constructor(
    @Optional() @Self() public input: ElementRef<HTMLInputElement>
  ) { }

  ngOnInit() {
    this.keyPressSubscription = fromEvent(this.input.nativeElement, 'keypress').pipe(
      takeUntilDestroyed(this),
    ).subscribe(this.handleKeyPress);
    fromEvent(this.input.nativeElement, 'paste').subscribe(this.handlePaste);
  }

  ngOnDestroy() { }

  private handleKeyPress = (event: Event): void => {
    if (this.min > 0 && (event as any).key === '-') {
      event.preventDefault();
    }
  }

  private handlePaste = (event: Event): void => {
    const clipboardData = (event as any).clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    if (pastedText.indexOf('-') !== -1) {
      event.preventDefault();
    }
  }

}
