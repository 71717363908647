import { FormContext } from '../../tpt-forms/base/form.context';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddTaskFormModel } from '../models/add-task-form.model';
import { ProjectDetailedTaskModel } from '../models/project-detailed-task.model';

export class AddTaskForm extends FormContext<AddTaskFormModel> {

  static createForm(task: ProjectDetailedTaskModel, isFixed: boolean): AddTaskForm {
    const form = new FormGroup({
      id: new FormControl(task.id),
      taskTitle: new FormControl(task.taskTitle, [Validators.required]),
      description: new FormControl(task.description),
      fileIds: new FormControl(task.files || []),
      status: new FormControl(task.status),
      deadline: new FormControl(task.deadline),
      jobId: new FormControl(task.jobId),
      job: new FormControl({details: task.jobDetails}, [Validators.required]),
      priority: new FormControl(task.priority || 'MEDIUM'),
    });

    const deadlineValidators = isFixed ? [Validators.required] : null;
    form.get('deadline').setValidators(deadlineValidators);

    return new AddTaskForm(form, task);
  }

  public get id(): AbstractControl {
    return this.getControl('id');
  }

  public get taskTitle(): AbstractControl {
    return this.getControl('taskTitle');
  }

  public get description(): AbstractControl {
    return this.getControl('description');
  }

  public get fileIds(): AbstractControl {
    return this.getControl('fileIds');
  }

  public get status(): AbstractControl {
    return this.getControl('status');
  }

  public get deadline(): AbstractControl {
    return this.getControl('deadline');
  }

  public get jobId(): AbstractControl {
    return this.getControl('jobId');
  }

  public get job(): AbstractControl {
    return this.getControl('job');
  }

  public get priority(): AbstractControl {
    return this.getControl('priority');
  }

  public get isEditForm(): boolean {
    return Boolean(this.data && this.data.id);
  }

  constructor(
    public form: FormGroup,
    public data: any,
  ) {
    super(form, data);
  }

  public getFormData(): AddTaskFormModel {
    return this.form.value;
  }

}
