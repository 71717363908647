import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteHelper {

  static EMPTY_PATH = '';

  static PROJECTS_DASHBOARD_PATH = 'jobs';

  static FREELANCER_PROJECTS_DASHBOARD_PATH = 'my-projects';

  static PROJECTS_DASHBOARD_COMPLETED_PATH = 'completed';

  static CONTRACTS_PATH = 'contracts';

  static NOTIFICATIONS = 'notifications';

  static SETTINGS_PATH = 'settings';

  static SETTINGS_GENERAL_PATH = 'general';

  static SETTINGS_CONTACTS_PATH = 'contacts';

  static SETTINGS_SPECIALIZATION_PATH = 'specialization';

  static SETTINGS_LEGAL_STATUS_PATH = 'legal-status';

  static SETTINGS_SECURITY_PATH = 'security';

  static SETTINGS_FEEDBACK_PATH = 'feedback';

  static CONTRACTS_PROPOSALS_PATH = 'proposal';

  static CONTRACTS_PROPOSALS_URL = 'proposal/:id';

  static CONTRACTS_CHATS_PATH  = 'chat';

  static CONTRACTS_CHATS_URL = 'chat/:id';

  static CONTRACTS_EMPLOYER = 'employer';

  static CONTRACTS_FREELANCER = 'freelancer';

  static PROJECTS_DASHBOARD_DRAFTS_PATH = 'drafts';

  static PROJECT_URL = ':id';

  static TRACKER = 'task-tracker';

  static TEAM = 'team';

  static ACCESS = 'access';

  static USERS = 'users';

  static ROLES = 'roles';

  static EDIT_PROJECT_PATH = 'edit-project';

  static EDIT_PROJECT_URL = 'edit-project/:id';

  static FINANCE_PATH = 'finance';

  static FINANCE_COMMON_PATH = 'common';

  static FINANCE_HISTORY_PATH = 'transactions-history';

  static FINANCE_PAYMENT_DETAILS_PATH = 'payment-details';

  static MAKE_DEPOSIT_PATH = 'accountDeposit';

  static SEARCH_PATH = 'search';

  static PROJECTS = 'projects';

  static FREELANCERS = 'freelancers';

  static CREATE_JOB = 'new-job';

  static USER_PAGE = 'profiles';

  static PROJECTS_PAGE = 'projects';

  public get home(): Array<any> {
    return [RouteHelper.EMPTY_PATH];
  }

  public get finances(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
    ];
  }

  public get financesCommon(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
      RouteHelper.FINANCE_COMMON_PATH,
    ];
  }

  public get financesTransactions(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
      RouteHelper.FINANCE_HISTORY_PATH,
    ];
  }

  public get financesPaymentDetails(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
      RouteHelper.FINANCE_PAYMENT_DETAILS_PATH,
    ];
  }

  public get employeerProjectsDashboard(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
    ];
  }

  public get freelancerProjectsDashboard(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH,
    ];
  }

  public get employeerProjectsCompleted(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      RouteHelper.PROJECTS_DASHBOARD_COMPLETED_PATH,
    ];
  }

  public get freelancerProjectsCompleted(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH,
      RouteHelper.PROJECTS_DASHBOARD_COMPLETED_PATH,
    ];
  }

  public get employeerProjectsDrafts(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      RouteHelper.PROJECTS_DASHBOARD_DRAFTS_PATH,
    ];
  }

  public get employeerCreateProject(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      RouteHelper.EDIT_PROJECT_PATH,
    ];
  }

  public get makeDeposit(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
      RouteHelper.MAKE_DEPOSIT_PATH,
    ];
  }

  public employeerEditProject(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      RouteHelper.EDIT_PROJECT_PATH,
      id,
    ];
  }

  public employerProjectPage(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
    ];
  }

  public newJobPage(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TEAM,
      RouteHelper.CREATE_JOB
    ];
  }

  public employerProjectPageTeam(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TEAM
    ];
  }

  public employerProjectTaskTracker(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TRACKER
    ];
  }

  public employerProjectPageAccess(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.ACCESS
    ];
  }

  public employerAccessPageUsers(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.ACCESS,
      RouteHelper.USERS
    ];
  }

  public employerAccessPageRoles(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.ACCESS,
      RouteHelper.ROLES
    ];
  }

  public freelancerProjectPage(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH,
      id,
    ];
  }

  public freelancerProjectPageTeam(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TEAM
    ];
  }

  public freelancerProjectTaskTracker(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TRACKER
    ];
  }

  public freelancerProjectPageAccess(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.ACCESS
    ];
  }

  // contracts routes for employers
  public get employerContracts(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_EMPLOYER,
    ];
  }

  public get contractsProposals(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_EMPLOYER,
      RouteHelper.CONTRACTS_PROPOSALS_PATH,
    ];
  }

  public contractsProposalsUrl(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_EMPLOYER,
      RouteHelper.CONTRACTS_PROPOSALS_PATH,
      id,
    ];
  }

  public get contractsChats(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_EMPLOYER,
      RouteHelper.CONTRACTS_CHATS_PATH,
    ];
  }

  public contractsChatsUrl(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_EMPLOYER,
      RouteHelper.CONTRACTS_CHATS_PATH,
      id,
    ];
  }

  // contracts routes for freelancers
  public get frContracts(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_FREELANCER,
    ];
  }

  public get frContractsProposals(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_FREELANCER,
      RouteHelper.CONTRACTS_PROPOSALS_PATH,
    ];
  }

  public frContractsProposalsUrl(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_FREELANCER,
      RouteHelper.CONTRACTS_PROPOSALS_PATH,
      id,
    ];
  }

  public get frContractsChats(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_FREELANCER,
      RouteHelper.CONTRACTS_CHATS_PATH,
    ];
  }

  public frContractsChatsUrl(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_FREELANCER,
      RouteHelper.CONTRACTS_CHATS_PATH,
      id,
    ];
  }

  public get settings(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
    ];
  }

  public get settingsGeneral(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_GENERAL_PATH,
    ];
  }

  public get settingsSpecialization(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_SPECIALIZATION_PATH,
    ];
  }

  public get settingsLegalStatus(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_LEGAL_STATUS_PATH,
    ];
  }

  public get settingsContacts(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_CONTACTS_PATH,
    ];
  }

  public get settingsSecurity(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_SECURITY_PATH,
    ];
  }

  public get settingsFeedback(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_FEEDBACK_PATH,
    ];
  }

  public get search(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SEARCH_PATH,
    ];
  }

  public get projectSearch(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SEARCH_PATH,
      RouteHelper.PROJECTS,
    ];
  }

  public get freelancerSearch(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SEARCH_PATH,
      RouteHelper.FREELANCERS,
    ];
  }

  public get notifications(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.NOTIFICATIONS
    ];
  }

  public userPage(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.USER_PAGE,
      id
    ];
  }

  public projectPage(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_PAGE,
      id
    ];
  }
}
