import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService, TokenData } from '../services/auth.service';
import { ProfileService } from '../services/profile.service';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private socialInit = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService,
              private socialAuthService: SocialAuthService,
              private profileService: ProfileService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('Authorization') && !req.headers.has(InterceptorSkipHeader)) {
      const token = localStorage.getItem(TOKEN_KEY);

      const headers = req.headers;

      if (token) {
        const lang = localStorage.getItem('currentLang') || 'en';
        const authHeader = `Bearer ${token}`;
        return next.handle(req.clone({
          headers: req.headers.set('Authorization', authHeader)
            .set('Accept-Language', lang)
        }))
          .pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401Error(req, next);
            }

            return throwError(error);
          }));
      } else {
        return next.handle(req.clone({headers}));
      }
    }
    return next.handle(req);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = localStorage.getItem(REFRESHTOKEN_KEY);

      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((data: TokenData) => {
            this.isRefreshing = false;

            this.profileService.updateToken(data);
            this.refreshTokenSubject.next(data.access_token);

            return next.handle(this.addTokenHeader(request, data.access_token));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.profileService.logout();
            return throwError(err);
          })
        );
      } else {
        if (this.socialInit) {
          this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then();
        }

        this.socialAuthService.initState.subscribe((res) => {
          if (!this.socialInit) {
            this.socialInit = res;
            this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then();
          }
        });

        this.socialAuthService.authState.subscribe((res: SocialUser) => {
          if (res) {
            this.isRefreshing = false;
            localStorage.setItem(TOKEN_KEY, res.idToken);
            this.refreshTokenSubject.next(res.idToken);
            return next.handle(this.addTokenHeader(request, res.idToken));
          }
        });
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({headers: request.headers.set('Authorization', 'Bearer ' + token)});
  }
}
