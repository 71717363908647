import { FormContext } from '../modules/tpt-forms/base/form.context';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class LoginForm extends FormContext<{ username: string, password: string }> {

  static createForm(): LoginForm {
    const form = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });

    return new LoginForm(form);
  }

  public get username(): AbstractControl {
    return this.getControl('username');
  }

  public get password(): AbstractControl {
    return this.getControl('password');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): { username: string; password: string } {
    return this.form.value;
  }

}
