import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { Role } from '../classes/enums';
import { RouteHelper } from '../helpers/route.helper';
import { environment } from '../../environments/environment';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private profileService: ProfileService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const currentUser = JSON.parse(localStorage.getItem(USER_KEY));

    const role = currentUser?.role || null;

    if (!currentUser) {
      let url = route.data.externalUrl;
      if (!environment.production && environment.currentFlow === 'dev') {
        url = `https://x-lance.com${route.data.externalUrl}`;
      }
      window.location.href = url;
      return true;
    }

    if (currentUser && role === Role.EMPLOYER) {
      this.router.navigate([RouteHelper.PROJECTS_DASHBOARD_PATH]);
      return false;
    }

    if (currentUser && role === Role.FREELANCER) {
      this.router.navigate([RouteHelper.SEARCH_PATH]);
      return false;
    }

  }
}
