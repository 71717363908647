import { BaseService } from './base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { Button } from '../classes/button';

export abstract class BaseProjectService extends BaseService {

  protected constructor(protected http: HttpClient,
                        protected alertService: AlertService) {
    super(http);
  }

  public postWithCheck<T>(url: string, data: any, options?: {}): Promise<T> {
    return this.executePost<T>(url, data, options)
      .catch((error) => {
        return this.confirmation(error)
          .then(() => {
            return this.executePost<T>(url, data, this.prepareOptions(options));
          });
      });
  }

  public putWithCheck<T>(url: string, data: any, options?: {}): Promise<T> {
    return this.executePut<T>(url, data, options).toPromise()
      .catch((error) => {
        return this.confirmation(error)
          .then(() => {
            return this.executePut<T>(url, data, this.prepareOptions(options)).toPromise();
          });
      });
  }

  public deleteWithCheck<T>(url: string, options?: {}): Promise<T> {
    return this.executeDelete<T>(url, options)
      .catch((error) => {
        return this.confirmation(error)
          .then(() => {
            return this.executeDelete<T>(url, this.prepareOptions(options));
          });
      });
  }

  protected confirmation(error: any): Promise<any> {
    if (error && error.message === 'SERVER_ERROR.CANT_MODIFY_PROJECT_EXIST_SUGGESTION') {
      return this.showConfirmation();
    } else {
      return Promise.reject(error);
    }
  }

  protected prepareOptions(options?: {}) {
    return Object.assign({
      params: {
        recreateSuggestions: true
      }
    }, options);
  }

  private showConfirmation(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alertService.showCustomAlert('PROJECT.MODIFY_PROJECT_EXIST_SUGGESTION', [
        Button.CancelButton(),
        new Button('BUTTON.PROCEED', 'warn', true),
      ], () => {
        resolve();
      });
    });
  }

}
