import { Injectable } from '@angular/core';
import { BaseService } from '../../../../services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NoteItemModel } from '../../models/project-notes/note-item.model';
import { CreateProjectNoteRequestModel } from '../../models/project-notes/create-project-note-request.model';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable()
export class NotesApiService extends BaseService {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getNotes = (projectId: number): Observable<Array<NoteItemModel>> => {
    const params = { params: { projectId }};
    return this.executeGet('v2/notes', params);
  }

  public createNote = (model: CreateProjectNoteRequestModel): Observable<NoteItemModel> => {
    return fromPromise(this.executePost('v2/notes', model));
  }

  public deleteNote = (id: number): Observable<void> => {
    const params = { params: { id }};
    return fromPromise(this.executeDelete('v2/notes', params));
  }

}
