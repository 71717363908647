<div *ngIf="categories$ | async as categories" class="wrapper">
  <div class="sort">
    <h2 class="sort-title">{{'SEARCH.SORT.SORT_BY' | translate}}</h2>
    <mat-form-field class="filtered-page__filters-field" floatLabel="never" appearance="none">
      <mat-select placeholder="{{'SEARCH.SORT.PUBLISH_DATE_DESC' | translate }}"
                  (valueChange)="emitSortChange.emit($event)" name="sort">
        <mat-option *ngFor='let sort of sortList' [value]="sort">
          {{'SEARCH.SORT.' + sort | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="search-row">
    <mat-icon class="search-icon" [svgIcon]="svgIconsEnum.Search"></mat-icon>
    <input [formControl]="searchControl"
           matInput
           class="search-input"
           placeholder="{{'SEARCH.PROJECT_SEARCH_PLACEHOLDER' | translate}}"
           (keyup.enter)="searchProject()"
    >
    <tpt-button type="primary" (onClick)="searchProject()">
      {{'BUTTON.SEARCH' | translate}}
    </tpt-button>
  </div>

  <ng-container *ngIf="!showSkeleton; else projectsLoader">
    <ng-container *ngIf="projects$.length; else notFound">
      <div *ngFor="let project of projects$" class="mb-16">
        <mat-card (click)="openCardDetails(project)">
          <div class="grid-card">
            <div class="left">
              <div class="category-block">
                <div class="category" *ngIf="project?.parentCategoryId">{{getProjectCategory(project, categories)}}
                  <span class="subcategory"
                        *ngIf="project?.subCategoryId">/{{getProjectSubCategory(project, categories)}}</span>
                </div>
                <div class="publication-time-ago">
                  <span class="caption">{{'SEARCH.PUBLISHED' | translate}} {{project.publishDate | timeAgo}}</span>
                </div>
              </div>
              <div class="headline name">{{project?.projectInfo?.name}}</div>
              <div class="regular description">
                {{project?.detailsOpened ? project?.projectInfo?.description : (project?.projectInfo?.description | tptTruncate: 220)}}
                <span *ngIf="project?.projectInfo?.description?.length > 220 && !project?.detailsOpened"
                      class="link-style">
              {{'NOTIFICATION_CONTENT_COMPONENT.SHOW_ALL' | translate}}</span>
              </div>
              <div *ngIf="project?.projectInfo?.files?.length && project?.detailsOpened">
                <div class="caption mb-4">{{'CONTRACTS.DOCS' | translate}}</div>

                <div class="mb-8" *ngFor="let file of project?.projectInfo?.files">
                  <tpt-uploaded-file [file]="file" (click)="$event.stopPropagation()"></tpt-uploaded-file>
                </div>
              </div>

              <ng-container *ngIf="!project?.detailsOpened">
                <div class="main-info" *ngIf="project?.details">
                  <div *ngIf='project.details.paymentMethod' class="payment-method sub-main-info">
                    <p class="main-info-category">{{'SEARCH.OPTIONS.PAYMENT_METHOD' | translate}}</p>
                    <p class="main-info-value">{{'SEARCH.PAYMENT.' + project.details.paymentMethod | translate}}</p>
                  </div>

                  <div *ngIf='project.details.paymentMethod === "FIXED" && project.details.tillDate'
                       class="payment-period sub-main-info">
                    <p class="main-info-category">{{'FINANCE.COLUMN.DEADLINE' | translate}}</p>
                    <p class="main-info-value">{{project.details.tillDate | localDate:'dd.MM.yyyy'}}</p>
                  </div>

                  <div *ngIf='project.details.paymentMethod === "HOURLY" && project.details.paymentPeriod'
                       class="payment-period sub-main-info">
                    <p class="main-info-category">{{'SEARCH.PAYMENT_PERIOD' | translate}}</p>
                    <p
                      class="main-info-value">{{'SEARCH.PAYMENT_PERIOD_OPTION.' + project.details.paymentPeriod | translate}}</p>
                  </div>

                  <div *ngIf='project.details.paymentMethod === "HOURLY" && project.details.hoursPerWeek'
                       class="hours-per-week sub-main-info">
                    <p class="main-info-category">{{'SEARCH.HOURS_PER_WEEK' | translate}}</p>
                    <p class="main-info-value">{{project.details.hoursPerWeek}}</p>
                  </div>

                  <div *ngIf='project.country && project.country.name' class="country-name sub-main-info">
                    <p class="main-info-category">{{'SEARCH.OPTIONS.FREELANCERS_LOCATION' | translate}}</p>
                    <p class="main-info-value">{{getTranslation(project.country)}}</p>
                  </div>

                  <div *ngIf='!project.country || !project.country.name' class="country-name sub-main-info">
                    <p class="main-info-category">{{'SEARCH.OPTIONS.FREELANCERS_LOCATION' | translate}}</p>
                    <p class="main-info-value">{{'SEARCH.ANY' | translate}}</p>
                  </div>
                </div>
                <div class="skills-wrap">
                  <div class="skills pseudo-chips" [class.filtered]="getChipStyle(skill)"
                       *ngFor="let skill of project.skills">
                    {{getTranslation(skill)}}
                  </div>
                  <div class="skills pseudo-chips" [class.filtered]="getChipStyle(skill)"
                       *ngFor="let skill of project.customSkills">
                    {{skill}}
                  </div>
                </div>
              </ng-container>

            </div>
            <div class="right">
              <div class="owner-wrapper">
                <div class="owner">
                  <div class="owner-info">
                    <div *ngIf="project.owner?.firstName || project.owner?.lastName" class="owner-fullname">
                      {{project.owner?.firstName}} {{project.owner?.lastName}}
                    </div>
                    <div class="owner-rating caption" *ngIf="project?.owner?.rating">
                      {{'SETTINGS.RATING' | translate}} {{ project.owner.rating | percent }}
                    </div>
                  </div>
                  <div class="avatar">
                    <tpt-avatar [textSizeRatio]="2.5" [user]="project?.owner"
                                size="32"></tpt-avatar>
                  </div>
                </div>

                <button mat-stroked-button
                        class="about-button"
                        (click)="$event.stopPropagation(); openEmployerInfo(project?.owner)"
                >{{'SEARCH.ABOUT_EMPLOYER' | translate}}</button>
              </div>

              <div *ngIf="!project?.detailsOpened">
                <div class="price-rate">
                  <p
                    *ngIf="project.details.paymentMethod === 'HOURLY'">{{currencySymbol[project?.currency?.code]}}{{project.details.hourlyRate}}
                    /{{'SEARCH.HOUR' | translate}}</p>
                  <p
                    *ngIf="project.details.paymentMethod === 'FIXED'">{{currencySymbol[project?.currency?.code]}}{{project.details.budget | number}}</p>
                </div>

                <div class="actions">
                  <div class="request-btn" *ngIf="!isEmployer">
                    <tpt-button type="accent" *ngIf="!project.currentUserState" class="send-request"
                                (click)="$event.stopPropagation(); sendProposal(project)"
                    >{{'SEARCH.SEND_REQUEST' | translate}}</tpt-button>
                    <div *ngIf="project.currentUserState === 'PROPOSAL_SUBMITTED'" class="request-sent"
                         (click)="$event.stopPropagation()">
                      {{'SEARCH.REQUEST_SENT' | translate}}
<!--                      <mat-icon [svgIcon]="svgIconsEnum.Check" class="check-icon"></mat-icon>-->
                    </div>
                    <div *ngIf="project.currentUserState === 'PARTICIPANT'" class="request-sent"
                         (click)="$event.stopPropagation()">
                      {{'SEARCH.IN_PROGRESS' | translate}}
                    </div>
                  </div>

                  <button mat-icon-button class="copy-btn"
                          (click)="$event.stopPropagation(); copyJobLink(project)">
                    <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>

    <ng-template #notFound>
      <div class="not-found">
        <div class="not-found-title">{{'SEARCH.NO_RESULTS_TITLE' | translate}}</div>
        <div class="not-found-subtitle">{{'SEARCH.NO_RESULTS_SUBTITLE' | translate}}</div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #projectsLoader>
    <ngx-skeleton-loader
      count="9"
      animation="progress"
      [theme]="{
          'border-radius': '4px',
          height: '264px',
          background: '#eff1f6',
          'box-shadow': '0 4px 4px rgba(0, 0, 0, 0.1)'
        }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>

<tpt-employer-info-dialog></tpt-employer-info-dialog>

<tpt-self-employed-warning-dialog></tpt-self-employed-warning-dialog>

<tpt-get-acquainted-dialog></tpt-get-acquainted-dialog>
