<div
  *ngIf="profileService.currentProfile"
  class="sidebar"
  [class.-extended]="extendedSidebar"
>
  <div
    class="sidebar-main"
    [class.-expanded]="expanded"
  >
    <div class="logo-and-menu">
      <a href="/landing">
        <mat-icon
          class="logo"
          [class.-hidden]="!expanded"
          [svgIcon]="svgIconsEnum.ExpandedLogo"
        ></mat-icon>
      </a>

      <a href="/landing">
        <mat-icon
          class="logo"
          [class.-hidden]="expanded"
          [svgIcon]="svgIconsEnum.SidebarLogo"
        ></mat-icon>
      </a>

      <div class="menu">
        <a [routerLink]="projectsRoute">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarProjects"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.MY_PROJECTS' | translate}}</span>
          </span>
          </div>
        </a>

        <a [routerLink]="routeHelper.finances">
          <div
            class="menu-item"
            routerLinkActive="active-link"
            [bdcWalkTriggerFor]="isEmployer ? onBoardingEmployerStep4 : onBoardingFreelancerStep4"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarFinances"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.FINANCE' | translate}}</span>
          </span>
          </div>
        </a>

        <a [routerLink]="isEmployer ? routeHelper.freelancerSearch : routeHelper.projectSearch">
          <div
            class="menu-item"
            [class.active-link]="searchIsActive"
            [bdcWalkTriggerFor]="isEmployer ? onBoardingEmployerStep2 : onBoardingFreelancerStep2"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarSearch"
              routerLinkActive="active-link"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.SEARCH' | translate}}</span>
          </span>
          </div>
        </a>

        <a [routerLink]="contractsRoute">
          <div
            class="menu-item message"
            routerLinkActive="active-link"
            [bdcWalkTriggerFor]="isEmployer ? onBoardingEmployerStep3 : onBoardingFreelancerStep3"
          >
            <div class="menu-item">
              <mat-icon matBadge="{{unreadCount > 99 ? '99+' : unreadCount}}"
                        [matBadgeHidden]="expanded || !unreadCount" matBadgeColor="primary"
                        [svgIcon]="svgIconsEnum.SidebarMessages"
              ></mat-icon>
              <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.MESSAGES' | translate}}</span>
              <div *ngIf="unreadCount && expanded" class="unread-messages">{{unreadCount > 99 ? '99+' : unreadCount}}</div>
            </span>
              <div
                *ngIf="profileService.onBoardingInfo.launched"
                [bdcWalkTriggerFor]="onBoardingIsIncoming() ? empInStep1 : empOutStep1"
              ></div>
            </div>
          </div>
        </a>

        <a [routerLink]="routeHelper.settings">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarSettings"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.SETTINGS' | translate}}</span>
          </span>
          </div>
        </a>
      </div>
    </div>

    <div
      *ngIf="!extendedSidebar"
      class="toggle-sidebar-button"
      (click)="toggleSidebar()"
    >
      <mat-icon [svgIcon]="svgIconsEnum.SidebarArrow"></mat-icon>
    </div>
  </div>

  <div *ngIf="extendedSidebar" class="sidebar-extended">
    <ng-template [cdkPortalOutlet]="sidebarPortal"></ng-template>
  </div>
</div>

<help-dialog></help-dialog>

<bdc-walk-popup #onBoardingEmployerStep2 name="onBoardingEmployerStep2"
                [header]="'TOUR.EMPLOYER.2_TITLE' | translate"
                xPosition="after"
                sideNoteText="2/6"
                [showButton]="true" [buttonText]="'BUTTON.NEXT' | translate"
                [horizontal]="true"
                [mustCompleted]="{onBoardingEmployerNext: 1}" [onButtonCompleteTask]="{onBoardingEmployerNext: 2}">
  <div class="tour-text">
    {{'TOUR.EMPLOYER.2_TEXT' | translate}}
  </div>
</bdc-walk-popup>

<bdc-walk-popup #onBoardingEmployerStep3 name="onBoardingEmployerStep3"
                [header]="'TOUR.EMPLOYER.3_TITLE' | translate"
                xPosition="after"
                sideNoteText="3/6"
                [showButton]="true" [buttonText]="'BUTTON.NEXT' | translate"
                [horizontal]="true"
                [mustCompleted]="{onBoardingEmployerNext: 2}" [onButtonCompleteTask]="{onBoardingEmployerNext: 3}">
  <div class="tour-text">
    {{'TOUR.EMPLOYER.3_TEXT' | translate}}
  </div>
</bdc-walk-popup>

<bdc-walk-popup #onBoardingEmployerStep4 name="onBoardingEmployerStep4"
                [header]="'TOUR.EMPLOYER.4_TITLE' | translate"
                xPosition="after"
                sideNoteText="4/6"
                [showButton]="true" [buttonText]="'BUTTON.NEXT' | translate"
                [horizontal]="true"
                [mustCompleted]="{onBoardingEmployerNext: 3}" [onButtonCompleteTask]="{onBoardingEmployerNext: 4}">
  <div class="tour-text">
    {{'TOUR.EMPLOYER.4_TEXT' | translate}}
  </div>
</bdc-walk-popup>

<bdc-walk-popup #onBoardingFreelancerStep2
                name="onBoardingFreelancerStep2"
                sideNoteText="2/5"
                [header]="'TOUR.FREELANCER.2_TITLE' | translate"
                xPosition="after"
                [showButton]="true"
                [horizontal]="true"
                [buttonText]="'BUTTON.NEXT' | translate"
                [mustCompleted]="{onBoardingFreelancerNext: 1}"
                [onButtonCompleteTask]="{onBoardingFreelancerNext: 2}">
  <div class="tour-text">
    {{'TOUR.FREELANCER.2_TEXT' | translate}}
  </div>
</bdc-walk-popup>


<bdc-walk-popup #onBoardingFreelancerStep3
                name="onBoardingFreelancerStep3"
                sideNoteText="3/5"
                [header]="'TOUR.FREELANCER.3_TITLE' | translate"
                xPosition="after"
                [showButton]="true"
                [horizontal]="true"
                [buttonText]="'BUTTON.NEXT' | translate"
                [mustCompleted]="{onBoardingFreelancerNext: 2}"
                [onButtonCompleteTask]="{onBoardingFreelancerNext: 3}">
  <div class="tour-text">
    {{'TOUR.FREELANCER.3_TEXT' | translate}}
  </div>
</bdc-walk-popup>

<bdc-walk-popup #onBoardingFreelancerStep4
                name="onBoardingFreelancerStep4"
                sideNoteText="4/5"
                [header]="'TOUR.FREELANCER.4_TITLE' | translate"
                xPosition="after"
                [showButton]="true"
                [horizontal]="true"
                [buttonText]="'BUTTON.NEXT' | translate"
                [mustCompleted]="{onBoardingFreelancerNext: 3}"
                [onButtonCompleteTask]="{onBoardingFreelancerNext: 4}">
  <div class="tour-text">
    {{'TOUR.FREELANCER.4_TEXT' | translate}}
  </div>
</bdc-walk-popup>

<!--below is onBoarding for chat-->

<bdc-walk-popup #empInStep1 name="empInStep1"
                [header]="(isEmployer ? 'MESSAGE_TOUR.EMPLOYER_INCOMING.1_TITLE' : 'MESSAGE_TOUR.FREELANCER_INCOMING.1_TITLE') | translate"
                xPosition="after"
                [showButton]="false"
                class="contracts-popup"
                [horizontal]="true"
                [mustCompleted]="{empInStepNext: 1}">
  <div class="tour-text">
    {{(isEmployer ? 'MESSAGE_TOUR.EMPLOYER_INCOMING.1_TEXT' : 'MESSAGE_TOUR.FREELANCER_INCOMING.1_TEXT') | translate}}
    <div class="bottom-actions">
      <div>1/7</div>
      <button mat-stroked-button (click)="goToProposals('incoming')" class="next-btn">{{'BUTTON.NEXT' | translate}}</button>
    </div>
  </div>
</bdc-walk-popup>

<bdc-walk-popup #empOutStep1 name="empOutStep1"
                [header]="(isEmployer ? 'MESSAGE_TOUR.EMPLOYER_OUTGOING.1_TITLE' : 'MESSAGE_TOUR.FREELANCER_OUTGOING.1_TITLE') | translate"
                xPosition="after"
                [showButton]="false"
                class="contracts-popup"
                [horizontal]="true"
                [mustCompleted]="{empOutStepNext: 1}">
  <div class="tour-text">
    {{(isEmployer ? 'MESSAGE_TOUR.EMPLOYER_OUTGOING.1_TEXT' : 'MESSAGE_TOUR.FREELANCER_OUTGOING.1_TEXT') | translate}}

    <div class="bottom-actions">
      <div>1/7</div>
      <button mat-stroked-button (click)="goToProposals('outgoing')" class="next-btn">{{'BUTTON.NEXT' | translate}}</button>
    </div>
  </div>
</bdc-walk-popup>
