import { Component, Input } from '@angular/core';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { Feedback } from '../../../../models/feedback/rating-variables';
import { ProfileService } from '../../../../services/profile.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tpt-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss']
})
export class ReviewCardComponent {
  svgIconsEnum = SvgIconsEnum;

  public employerStats = [
    {name: 'EMP_accuracy', field: 'accuracy'},
    {name: 'rapidity', field: 'rapidity'},
    {name: 'EMP_quality', field: 'quality'},
    {name: 'adequacy', field: 'adequacy'}
  ];

  public freelancerStats = [
    {name: 'FR_accuracy', field: 'accuracy'},
    {name: 'rapidity', field: 'rapidity'},
    {name: 'FR_quality', field: 'quality'},
    {name: 'adequacy', field: 'adequacy'}
  ];

  @Input() public feedback: Feedback;
  @Input() public isEmployer: boolean;

  constructor(private profileService: ProfileService,
              private translate: TranslateService) { }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }
}
