<tpt-dialog>
  <div class="tpt-payment-dialog-root">
    <ng-container *ngIf="paymentState === paymentStateEnum.Initial">
      <div class="dialog-title subhead-1">{{ 'FINANCE.MAKE_PAYMENT_DIALOG.TITLE_'+ info?.paymentMethod | translate }}</div>

      <div class="dialog-text">
        {{ 'FINANCE.MAKE_PAYMENT_DIALOG.TEXT_'+ info?.paymentMethod | translate }}
      </div>

      <div class="info">
        <div class="first-row">
          <div class="freelancer-info mb-16">
            <tpt-avatar [user]="info?.freelancer" [textSizeRatio]="3" class="mr-8"
                        size="36"></tpt-avatar>
            <div>
              <div class="freelancer-name">{{info?.freelancer.firstName}} {{info?.freelancer.lastName}}</div>
            </div>
          </div>
          <div class="project-info column mb-16">
            <div class="column-name">{{ 'FINANCE.COLUMN.PROJECT_NAME' | translate }}</div>
            <div class="dark-text">{{info?.job?.name}}</div>
          </div>
        </div>

        <div class="second-row" [ngClass]="{'fixed-type': info?.paymentMethod === 'FIXED'}">
          <div class="payment-column column">
            <div class="column-name">{{ 'FINANCE.COLUMN.PAYMENT_TYPE' | translate }}</div>
            <div class="dark-text" *ngIf="info?.paymentMethod === 'FIXED'">{{ 'FINANCE.COLUMN.FIXED' | translate }}</div>
            <div class="dark-text" *ngIf="info?.paymentMethod === 'HOURLY'">{{ 'FINANCE.COLUMN.HOURLY' | translate }}</div>
          </div>
          <div  class="column deadline" *ngIf="info?.deadline">
            <div>
              <div class="column-name">{{ 'FINANCE.COLUMN.DEADLINE' | translate }}</div>
              <div class="dark-text datepicker-row">
                {{info?.deadline | localDate: 'dd.MM.yyyy'}}
              </div>
            </div>
          </div>
          <div class="column" *ngIf="info?.paymentMethod === 'HOURLY'">
            <div>
              <div class="column-name">{{ 'FINANCE.COLUMN.PAYMENT_PERIOD' | translate }}</div>
              <div class="dark-text">{{paymentPeriod[info?.paymentPeriod] | translate}}</div>
            </div>
          </div>

          <div class="hourly-rate column">
            <ng-container *ngIf="info?.paymentMethod === 'HOURLY'">
              <div class="column-name">{{ 'FINANCE.COLUMN.RATE' | translate }}</div>
              <div class="dark-text">${{info?.hourlyRate}}
                /{{ 'FINANCE.HOUR' | translate }}</div>
            </ng-container>
          </div>
        </div>

        <div class="amount-block">
          <div class="first-payment-text">{{ 'FINANCE.MAKE_PAYMENT_DIALOG.PAYMENT_AMOUNT' | translate }}</div>
          <div class="first-payment-amount">${{ info?.total }}</div>
        </div>

        <div class="actions-row">
          <tpt-button type="secondary" class="mr-12" (onClick)="dialog.close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
          <tpt-button type="accent" (onClick)="confirm()">{{'BUTTON.OK' | translate}}</tpt-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.PaymentRequestInProgress">
      <div class="step-wrapper">
        <div class="step-title">{{'FINANCE.REQUEST_IN_PROGRESS' | translate}}</div>

        <div class="step-text">{{'FINANCE.PLEASE_WAIT' | translate}}</div>

        <mat-spinner [diameter]="32"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.PaymentInProgress">
      <div class="step-wrapper" [class.small]="canBeClosed">
        <div class="step-title">{{'FINANCE.PAYMENT_IN_PROGRESS' | translate}}</div>

        <div class="step-text">{{'FINANCE.PLEASE_WAIT' | translate}}</div>

        <mat-spinner [diameter]="32"></mat-spinner>

        <div *ngIf="canBeClosed" class="in-progress">
          <div class="info-message mt-24" [innerHTML]="'FINANCE.PAYMENT_INFO_MESSAGE' | translate"></div>

          <div class="actions-row">
            <tpt-button
              type="accent"
              (onClick)="close()"
            >
              {{'FINANCE.BACK_TO_WORK'| translate}}
            </tpt-button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.Success">
      <div class="top-up-completed">
        <mat-icon [svgIcon]="svgIconsEnum.CircleCheckmark"></mat-icon>

        <div class="headline">
          {{'FINANCE.PAYMENT_SUCCESS_TITLE'| translate}}
        </div>
        <div class="regular">
          {{'FINANCE.PAYMENT_SUCCESS_TEXT'| translate}}
        </div>
        <tpt-button type="secondary" class="full-width" (onClick)="close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.Error">
      <div class="top-up-completed">

        <div class="headline">
          {{'FINANCE.PAYMENT_FAIL_TITLE'| translate}}
        </div>

        <tpt-button type="secondary" class="full-width" (onClick)="dialog.close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
      </div>
    </ng-container>
  </div>
</tpt-dialog>

<tpt-insufficient-funds-dialog (depositIsSuccess)="closeAndUpdate.emit()"></tpt-insufficient-funds-dialog>
