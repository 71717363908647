import { ProfileNotificationType } from '../../classes/enums';

export class ProfileSettings {

  get job(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.JOB);
  }

  set job(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.JOB, val);
  }

  get project(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.PROJECT);
  }

  set project(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.PROJECT, val);
  }

  get task(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.TASK);
  }

  set task(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.TASK, val);
  }

  get suggestion(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.SUGGESTION);
  }

  set suggestion(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.SUGGESTION, val);
  }

  get document(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.DOCUMENT);
  }

  set document(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.DOCUMENT, val);
  }

  get deposit(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.DEPOSIT);
  }

  set deposit(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.DEPOSIT, val);
  }

  get dispute(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.DISPUTE);
  }

  set dispute(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.DISPUTE, val);
  }

  get milestone(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.MILESTONE);
  }

  set milestone(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.MILESTONE, val);
  }

  get projectState(): boolean {
    return this.getEmailNotificationBit(ProfileNotificationType.PROJECT_STATE);
  }

  set projectState(val: boolean) {
    this.setEmailNotificationBit(ProfileNotificationType.PROJECT_STATE, val);
  }

  get isAllNotifications(): boolean {
    return this.emailNotification === ProfileSettings.MAX_NOTIFICATION_VALUE;
  }

  set isAllNotifications(val: boolean) {
    if (val) {
     this.emailNotification = ProfileSettings.MAX_NOTIFICATION_VALUE;
    }
  }

  get isEmailNotifications(): boolean {
    return this.emailNotification > 0;
  }

  set isEmailNotifications(val: boolean) {
    if (!val) {
      this.emailNotification = 0;
    }
  }
  public static MAX_NOTIFICATION_VALUE = 0x7FFF_FFFF;

  public static fromEmailNotification(emailNotification: number): ProfileSettings {
    const settings = new ProfileSettings();
    settings.emailNotification = emailNotification;
    return settings;
  }
  public id: number;
  public emailNotification = 0;

  /* tslint:disable:no-bitwise*/
  private setEmailNotificationBit(mask: number, value: boolean) {
    if (value) {
      this.emailNotification |= mask;
    } else {
      this.emailNotification &= ~mask;
    }
  }

  private getEmailNotificationBit(mask: number): boolean {
    return ((this.emailNotification & mask) > 0);
  }
}
