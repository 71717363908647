
export const stripeCardStyleConfig = {
  iconStyle: 'solid',
  placeholder: '**** **** **** ****',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#8993A5',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',

      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#8993A5',
      },
    },
    invalid: {
      iconColor: '#e25950',
      color: '#e25950',
    },
  },
};
