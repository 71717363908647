<tpt-page-header>
    <ng-container page-title>
      {{'SEARCH.TITLE' | translate}}
    </ng-container>
    <nav class="header-menu">
      <ul class="header-menu-list">
        <li
          class="header-menu-item"
          [routerLink]="routeHelper.projectSearch"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="header-menu-item-active"
        >
          {{'SEARCH.PROJECTS' | translate}}        
        </li>
        <li
          class="header-menu-item"
          [routerLink]="routeHelper.freelancerSearch"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="header-menu-item-active"
        >
          {{'SEARCH.FREELANCERS' | translate}}
        </li>
      </ul>
    </nav>
  </tpt-page-header>