import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';

@Component({
  selector: 'tpt-remove-payoneer-account-dialog',
  templateUrl: './remove-payoneer-account-dialog.component.html',
  styleUrls: [ './remove-payoneer-account-dialog.component.scss' ],
})
export class RemovePayoneerAccountDialogComponent {

  @Output()
  public readonly cancel = new EventEmitter<void>();

  @Output()
  public readonly confirm = new EventEmitter<void>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private className = 'tpt-remove-payoneer-account-dialog';

  public open(): void {
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public confirmDelete(): void {
    this.confirm.emit();
    this.close();
  }

}
