import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { environment } from '../../../../../environments/environment';
import { DiffKey } from '../../../../classes/diffKey';
import { ContentType, SettingsKeys } from '../../../../classes/enums';
import { FileUpload } from '../../../../models/fileUpload';
import { AlertService } from '../../../../services/alert.service';
import { LogService } from '../../../../services/log.service';
import { SettingsService } from '../../../../services/settings.service';
import { uploadedFileUrl } from '../../../../utils';
import { FileUploadService } from '../../../../services/file-upload.service';

interface IFormData {
  concurrency: number;
  autoUpload: boolean;
  verbose: boolean;
}

@Component({
  selector: 'xln-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  @Input() public uploadedFiles: FileUpload[] = [];
  @Output() public uploadedFilesChange: EventEmitter<FileUpload[]> = new EventEmitter<FileUpload[]>();

  @Input() public customTitle: string;
  @Input() public contentId = -1;
  @Input() public contentType: ContentType = ContentType.PROJECT;
  @Input() public maxSize = 0;
  @Input() public onlyButton = false;
  @Input() public customButton = false;
  @Input() public title = 'FILE_UPLOADER_COMPONENT.UPLOAD';
  @Input() public documentId: number = null;
  @Input() public icon: string;
  @Input() public uploaderIcon = 'file_upload';
  @Input() public diffKey: DiffKey = null;

  public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

  public formData: IFormData;
  public files: UploadFile[] = [];
  public humanizeBytes: (bytes: number) => string;
  public dragOver: boolean;
  public options: UploaderOptions;
  public isFileUploading = false;
  public duplicateFiles: string[] = [];

  public uploadedFileUrl = uploadedFileUrl;

  @ViewChild('fileInput')
  public readonly fileInput: ElementRef<HTMLInputElement>;

  constructor(private fileUploadService: FileUploadService,
              private settingsService: SettingsService,
              private logService: LogService,
              private alertService: AlertService) {
    this.formData = {
      concurrency: 1,
      autoUpload: true,
      verbose: true,
    };

    this.humanizeBytes = humanizeBytes;
    if (this.settingsService.getDefaults()) {
      const maxSize = this.settingsService.getDefaults()[SettingsKeys.MAX_SIZE];
      if (maxSize) {
        this.maxSize = maxSize;
      }
    }
    this.options = {concurrency: this.formData.concurrency};
  }

  public isUploadDone(file: UploadFile): boolean {
    return file.progress.data.percentage === 100;
  }

  public uploadError(file: UploadFile): string {
    return file.response && file.response.error ? file.response.error : '';
  }

  public onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      for (const id of this.duplicateFiles) {
        this.removeUpload(id);
        this.alertService.error('FILE_UPLOADER_COMPONENT.FILE_ALREADY_EXISTS');
      }
      this.duplicateFiles = [];
      const token = localStorage.getItem('auth-token');
      const event: UploadInput = {
        type: 'uploadAll',
        url: `${window.location.protocol}//${environment.MAIN_HOST}/api/v2/files` +
          (this.documentId > 0 ? `/${this.documentId}/actions/addVersion` : ''),
        method: 'POST',
        file: output.file,
        data: {
          contentId: this.contentId.toString(),
          contentType: this.contentType.toString(),
          fromSuggestion: (!!this.diffKey).toString(),
        },
        headers: {Authorization: 'Bearer ' + token},
      };
      this.uploadInput.emit(event);

    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      if (this.uploadedFiles.find((file) => typeof output.file !== 'undefined' &&
        (file.name + '.' + file.extension) === output.file.name &&
        file.size === output.file.size)) {
        this.duplicateFiles.push(output.file.id);
      } else {
        this.files.push(output.file);
        this.isFileUploading = true;
      }
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
      this.isFileUploading = true;
    } else if (output.type === 'cancelled') {
      this.fileInput.nativeElement.value = '';

    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
      this.fileInput.nativeElement.value = '';
    } else if (output.type === 'done') {
      if (output.file.response) {
        const uploadedFile = output.file.response as FileUpload;
        this.uploadedFiles = this.uploadedFiles.map((x) => Object.assign(new FileUpload(), x));
        if (this.documentId) {
          this.uploadedFiles = [uploadedFile];
        } else {
          this.uploadedFiles.push(uploadedFile);
        }
        this.fileUploadService.fixHistory(uploadedFile, this.diffKey);
        // replace array to force reload by onChanges

        this.uploadedFilesChange.emit(this.uploadedFiles);
      } else {
        this.logService.info(output);
        this.alertService.error(
          output.file.response.message
            ? output.file.response.message
            : 'FILE_UPLOADER_COMPONENT.FILE_TOO_LARGE',
        );
      }
      this.files = [];
      this.isFileUploading = false;
      this.fileInput.nativeElement.value = '';
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  public removeUpload(id: string): void {
    this.uploadInput.emit({type: 'remove', id});
    this.files = this.files.filter((file: UploadFile) => file.id !== id);
  }

  public stopProp(ev) {
    ev.stopPropagation();
  }

}
