import { FormContext } from '../../tpt-forms/base/form.context';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { WithdrawFundsModel } from '../models/withdraw-funds.model';

export class WithdrawFundsForm extends FormContext<WithdrawFundsModel> {

  static createForm(accountId: number): WithdrawFundsForm {
    const form = new FormGroup({
      accountId: new FormControl(accountId),
      amount: new FormControl(null, [Validators.required, Validators.min(10)]),
    });

    return new WithdrawFundsForm(form);
  }

  public get accountId(): AbstractControl {
    return this.getControl('accountId');
  }

  public get amount(): AbstractControl {
    return this.getControl('amount');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): WithdrawFundsModel {
    return this.form.value;
  }

}
