import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'emp-delete-note-dialog',
  templateUrl: './delete-note-dialog.component.html',
  styleUrls: [ './delete-note-dialog.component.scss' ]
})
export class DeleteNoteDialogComponent {

  @Output()
  public readonly deleteNote = new EventEmitter<void>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private className = 'emp-delete-note-dialog';

  private config: MatDialogConfig = {
    width: '729px',
    height: '266px',
  };

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public confirmDelete(): void {
    this.deleteNote.emit();
    this.close();
  }

}
