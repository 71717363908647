<tpt-dialog>
  <div class="wrapper" *ngIf="project">

    <div class="left">
      <project-details-card [project]="project"></project-details-card>
      <div class="actions-row" *ngIf="!isEmployer">
        <ng-container *ngIf="!project.currentUserState">
          <button mat-button class="button cancel" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
          <button mat-button class="button blue" (click)="sendProposal(project)">{{'SEARCH.SEND_REQUEST' | translate}}</button>
        </ng-container>
        <ng-container *ngIf="project.currentUserState === 'PROPOSAL_SUBMITTED'">
          <button mat-button class="button cancel" (click)="cancelProposal(project)">{{'CONTRACTS.CANCEL' | translate}}</button>
          <button mat-button class="button info">
            <span>{{'SEARCH.REQUEST_SENT' | translate}}</span>
            <span class="material-icons">done</span>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="right">
      <div *ngIf="project?.owner as owner" class="employer-info-wrapper">
        <img [src]="placeholderIcon" class="employer-avatar" alt="freelancer-avatar" />
        <h3 class="employer-name">{{ owner.firstName }} {{ owner.lastName }}</h3>
        <div class="employer-location">
          <mat-icon [svgIcon]="svgIconsEnum.Location" class="location-icon"></mat-icon>{{ owner.country?.name }}
        </div>
        <div class="employer-local-time">{{'CONTRACTS.LOCAL_TIME' | translate}} {{ owner.currentTime | date: 'HH:MM' }}</div>
        <div class="employer-about">{{ owner.description }}</div>
        <div class="employer-section">
          <div class="section-title">{{'CONTRACTS.LANGUAGE_SKILLS' | translate}}</div>
          <div class="section-content">
            <div class="section-content-row"
              *ngFor="let language of owner.languages">
              <span class="key">{{getLangByCode(language)}}</span>
              <span class="value" *ngIf="language.level">{{'CREATE_PROJECT.STEP3.'+language.level | translate}}</span>
            </div>
          </div>
        </div>

        <div class="employer-section">
          <div class="section-title">{{'CONTRACTS.LINKS' | translate}}</div>
          <div class="section-content">
            <a *ngFor="let link of owner?.links" href="#" target="_blank" class="freelancer-link">{{link}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</tpt-dialog>
