import {WSViduMessageId} from './enums';

export class WSViduMessage {
  public id: WSViduMessageId = WSViduMessageId.ERROR;

  public to: number;
  public from: number;
  public user: number;
  public response: string;
  public request: string;
  public message: string;
  public ovToken: string;
  public ovSessionId: string;
  public room: string;
  public fromName: string;
}
