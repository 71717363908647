import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss'],
})

export class RenameDialog {
  public name = '';
  public allowEmpty = false;
  public title = 'RENAME_DIALOG_COMPONENT.TITLE';
  // in html will use default browser value 524288
  public maxLength = -1;
  public loading = false;

  constructor(public dialogRef: MatDialogRef<RenameDialog>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.allowEmpty !== undefined) {
        this.allowEmpty = data.allowEmpty;
      }
      if (data.title !== undefined) {
        this.title = data.title;
      }
      if (data.maxLength !== undefined) {
        this.maxLength = data.maxLength;
      }

      translate.get(this.title).subscribe((str: string) => {
        this.title = str;
      });

    }
  }

  public rename(valid) {
    if (valid) {
      this.dialogRef.close(this.name);
    }
  }
}
