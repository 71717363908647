import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Button } from '../../../../classes/button';
import { DiffKey } from '../../../../classes/diffKey';
import { ChangeHistoryStatus } from '../../../../classes/enums';
import { FileUpload } from '../../../../models/fileUpload';
import { FileUploadHistory } from '../../../../models/fileUploadHistory';
import { AlertService } from '../../../../services/alert.service';
import { LogService } from '../../../../services/log.service';
import { uploadedFileUrl } from '../../../../utils';
import { RenameDialog } from '../../../../components/common-dialogs/rename-dialog/rename-dialog.component';
import { take } from 'rxjs/operators';
import { newValue } from '../../../../changeable-utils';
import { FileUploadService } from '../../../../services/file-upload.service';

@Component({
  selector: 'xln-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnChanges {
  @Input() public file: FileUpload;
  @Output() public fileChange: EventEmitter<FileUpload> = new EventEmitter<FileUpload>();

  /*actions*/
  @Input() public allowDelete = false;
  @Input() public allowAddVersion = false;
  @Input() public allowRename = false;

  /*UI*/
  @Input() public showDate = false;
  @Input() public showVersion = false;
  @Input() public showFrom = false;
  @Input() public showHistory = false;

  // show color diff
  @Input() public diffKey: DiffKey = null;

  @ViewChild('trigger') public trigger: MatMenuTrigger;

  public shouldShowHistory = false;
  public shouldAllowAddVersion = false;
  public status: ChangeHistoryStatus = ChangeHistoryStatus.CURRENT;
  public filename: string;
  public forceDeleted = false;

  public ChangeHistoryStatus = ChangeHistoryStatus;

  constructor(private fileUploadService: FileUploadService,
              private logService: LogService,
              private alertService: AlertService,
              public dialog: MatDialog) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.file) {
      if (changes.file || changes.showHistory) {
        this.shouldShowHistory = this.showHistory && this.file.changeVersion > 1;
      }
      if (changes.file || changes.allowAddVersion) {
        this.shouldAllowAddVersion = this.allowAddVersion &&
          !(this.file.histories.length === 1 && this.file.histories[0].status === ChangeHistoryStatus.ADDED);
      }
      if (changes.file || changes.showDiff) {
        this.status = ChangeHistoryStatus.CURRENT;
        this.filename = `${this.file.name}\u200B.${this.file.extension}`;
        this.prepareDiff();
      }
    }
  }

  public closeMenu(files: FileUpload[]) {
    this.file = files[0];
    this.fileChange.emit(this.file);
    this.trigger.closeMenu();
  }

  public download(): void {
    window.location.href = uploadedFileUrl(this.file);
  }

  public viewFile(): void {
    alert('View file ' + uploadedFileUrl(this.file));
  }

  public rename() {
    const dialogCfg: MatDialogConfig = new MatDialogConfig();
    dialogCfg.disableClose = true;
    dialogCfg.data = {};
    dialogCfg.data.name = this.file.name;
    dialogCfg.data.title = 'FILE_VIEWER_COMPONENT.RENAME';

    const dialogRef: MatDialogRef<RenameDialog> = this.dialog.open(RenameDialog, dialogCfg);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result && result !== this.file.name) {
          this.processChanges(this.fileUploadService.rename(this.file.id, result, !!this.diffKey));
        }
      });
  }

  public toggleHistory() {
    alert('Show file history');
  }

  public deleteUpload(): void {
    this.alertService.showCustomAlert('FILE_VIEWER_COMPONENT.ASK_DELETE_FILE', [
      Button.CancelButton(),
      new Button('BUTTON.DELETE', 'warn', true),
    ], (result) => {
      this.processChanges(this.fileUploadService.deleteFile(this.file.id,
        (typeof result === 'string') ? result : null,
        !!this.diffKey));
    }, 'FILE_VIEWER_COMPONENT.REASON');
  }

  public restoreUpload(): void {
    if (this.diffKey != null) {
      const lastHistory: FileUploadHistory = this.file.histories.length > 0 ?
        newValue(this.file, this.diffKey.suggestionId) : null;
      if (lastHistory) {
        this.processChanges(this.fileUploadService.decline(lastHistory.id, null, !!this.diffKey));
      }
    }
  }

  private processChanges(promise: Promise<FileUpload>) {
    promise.then((fileUpload) => {
      if (fileUpload) {
        this.file = fileUpload;
        this.fileUploadService.fixHistory(this.file, this.diffKey);
      } else {
        this.file = null;
      }
      this.fileChange.emit(this.file);
    }).catch((error) => {
      if (error.error.message === 'PROJECT_ERROR_CANNOT_CHANGE_SUGGESTION_ACCEPTING_STATE') {
        // TODO:
      }
      this.logService.error(error);
      this.alertService.error(error);
    });
  }

  private prepareDiff() {
    if (this.diffKey) {
      const lastHistory: FileUploadHistory = this.file.histories.length > 0 ?
        newValue(this.file, this.diffKey.suggestionId) : null;
      if (lastHistory) {
        if (lastHistory.packageVersion >= this.diffKey.packageVersion) {
          this.status = lastHistory.status;
          if (lastHistory.status !== ChangeHistoryStatus.DELETED) {
            this.filename = `${lastHistory.name}\u200B.${lastHistory.extension}`;
          }
        } else if (lastHistory.status === ChangeHistoryStatus.DELETED) {
          this.forceDeleted = true;
        }
      }
    }
  }
}
