import { FormContext } from '../../tpt-forms/base/form.context';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { minLengthWithTrim } from '../../common-directives/directives/min-length-trim.directive';

export class AddPaymentMethodForm extends FormContext<{ name: string }> {

  static createForm(): AddPaymentMethodForm {
    const form = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50),
        minLengthWithTrim(1),
      ]),
    });

    return new AddPaymentMethodForm(form);
  }

  public get name(): AbstractControl {
    return this.getControl('name');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): { name: string } {
    return this.form.value;
  }

}
