import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { isMobileDevice } from '../../../helpers/get-mobile.helper';

@Component({
  selector: 'tpt-header-light',
  templateUrl: './header-light.component.html',
  styleUrls: ['./header-light.component.scss']
})
export class HeaderLightComponent {

  public get switchTo(): string {
    const lang = this.translate.currentLang;
    return lang === 'en' ? 'ru' : 'en';
  }

  public svgIconsEnum = SvgIconsEnum;

  public langFE = {
    ru: 'ру',
    en: 'en'
  };

  public isMobile = isMobileDevice();

  constructor(private translate: TranslateService) { }

  @HostListener('window:resize') onResize() {
    this.isMobile = isMobileDevice();
  }

  public setLang(): void {
    const lang = this.translate.currentLang === 'en' ? 'ru' : 'en';
    this.translate.use(lang);
    localStorage.setItem('currentLang', lang);
  }

}
