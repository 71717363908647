import { Metadata } from '../../../models/finance/metadata';

export enum TxTypeEnum {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  TRANSFER = 'TRANSFER',
  HOLD = 'HOLD',
  RELEASE_HOLD = 'RELEASE_HOLD',
  WITHDRAW = 'WITHDRAW',
  DEPOSIT = 'DEPOSIT',
  ARBITRATION = 'ARBITRATION',
  SERVICE = 'SERVICE'
}

enum TxStatusEnum {
  NEW = 'new',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  ERROR = 'error',
  FORCE_CLOSED = 'force closed',
  TIMEOUT = 'timeout'
}

export class TransactionModel {

  public id: number;

  public txType: TxTypeEnum;

  public status: TxStatusEnum;

  public metadata: Metadata;

  public createDate: string;

  public code: string;
}
