import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Skill } from '../models/skill';
import { BaseService } from './base.service';
import { SkillV2Model } from '../models/skill-v2.model';

@Injectable({
  providedIn: 'root',
})
export class SkillService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getSkills(): Observable<Skill[]> {
    return this.executeGet<Skill[]>('skills').pipe(
      map((data) => plainToClass(Skill, data))
    );
  }

  public getSkillsV2(): Observable<SkillV2Model[]> {
    return this.executeGet<SkillV2Model[]>('v2/skills');
  }

  public getCategorySkillsV2(categoryId: number, prefix?: string): Observable<SkillV2Model[]> {
    const params = { prefix, categoryId };
    if (!params.prefix) {
      delete params.prefix;
    }

    if (!params.categoryId) {
      delete params.categoryId;
    }
    return this.executeGet<SkillV2Model[]>('v2/skills', { params });
  }

  public createSkill(skill: Skill): Promise<Skill> {
    return this.executePost<Skill>('skills', skill)
      .then((data) => plainToClass(Skill, data));
  }

  public addCustomSkillV2 = (prefix: string, selected?: Array<number>, categoryId?: number): Promise<SkillV2Model> => {
    const params = { prefix, selected, categoryId };
    if (!params.selected) {
      delete params.selected;
    }

    if (!params.categoryId) {
      delete params.categoryId;
    }

    return this.executeGet<SkillV2Model>('v2/skills', { params }).toPromise();
  }

  public addCustomSkill = (skillName: string, selectedSkills: Skill[], allSkill: Skill[]): Skill | Promise<Skill> => {
    if (((skillName || '').trim())) {
      const savedSkills = allSkill.filter((skill) => skill.skill === skillName);
      if (savedSkills.length === 0) {
        return  this.createSkill(new Skill(skillName));
      } else if (selectedSkills.filter((skill) => skill.skill === skillName).length === 0) {
        return savedSkills[0];
      }
    }
  };

}
