export class EditRoleModel {

  roleId: number = null;

  name = '';

  rolesEditing = false;

  roleAssigning = false;

  chatCreating = false;

  tasksRead: any[] = [];

  taskCommenting: any[] = [];
}
