import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { omitBy } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { ProjectSearchSorting } from 'src/app/classes/enums';
import { CategoryService } from 'src/app/services/category.service';
import { SearchService } from 'src/app/services/search.service';
import { getAvatarUrl } from 'src/app/utils';
import { IBaseFilters } from '../../../../../classes/iBaseFilters';
import { Profile } from '../../../../../models/profile/profile';
import { AlertService } from '../../../../../services/alert.service';
import { LazyLoadService } from '../../../../../services/lazy-load.service';
import { ProfileManagementService } from '../../../../../services/profile-management.service';
import { ProjectApiService } from '../../../../../services/project.api.service';
import { SpinnerService } from '../../../../tpt-ui/services/spinner.service';
import { ProjectSearchItemModel } from '../../../../../models/search/projectSearchItem.model';
import { ProfileService } from '../../../../../services/profile.service';
import { DataLayerService} from '../../../../../services/data-layer.service';
import { SimpleProjectFixedPaymentModel } from '../../../../employer/models/simple-project-fixed-payment.model';
import { SimpleProjectHourlyPaymentModel } from '../../../../employer/models/simple-project-hourly-payment.model';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { EmployerInfoDialogComponent } from '../../../../common-dialogs/components/employer-info-dialog/employer-info-dialog.component';
import { CurrencyEnum, CurrencySymbol } from '../../../../../models/currency.enum';
import { ProfilesApiService } from '../../../../../services/profiles.api.service';
import { FreelancerLegalStatusEnum } from '../../../../../models/legalStatuses.enum';
import { SelfEmployedWarningDialogComponent } from '../../../../common-dialogs/components/self-employed-warning-dialog/self-employed-warning-dialog.component';
import { RouteHelper } from '../../../../../helpers/route.helper';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarNotificationsService } from '../../../../../services/snackbar-notifications.service';
import { GetAcquaintedDialogComponent } from '../../../../common-dialogs/components/get-acquainted-dialog/get-acquainted-dialog.component';

@Component({
  selector: 'tpt-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit, OnDestroy {

  @ViewChild(EmployerInfoDialogComponent)
  public employerInfoDialog: EmployerInfoDialogComponent;

  @ViewChild(SelfEmployedWarningDialogComponent)
  public warningDialog: SelfEmployedWarningDialogComponent;

  @ViewChild(GetAcquaintedDialogComponent)
  public acquaintedDialog: GetAcquaintedDialogComponent;

  @Output()emitSortChange = new EventEmitter();
  @Output()searchEvent = new EventEmitter();
  @Input() projects$: ProjectSearchItemModel[];
  @Input() skillsFilter: FormArray;
  @Input() showSkeleton: boolean;

  public filters: Partial<IBaseFilters> = {
    sort: ProjectSearchSorting.PUBLISH_DATE_DESC,
    skills: [],
    expertLevel: null,
    location: null,
    searchText: '',
    pageIndex: 0,
    pageSize: 20,
    hourlyRate: [0, 1000],
  };

  public profilesCount = 100;
  public projectsCount = 100;
  public profiles: Profile[] = [];
  public hireFor: ProjectSearchItemModel = null;

  public getAvatarUrl = getAvatarUrl;
  public categories: any;
  public categories$: Observable<any>;
  public svgIconsEnum = SvgIconsEnum;

  public searchControl: FormControl;
  public currencySymbol = CurrencySymbol;
  public currencyEnum = CurrencyEnum;
  public freelancerLegalEnum = FreelancerLegalStatusEnum;
  public allowRub = false;

  get sortList() {
    return ['PUBLISH_DATE_ASC', 'PUBLISH_DATE_DESC'];
  }

  get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  constructor(
    public profileManagementService: ProfileManagementService,
    public projectService: ProjectApiService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private routeHelper: RouteHelper,
    private alertService: AlertService,
    private categoryService: CategoryService,
    private searchService: SearchService,
    private lazyLoadService: LazyLoadService,
    private spinner: SpinnerService,
    private profileService: ProfileService,
    private dataLayerService: DataLayerService,
    private translate: TranslateService,
    private profileApiService: ProfilesApiService,
    private clipboard: Clipboard,
    private snack: SnackbarNotificationsService,
  ) {}

  ngOnInit(): void {
    this.getCategories();

    this.searchControl = new FormControl('');

    // this.searchControl.valueChanges.pipe(
    //   debounceTime(500)
    // ).subscribe((val) => {
    //   this.searchEvent.emit(val);
    // });

    this.profileApiService.getUserLegal().subscribe(res => {
      if (res && res.legalStatus === this.freelancerLegalEnum.SELF_EMPLOYED) {
        this.allowRub = true;
      }
    });
  }

  ngOnDestroy(): void { }

  public openCardDetails(project: ProjectSearchItemModel): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.projectPage(project.id))
    );

    window.open(url, '_blank');
  }

  public getChipStyle(skill): boolean {
    const skillsFilter = this.skillsFilter.value;

    return skillsFilter.some(item => item.id === skill.id);
  }

  public getCategories() {
    this.categories$ = this.categoryService.getCategoriesV2();
  }

  public getProjectCategory(project, categories) {
    const category = categories.find(c => c.id === project.parentCategoryId);
    project.category = category;
    return this.getTranslation(category);
  }

  public getProjectSubCategory(project, categories) {
    const category = categories.find(c => c.id === project.parentCategoryId);
    const subCategory = category.children.find(c => c.id === project.subCategoryId);
    project.subCategory = subCategory;
    return this.getTranslation(subCategory);
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public copyJobLink(job): void {
    let baseUrl = window.location.href;
    baseUrl = baseUrl.split('/').slice(0, 3).join('/');

    const userUrl = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.projectPage(job.id))
    );

    const fullUrl = `${baseUrl}${userUrl}`;

    this.clipboard.copy(fullUrl);
    this.snack.showNotification('SETTINGS.JOB_LINK_COPIED', 'success');
  }

  public sendProposal(project: ProjectSearchItemModel) {
    const user = this.profileService.newProfileObject.value;
    if (!user.firstName && !user.lastName) {
      this.acquaintedDialog.open(project);
      return;
    }

    if (!this.allowRub && project.currency.code === this.currencyEnum.RUB) {
      this.warningDialog.open();
      return;
    }

    this.spinner.startSpinner();
    this.searchService.sendProposal(project.id)
      .then(res => {
        if (res) {
          project.chatId = res.id;
          project.currentUserState = 'PROPOSAL_SUBMITTED';
          this.pushFreelancerSendRequestToDataLayer(project);
          this.spinner.stopSpinner();
        }
      })
      .catch(this.spinner.stopSpinner);
  }

  public openEmployerInfo(owner): void {
    this.employerInfoDialog.open(owner, false, false);
  }

  public searchProject(): void {
    const searchValue = this.searchControl.value.trim();
    this.searchEvent.emit(searchValue);
  }

  public hourlyProjectTotalCost(project): string {
    const { hourlyRate, hoursPerWeek, paymentPeriod } = project.details as SimpleProjectHourlyPaymentModel;
    const dayCost = (hourlyRate * hoursPerWeek) / 5;
    if (paymentPeriod === 'P_14DAYS') {
      return Number(dayCost * 5 * 2).toFixed(2);
    } else if (paymentPeriod === 'P_30DAYS') {
      return Number(dayCost * 5 * 4).toFixed(2);
    } else {
      return Number(dayCost * 5).toFixed(2);
    }
  }

  private pushFreelancerSendRequestToDataLayer = (project: ProjectSearchItemModel): void => {
    const event = 'freelancerSendRequest';
    const visible = this.transformVisibilityToAnalyticsForm(project.projectInfo.visibility);
    const level = this.transformExpertLevelsToAnalyticsForm(project.expertLevels);
    const paymentType = project.details.paymentMethod.toLocaleLowerCase();
    let budget;
    if (project.details.paymentMethod.toLocaleLowerCase() === 'fixed') {
      budget = (project.details as SimpleProjectFixedPaymentModel).budget;
    } else {
      budget = this.hourlyProjectTotalCost(project);
    }
    const currency = project.currency.code.toLowerCase();
    const projectType = project.projectInfo.type === 'COMPLEX' ? 'complex' : 'single';

    const model = omitBy(
      { event, visible, level, paymentType, budget, currency, jobId: `${project.id}`, projectId: `${project.projectInfo.id}`, projectType},
      (field: string | number) => !Boolean(field),
    );
    this.dataLayerService.pushToDataLayer(model);
  }

  private transformExpertLevelsToAnalyticsForm(levels: Array<'BEGINNER' | 'INTERMEDIATE' | 'MASTER'>): string {
    return levels.map((value: 'BEGINNER' | 'INTERMEDIATE' | 'MASTER') => {
      return value === 'BEGINNER' ? 'junior' : value === 'INTERMEDIATE' ? 'middle' : 'expert';
    }).join(',');
  }

  private transformVisibilityToAnalyticsForm(visibility: 'PRIVATE' | 'INTERNALLY' | 'PUBLIC'): string {
    if (visibility === 'PRIVATE') {
      return 'private';
    } else if (visibility === 'INTERNALLY') {
      return 'inside';
    } else {
      return 'public';
    }
  }
}
