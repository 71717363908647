import { FormContext } from '../../tpt-forms/base/form.context';
import { DepositFundsModel } from '../models/deposit-funds.model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class DepositFundsForm extends FormContext<DepositFundsModel> {

  static createForm(accountId: number): DepositFundsForm {
    const form = new FormGroup({
      accountId: new FormControl(accountId),
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
      cardId: new FormControl(null, [Validators.required]),
    });

    return new DepositFundsForm(form);
  }

  public get accountId(): AbstractControl {
    return this.getControl('accountId');
  }

  public get amount(): AbstractControl {
    return this.getControl('amount');
  }

  public get cardId(): AbstractControl {
    return this.getControl('cardId');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): DepositFundsModel {
    return this.form.value;
  }

}
