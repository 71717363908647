import { FormContext } from '../modules/tpt-forms/base/form.context';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TptValidators } from '../modules/tpt-forms/validators/tpt.validators';

export class ChangePasswordForm extends FormContext<{ changePasswordNew: string, changePasswordConfirm: string }> {

  static createForm(): ChangePasswordForm {
    const form = new FormGroup({
      changePasswordNew: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        TptValidators.passwordLettersDigits('changePasswordNew'),
      ]),
      changePasswordConfirm: new FormControl('',
        [
          Validators.required,
          Validators.minLength(8),
          TptValidators.passwordConfirmation('changePasswordNew', 'changePasswordConfirm')
        ]
      ),
    });

    return new ChangePasswordForm(form);
  }

  public get changePasswordNew(): AbstractControl {
    return this.getControl('changePasswordNew');
  }

  public get changePasswordConfirm(): AbstractControl {
    return this.getControl('changePasswordConfirm');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): { changePasswordNew: string; changePasswordConfirm: string } {
    return this.form.value;
  }

}
