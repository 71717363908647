import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Country } from '../models/country';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getCountries(): Observable<Country[]> {
    return this.executeGet<Country[]>('countries').pipe(
      map((data) => plainToClass(Country, data))
    );
  }

  public getCountriesV2(name?: string): Observable<Country[]> {
    return this.executeGet<Country[]>('v2/countries', name ? {params: {name}} : {})
      .pipe(tap((countries) => countries.unshift({
        name: 'Irrelevant',
        nameRu: 'Не имеет значения',
        code: '',
        id: null
      })));
  }

}
