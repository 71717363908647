import {SuggestionType} from './enums';

export class HierarchyReference {
  public jobId: number = null;
  public projectId: number = null;
  public suggestionId: number = null;
  public conversationId: number = null;
  public taskId: number = null;
  public documentId: number = null;
  public profileId: number = null;
  public suggestionType: SuggestionType = null;
  public milestoneId: number = null;
  public invoiceId: number = null;

  constructor(jobId: number, projectId: number, suggestionId: number, conversationId: number,
              taskId: number) {
    this.jobId = jobId;
    this.projectId = projectId;
    this.suggestionId = suggestionId;
    this.conversationId = conversationId;
    this.taskId = taskId;
  }

  public generateId(): string {
    return `${this.jobId}.${this.projectId}.${this.suggestionId}
    .${this.conversationId}.${this.taskId}`;
  }

  public compareByTarget(hierarchyReference: HierarchyReference): boolean {
    return this.jobId === hierarchyReference.jobId &&
      this.projectId === hierarchyReference.projectId &&
      this.suggestionId === hierarchyReference.suggestionId &&
      this.conversationId === hierarchyReference.conversationId;
  }
}
