<div class="page-wrapper">
  <div class="register-root">
    <div>
      <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full" *ngIf="!isMobile"></mat-icon>

      <div class="title-wrapper" *ngIf="isMobile">
        <div class="mobile-title">{{'REGISTER_COMPONENT.LETS_START' | translate}}</div>
        <div class="sup">{{'REGISTER_COMPONENT.DESKTOP_ONLY' | translate}}</div>
      </div>

      <div class="register">
        <div class="register-column" *ngIf="!isMobile">
          <h2 class="register__title">{{ 'REGISTER_COMPONENT.REGISTER' | translate }}</h2>

          <ng-container>
            <div class="message-title">{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED' | translate}}
              <mat-icon [svgIcon]="svgIconsEnum.ConfirmSuccess" class="success-icon"></mat-icon>
            </div>
            <div class="message-text" innerHTML="{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED_TEXT' | translate}}"></div>
          </ng-container>

          <div class="btn-wrap">
            <button mat-button class="login-button"
                    [routerLink]="'/login'">{{'LOGIN_COMPONENT.LOGIN' | translate}}</button>
          </div>
        </div>

        <div *ngIf="isMobile">
          <div class="message-title">{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED' | translate}}</div>

          <div class="message-text" innerHTML="{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED_MOBILE_TEXT' | translate}}"></div>

          <img *ngIf="isMobile" class="home-image" [src]="'assets/images/home-page-mobile-' +translate.currentLang + '.png'">
        </div>
      </div>
    </div>
  </div>

</div>
