<tpt-dialog>
  <div class="tpt-help-dialog-root">
    <div class="deposit-funds-title">{{'SIDEBAR.HELP' | translate}}</div>

    <ng-container>
      <div class="paragraph">{{'HELP.FIRST_PARAGRAPH' | translate}}
        <a class="link" href="https://talentpowered.team/support/{{isEmployer ? 'employer' : 'freelancer'}}">
          {{'HELP.FIRST_LINK' | translate}}
        </a>.
      </div>
      <div class="paragraph">{{'HELP.SECOND_PARAGRAPH' | translate}}
        <a class="link" href="https://talentpowered.team/legal">{{'HELP.SECOND_LINK' | translate}}</a>.
      </div>
      <div class="paragraph">{{'HELP.THIRD_PARAGRAPH' | translate}}
        <br/>
        <a href="mailto:support@talentpowered.team" class="email-link">support@talentpowered.team</a>
      </div>
      <div class="paragraph">
        <span *ngIf="isEmployer" [innerHTML]="'HELP.EMPLOYER_TEXT_1' | translate"></span>
        <span *ngIf="!isEmployer" [innerHTML]="'HELP.FREELANCER_TEXT_1' | translate"></span>

        <div class="contact">- <a class="email-link" href="mailto:support@talentpowered.team"><b>support@talentpowered.team</b></a></div>
        <div class="contact">- <span [innerHTML]="'HELP.PHONE_SUPPORT' | translate"></span></div>
        <div class="contact">- <a [href]="isRu ? 'https://docs.google.com/forms/d/1BvqfGBQzUK5zj8bS0565uGlwDWrhaekrNMslBQkbpsU/viewform?edit_requested=true' :
          'https://docs.google.com/forms/d/e/1FAIpQLSdFYmZop4xqcyBrmIHVfegyn3OdGcUEbxd6os8F4P7V8U_LVQ/viewform'"
                                  target="_blank"
                                  class="link">{{'HELP.SERVICES_LINK' | translate}}</a></div>
      </div>
    </ng-container>

    <button mat-stroked-button (click)="reset()" class="mt-28 restart-btn">
      {{'TOUR.RUN_TOUR' | translate}}
    </button>
  </div>
</tpt-dialog>
