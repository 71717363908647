<div class="component-wrapper mb-16">
  <div class="category" *ngIf="project.parentCategoryId">{{getProjectCategory()}}
    <span class="subcategory" *ngIf="project.subCategoryId">{{getProjectSubCategory()}}</span>
  </div>
  <div class="title">{{project?.projectInfo?.name}}</div>

  <div class="main-info" *ngIf="project.details">
    <div *ngIf='project?.details?.paymentMethod' class="payment-method sub-main-info">
      <p class="main-info-category">{{'SEARCH.OPTIONS.PAYMENT_METHOD' | translate}}</p>
      <p class="main-info-value">{{'SEARCH.PAYMENT.' + project.details?.paymentMethod | translate}}</p>
    </div>

    <ng-container *ngIf="project.details?.paymentMethod === 'HOURLY'">
      <div *ngIf="project.details.paymentPeriod" class="payment-period sub-main-info">
        <p class="main-info-category">{{'SEARCH.PAYMENT_PERIOD' | translate}}</p>
        <p class="main-info-value">{{'SEARCH.PAYMENT_PERIOD_OPTION.' + project.details.paymentPeriod | translate}}</p>
      </div>

      <div *ngIf="project.details?.hourlyRate" class="hours-per-week sub-main-info">
        <p class="main-info-category">{{'FINANCE.COLUMN.RATE' | translate}}</p>
        <p class="main-info-value">{{currencySymbol[project?.currency?.code]}}{{project.details.hourlyRate}}/{{ 'FINANCE.HOUR' | translate }}</p>
      </div>

      <div *ngIf="project.details?.hoursPerWeek" class="hours-per-week sub-main-info">
        <p class="main-info-category">{{'SEARCH.HOURS_PER_WEEK' | translate}}</p>
        <p class="main-info-value">{{project.details.hoursPerWeek}}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="project.details?.paymentMethod === 'FIXED'">
      <div *ngIf="project.details?.tillDate" class="sub-main-info">
        <p class="main-info-category">{{ 'FINANCE.COLUMN.DEADLINE' | translate }}</p>
        <p class="main-info-value">
          <mat-icon class="calendar-icon" [svgIcon]="svgIconsEnum.DatepickerCalendar"></mat-icon>
          {{project.details.tillDate | localDate: 'dd.MM.yyyy'}}
        </p>
      </div>
    </ng-container>
  </div>

  <div class="tasks">
    <div *ngFor="let task of project?.tasks; let idx = index;" class="project-task">
      <div class="project-task-title-row">
        <div class="task-title">
          {{ task?.taskTitle }}
        </div>
        <div class="task-number-block">
          {{'CONTRACTS.TASK' | translate}}#{{ idx + 1 }}
        </div>
      </div>

      <div class="project-task-description">
        <ng-container>
          {{ task?.description }}
        </ng-container>
      </div>
      <div class="project-task-attachments">
        <ng-container>
          <tpt-uploaded-file
            *ngFor="let file of task?.files"
            [file]="file"
          ></tpt-uploaded-file>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="requirements">
    <div>
      <div class="requirement-block">
        <div class="requirement-label">{{'CONTRACTS.EXPERT_LEVEL' | translate}}</div>
        <div *ngFor="let level of project?.expertLevels; let lst = last;"
             class="requirement-value">
          {{ 'SEARCH.LEVEL.'+level | translate}}{{ lst ? '' : ',' }}
        </div>
      </div>

      <div class="requirement-block">
        <div class="requirement-label">{{'CONTRACTS.LANGUAGE_SKILLS' | translate}}</div>
        <div class="requirement-value">
          <div *ngFor="let language of project?.languages"
               class="language-requirements-row">
            <span class="language">{{getLangByCode(language)}}</span>
            <span class="level" *ngIf="language?.level">{{'CREATE_PROJECT.STEP3.'+language.level | translate}}</span>
          </div>
        </div>
      </div>

      <div class="requirement-block">
        <div class="requirement-label">{{'CONTRACTS.FREELANCER_LOCATION' | translate}}</div>
        <div class="requirement-value freelancer-location">
          {{ project?.country?.name || '-' }}
        </div>
      </div>
    </div>

    <div class="skills-column">
      <div *ngFor="let skill of project?.skills"
           class="skill-item">
        {{ skill.name }}
      </div>
    </div>
  </div>

  <div class="budget">
    <ng-container *ngIf="project.details.paymentMethod === 'HOURLY'">
      <div class="budget-row">
        <div class="label">
          {{'CONTRACTS.1ST_PAYMENT_AMOUNT' | translate}}
        </div>

        <div class="budget-value">
          {{currencySymbol[project?.currency?.code]}}{{ hourlyProjectTotalCost }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="project.details.paymentMethod === 'FIXED' && project?.details?.milestones?.length <= 1">
      <div class="budget-row">
        <div class="label">
          {{'CONTRACTS.PROJECT_BUDGET' | translate}}
        </div>
        <div class="budget-value">
          {{currencySymbol[project?.currency?.code]}}{{ project?.details?.budget }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="project.details.paymentMethod === 'FIXED' && project?.details?.milestones?.length > 1">
      <div class="budget-row -multiple-milestones-total">
        <div class="label">
          {{'CONTRACTS.TOTAL_PROJECT_BUDGET' | translate}}
        </div>

        <div class="budget-value">
          {{currencySymbol[project?.currency?.code]}}{{ project?.details?.budget }}
        </div>
      </div>

      <div
        *ngFor="let milestone of project?.details?.milestones; let idx = index;"
        class="budget-row">
        <div class="label">
          {{'CONTRACTS.PAYMENT_FOR_MILESTONE' | translate}}#{{ idx + 1 }}
        </div>
        <div class="budget-value">
          {{currencySymbol[project?.currency?.code]}}{{ milestone.budget }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
