import { TextMessageModel } from '../../employer/models/task-comments/text-message.model';

export class SendMessageRequestModel {

  constructor(
    public chatId: number,
    public type: string,
    public content: TextMessageModel,
  ) { }

}
