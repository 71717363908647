import {Type} from 'class-transformer';
import {FileUpload} from '../fileUpload';
import {BaseProfile} from '../profile/baseProfile';
import {JobListView} from './jobListView';
import {JobSettings} from './jobSettings';

export class SimpleJob extends JobListView {
  @Type(() => JobSettings)
  public settings: JobSettings;

  @Type(() => BaseProfile)
  public owner: BaseProfile;

  public description: string;

  @Type(() => FileUpload)
  public fileUploads: FileUpload[] = [];
}
