import { FormContext } from '../modules/tpt-forms/base/form.context';
import { RegistrationFormModel } from '../models/registration-form.model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { minLengthWithTrim } from '../modules/common-directives/directives/min-length-trim.directive';
import { Constants } from '../classes/constants';
import { Role } from '../classes/enums';
import { TptValidators } from '../modules/tpt-forms/validators/tpt.validators';

export class RegistrationForm extends FormContext<RegistrationFormModel> {

  static createForm(): RegistrationForm {
    const form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$'), Validators.maxLength(50), minLengthWithTrim(6)]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(Constants.PASSWORD_MIN_LENGTH),
        TptValidators.passwordLettersDigits('password'),
      ]),
      // passwordConfirmation: new FormControl(null, [Validators.required, TptValidators.passwordConfirmation('password', 'passwordConfirmation')]),
      userAgreement: new FormControl(null, [Validators.required]),
      privacyPolicy: new FormControl(null, [Validators.required]),
      role: new FormControl(Role.EMPLOYER, [Validators.required]),
    });

    return new RegistrationForm(form);
  }

  public get email(): AbstractControl {
    return this.getControl('email');
  }

  public get password(): AbstractControl {
    return this.getControl('password');
  }

  public get userAgreement(): AbstractControl {
    return this.getControl('userAgreement');
  }

  public get privacyPolicy(): AbstractControl {
    return this.getControl('privacyPolicy');
  }

  public get role(): AbstractControl {
    return this.getControl('role');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): RegistrationFormModel {
    return this.form.value;
  }

}
