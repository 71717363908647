import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { ProfileService } from '../../../../services/profile.service';
import { Router } from '@angular/router';
import { RouteHelper } from '../../../../helpers/route.helper';
import { BdcWalkService } from 'bdc-walkthrough';

@Component({
  selector: 'tpt-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public step = 1;

  private className = 'tpt-help-center';

  private config: MatDialogConfig = {
    position: { top: '54px', right: '0px' },
    width: '420px',
    height: 'calc(100% - 55px)',
    hasBackdrop: false,
  };

  constructor(private profileService: ProfileService,
              private router: Router,
              private routeHelper: RouteHelper,
              private bdcWalkService: BdcWalkService) {
  }

  public open(): void {
    this.dialog.config = this.config;

    if (this.dialog.dialogRef) {
      this.close();
      return;
    }

    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public openOnBoardingsStep(): void {
    this.step = 2;
  }

  public startMainOnBoarding(): void {
    this.profileService.startOnBoardingSubject.next();
    this.bdcWalkService.reset(this.profileService.currentProfile.isEmployer() ? 'onBoardingEmployer' : 'onBoardingFreelancer');
    this.bdcWalkService.setTaskCompleted('dialogWelcome', true);
    this.router.navigate(this.profileService.currentProfile.isEmployer() ? this.routeHelper.employeerProjectsDashboard :
      this.routeHelper.freelancerProjectsDashboard);
    this.close();
  }

  public startFinanceTour(): void {
    this.router.navigate(this.routeHelper.financesCommon);
    this.bdcWalkService.reset('financeOnBoarding');
    this.close();
  }

  public startProfileTour(): void {
    this.router.navigate(this.routeHelper.settingsGeneral);
    this.bdcWalkService.reset('profileOnBoarding');
    this.bdcWalkService.setTaskCompleted('startProfileTour', true);
    this.close();
  }
}
