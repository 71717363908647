import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { Stomp } from '@stomp/stompjs/esm6';
import * as SockJS from 'sockjs-client';
import { ContractsService } from '../modules/contracts/services/contracts.service';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class NewWsService {
  public newNotification: BehaviorSubject<any> = new BehaviorSubject(null);

  public chatOnBoardingWs: BehaviorSubject<any> = new BehaviorSubject(null);

  public newMessage: BehaviorSubject<any> = new BehaviorSubject(null);

  public updateUnreadCount = new Subject<any>();

  public operationSuccess: Subject<any> = new Subject<any>();

  public taskSubject = new Subject<any>();

  private stompClient;

  constructor(private contractsService: ContractsService) {}

  connect() {
    const serverUrl = `${window.location.protocol}//${environment.MAIN_HOST}/ws/connect`;
    const ws = new SockJS(serverUrl);
    this.stompClient = Stomp.over(ws);

    this.stompClient.connect({ Authorization: this.token}, (frame) => {
      this.stompClient.subscribe('/user/queue/notifications', (greeting) => {
        const message = JSON.parse(greeting.body);
        this.newNotification.next(message);
      });

      this.stompClient.subscribe('/user/queue/negotiation', (message) => {
        if (message && message.body) {
          this.contractsService.termsChanged.next(message.body);
        }
      });

      this.stompClient.subscribe('/user/queue/system', (message) => {
        if (message && message.body) {
          this.chatOnBoardingWs.next(JSON.parse(message.body));
        }
      });

      this.stompClient.subscribe('/user/queue/finance', (message) => {
        if (message && message.body) {
          this.operationSuccess.next(JSON.parse(message.body));
        }
      });

      this.stompClient.subscribe('/user/queue/chats', (message) => {
        if (message && message.body) {
          const value = JSON.parse(message.body);
          this.newMessage.next(value);

          this.updateUnreadCount.next(value?.sender?.id);
        }
      });

      this.stompClient.subscribe('/user/queue/tasks', (message) => {
        if (message && message.body) {
          this.taskSubject.next(JSON.parse(message.body));
        }
      });
    });

    this.stompClient.onStompError = (err) => {
      console.log(err, '<<<<<onStompError');
    };
  }

  disconnect() {
    this.stompClient?.disconnect();
  }

  get token() {
    return 'Bearer ' + localStorage.getItem(TOKEN_KEY);
  }
}
