import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root',
})
export class SchemalessService extends BaseService {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public saveCheckList = (data: any): Observable<any> => {
    return fromPromise(this.executePost<any>(`v2/schemaless`, data));
  }

}
