import { Category } from '../models/category';
import { Country } from '../models/country';
import { Currency } from '../models/currency';
import { Skill } from '../models/skill';
import { Settings } from './settings';

export class dictionary  {
  constructor( readonly categories: Category[],
               readonly countries: Country[],
               readonly currencies: Currency[],
               readonly skills: Skill[],
               readonly settings: Settings) {
  }
}

export enum TimelineBlockType {
  RESOURCE  = 'RESOURCE',
  MILESTONE = 'MILESTONE',
  TASK      = 'TASK',
}

export enum TimelineScale {
  QUARTERLY = 'QUARTERLY',
  MONTHLY   = 'MONTHLY',
}

export enum SettingsKeys {
  MAX_SIZE = 'maxsize',
}

export enum BugStatus {
  OPENED   = 'OPE',
  FIXED    = 'FIX',
  REJECTED = 'REJ',
  CLOSED   = 'CLO',
}

export enum TestExecutionStatus {
  FAILED     = 'FAI',
  NOT_TESTED = 'NOT',
  PASSED     = 'PAS',
}

export enum ProjectState {
  INIT               = 'IN',
  ACCEPTED           = 'AC',
  STARTED            = 'ST',
  ENDED              = 'EN',
  PAUSED             = 'PA',
  RESUMING           = 'RE',
  ENDED_WITH_DISPUTE = 'DI',
}

export enum MilestoneState {
  STARTED                        = 'STA',
  PAUSED_WITH_DISPUTE            = 'PWD',
  PAUSED_WITH_INSUFFICIENT_FUNDS = 'PWIF',
  PAUSED                         = 'PAU',
  ENDED_WITH_DISPUTE             = 'EWD',
  FINISHED                       = 'FIN'
}

export enum ProgressStatus {
  OPEN             = 'OP',
  IN_PROGRESS      = 'PR',
  READY_FOR_REVIEW = 'RR',
  NOT_ACCEPTED     = 'NA',
  ACCEPTED         = 'AC',
}

export enum ProjectNodeWidth {
  SHORT = 0,
  BASE  = 1,
  WIDE  = 2,
  FULL  = 3,
}

export enum Role {
  EMPLOYER        = 'EMPLOYER',
  FREELANCER      = 'FREELANCER',
  ADMIN           = 'ADMIN',
  ARBITRAGE_JUDGE = 'ARBITRAGE_JUDGE',
  NOT_SELECTED = 'NOT_SELECTED'
}

export enum RIssueStatus {
  NEW     = 'NEW',
  BACKLOG = 'BACKLOG',
}

export enum SignalType {
  JOB           = 'JOB',
  PROJECT       = 'PROJECT',
  TASK          = 'TASK',
  SUGGESTION    = 'SUGGESTION',
  DOCUMENT      = 'DOCUMENT',
  PAYMENT       = 'PAYMENT',
  DISPUTE       = 'DISPUTE',
  MILESTONE     = 'MILESTONE',
  DEPOSIT       = 'DEPOSIT',
  UPCOMING_HOLD = 'UPCOMING_HOLD',
  MESSAGE       = 'MESSAGE',
  PROJECT_STATE = 'PROJECT_STATE'
}

export enum NotificationType {
  EMPLOYER_REGISTERED= 'EMPLOYER.REGISTERED',
  FREELANCER_REGISTERED= 'FREELANCER.REGISTERED',
  EMPLOYER_FREELANCER_INVITED = 'EMPLOYER.FREELANCER_INVITED',
  FREELANCER_FREELANCER_INVITED = 'FREELANCER.FREELANCER_INVITED',
  EMPLOYER_EMPLOYER_CHANGE_PROJECT = 'EMPLOYER.EMPLOYER_CHANGE_PROJECT',
  FREELANCER_EMPLOYER_CHANGE_PROJECT = 'FREELANCER.EMPLOYER_CHANGE_PROJECT',
  EMPLOYER_FREELANCER_PROPOSAL = 'EMPLOYER.FREELANCER_PROPOSAL',
  FREELANCER_FREELANCER_PROPOSAL = 'FREELANCER.FREELANCER_PROPOSAL',
  EMPLOYER_EMPLOYER_ACCEPTED = 'EMPLOYER.EMPLOYER_ACCEPTED',
  FREELANCER_EMPLOYER_ACCEPTED = 'FREELANCER.EMPLOYER_ACCEPTED',
  EMPLOYER_EMPLOYER_ACCEPTED_CHANGES = 'EMPLOYER.EMPLOYER_ACCEPTED_CHANGES',
  FREELANCER_EMPLOYER_ACCEPTED_CHANGES = 'FREELANCER.EMPLOYER_ACCEPTED_CHANGES',
  EMPLOYER_FREELANCER_ACCEPTED = 'EMPLOYER.FREELANCER_ACCEPTED',
  FREELANCER_FREELANCER_ACCEPTED = 'FREELANCER.FREELANCER_ACCEPTED',
  EMPLOYER_FREELANCER_ACCEPTED_CHANGES = 'EMPLOYER.FREELANCER_ACCEPTED_CHANGES',
  FREELANCER_FREELANCER_ACCEPTED_CHANGES = 'FREELANCER.FREELANCER_ACCEPTED_CHANGES',
  EMPLOYER_EMPLOYER_DECLINED = 'EMPLOYER.EMPLOYER_DECLINED',
  FREELANCER_EMPLOYER_DECLINED = 'FREELANCER.EMPLOYER_DECLINED',
  EMPLOYER_EMPLOYER_DECLINED_CHANGES = 'EMPLOYER.EMPLOYER_DECLINED_CHANGES',
  FREELANCER_EMPLOYER_DECLINED_CHANGES = 'FREELANCER.EMPLOYER_DECLINED_CHANGES',
  EMPLOYER_FREELANCER_DECLINED_CHANGES = 'EMPLOYER.FREELANCER_DECLINED_CHANGES',
  FREELANCER_FREELANCER_DECLINED_CHANGES = 'FREELANCER.FREELANCER_DECLINED_CHANGES',
  EMPLOYER_FREELANCER_DECLINED = 'EMPLOYER.FREELANCER_DECLINED',
  FREELANCER_FREELANCER_DECLINED = 'FREELANCER.FREELANCER_DECLINED',
  FREELANCER_FREELANCER_OPEN_DISPUTE = 'FREELANCER.FREELANCER_OPEN_DISPUTE',
  EMPLOYER_FREELANCER_OPEN_DISPUTE = 'EMPLOYER.FREELANCER_OPEN_DISPUTE',
  EMPLOYER_EMPLOYER_OPEN_DISPUTE = 'EMPLOYER.EMPLOYER_OPEN_DISPUTE',
  FREELANCER_EMPLOYER_OPEN_DISPUTE = 'FREELANCER.EMPLOYER_OPEN_DISPUTE',
  FREELANCER_EMPLOYER_CLOSED_DISPUTE = 'FREELANCER.EMPLOYER_CLOSED_DISPUTE',
  EMPLOYER_FREELANCER_CLOSED_DISPUTE = 'EMPLOYER.FREELANCER_CLOSED_DISPUTE',
  EMPLOYER_EMPLOYER_ACCEPTED_ALL_TASKS = 'EMPLOYER.EMPLOYER_ACCEPTED_ALL_TASKS',
  FREELANCER_EMPLOYER_ACCEPTED_ALL_TASKS = 'FREELANCER.EMPLOYER_ACCEPTED_ALL_TASKS',
  EMPLOYER_EMPLOYER_INSUFFICIENT_FUNDS = 'EMPLOYER.EMPLOYER_INSUFFICIENT_FUNDS',
  EMPLOYER_MILESTONE_INSUFFICIENT_FUNDS = 'EMPLOYER.MILESTONE_INSUFFICIENT_FUNDS',
  EMPLOYER_PROJECT_INSUFFICIENT_FUNDS = 'EMPLOYER.PROJECT_INSUFFICIENT_FUNDS',
  FREELANCER_GOT_PAID = 'FREELANCER.GOT_PAID',
  CARD_ATTACHED = 'CARD_ATTACHED',
  CARD_DETACHED = 'CARD_DETACHED',
  PAYONEER_DETACHED = 'PAYONEER_DETACHED',
  FREELANCER_OTHER_FREELANCER_SELECTED = 'FREELANCER.OTHER_FREELANCER_SELECTED',
  EMPLOYER_FREELANCER_DECLINE_SELF = 'EMPLOYER.FREELANCER_DECLINE_SELF',
  FREELANCER_FREELANCER_DECLINE_SELF = 'FREELANCER.FREELANCER_DECLINE_SELF',
  EMPLOYER_EMPLOYER_DECLINE_SELF = 'EMPLOYER.EMPLOYER_DECLINE_SELF',
  FREELANCER_EMPLOYER_DECLINE_SELF = 'FREELANCER.EMPLOYER_DECLINE_SELF',
  EMPLOYER_FREELANCER_LEFT_COMMENT = 'EMPLOYER.FREELANCER_LEFT_COMMENT',
  FREELANCER_EMPLOYER_LEFT_COMMENT = 'FREELANCER.EMPLOYER_LEFT_COMMENT',
  EMPLOYER_FREELANCER_TASK_STATUS_IN_PROGRESS = 'EMPLOYER.FREELANCER_TASK_STATUS_IN_PROGRESS',
  EMPLOYER_FREELANCER_TASK_STATUS_REVIEW = 'EMPLOYER.FREELANCER_TASK_STATUS_REVIEW',
  EMPLOYER_FREELANCER_TASK_STATUS_TO_DO = 'EMPLOYER.FREELANCER_TASK_STATUS_TO_DO',
  FREELANCER_EMPLOYER_ACCEPTED_TASK = 'FREELANCER.EMPLOYER_ACCEPTED_TASK',
  FREELANCER_EMPLOYER_DECLINED_TASK = 'FREELANCER.EMPLOYER_DECLINED_TASK',
  FREELANCER_EMPLOYER_EDITED_TASK = 'FREELANCER.EMPLOYER_EDITED_TASK',
  EMPLOYER_FREELANCER_REWORK_TASK = 'EMPLOYER.FREELANCER_REWORK_TASK',
  FREELANCER_EMPLOYER_CREATED_TASK = 'FREELANCER.EMPLOYER_CREATED_TASK',
  EMPLOYER_FREELANCER_STARTED_TASK = 'EMPLOYER.FREELANCER_STARTED_TASK',
  EMPLOYER_FREELANCER_DECLINED_TASK = 'EMPLOYER.FREELANCER_DECLINED_TASK',

  EMPLOYER_FREELANCER_CHANGE_PROJECT = 'EMPLOYER.FREELANCER_CHANGE_PROJECT',
  FREELANCER_FREELANCER_CHANGE_PROJECT = 'FREELANCER.FREELANCER_CHANGE_PROJECT',
  EMPLOYER_REVIEW_DEADLINE_IS_SOON = 'EMPLOYER.REVIEW_DEADLINE_IS_SOON',
  EMPLOYER_AUTO_ACCEPT = 'EMPLOYER.AUTO_ACCEPT',
  FREELANCER_EMPLOYER_REVOKE_OWN_CHANGES = 'FREELANCER.EMPLOYER_REVOKE_OWN_CHANGES',
  EMPLOYER_FREELANCER_REVOKE_OWN_CHANGES = 'EMPLOYER.FREELANCER_REVOKE_OWN_CHANGES',
  EMPLOYER_FREELANCER_STARTED_ARBITRAGE = 'EMPLOYER.FREELANCER_STARTED_ARBITRAGE',
  FREELANCER_EMPLOYER_STARTED_ARBITRAGE = 'FREELANCER.EMPLOYER_STARTED_ARBITRAGE',
  EMPLOYER_REVIEW_ABOUT_FREELANCER_IS_ALLOWED = 'EMPLOYER.REVIEW_ABOUT_FREELANCER_IS_ALLOWED',
  FREELANCER_REVIEW_ABOUT_EMPLOYER_IS_ALLOWED = 'FREELANCER.REVIEW_ABOUT_EMPLOYER_IS_ALLOWED',

  EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER = 'EMPLOYER.ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER',
  FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER = 'FREELANCER.ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER',
  EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER = 'EMPLOYER.ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER',
  FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER = 'FREELANCER.ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER',
  EMPLOYER_ARBITRAGE_DECISION_IN_PROPORTION = 'EMPLOYER.ARBITRAGE_DECISION_IN_PROPORTION',
  FREELANCER_ARBITRAGE_DECISION_IN_PROPORTION = 'FREELANCER.ARBITRAGE_DECISION_IN_PROPORTION',

  EMPLOYER_RUBLE_NEED_CREATE_AN_INVOICE = 'EMPLOYER.RUBLE_NEED_CREATE_AN_INVOICE',
  EMPLOYER_RUBLE_NEED_PAY_AN_INVOICE = 'EMPLOYER.RUBLE_NEED_PAY_AN_INVOICE',
  EMPLOYER_RUBLE_BALANCE_REPLENISHED = 'EMPLOYER.RUBLE_BALANCE_REPLENISHED',
}

export enum SuggestionType {
  OFFER    = 'O',
  PROPOSAL = 'P',
}

export enum JobState {
  PUBLISHED          = 'PU',
  STARTED            = 'ST',
  COMPLETE           = 'CO',
  ENDED              = 'EN',
  ENDED_WITH_DISPUTE = 'DI',
}

export enum EnglishLevel {
  BEGINNER           = 'B',
  ELEMENTARY         = 'E',
  PRE_INTERMEDIATE   = 'P',
  INTERMEDIATE       = 'I',
  UPPER_INTERMEDIATE = 'U',
  ADVANCED           = 'A',
  FLUENT             = 'F',
}

export enum RatingStarSize {
  SMALL  = 'small',
  NORMAL = 'normal',
  BIG    = 'big',
}

export enum EnglishLevelStars {
  B = 1,
  E = 2,
  P = 3,
  I = 4,
  U = 5,
  A = 6,
  F = 7,
}
export enum TransactionStatus {
  SUCCEEDED = 'succeeded',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method'
}

export enum OperationTypes {
  DEBET        = 'DEBET'       ,
  CREDIT       = 'CREDIT'      ,
  HOLD         = 'HOLD'        ,
  UNHOLD       = 'UNHOLD'      ,
  WITHDRAW     = 'WITHDRAW'    ,
  DEPOSIT      = 'DEPOSIT'     ,
  FEE          = 'FEE'         ,
  PENALTY      = 'PENALTY'     ,
  EXTERNAL_FEE = 'EXTERNAL_FEE',
}

export enum TransactionTypes {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  TRANSFER       = 'TRANSFER'      ,
  HOLD           = 'HOLD'          ,
  RELEASEHOLD    = 'RELEASEHOLD'   ,
  WITHDRAW       = 'WITHDRAW'      ,
  DEPOSIT        = 'DEPOSIT'       ,
}

export enum ExpertLevel {
  BEGINNER     = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  MASTER       = 'MASTER',
}

export enum PaymentMethod {
  HOURLY = 'H',
  FIXED  = 'F',
}

export enum ContentType {
  CONVERSATION = 'CV',
  JOB          = 'JB',
  TASK         = 'TS',
  PROJECT      = 'PR',
  AVATAR       = 'AV',
}

export enum JobVisibility {
  PRIVATE    = 'PRI',
  INTERNALLY = 'INT',
  PUBLIC     = 'PUB',
}

export enum ProjectProblem {
  NO                 = 'NO',
  PAUSE              = 'PAUSE',
  ARBITRAGE          = 'ARBITRAGE',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  RESUMING           = 'RESUMING',
  DISPUTE            = 'DISPUTE',
  ARB_DIS            = 'ARB_DIS',
}

export enum Action {
  SEND               = 'SEND',
  CREATE             = 'CREATE',
  ADD                = 'ADD',
  MODIFY             = 'MODIFY',
  DELETE             = 'DELETE',
  ACCEPT             = 'ACCEPT',
  DECLINE            = 'DECLINE',
  UPLOAD             = 'UPLOAD',
  TRANSFER           = 'TRANSFER',
  HOLD               = 'HOLD',
  HOLD_RELEASED      = 'HOLD_RELEASED',
  NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
  WARN_NOT_ENOUGH_BALANCE = 'WARN_NOT_ENOUGH_BALANCE',
  PAUSED             = 'PAUSED',
  RESUMED            = 'RESUMED',
  SUCCESS            = 'SUCCESS',
  NOT_SUCCESS        = 'NOT_SUCCESS',
  START_RESUME       = 'START_RESUME',
  DECLINE_RESUME     = 'DECLINE_RESUME',
  LEAVE              = 'LEAVE',
  TRACK_TIME         = 'TRACK_TIME',
  MOVE_TO_ARBITRAGE  = 'MOVE_TO_ARBITRAGE',
  FINISHED           = 'FINISHED',
  EMPLOYER_WIN       = 'EMPLOYER_WIN',
  FREELANCER_WIN     = 'FREELANCER_WIN',
}

export enum LogLevel {
  ERROR = 1,
  WARN  = 2,
  INFO  = 3,
}

export enum ChangeHistoryStatus {
  ADDED    = 'ADD',
  DELETED  = 'DEL',
  CHANGED  = 'CHA',
  CURRENT  = 'CUR',
  ARCHIVE  = 'ARC',
  DECLINED = 'DEC',
}

export enum AcceptanceState {
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  ACCEPTING    = 'ACCEPTING',
  ACCEPTED     = 'ACCEPTED',
}

export enum ProfileNotificationType {
  JOB           = 1,
  PROJECT       = 2,
  TASK          = 4,
  SUGGESTION    = 8,
  DOCUMENT      = 16,
  PAYMENT       = 32,
  DISPUTE       = 64,
  MILESTONE     = 128,
  DEPOSIT       = 256,
  PROJECT_STATE = 512,
  UPCOMING_HOLD = 1024,
}

export enum TaskHistoryType {
  CREATE = 'CR',
  UPDATE = 'UP',
  DELETE = 'DE',
}

export enum TaskHistoryStatus {
  ATTEMPT  = 'AT',
  ACCEPTED = 'AC',
  DECLINED = 'DE',
}

export enum SuggestionSort {
  NO_SELECTED = 1,
  START,
  TILL,
  DURATION,
  BUDGET,
}

export enum InvoiceOperationStatus {
  INIT               = 'INIT',
  FAIL               = 'FAIL',
  PROCESSED          = 'PROCESSED',
  SUCCESS            = 'SUCCESS',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum FinEventType {
  EXPENCE = 'EXPENCE',
  INCOME  = 'INCOME',
  HOLD    = 'HOLD'
}

export enum InvoiceOperation {
  HOLD           = 'HOLD',
  TRANSFER       = 'TRANSFER',
  MANUAL_CHANGES = 'MANUAL_CHANGES',
}

export enum InvoiceStatus {
  RELEASED                     = 'RELEASED',
  PAID                         = 'PAID',
  PAID_WITH_EMPLOYER_PENALTY   = 'PAID_WITH_EMPLOYER_PENALTY',
  PAID_WITH_FREELANCER_PENALTY = 'PAID_WITH_FREELANCER_PENALTY',
  PAID_WITH_MUTUAL_PENALTY     = 'PAID_WITH_MUTUAL_PENALTY',
}

export enum AdminAlertStatus {
  INIT          = 'INIT',
  AUTO_RESOLVED = 'AUTO_RESOLVED',
  RESOLVED      = 'RESOLVED',
}

export enum AdminAlertType {
  WARNING = 'WARNING',
  ALARM   = 'ALARM',
}

export enum SuggestionCreationMode {
  CREATION   = 'CREATION',
  RECREATION = 'RECREATION',
  UPDATE     = 'UPDATE',
}

export enum MilestoneNegotiationStatus {
  INIT              = 'IN',
  ACCEPTED          = 'AC',
  DECLINED          = 'DE',
  CLOSED            = 'CL',
  MOVE_TO_ARBITRAGE = 'MA',
  EMPLOYER_WIN      = 'EW',
  FREELANCER_WIN    = 'FW',
}

export enum TransactionType {
  DEPOSIT  = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW'
}

export enum ProjectSorting {
  NEWEST_FIRST = 'NEWEST_FIRST',
  OLDEST_FIRST = 'OLDEST_FIRST',
}

export enum ProjectSearchSorting {
  PUBLISH_DATE_ASC = 'PUBLISH_DATE_ASC',
  PUBLISH_DATE_DESC = 'PUBLISH_DATE_DESC',
}

export enum FreelancerSearchSorting {
  RATE_ASC = 'RATE_ASC',
  RATE_DESC = 'RATE_DESC',
}

export enum ProjectViewMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum ResourceDiffMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum ProfileOnlineStatus {
  ONLINE  = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum JobSort {
  RECENT = 'RECENT',
  OLDEST = 'OLDEST',
}

export enum InboxFilter {
  ALL,
  PROJECTS,
  PROPOSALS,
  OFFERS
}

export enum ProjectWorkflowType {
  PRE_PROJECT = 'PRE_PROJECT',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEW      = 'REVIEW',
  NEED_REVIEW = 'NEED_REVIEW',
  UNSOLVED    = 'UNSOLVED',
  FINISHED    = 'FINISHED',
  DECLINED    = 'DECLINED',
  ACCEPTED    = 'ACCEPTED'
}

export enum ActivityBadgeStyle {
  DEFAULT  = 'DEFAULT',
  PRIMARY  = 'PRIMARY',
  ACCEPTED = 'ACCEPTED',
  CHANGED  = 'CHANGED',
  CANCELED = 'CANCELED',
  HIDDEN   = 'HIDDEN',
}

export enum ActivityCardStyle {
  DEFAULT = 'DEFAULT',
  ALERT   = 'ALERT',
  INCOME  = 'INCOME',
  OUTCOME = 'OUTCOME',
}

export enum ActivityCardFormat {
  DEFAULT  = 'DEFAULT',
  CENTERED = 'CENTERED',
}

export enum SuggestionViewResult {
  ACCEPT      = 'ACCEPT',
  DECLINE     = 'DECLINE',
  SET_DEFAULT = 'SET_DEFAULT',
}

export enum RateTabMode {
  REVIEWS = 'REVIEWS',
  MYRATES = 'MYRATES',
}

export enum RateFilter {
  ALL       = 'ALL',
  RATED     = 'RATED',
  NOT_RATED = 'NOT_RATED',
}

export enum InboxCardStyle {
  STACK,
  NAME,
  VISIBLE
}

export enum ForgotPasswordStates {
  INIT             = 0,
  EMAIL_INPUT      = 1,
  EMAIL_SENT       = 2,
  PASSWORD_INPUT   = 4,
  PASSWORD_CHANGED = 8
}

export enum WSViduMessageId {
  REGISTER               = 'register',
  UNREGISTER             = 'unregister',
  CREATE_ROOM            = 'createRoom',
  INVITE_USER            = 'inviteUser',
  WITHDRAW_INVITE_USER   = 'withdrawInviteUser',
  JOIN_ROOM              = 'joinRoom',
  LEAVE_ROOM             = 'leaveRoom',
  INCOMING_CALL_RESPONSE = 'incomingCallResponse',

  // outgoing
  INVITE_RESPONSE        = 'inviteResponse',
  INCOMING_CALL          = 'incomingCall',
  WITHDRAW_RESPONSE      = 'withdrawResponse',
  REGISTER_RESPONSE      = 'registerResponse',
  CREATE_ROOM_RESPONSE   = 'createRoomResponse',
  WITHDRAW_INCOMING_CALL = 'withdrawIncomingCall',
  JOIN_ROOM_RESPONSE     = 'joinRoomResponse',
  USER_LEAVE_ROOM        = 'userLeaveRoom',
  LEAVE_ROOM_RESPONSE    = 'leaveRoomResponse',
  ERROR                  = 'error'
}

export enum DraftedTasksActions {
  NOTHING             = 'nothing',
  SEND_TO_TARGET      = 'sendToTarget',
  CREATE_NEW_RESOURCE = 'createNewResource',
  DELETE_TASKS        = 'deleteTasks'
}

export enum ActionTarget {
  FREELANCER = 'freelancer',
  PROJECT    = 'project',
  RESOURCE   = 'resource',
}

export enum ArbitrageMilestoneState {
  IN_ARBITRAGE = 'IN_A',
  WON          = 'WON',
  LOST         = 'LOST',
}
