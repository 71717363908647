import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { HireFreelancerDialogComponent } from '../../dialogs/hire-freelancer-dialog/hire-freelancer-dialog.component';
import { ProfileModel } from '../../../../../models/profile.model';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { PlatformsEnum, PlatformsEnumFrontend } from '../../../../../types/platforms.enum';
import { FreelancerLegalStatusEnum } from '../../../../../models/legalStatuses.enum';
import { ProfilesApiService } from '../../../../../services/profiles.api.service';
import { RouteHelper } from '../../../../../helpers/route.helper';
import { ProfileService } from '../../../../../services/profile.service';
import { SnackbarNotificationsService } from '../../../../../services/snackbar-notifications.service';
import { SpecializationModel } from '../../../../../models/specialization.model';

@Component({
  selector: 'tpt-freelancer-search-card',
  templateUrl: './freelancer-search-card.component.html',
  styleUrls: ['./freelancer-search-card.component.scss']
})
export class FreelancerSearchCardComponent implements OnInit {

  @ViewChild(HireFreelancerDialogComponent) hireFreelancerDialog: HireFreelancerDialogComponent;

  @Input() freelancer: ProfileModel;
  @Input() empProjects = [];
  @Input() skillsFilter: FormArray;

  public platformsEnumFe = PlatformsEnumFrontend;
  public svgIconsEnum = SvgIconsEnum;
  public platformsEnum = PlatformsEnum;
  public freelancerLegalStatusEnum = FreelancerLegalStatusEnum;

  get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  constructor(
    private translate: TranslateService,
    public profileApiService: ProfilesApiService,
    public profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private routeHelper: RouteHelper,
    private clipboard: Clipboard,
    private snack: SnackbarNotificationsService,
  ) { }

  ngOnInit(): void {
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public openCardDetails(freelancer): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.userPage(freelancer.id))
    );

    window.open(url, '_blank');
  }

  public getChipStyle(skill): boolean {
    const skillsFilter = this.skillsFilter.value;

    return skillsFilter.some(item => item.id === skill.id);
  }

  hireFreelancer(freelancer): void {
    this.hireFreelancerDialog.open(freelancer, this.empProjects);
  }

  public getLink(uuid: string): string {
    return this.profileApiService.getReviewsLink(uuid);
  }

  public getYears(val): number {
    if (val < 12) {
      return 0;
    }
    return Math.floor(val / 12);
  }

  public getMonths(val): number {
    return val % 12;
  }

  stop(e) {
    e.stopPropagation();
  }

  public specSkills(specialization: SpecializationModel) {
    const skills = specialization.skills || [];
    const customSkills = specialization.customSkills || [];

    const allSkills = [...skills, ...customSkills];
    const skillsLength = allSkills?.length;

    let showedSkills;
    if (skillsLength > 6 && !specialization.showAllSkills) {
      showedSkills = allSkills.slice(0, 6);
      specialization.moreSkillsCount = skillsLength - 6;
    } else {
      showedSkills = allSkills;
    }

    return showedSkills;
  }

  public showAllSkills(spec: SpecializationModel): void {
    spec.showAllSkills = true;
    spec.moreSkillsCount = 0;
  }

  public openProfilePage(freelancer): void {
    let baseUrl = window.location.href;
    baseUrl = baseUrl.split('/').slice(0, 3).join('/');

    const userUrl = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.userPage(freelancer.id))
    );

    const fullUrl = `${baseUrl}${userUrl}`;

    this.clipboard.copy(fullUrl);
    this.snack.showNotification('SETTINGS.PROFILE_LINK_COPIED', 'success');
  }
}
