import { Injectable } from '@angular/core';
import { ProjectApiService } from '../../../services/project.api.service';
import { Observable, of, Subject } from 'rxjs';
import { TasksResponseModel } from '../models/tasks-response.model';
import { AddTaskForm } from '../forms/add-task.form';
import { AddTaskFormModel } from '../models/add-task-form.model';
import { SimpleProjectResponseModel } from '../models/simple-project-response.model';
import { tap } from 'rxjs/operators';
import { get, map, set, omitBy } from 'lodash';
import * as moment from 'moment';
import { ProjectDetailedTaskModel } from '../models/project-detailed-task.model';
import { CommentsApiService } from './api/comments-api.service';
import { NotesApiService } from './api/notes-api.service';
import { TaskCommentsResponseModel } from '../models/task-comments/task-comments-response.model';
import { TaskCommentsRequestModel } from '../models/task-comments/task-comments-request.model';
import { SendTaskCommentsRequestModel } from '../models/task-comments/send-task-comments-request.model';
import { SendTaskCommentsResponseModel } from '../models/task-comments/send-task-comments-response.model';
import { FileUpload } from '../../../models/fileUpload';
import { TextMessageModel } from '../models/task-comments/text-message.model';
import { ProjectStatusEnum } from '../types/project-status.enum';
import { NoteItemModel } from '../models/project-notes/note-item.model';
import { CreateProjectNoteRequestModel } from '../models/project-notes/create-project-note-request.model';
import { ChangeTaskStatusMessageModel } from '../models/task-comments/change-task-status-message.model';
import { ProfileService } from '../../../services/profile.service';
import { DataLayerService } from '../../../services/data-layer.service';
import { PriorityTypeEnum } from '../types/priority-type.enum';

@Injectable()
export class ProjectBoardService {

  public currentProjectId: number;

  public projectUpdated: Subject<any> = new Subject<any>();

  public get isProjectTypeFixed(): boolean {

    return get(this.projectInfo, 'details.paymentMethod', 'FIXED') === 'FIXED';
  }

  public get isProjectTypeHourly(): boolean {
    return get(this.projectInfo, 'details.paymentMethod') === 'HOURLY';
  }

  private projectInfo: SimpleProjectResponseModel;

  constructor(
    public projectApiService: ProjectApiService,
    private notesApiService: NotesApiService,
    private commentsApiService: CommentsApiService,
    private profileService: ProfileService,
    private dataLayerService: DataLayerService,
  ) { }

  public getClassByStatus(status: ProjectStatusEnum): string {
    switch (status) {
      case ProjectStatusEnum.ToDo:
        return 'to-do';
      case ProjectStatusEnum.InProgress:
        return 'in-progress';
      case ProjectStatusEnum.Review:
        return 'review';
      case ProjectStatusEnum.Done:
        return 'done';
      case ProjectStatusEnum.Hold:
        return 'hold';
    }
  }

  public transformProjectStatusToFrontendFormat(status: ProjectStatusEnum): string {
    switch (status) {
      case ProjectStatusEnum.ToDo:
        return 'To Do';
      case ProjectStatusEnum.InProgress:
        return 'In Progress';
      case ProjectStatusEnum.Review:
        return 'Review';
      case ProjectStatusEnum.Done:
        return 'Done';
      case ProjectStatusEnum.Hold:
        return 'Hold';
    }
  }

  public createAddTaskForm(task: ProjectDetailedTaskModel): AddTaskForm {
    return AddTaskForm.createForm(task ? task : new ProjectDetailedTaskModel(), this.isProjectTypeFixed);
  }

  public getProjectInfo = (id: number): Observable<SimpleProjectResponseModel> => {
    return this.projectApiService.getProjectById(id).pipe(
      tap((project: SimpleProjectResponseModel) => this.projectInfo = project)
    );
  }

  public getProjectTasks = (id: number | null, freelancerId?: number | null, group?: string, taskName?: string, allJobs?: boolean): Observable<TasksResponseModel> => {
    if (!id) {
      return of(null);
    }

    return this.projectApiService.getProjectTasks(id, freelancerId, group, taskName, allJobs);
  }

  public editBoardTask = (model: AddTaskFormModel): Observable<any> => {
    set(model, 'deadline', model.deadline ? moment(model.deadline).format('YYYY-MM-DD') : null);
    if (model.job && model.job.id) {
      set(model, 'jobId', model.job.id || null);
    }

    return this.projectApiService.editProjectTask(model);
  }

  public getTaskDetails = (taskId: number): Observable<ProjectDetailedTaskModel> => {
    return this.projectApiService.getTaskDetails(taskId);
  }

  public getTaskComments = (taskId: number): Observable<TaskCommentsResponseModel> => {
    const model = new TaskCommentsRequestModel(taskId);
    return this.commentsApiService.getTaskComments(model);
  }

  public sendTaskTextComment = (
    taskId: number,
    text: string,
    files: Array<FileUpload> = [],
  ): Observable<SendTaskCommentsResponseModel> => {
    const messageModel = new TextMessageModel(text, map(files, (_: FileUpload) => _.id));
    const model = new SendTaskCommentsRequestModel(taskId, messageModel);

    return this.commentsApiService.sendTaskComments(model);
  }

  public sendTaskStatusChangeComment = (
    taskId: number,
    message: string,
    status: ProjectStatusEnum,
  ): Observable<SendTaskCommentsResponseModel> => {
    const messageModel = new ChangeTaskStatusMessageModel(message, status);
    const model = new SendTaskCommentsRequestModel(taskId, messageModel);
    return this.commentsApiService.sendTaskComments(model);
  }

  public changeTaskStatus = (
    id: number,
    status: ProjectStatusEnum
  ): Observable<{ id: number, status: ProjectStatusEnum }> => {
    const model = { id, status };
    return this.projectApiService.changeTaskStatus(model);
  }

  public deleteTask = (taskId: number): Observable<void> => {
    return this.projectApiService.deleteTask(taskId);
  }

  public getVacancies = (projectId: number, stateType?: string): Observable<any> => {
    return this.projectApiService.getVacancies(projectId, stateType);
  }

  public changeTaskPriority = (model: { id: number, priority: PriorityTypeEnum }): Observable<void> => {
    return this.projectApiService.changePriority(model);
  }

  public getNotes = (projectId: number): Observable<Array<NoteItemModel>> => {
    return this.notesApiService.getNotes(projectId);
  }

  public createNote = (projectId: number, text: string): Observable<NoteItemModel> => {
    const model = new CreateProjectNoteRequestModel(projectId, text);
    return this.notesApiService.createNote(model);
  }

  public deleteNote = (note: NoteItemModel): Observable<void> => {
    return this.notesApiService.deleteNote(note.id);
  }

  public pushChangeStatusToDataLayer = (id: number, status: ProjectStatusEnum, jobId?: number): void => {
    const event = 'moveTask';
    const projectId = `${this.currentProjectId}`;
    const taskId = `${id}`;
    const projectType = this.projectInfo.type === 'COMPLEX' ? 'complex' : 'single';
    // console.log(status);
    const taskStatus = status === ProjectStatusEnum.ToDo
      ? 'todo'
      : status === ProjectStatusEnum.InProgress
        ? 'inProgress'
        : status === ProjectStatusEnum.Done
          ? 'done'
          : status === ProjectStatusEnum.Review
            ? 'review'
            : status === ProjectStatusEnum.Hold
              ? 'hold'
              : null;

    if (!taskStatus) {
      return;
    }

    const model = omitBy(
      { event, projectId, taskId, taskStatus, projectType, jobId: `${jobId}` },
      (field: string | number) => !Boolean(field),
    );

    this.dataLayerService.pushToDataLayer(model);
  }

}
