import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../classes/enums';
import { ProfileService } from '../services/profile.service';

const USER_KEY = 'auth-user';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private profileService: ProfileService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem(USER_KEY));
    const expectedRoles = route.data.expectedRoles as Role[];

    if (currentUser && expectedRoles.indexOf(currentUser.role) !== -1) {
      // authorized under the required role so return true
      return true;
    }

    // unauthorized under the required role so redirect to home page with the return url
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
