import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { NoteItemModel } from '../../employer/models/project-notes/note-item.model';
import * as moment from 'moment';
import { upperFirst } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'emp-project-note',
  templateUrl: './project-note.component.html',
  styleUrls: [ './project-note.component.scss' ],
})
export class ProjectNoteComponent {

  @Input()
  public note: NoteItemModel;

  @Input()
  public activeNote: boolean;

  @Output()
  public readonly deleteNote = new EventEmitter<NoteItemModel>();

  public svgIconsEnum = SvgIconsEnum;

  constructor(private translate: TranslateService) {}

  public get noteCreateTime(): string {
    const lang = this.translate.currentLang;
    return upperFirst(moment(this.note.date).locale(lang).format('DD.MM.YYYY HH:MM'));
  }
}
