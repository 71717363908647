import {Type} from 'class-transformer';
import {Action, SignalType} from './enums';
import {HierarchyReference} from './hierarchyReference';

export class NotificationEvent {
  public from: number;
  public to: number;
  public type: SignalType;
  public action: Action;
  public targetName: string;
  public nick: string = null;
  public comment: string = null;
  public targetId: number = null;

  @Type(() => Date)
  public date: Date = null;

  @Type(() => HierarchyReference)
  public hierarchyReference: HierarchyReference;

  constructor(targetId: number, jobId: number, projectId: number, suggestionId: number, conversationId: number,
              taskId: number, from: number, to: number, type: SignalType, action: Action, comment?: string) {
    this.targetId = targetId;
    this.from = from;
    this.to = to;
    this.type = type;
    this.action = action;
    this.comment = comment;
    this.hierarchyReference = new HierarchyReference(jobId, projectId, suggestionId, conversationId, taskId);
  }

  public generateTargetId(): string {
    return `${this.targetId}.${this.hierarchyReference.generateId()}`;
  }

  public compareByTarget(event: NotificationEvent): boolean {
    return this.hierarchyReference.compareByTarget(event.hierarchyReference);
  }
}
