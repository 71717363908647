<div [ngClass]="{'webrtc': true, 'invisible': webRtcService.callState != WebRtcCallState.CALL}">
  <div [ngClass]="{'webrtc__dialog': true, 'opacity_bg': fullscreenMode}" #dialog>
    <div class="webrtc__dialog__remote" #remote>
      <div *ngFor="let participant of remoteParticipants" [ngClass]="{'webrtc__dialog__remote__participant': true, 'fullscreen': participant.fullscreen}">
        <div [ngClass]="{'webrtc__dialog__remote__participant__name': true, 'invisible': participant.fullscreen}">{{participant.fullName}}

        </div>
        <div class="webrtc__dialog__remote__participant__status" [id]="'participant_' + participant.hash">
          {{participant.statusMsg | translate}}
          <button mat-button [ngClass]="{'invisible': participant.status != WebRtcParticipantStatus.CALLING}"
                  (click)="withdrawInviteUser(participant.hash)" color="warn">Withdraw</button>
        </div>
      </div>
    </div>
    <div [ngClass]="{'webrtc__dialog__local': true, 'invisible': fullscreenMode}" id="webrtc__local__video" #local>
    </div>
    <div [ngClass]="{'webrtc__dialog__buttons': true, 'invisible': fullscreenMode}">
      <button mat-mini-fab color="warn" (click)="stop()" class="webrtc__dialog__buttons__button">
        <mat-icon>close</mat-icon>
      </button>
      <button *ngIf="webRtcService.videoSource == 'camera'" mat-mini-fab (click)="switchVideoTo(WebRtcSource.SCREEN)"
              class="webrtc__dialog__buttons__button switch" title="Switch to screen sharing">
        <mat-icon>screen_share</mat-icon>
      </button>
      <button *ngIf="webRtcService.videoSource == 'screen'" mat-mini-fab (click)="switchVideoTo(WebRtcSource.CAMERA)"
              class="webrtc__dialog__buttons__button switch" title="Switch to camera sharing">
        <mat-icon>videocam</mat-icon>
      </button>
    </div>
  </div>
</div>
<div [ngClass]="{'webrtc__incoming-call': true, 'invisible': webRtcService.callState != WebRtcCallState.INCOMING_CALL}">
  <mat-card>
    <div class="webrtc__incoming-call__from">
      <span>{{"RTC.INCOMING_FROM" | translate}} {{webRtcService.incomingPeer}}</span>
    </div>
    <div class="webrtc__incoming-call__buttons">
      <button mat-flat-button color="primary" (click)="acceptCall()">{{"RTC.ACCEPT_CALL" | translate}} </button>
      <button mat-flat-button color="warn" (click)="rejectCall()">{{"RTC.REJECT_CALL" | translate}} </button>
    </div>
  </mat-card>
</div>
