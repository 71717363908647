<div class="forgot-password-root">
  <div class="form-wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full"></mat-icon>
    <div class="form-column">
      <h2 class="login__title">
        {{ cardTitle | translate}}
      </h2>

      <div *ngIf="state == ForgotPasswordStates.EMAIL_INPUT">
        <div class="info-text">
          {{ 'FORGOT_COMPONENT.INIT_INFO_TEXT' | translate }}
        </div>
        <form (submit)="reset()">
          <tpt-input
            [control]="restorePasswordEmailForm.username"
            placeholder="{{ 'LOGIN_COMPONENT.ENTER_EMAIL_ADDRESS' | translate }}"
          >
            <ng-container label>Email</ng-container>
          </tpt-input>
          <button
            type="submit"
            mat-flat-button
            [disabled]="loading"
            class="restore-password-button"
          >
            {{ 'FORGOT_COMPONENT.RESET_PASSWORD' | translate }}
          </button>
        </form>
      </div>

      <div
        *ngIf="state == ForgotPasswordStates.EMAIL_SENT"
      >
        <h2 class="check-email">
          {{ 'FORGOT_COMPONENT.EMAIL_SENT' | translate }}
        </h2>
        <p class="link-sent">
          {{ 'FORGOT_COMPONENT.LINK_SEND' | translate }}
          <span class="email">{{restorePasswordEmailForm.username.value}}</span>
        </p>
      </div>


    <div *ngIf="state == ForgotPasswordStates.PASSWORD_INPUT && hasToken">
      <form (submit)="change()" class="change-password-form">

          <tpt-input
            [control]="changePasswordForm.changePasswordNew"
            type="password"
            placeholder="{{ 'FORGOT_COMPONENT.RESTORE_FORGOT_PASSWORD_TEXT' | translate }}"
          >
            <ng-container label>{{ 'FORGOT_COMPONENT.NEW_PASSWORD' | translate }}</ng-container>
          </tpt-input>

          <tpt-input
            [control]="changePasswordForm.changePasswordConfirm"
            type="password"
            placeholder="{{ 'FORGOT_COMPONENT.ENTER_PASSWORD_AGAIN' | translate }}"
          >
            <ng-container label>{{ 'REGISTER_COMPONENT.CONFIRM' | translate }}</ng-container>
          </tpt-input>

          <button mat-flat-button [disabled]="isChangePasswordButtonDisabled" class="restore-password-button">
            {{ 'FORGOT_COMPONENT.CHANGE_PASSWORD' | translate }}
          </button>

        </form>
      </div>

      <div *ngIf="state == ForgotPasswordStates.PASSWORD_CHANGED">
        <h2 class="check-email">{{ 'FORGOT_COMPONENT.CHANGED_SUCCESS' | translate }}
          <mat-icon [svgIcon]="svgIconsEnum.ConfirmSuccess" class="success-icon"></mat-icon>
        </h2>
        <p class="link-sent link-sent-pass-changed">{{ 'FORGOT_COMPONENT.YOU_CAN_LOGIN' | translate }}</p>
        <button mat-flat-button [routerLink]="['/login']" class="restore-password-button">
          {{ 'FORGOT_COMPONENT.LOGIN' | translate }}
        </button>
      </div>
    </div>
  </div>

</div>
