import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { MatDialogConfig } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { NouisliderComponent } from 'ng2-nouislider';
import { TranslateService } from '@ngx-translate/core';

import { ProjectApiService } from '../../../../services/project.api.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { SkillService } from '../../../../services/skill.service';
import { CategoryService } from '../../../../services/category.service';
import { SkillV2Model } from '../../../../models/skill-v2.model';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { CategoryV2Model } from '../../../../models/category-v2.model';
import { Country } from '../../../../models/country';
import { CountryService } from '../../../../services/country.service';
import { Language } from '../../../../models/language';
import { LanguageService } from '../../../../services/language.service';
import { ProfileService } from '../../../../services/profile.service';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { Subject } from 'rxjs';

const BUDGET_MAX = 3000;
const RATE_MAX = 120;

@Component({
  selector: 'tpt-filter-proposals-dialog',
  templateUrl: './filter-proposals-dialog.component.html',
  styleUrls: ['./filter-proposals-dialog.component.scss']
})
export class FilterProposalsDialogComponent implements OnInit, OnDestroy {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild('hourlyRateSlider')
  public hourlyRateSlider: NouisliderComponent;

  @ViewChild('budgetSlider')
  public budgetSlider: NouisliderComponent;

  @Input()
  public form: FormGroup;

  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();

  public projects;

  public svgIconsEnum = SvgIconsEnum;

  public count: number;
  public countriesCount: number;
  public categories: CategoryV2Model[];
  public filteredItems = [];
  public separatorKeysCodes: number[] = [ENTER];

  public skillControl = new FormControl('');

  public countries: Country[];

  public hourlyRateSliderConfig = {
    connect: [false, true, false],
    range: {
      min: 1,
      max: 120,
    },
    format: {
      to: (value) => value === RATE_MAX ? `> ${RATE_MAX}` : Math.round(value),
      from: (value) => Number(`${value}`.replace('> ', '')),
    },
    step: 1
  };

  public budgetSliderConfig = {
    connect: [false, true, false],
    range: {
      min: 1,
      max: 3000,
    },
    format: {
      to: (value) => value === BUDGET_MAX ? `> ${BUDGET_MAX}` : Math.round(value),
      from: (value) => Number(`${value}`.replace('> ', '')),
    },
    step: 1
  };

  public filterSlider = {
    budget: [0, 3000],
    hourlyRate: [0, 120]
  };

  public languageLevels = [
    'BEGINNER',
    'MEDIUM',
    'ADVANCED',
    'NATIVE'
  ];

  public languages: Language[];

  private allSkills: SkillV2Model[] = [];
  private allSkillsArray: SkillV2Model[] = [];

  private config: MatDialogConfig = {
    width: '840px',
    height: '720px',
    panelClass: 'filter-dialog'
  };

  private className = 'tpt-filter-proposals-dialog';

  private readonly destroy$ = new Subject();

  get languagesForm(): FormArray {
    return this.form.get('languageLevels') as FormArray;
  }

  get skillsFormArray(): FormArray {
    return this.form.get('skills') as FormArray;
  }

  constructor(private projectApiService: ProjectApiService,
              private translate: TranslateService,
              public skillService: SkillService,
              private cd: ChangeDetectorRef,
              private languageService: LanguageService,
              private categoryService: CategoryService,
              private countryService: CountryService,
              private profile: ProfileService,
              private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.projectApiService.getMyProjectsWithProposal().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.projects = res;
      this.count = 0;
      this.spinner.stopSpinner();
    }, this.spinner.stopSpinner);

    this.skillControl.valueChanges.pipe(startWith('')).subscribe((value) => {
      this.findSkills(value);
    });

    this.getCategories();
    this.getAllSkills();
    this.getCountries(true);
    this.getLanguages();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public refreshFilters(): void {
    this.form.reset(
      {
        projectId: '',
        categories: [],
        levels: {
          beginner: false,
          intermediate: false,
          master: false,
        },
        ratingLevel: '',
        rateMin: 0,
        rateMax: 120,
        budgetMin: 0,
        budgetMax: 3000,
        hourlyProject: null,
        fixedProject: null,
        countryCode: '',
        skills: [],
        languageLevels: [],
        provenRecord: null
      }
    );
    this.filterSlider.hourlyRate = [0, 120];
    this.filterSlider.budget = [0, 3000];

    this.categories.forEach(cat => {
      cat.isSelected = false;
      cat.children.forEach(subCat => {
        subCat.isSelected = false;
      });
    });

    this.skillsFormArray.clear();
    this.getAllSkills();
    this.filterEvent.emit();
  }

  public applyFilters(): void {
    this.filterEvent.emit();
    this.close();
  }

  public getAllSkills(): void {
    this.skillService.getSkillsV2().subscribe(res => {
      this.allSkills = res;
      this.allSkillsArray = this.allSkills;
      this.findSkills('');
    });
  }

  public setAll(checked: boolean, category) {
    category.children.forEach(item => item.isSelected = checked);
  }

  public getTranslation(item): string {
    if (!item) {
      return;
    }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  async getUpdAllSkills(checked, category, subCategory): Promise<void> {
    if (checked && !subCategory) {
      const categoryId = category.id;
      const res = await this.skillService.getCategorySkillsV2(categoryId).toPromise();
      res.forEach(item => this.allSkillsArray.push(item));
    }

    if (!checked && !subCategory) {
      const categoryId = category.id;
      const res = await this.skillService.getCategorySkillsV2(categoryId).toPromise();
      const filterArr = res.map(item => item.id);
      this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
      if (!this.allSkillsArray.length) {
        this.allSkillsArray = this.allSkills;
      }
    }

    if (checked && subCategory) {
      const res = await this.skillService.getCategorySkillsV2(subCategory.id).toPromise();
      res.forEach(skill => this.allSkillsArray.push(skill));
    }

    if (!checked && subCategory) {
      if (category.children.every(child => !child.isSelected)) {
        const res = await this.skillService.getCategorySkillsV2(subCategory.id).toPromise();
        const filterArr = res.map(item => item.id);
        this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
        if (!this.allSkillsArray.length) {
          this.allSkillsArray = this.allSkills;
        }
      }
    }

    this.updateCategoryFormControl();
    this.filteredItems = this.allSkillsArray;
  }

  public selectedSkill(event: any): void {
    const skills = this.form.get('skills') as FormArray;

    const alreadyAdded = skills.value.some(item => item.id === event.option.value.id);
    if (alreadyAdded) { return; }

    skills.push(new FormControl(this.skillControl.value));
    this.skillControl.setValue('');
  }

  public removeSkill(index: number): void {
    const skills = this.form.get('skills') as FormArray;

    if (index >= 0) {
      skills.removeAt(index);
    }
  }

  public updateCategoryFormControl(): void {
    if (!this.categories) { return; }

    const categoriesArray = [];

    this.categories.forEach(item => {
      if (item.isSelected) {
        categoriesArray.push(item.id);
      }
    });

    const notAllSelected = this.categories.filter(item => !item.isSelected);
    notAllSelected.forEach(item => {
      item.children.forEach(child => {
        if (child.isSelected) {
          categoriesArray.push(child.id);
        }
      });
    });

    this.form.controls.categories.patchValue(categoriesArray);
  }

  public getCategories(): void {
    this.categoryService.getCategoriesV2().subscribe(res => {
      this.categories = res;
      this.categories.forEach(item => {
        item.isSelected = false;
        item.expanded = false;

        item.children.forEach(child => {
          child.isSelected = false;
        });
      });
    });
  }

  public toggleSubcats(category): void {
    category.expanded = !category.expanded;

    if (category.expanded) {
      this.categories.forEach(item => {
        item.expanded = item.id === category.id;
      });
    }
  }

  public subCategoryChanged(event, subCat, cat): void {
    subCat.isSelected = !subCat.isSelected;
    cat.isSelected = this.getAllComplete(cat);
  }

  public someChecked(category): boolean {
    const countOfChecked = category.children.filter(item => item.isSelected).length;
    return countOfChecked > 0 && countOfChecked < category.children.length;
  }

  public getViewField(): string {
    if (this.translate.currentLang === 'ru') {
      return 'nameRu';
    }
    return 'name';
  }

  public rateChange(val: number[]): void {
    this.form.controls.rateMin.patchValue(val[0]);
    this.form.controls.rateMax.patchValue(val[1]);
  }

  public budgetChange(val: number[]): void {
    this.form.controls.budgetMin.patchValue(val[0]);
    this.form.controls.budgetMax.patchValue(val[1]);
  }

  public setBudgetSliderValue() {
    this.budgetSlider.slider.set(this.filterSlider.budget);
  }

  public setRateSliderValue() {
    this.hourlyRateSlider.slider.set(this.filterSlider.hourlyRate);
  }

  public getCountries(setCount: boolean, name?: string): void {
    if (!name) {
      name = '';
    }

    this.countryService.getCountriesV2(name).subscribe(res => {
      this.countries = res;

      if (setCount) {
        this.countriesCount = res.length;
      }
    });
  }

  public addLang(): void {
    (this.form.controls.languageLevels as FormArray).push(
      new FormGroup({
        code: new FormControl(null),
        name: new FormControl(null),
        level: new FormControl(null),
      })
    );
  }

  public removeLang(i): void {
    (this.form.controls.languageLevels as FormArray).removeAt(i);
  }

  private getLanguages(): void {
    this.languageService.getLanguagesV2().pipe(
      takeUntil(this.destroy$))
      .subscribe(languages => {
          this.languages = languages;
        }
      );
  }

  private async findSkills(val): Promise<void> {
    if (!val) {
      this.filteredItems = this.allSkillsArray;
      return;
    }

    if (typeof val === 'string') {
      this.filteredItems = await this.skillService.getCategorySkillsV2(null, val).toPromise();
    }
  }

  private getAllComplete(category): boolean {
    return category.children.every(item => item.isSelected);
  }

}
