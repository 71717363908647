import { Component, HostBinding, Input } from '@angular/core';
import { TptControlDirective } from '../../directives/tpt-control.directive';

@Component({
  selector: 'tpt-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: [ './textarea.component.scss' ],
})
export class TextareaComponent extends TptControlDirective {

  @Input()showLabel = true;

  @HostBinding('class.tpt-textarea')
  public baseClass = true;

}
