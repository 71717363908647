import { Currency } from '../models/currency';

export class Constants {
    public static PASSWORD_MIN_LENGTH = 8;
    public static DEFAULT_TRANSITION_TIME = '225ms';
    public static DEFAULT_TRANSITION_FUNCTION = 'cubic-bezier(.4, 0, .2, 1)';
    public static USD_CURRENCY = Currency.createCurrency('USD', 'USD', 2, true);
    public static DEFAULT_CURRENCY_CODE = 'USD';
}

export const STRIPE_COUNTRIES = [
    { name: 'AUSTRALIA', code: 'AU', default: false },
    { name: 'AUSTRIA', code: 'AT', default: false },
    { name: 'BELGIUM', code: 'BE', default: false },
    { name: 'BRAZIL', code: 'BR', default: false },
    { name: 'BULGARIA', code: 'BG', default: false },
    { name: 'CANADA', code: 'CA', default: false },
    { name: 'CYPRUS', code: 'CY', default: false },
    { name: 'CZECH REPUBLIC', code: 'CZ', default: false },
    { name: 'DENMARK', code: 'DK', default: false },
    { name: 'ESTONIA', code: 'EE', default: false },
    { name: 'FINLAND', code: 'FI', default: false },
    { name: 'FRANCE', code: 'FR', default: false },
    { name: 'GERMANY', code: 'DE', default: false },
    { name: 'GREECE', code: 'GR', default: false },
    { name: 'HONG KONG', code: 'HK', default: false },
    { name: 'INDIA', code: 'IN', default: false },
    { name: 'IRELAND', code: 'IE', default: false },
    { name: 'ITALY', code: 'IT', default: false },
    { name: 'JAPAN', code: 'JP', default: false },
    { name: 'LATVIA', code: 'LV', default: false },
    { name: 'LITHUANIA', code: 'LT', default: false },
    { name: 'LUXEMBOURG', code: 'LU', default: false },
    { name: 'MALAYSIA', code: 'MY', default: false },
    { name: 'MALTA', code: 'MT', default: false },
    { name: 'MEXICO', code: 'MX', default: false },
    { name: 'NETHERLANDS', code: 'NL', default: false },
    { name: 'NEW ZEALAND', code: 'NZ', default: false },
    { name: 'NORWAY', code: 'NO', default: false },
    { name: 'POLAND', code: 'PL', default: false },
    { name: 'PORTUGAL', code: 'PT', default: false },
    { name: 'ROMANIA', code: 'RO', default: false },
    { name: 'SINGAPORE', code: 'SG', default: false },
    { name: 'SLOVAKIA', code: 'SK', default: false },
    { name: 'SLOVENIA', code: 'SI', default: false },
    { name: 'SPAIN', code: 'ES', default: false },
    { name: 'SWEDEN', code: 'SE', default: false },
    { name: 'SWITZERLAND', code: 'CH', default: false },
    { name: 'UNITED KINGDOM', code: 'GB', default: false },
    { name: 'UNITED STATES', code: 'US', default: false },
];
