import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { shouldHide } from '../../../../changeable-utils';
import { DiffKey } from '../../../../classes/diffKey';
import { FileUpload } from '../../../../models/fileUpload';

@Component({
  selector: 'xln-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnChanges {
  @Input() public uploadedFiles: FileUpload[] = [];
  @Output() public uploadedFilesChange: EventEmitter<FileUpload[]> = new EventEmitter<FileUpload[]>();

  /*actions*/
  @Input() public allowDelete = false;
  @Input() public allowAddVersion = false;
  @Input() public allowRename = false;

  // show color diff
  @Input() public diffKey: DiffKey = null;

  /*UI*/
  @Input() public showDate = false;
  @Input() public showVersion = false;
  @Input() public showFrom = false;
  @Input() public showHistory = false;

  @Input()
  public displayUploadedElements = true;

  public hidden: boolean[] = [];
  public count = 0;

  public ngOnChanges(changes: SimpleChanges): void {
    this.calcCount();
  }

  public calcCount() {
    this.count = 0;
    if (this.uploadedFiles) {
      this.hidden = [];
      this.uploadedFiles.forEach((file) => {
        if (shouldHide(file, this.diffKey)) {
          this.hidden.push(true);
        } else {
          this.count++;
          this.hidden.push(false);
        }
      });
    }
  }

  public onFileChange(file: FileUpload, index: number) {
    if (file) {
      this.uploadedFiles[index] = file;
    } else {
      this.uploadedFiles.splice(index, 1);
    }
    this.calcCount();
    this.uploadedFilesChange.emit(this.uploadedFiles);
  }
}
