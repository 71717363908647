import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { cloneDeep } from 'lodash';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { UserRoleModel } from '../../../../models/project-role/user-role.model';
import { ProjectRolesService } from '../../../../services/project-roles.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { RoleModel } from '../../../../models/project-role/role.model';
import {ProfileService} from '../../../../services/profile.service';

@Component({
  selector: 'user-roles-dialog',
  templateUrl: './user-roles-dialog.component.html',
  styleUrls: ['./user-roles-dialog.component.scss']
})
export class UserRolesDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() userRolesUpdated: EventEmitter<any> = new EventEmitter<any>();

  public svgIconsEnum = SvgIconsEnum;

  public user: UserRoleModel;

  public userRoles: RoleModel[] = [];

  public projectRoles: RoleModel[];

  public rolesAssigning: boolean;

  public currentProfileId: number;

  private projectId: number;

  private config: MatDialogConfig = {
    width: '729px',
  };

  private className = 'user-roles-dialog';

  constructor(private rolesService: ProjectRolesService,
              private profileService: ProfileService,
              private spinnerService: SpinnerService) {
  }

  public open(user: UserRoleModel, projectId: number, rolesAssigning: boolean): void {
    this.user = user;
    this.userRoles = cloneDeep(user.roles);
    this.projectId = projectId;
    this.rolesAssigning = rolesAssigning;
    this.currentProfileId = this.profileService.currentProfile.id;
    this.getProjectRoles();
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public changeSelection(event: MatSelectionListChange) {
    if (event.option.selected) {
      this.userRoles.push(event.option.value);
    } else {
      this.userRoles = this.userRoles.filter(role => role.roleId !== event.option.value.roleId);
    }
  }

  public getProjectRoles(): void {
    this.spinnerService.startSpinner();
    this.rolesService.getProjectRoles(this.projectId).subscribe(res => {
      this.projectRoles = res;
      this.spinnerService.stopSpinner();
    });
  }

  public isChecked(roleId): boolean {
    return !!this.userRoles.filter(role => role.roleId === roleId).length;
  }

  public saveRoles(): void {
    this.spinnerService.startSpinner();
    const roleIds = this.userRoles.map(role => role.roleId);
    this.rolesService.setAssignedRoles(this.projectId, this.user.profile.id, roleIds).subscribe(() => {
      this.spinnerService.stopSpinner();
      this.close();
      this.userRolesUpdated.emit();
    }, this.spinnerService.stopSpinner);
  }
}
