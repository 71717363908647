export enum EmployerLegalStatusEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
}

export enum FreelancerLegalStatusEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  SELF_EMPLOYED = 'SELF_EMPLOYED'
}
