import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { LogoutService } from './logout.service';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {
    protected baseUrl = `${window.location.protocol}//${environment.MAIN_HOST}/api/subscription`;

    constructor(private readonly httpClient: HttpClient) {}

    public subscribeEmail(email: string): Observable<any> {
        const data = {
            email,
        };
        console.log(email);

        return this.httpClient.post(this.baseUrl + '/subscribe', data, {}).pipe(catchError(this.errorHandler));
    }

    public unsubscribeEmail(email: string, reason: string): Observable<any> {
        const data = {
            email,
            reason,
        };
        return this.httpClient
            .request('DELETE', this.baseUrl + '/unsubscribe', { body: data })
            .pipe(catchError(this.errorHandler));
    }

    public isValidEmail(email: string): boolean {
        return !(email.match('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$') === null);
    }

    protected errorHandler(error: HttpErrorResponse): Observable<any> {
        if (Number(error.status) === 401) {
            LogoutService.logout();
        }

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // console.error(`Backend returned code ${error.status}, ` +
            //   `body was: ${error.error}`);
        }
        return throwError(error.error);
    }
}
