import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextileComponent } from './components/textile/textile.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    TextileComponent,
    SafeHtmlPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TextileComponent,
    SafeHtmlPipe
  ]
})
export class TextileModule {
}
