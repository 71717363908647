export enum WebRtcCallState {
  INITIAL = 0,
  INCOMING_CALL = 1,
  CALL = 2,
}

export enum WebRtcRegisterState {
  NOT_REGISTERED = 0,
  REGISTERING = 1,
  REGISTERED = 2,
}

export enum WebRtcParticipantStatus {
  NONE = 0,
  CALLING = 1,
  CONNECTING = 2,
  REJECT = 3,
  COMMUNICATING = 4,
}

export enum WebRtcSource {
  SCREEN = 'screen',
  CAMERA = 'camera',
}

export enum WSReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}
