<header class="project-page-header">
  <div class="project-title mr-12">
    <ng-container *ngIf="task">
      <mat-form-field appearance="none" class="project-select">
        <mat-select class="projects-dropdown"
                    [formControl]="currentProjectControl"
                    [compareWith]="compareFn"
                    [disableOptionCentering]="true">
          <mat-option
            *ngFor="let item of dropdownItems"
            [value]="item">
            {{ item?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="header-actions">
    <div class="tasks-search">
      <mat-icon class="search-icon" [svgIcon]="svgIconsEnum.Search"></mat-icon>
      <input class="task-input" [formControl]="taskNameControl" [placeholder]="'TRACKER.TASK_SEARCH' | translate">
      <mat-icon class="cross-icon" [svgIcon]="svgIconsEnum.Cross" (click)="clearForm()"></mat-icon>
    </div>

    <div class="priority">
      <div class="label">{{'TRACKER.GROUP_BY' | translate}}</div>
      <mat-select [value]="groupBy" (selectionChange)="group($event.value)"
                  class="custom-select group">
        <mat-option *ngFor="let priority of groupingTypes" [value]="priority.id">
          <div class="option">
            {{priority?.value | translate}}
          </div>
        </mat-option>
      </mat-select>
    </div>
    <tpt-button type="secondary" class="notes-icon" [matMenuTriggerFor]="filterMenu">
      <span class="button-text">{{'CONTRACTS.FILTERS' | translate}}</span>
      <mat-icon [svgIcon]="svgIconsEnum.FilterList"></mat-icon>
    </tpt-button>

    <mat-menu #filterMenu="matMenu">
      <div class="menu-wrapper" (click)="$event.stopPropagation()">
        <div class="filters-title">{{'CONTRACTS.FILTERS' | translate}}</div>

        <div class="assignees" *ngIf="project?.type === 'COMPLEX'">
          <mat-select [disabled]="!task?.freelancers?.length"
                      [(value)]="filterFreel" (selectionChange)="filterFreelancers($event.value)"
                      class="custom-select filter"
                      [disableOptionCentering]="true"
          >
            <mat-option [value]="0">
              <div class="all-assignees">
                {{'TRACKER.ALL_ASSIGNEES' | translate}}
              </div>
              <mat-divider class="divider"></mat-divider>
            </mat-option>
            <mat-option *ngFor="let freelancer of task?.freelancers" [value]="freelancer.id">
              <div class="option">
                {{freelancer?.firstName}} {{freelancer?.lastName}}
              </div>
            </mat-option>
          </mat-select>
        </div>

        <div class="toggle-archive">
          <mat-slide-toggle labelPosition="before" color="primary"  [(ngModel)]="showArchive"
                            (change)="toggleArchive($event.checked)">
            {{'TRACKER.ARCHIVE' | translate}}
          </mat-slide-toggle>
        </div>

        <div class="filter-actions">
          <tpt-button size="small" type="secondary"
                      (onClick)="clearFilter()">{{'CONTRACTS.CLEAR_BUTTON' | translate}}</tpt-button>
<!--          <tpt-button size="small" type="accent" class="ml-8">{{'BUTTON.APPLY' | translate}}</tpt-button>-->
        </div>
      </div>
    </mat-menu>

    <tpt-button type="secondary" class="notes-icon" (onClick)="openNotes()">
      <span class="button-text">{{'TRACKER.NOTES' | translate}}</span>
      <mat-icon [svgIcon]="svgIconsEnum.Sticky"></mat-icon>
    </tpt-button>
  </div>
</header>

<emp-project-notes-dialog></emp-project-notes-dialog>
