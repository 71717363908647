import { Component, EventEmitter, HostBinding, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { concat } from 'lodash';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'tpt-dialog',
  template: `
    <ng-template>
      <mat-dialog-actions>
        <button mat-button></button>
      </mat-dialog-actions>
      <mat-icon
        *ngIf="config.disableClose"
        [svgIcon]="svgIconsEnum.Cross"
        (click)="close()"
      ></mat-icon>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnDestroy {

  @Output()
  public readonly dialogClose = new EventEmitter<void>();

  @ViewChild(TemplateRef)
  public templateRef: TemplateRef<any>;

  @HostBinding('class.tpt-dialog')
  public baseClass = true;

  public svgIconsEnum = SvgIconsEnum;

  public get dialogRef(): MatDialogRef<DialogComponent> | undefined {
    return this._dialogRef;
  }

  public set config(cfg: MatDialogConfig) {
    this._config = { ...this._config, ...cfg };
  }

  public get config(): MatDialogConfig {
    return this._config;
  }

  private skyengDialogPaneClass = 'tpt-dialog-pane';

  private _dialogRef: MatDialogRef<DialogComponent>;

  private _config: MatDialogConfig = {
    hasBackdrop: true,
    closeOnNavigation: true,
    disableClose: true,
  };

  private maxWidth: string;

  private routeEventsSubscription: Subscription;

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnDestroy(): void  {
    if (this.routeEventsSubscription) {
      this.routeEventsSubscription.unsubscribe();
      this.routeEventsSubscription = null;
    }
  }

  public open(className: Array<string> | string): MatDialogRef<any> {
    this.maxWidth = '80vw';
    const classNames = this.addDefaultSkyengDialogPaneClass(className);
    const config = { panelClass: classNames, maxWidth: this.maxWidth, ...this.config };
    this._dialogRef = this.dialog.open(this.templateRef, config);

    return this._dialogRef;
  }

  public close = (): void => {
    this._dialogRef.close();
    this._dialogRef = null;
    this.dialogClose.emit(null);
  }

  public closeWithoutEmitting = (): void => {
    this._dialogRef.close();
  }

  private addDefaultSkyengDialogPaneClass = (className: Array<string> | string): Array<string> => {
    return Array.isArray(className)
      ? concat(className, this.skyengDialogPaneClass)
      : [className, this.skyengDialogPaneClass];
  }

}
