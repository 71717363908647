import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectRolesService } from '../../../../services/project-roles.service';
import { UserRoleModel } from '../../../../models/project-role/user-role.model';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { RoleModel } from '../../../../models/project-role/role.model';
import { UserRolesDialogComponent } from '../../../common-dialogs/components/user-roles-dialog/user-roles-dialog.component';
import { ProfileService } from '../../../../services/profile.service';
import { PermissionEnum } from '../../../../models/project-role/permission.model';

@Component({
  selector: 'tpt-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild(UserRolesDialogComponent) userRolesDialog: UserRolesDialogComponent;

  public users: UserRoleModel[];

  public showRolesTab = false;

  public rolesAssigning = false;

  public currentProfileId: number;

  private projectId: number;

  constructor(private rolesService: ProjectRolesService,
              private spinnerService: SpinnerService,
              private profileService: ProfileService,
              private activatedRoute: ActivatedRoute) {
    this.projectId = activatedRoute.parent.parent.snapshot.params.id;
  }

  ngOnInit() {
    this.getProjectUsersRoles();

    this.getMyPermissions();

    this.currentProfileId = this.profileService.currentProfile.id;
  }

  public updateUsers() {
    this.getProjectUsersRoles();
  }

  public splitArray(roles: RoleModel[]): {firstPart: RoleModel[], secondPart: RoleModel[], secondPartLength: number} {
    return this.rolesService.splitArray(roles);
  }

  public openUserRolesDialog(user: UserRoleModel) {
    this.userRolesDialog.open(user, this.projectId, this.rolesAssigning);
  }

  private getMyPermissions() {
    if (this.profileService.currentProfile.isEmployer()) {
      this.showRolesTab = true;
      this.rolesAssigning = true;
      return;
    }

    this.rolesService.getMyRolesInProject(this.projectId).subscribe(res => {
      const permissions = [];
      res.roles.forEach(role => {
        role.permissions.forEach(perm => {
          permissions.push(perm.name);
        });
      });

      this.showRolesTab = permissions.includes(PermissionEnum.ROLES_EDITING);
      this.rolesAssigning = permissions.includes(PermissionEnum.ROLES_ASSIGNING);
    }, () => {});
  }

  private getProjectUsersRoles() {
    this.spinnerService.startSpinner();
    this.rolesService.getProjectUsersRoles(this.projectId).subscribe((res) => {
      this.spinnerService.stopSpinner();
      this.users = res;
    });
  }
}
