import { Component, HostBinding, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { SpinnerService } from '../../services/spinner.service';
import { SpinnerEventModel } from '../../models/spinner-event.model';

@Component({
  selector: 'tpt-spinner',
  template: `
    <ng-container *ngIf="(spinner$ | async) as spinner">
      <div *ngIf="spinner.show" [class.overlay]="spinner.blockScreen">
        <mat-spinner [diameter]="spinner?.size"></mat-spinner>
      </div>
    </ng-container>
  `,
  styleUrls: [ './spinner.component.scss' ],
})
export class SpinnerComponent implements OnInit {

  @HostBinding('class.tpt-spinner')
  public baseClass = true;

  public spinner$ = of(new SpinnerEventModel(false));

  constructor(
    private spinnerService: SpinnerService,
  ) { }

  public ngOnInit(): void {
    this.spinner$ = this.spinnerService.spinner$.asObservable();
  }

}
