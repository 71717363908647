// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { LogLevel } from '../app/classes/enums';

export const environment = {
    production: false,
    hmr: false,
    contactMail: '',
    currentFlow: 'dev',
  // MAIN_HOST: '192.168.100.120:9001',
    TRACKING_CODE: 'UA-141210688-1',
    STOMP_PORT: 15678,
    // WEB_SOCKET_URL: "wss://im.xlance.e-ngineers.ru",
    WEB_SOCKET_URL: 'wss://im.xlance.e-ngineers.ru',
    CURRENCY_CONVERTER_URL: 'https://rest.coinapi.io/v1',
    CURRENCY_CONVERTER_API_KEY: 'CE19FEB8-5371-4D54-B143-51F4CB0D199D',
    G_VERIFICATION1: '',
    G_VERIFICATION2: '',
    FB_VERIFICATION: '',
    FB_PIXEL_ID: '',
    GTM_CODE: '',
    MAIN_HOST: 'teamfi.me:4443',
    LOG_SETTINGS: {
        LOG_LEVEL: LogLevel.INFO,
        LOG_SERVER_LEVEL: LogLevel.WARN,
        GLOBAL: true,
    },
    SITE_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    timeStamp: '23-01-2024/11-46/AM-MSK',
    google_client_id: '48421332865-mb5p0sjsdofn76lg4dtpp2jom6h9cjdc.apps.googleusercontent.com',
    google_app_name: 'stage',
    facebook_client_id: '',
    YA_WEBMASTER: '',
};
