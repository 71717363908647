export class Currency {

  static SYMBOLS = {
    BTC: '₿',
    LTC: 'Ł',
    LTCT: 'ŁT',
    ETH: 'ETH'
  };

  static createCurrency(name: string, code: string, fraction: number, fiat: boolean): Currency {
    const currency = new Currency();
    currency.name = name;
    currency.code = code;
    currency.fraction = fraction;
    currency.fiat = fiat;
    return currency;
  }
  public id: number;
  public name = '';
  public code = '';
  public fraction = 2;

  public fiat = true;
}
