<div class="login-root">
  <div class="login-column">
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full"></mat-icon>

    <div class="login">
      <div *ngIf="!googleSignUpStepTwo">
        <h2 class="login__title">{{ 'LOGIN_COMPONENT.LOGIN' | translate }}</h2>

        <form (submit)="login()">
          <tpt-input
            [control]="form.username"
            placeholder="{{ 'LOGIN_COMPONENT.ENTER_EMAIL_ADDRESS' | translate }}"
          >
            <ng-container label>Email</ng-container>
          </tpt-input>

          <tpt-input
            [control]="form.password"
            type="password"
            placeholder="{{ 'LOGIN_COMPONENT.ENTER_PASSWORD' | translate }}"
            hintAlign="end"
          >
            <ng-container label>{{ 'LOGIN_COMPONENT.PASSWORD' | translate }}</ng-container>
          </tpt-input>
          <div class="login__login-hint">
            <span routerLink="/forgotPassword">{{ 'LOGIN_COMPONENT.FORGOT_PASSWORD' | translate }}</span>
          </div>

          <button
            [disabled]="loading"
            mat-flat-button
            class="login__login-btn"
            type="submit"
          >
            {{ 'LOGIN_COMPONENT.LOGIN' | translate }}
          </button>
        </form>

        <tpt-button type="secondary" size="large" class="mt-12 sign-in-google" (onClick)="googleSignIn()">
          <mat-icon [svgIcon]="svgIconsEnum.Google" class="mr-4"></mat-icon>
          {{'LOGIN_COMPONENT.GOOGLE_SIGNIN' | translate}}
        </tpt-button>
      </div>

      <div *ngIf="googleSignUpStepTwo" class="register-column">
        <h2 class="login__title">{{ 'REGISTER_COMPONENT.GOOGLE_SIGNUP' | translate}}</h2>

        <div class="mb-24 info-text" [innerHTML]="'REGISTER_COMPONENT.GOOGLE_SIGNUP_TEXT' | translate: {email: user?.email}">
        </div>

        <div class="choose-role mb-8">
          {{ 'REGISTER_COMPONENT.CHOOSE_THE_ROLE' | translate }}
        </div>

        <mat-radio-group [formControl]="roleControl">
          <mat-radio-button value="EMPLOYER"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_CLIENT' | translate}}</mat-radio-button>
          <mat-radio-button value="FREELANCER"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_TALENT' | translate}}</mat-radio-button>
        </mat-radio-group>

        <tpt-button type="primary" class="mt-32" (onClick)="createAccountGoogle()">
          {{'REGISTER_COMPONENT.CREATE_ACCOUNT' | translate}}
        </tpt-button>
      </div>

      <div class="have-acc" *ngIf="!googleSignUpStepTwo">{{'LOGIN_COMPONENT.NO_ACCOUNT' | translate}}
        <span [routerLink]="['/register']" class="link">{{'LOGIN_COMPONENT.SIGN_UP' | translate}}</span>
      </div>
    </div>
  </div>
</div>

<tpt-auth-error-dialog></tpt-auth-error-dialog>
