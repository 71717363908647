import {BREAKPOINT} from '@angular/flex-layout';

const BREAKPOINTS = [{
  alias: 'hd.mw-hide',
  suffix: 'HdMinWidthHide',
  mediaQuery: 'screen and (min-width: 840px)',
  overlapping: false,
  priority: 1001 // Needed if overriding the default print breakpoint
}];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: BREAKPOINTS,
  multi: true
};
