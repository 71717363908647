import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MediaObserver } from '@angular/flex-layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../helpers/rxjs/take-until-destroyed';
import { BdcWalkService } from 'bdc-walkthrough';

import { LanguageChangeEventModel } from '../../models/data-layer/language-change-event.model';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { FinanceServiceV2 } from '../../modules/common-dialogs/services/finance.service';
import { AccountModel } from '../../models/financev2/account.model';
import { ProfilesApiService } from '../../services/profiles.api.service';
import { RouteHelper } from '../../helpers/route.helper';
import { CurrencyEnum, CurrencySymbol } from '../../models/currency.enum';
import { ProfileService } from '../../services/profile.service';
import { SignallingService } from '../../services/signalling.service';
import { PortalService } from '../../services/portal.service';
import { DataLayerService } from '../../services/data-layer.service';
import { LogoutService } from '../../services/logout.service';
import { HelpCenterComponent } from '../../modules/common-dialogs/components/help-center/help-center.component';

@DecorateUntilDestroy()
@Component({
  selector: 'xln-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(HelpCenterComponent) helpDialog: HelpCenterComponent;

  public get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  public accounts: AccountModel[] = [];
  public headerPortal: TemplatePortal | null = null;

  public isDark: boolean;

  public svgIconsEnum = SvgIconsEnum;

  public currencySymbol = CurrencySymbol;

  public langs = {en: 'English', ru: 'Русский'};

  constructor(
    public profileService: ProfileService,
    public profileApiService: ProfilesApiService,
    public signallingService: SignallingService,
    public financeService: FinanceServiceV2,
    public media: MediaObserver,
    public translate: TranslateService,
    private portalService: PortalService,
    private dataLayerService: DataLayerService,
    private cdf: ChangeDetectorRef,
    private router: Router,
    public routeHelper: RouteHelper,
    private bdcWalkService: BdcWalkService,
  ) {
    router.events.subscribe((val) => {
      // if (val instanceof NavigationEnd && val.urlAfterRedirects === '/') {
      //   this.isDark = true;
      //   return;
      // }

      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.isDark = val.url.startsWith('/subscription') ||
          val.url === '/';
      }
    });
  }

  ngOnInit(): void {
    this.getBalance();
  }

  public ngAfterViewInit(): void {
    this.portalService.headerPortal.pipe(
      takeUntilDestroyed(this),
    ).subscribe((portal: TemplatePortal) => {
      this.headerPortal = portal;
      this.cdf.detectChanges();
    });
  }

  public ngOnDestroy(): void { }

  public setCurrentLang(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('currentLang', lang);
    if (this.profileService.currentProfile) {
      this.profileApiService.setLocale().subscribe();
    }
    this.pushLanguageChangeEventToDataLayer(lang);
  }

  public logout(): void {
    LogoutService.logout(false);
  }

  public reset(): void {
    this.bdcWalkService.reset(this.isEmployer ? 'onBoardingEmployer' : 'onBoardingFreelancer');
    this.router.navigate(this.isEmployer ? this.routeHelper.employeerProjectsDashboard :
      this.routeHelper.freelancerProjectsDashboard);
  }

  public account(): AccountModel {
    if (!this.accounts.length) { return; }

    const mainAcc = this.accounts.filter(item => item.main)[0];
    if (mainAcc) {
      return mainAcc;
    }

    return this.accounts.filter(item => item.currency.code === CurrencyEnum.USD)[0];
  }

  private pushLanguageChangeEventToDataLayer(language: string): void {
    this.dataLayerService.pushToDataLayer(new LanguageChangeEventModel(language));
  }

  private getBalance(): void {
    this.financeService.balanceUpdated$.pipe(
      takeUntilDestroyed(this),
    ).subscribe(() => {
      if (this.profileService.currentProfile) {
        this.financeService.getAccount().subscribe((accounts: AccountModel[]) => {
          this.accounts = accounts;
        });
      }
    });

  }
}
