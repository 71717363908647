import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { environment } from '../environments/environment';
import { Role, SignalType } from './classes/enums';
import { Currency } from './models/currency';
import { FileUpload } from './models/fileUpload';
import { FileUploadHistory } from './models/fileUploadHistory';
import { moment } from './moment-helper';

export function objectsHaveDiff(a, b, exclude: string[] = []): boolean {
  const aPropCount = Object.keys(a).length;
  const bPropCount = Object.keys(b).length;

  if (aPropCount !== bPropCount) {
    return true;
  }

  for (const prop in a) {
    if (exclude.indexOf(prop) === -1) {
      if (b.hasOwnProperty(prop)) {
        if (a[prop] !== b[prop]) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
}

export function isExpansionPanelSelected(ev): boolean {
  let el = ev.target;
  while (el && !el.classList.contains('js-expansion-header')) {
    el = el.parentElement;
  }
  return el.classList.contains('mat-expanded');
}

export function diffInDays(d1, d2): number {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function daysBetween(date1: Date, date2: Date) {
  /*const ONE_DAY = 1000 * 60 * 60 * 24;
  let difference_ms = Math.abs(date1.getTime() - date2.getTime());
  return Math.round(difference_ms / ONE_DAY)*/
  return Math.abs(diffInDays(date1, date2));
}

export function compareById(o1, o2): boolean {
  return o1 === o2 || (o1 && o2 && o1.hasOwnProperty('id') && o2.hasOwnProperty('id') && (o1.id === o2.id));
}

export function uploadedFileUrl(file: FileUpload, history?: FileUploadHistory): string {
  return fileUrl(file.id, `${file.name}.${history ? history.extension : file.extension}`, history ? history.id : null);
}

export function fileUrl(id: number, filename: string, historyId?: number): string {
  const url = `${window.location.protocol}//${environment.MAIN_HOST}/api/files/${id}/`;
  if (historyId) {
    return url + `${historyId}/${filename}`;
  } else {
    return url + `${filename}`;
  }
}

export function nullableNumberSorter(ascending, fieldName) {

  return (a, b) => {

    if (a[fieldName] === null) {
      return 1;
    } else if (b[fieldName] === null) {
      return -1;
    } else if (a[fieldName] === b[fieldName]) {
      return 0;
    } else if (ascending) {
      return Number(a[fieldName]) < Number(b[fieldName]) ? -1 : 1;
    } else if (!ascending) {
      return Number(a[fieldName]) < Number(b[fieldName]) ? 1 : -1;
    }
  };
}

export function dateSorter(ascending, fieldName) {
  return (a, b) => {
    if (a[fieldName].getTime() >= b[fieldName].getTime()) {
      return (ascending) ? -1 : 1;
    } else {
      return (ascending) ? 1 : -1;
    }
  };
}

export function getAvatar(id: number) {
  return `${window.location.protocol}//${environment.MAIN_HOST}/api/files/avatar/${id}`;
}

export function getAvatarUrl(avatar: FileUpload): string {
  if (avatar) {
    return `${window.location.protocol}//${environment.MAIN_HOST}/api/files/
    ${avatar.id}/${avatar.name}.${avatar.extension}?v=${avatar.changeVersion}`;
  } else {
    return '/assets/no_avatar.png';
  }
}

export function addDays(date: Date, days: number): Date {
  const dat = new Date(date);
  dat.setDate(dat.getDate() + Number(days));
  return dat;
}

export function getComparator(fieldName: string) {
  return (a: any, b: any) => {
    if (a[fieldName] < b[fieldName]) {
      return -1;
    }
    if (a[fieldName] > b[fieldName]) {
      return 1;
    }
    return 0;
  };
}

export function nl2br(str): string {
  const breakTag = '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export function getOffsetFromDocument(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
}

export function assignExisting<T>(obj1: T, obj2: any): T {
  Object.keys(obj2).filter((key) => key in obj1).forEach((key) => obj1[key] = obj2[key]);
  return obj1;
}

export function isBlank(s: string) {
  return !s || s.length === 0;
}

export function floorToFraction(value: number, fraction: number): number {
  if (!value) {
    return 0;
  }
  return parseFloat(Number(value).toFixed(fraction));
}

export function goInboxUrl(jobId: number, projectId: number, suggestionId: number, role: Role, router: Router) {
  if (role === Role.EMPLOYER) {
    router.navigate([`/jobs/${jobId}/inbox`], {
      queryParams: {
        sProject: projectId,
        sId: suggestionId,
        pId: projectId
      }
    });
  } else if (role === Role.FREELANCER) {
    router.navigate([`/inbox`], {
      queryParams: {
        sProject: projectId,
        sId: suggestionId,
        pId: projectId
      }
    });
  }
}

export function calcMinBudget(currency: Currency): number {
  return Math.pow(10, -currency.fraction);
}

export function toMilles(date: Date | Moment): number {
  if (moment.isMoment(date)) {
    return date.valueOf();
  } else {
    return (date as Date).getTime();
  }
}

export function momentToDate(value: any): any {
  for (const prop in value) {
    if (value.hasOwnProperty(prop)) {
      if (moment.isMoment(value[prop])) {
        value[prop] = (value[prop] as Moment).toDate();
      }
    }
  }
  return value;
}

export function signalTypeGender(type: SignalType, isFreelancer: boolean, isProposal: boolean): string {
  let gender = 'm';
  switch (type) {
    case SignalType.MESSAGE:
      gender = '';
      break;
    case SignalType.TASK:
      gender = 'f';
      break;
    case SignalType.SUGGESTION:
      gender = !isFreelancer && isProposal ? 'f' : '';
      break;
  }
  return gender;
}

export function checkForm(form: NgForm | FormGroup): boolean {
  const controls = form.controls;
  for (const control in controls) {
    if (controls.hasOwnProperty(control)) {
      controls[control].markAsTouched();
    }
  }
  return form.valid;
}
