import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { BdcWalkService } from 'bdc-walkthrough';

import { RouteHelper } from '../../helpers/route.helper';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../helpers/rxjs/take-until-destroyed';
import { PortalService } from '../../services/portal.service';
import { ProfileService } from '../../services/profile.service';
import { HelpDialogComponent } from '../../modules/common-dialogs/components/help-dialog/help-dialog.component';
import { ProjectApiService } from '../../services/project.api.service';
import { ContractsService } from '../../modules/contracts/services/contracts.service';
import moment from 'moment';
import { NewWsService } from '../../services/new-ws.service';

@DecorateUntilDestroy()
@Component({
  selector: 'tpt-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent implements AfterViewInit, OnDestroy {

  public get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  @ViewChild(HelpDialogComponent) helpDialog: HelpDialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public sidebarPortal: TemplatePortal | null = null;

  public expanded: boolean;

  public extendedSidebar: boolean;

  public unreadCount = 0;

  private timeToGetChangesFromDOM = 50;

  constructor(
    public routeHelper: RouteHelper,
    public profileService: ProfileService,
    private projectService: ProjectApiService,
    private portalService: PortalService,
    private cdf: ChangeDetectorRef,
    private router: Router,
    private bdcWalkService: BdcWalkService,
    private contractsService: ContractsService,
    private newWs: NewWsService
  ) { }

  get projectsRoute() {
    if (!this.projectService.currentProject) {
      return this.profileService.currentProfile.isEmployer() ?
        this.routeHelper.employeerProjectsDashboard :
        this.routeHelper.freelancerProjectsDashboard;
    }

    return this.profileService.currentProfile.isEmployer() ?
      this.routeHelper.employerProjectPage(this.projectService.currentProject) :
      this.routeHelper.freelancerProjectPage(this.projectService.currentProject);
  }

  get contractsRoute() {
    return this.profileService.currentProfile.isEmployer() ?
      this.routeHelper.employerContracts :
      this.routeHelper.frContracts;
  }

  get searchRoute() {
    return this.profileService.currentProfile.isEmployer() ?
      this.routeHelper.freelancerSearch :
      this.routeHelper.projectSearch;
  }

  // a temporary solution, since routerLinkActive does not work properly with child routes here
  get searchIsActive(): boolean {
    return this.router.url.includes('/search');
  }

  ngAfterViewInit(): void {
    this.portalService.sidebarPortal.pipe(
      takeUntilDestroyed(this),
    ).subscribe(this.handleSidebarPortal);

    this.profileService.startOnBoardingSubject.pipe(takeUntilDestroyed(this)).subscribe(() => this.expanded = true);

    if (this.profileService.currentProfile) {
      this.getUnreadMessages();
    }

    this.newWs.updateUnreadCount.subscribe(senderId => {
      if (senderId === this.profileService.currentProfile?.id) {
        return;
      }

      this.getUnreadMessages();
    });
  }

  ngOnDestroy(): void {
  }

  public getUnreadMessages(): void {
    this.contractsService.getUnreadCount().subscribe(count => {
      this.unreadCount = count;
    });
  }

  public toggleSidebar(): void {
    this.expanded = !this.expanded;
  }

  public reset() {
    this.expanded = true;
    this.bdcWalkService.reset(this.isEmployer ? 'onBoardingEmployer' : 'onBoardingFreelancer');
    this.router.navigate(this.isEmployer ? this.routeHelper.employeerProjectsDashboard :
      this.routeHelper.freelancerProjectsDashboard);
  }

  public goToProposals(type) {
    this.router.navigate(this.isEmployer ? this.routeHelper.contractsProposals : this.routeHelper.frContractsProposals);

    switch (type) {
      case 'outgoing':
        this.bdcWalkService.setTaskCompleted('empOutStepNext', 2);
        break;
      case 'incoming':
        this.bdcWalkService.setTaskCompleted('empInStepNext', 2);
        break;
    }
  }

  public onBoardingIsIncoming(): boolean {
    return this.profileService.onBoardingInfo.isIncoming;
  }

  private handleSidebarPortal = (templatePortal: TemplatePortal): void => {
    this.extendedSidebar = Boolean(templatePortal);

    if (this.extendedSidebar && this.expanded) {
      this.expanded = false;
    }
    this.cdf.detectChanges();

    setTimeout(() => {
      this.sidebarPortal = templatePortal;
      this.cdf.detectChanges();
    }, this.timeToGetChangesFromDOM);
  }
}
