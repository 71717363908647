import {Type} from 'class-transformer';
import {Role} from '../../classes/enums';
import {Category} from '../category';
import {Country} from '../country';
import {FileUpload} from '../fileUpload';
import {Skill} from '../skill';
import {SimpleProfile} from './simpleProfile';
import {LanguageLevel} from '../languageLevel';
import { Currency } from '../currency';
import { ExpertLevel } from '../../classes/enums';

export class BaseProfile extends SimpleProfile {
  public city: string;
  @Type(() => Country)
  public country: Country;
  @Type(() => Category)
  public categories: Category[] = [];

  @Type(() => Skill)
  public skills: Skill[] = [];
  public birthDate: Date = null;

  @Type(() => LanguageLevel)
  public languages: LanguageLevel[] = [];
  public interfaceLanguage: string;
  public company: string;
  public website: string;
  public skype: string;
  public facebook: string;
  public contactEmail: string;
  public contactEmailVerified: boolean;
  public twitter: string;
  public linkedin: string;
  public timeZone: string;
  public phoneNumber: string;
  public description: string;
  public rabbitmqHash: number;
  public availabilityFromTime = '10:00:00';
  public availabilityTillTime = '18:00:00';

  @Type(() => FileUpload)
  public avatar: FileUpload;
  public male: boolean = null;
  public role: Role;

  public avgRate: number;
  public ratesCount: number;

  public completedProjects: number;
  public moneySpent: number;

  @Type(() => Currency)
  public hourlyRateCurrency: Currency;
  public hourlyRate: number;

  public available: boolean;
  public expertLevel: ExpertLevel;
  public onBoardingViewed: boolean;

  public isEmployer() {
    return this.role === Role.EMPLOYER;
  }

  public isFreelancer() {
    return this.role === Role.FREELANCER;
  }
}
