import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base.service';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

export interface TokenData {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
}

const REFRESHTOKEN_KEY = 'auth-refreshtoken';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(http: HttpClient) {
    super(http);

    this.baseUrl = `${window.location.protocol}//${environment.MAIN_HOST}/`;
  }

  public login(username: string, password: string): Promise<any> {
    return this.executePost<any>('auth/login', JSON.stringify({username, password}));
  }

  public loginV2(username: string, password: string): Observable<TokenData> {
    const params = {
      username: username.toLowerCase(),
      password,
      grant_type: 'password'
    };

    const headers = {
      Authorization: 'Basic dHB0X2NsaWVudDpnWitKOTdUeg=='
    };

    return fromPromise(this.executePost<any>('oauth/token', null, { params, headers }));
  }

  public refreshToken(refreshToken): Observable<any> {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    };

    const headers = {
      Authorization: 'Basic dHB0X2NsaWVudDpnWitKOTdUeg=='
    };

    return fromPromise(this.executePost<any>('oauth/token', null, { params, headers }));
  }

  public deleteRefreshToken(): Observable<any> {
    const token = localStorage.getItem(REFRESHTOKEN_KEY);

    return fromPromise(this.executeDelete('oauth/token/revoke', { params: {token} }));
  }
}
