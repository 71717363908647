import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Settings } from '../classes/settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {

  private settingsKey = 'settings';

  constructor(http: HttpClient) {
    super(http);
  }

  public getDefaults(): Map<string, string> {
    return JSON.parse(localStorage.getItem(this.settingsKey)) as Map<string, string>;
  }

  public initDefaults(): void {
    this.executeGet<string>('settings', {responseType: 'text'}).pipe(
      map((sett) => {
        localStorage.setItem(this.settingsKey, sett);
      })
    ).subscribe();
  }

  public getSettings(projectId: number): Observable<Settings> {
    return this.executeGet<Settings>(`settings/project/${projectId}`);
  }

}
