import { Component } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

@Component({
  template: ''
})
export class LazyDialogHelperComponent {
  constructor(public dialog: MatDialog) {
  }

  public open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
                                   config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, config);
  }

}
