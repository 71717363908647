import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { FinanceServiceV2 } from '../../services/finance.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { AccountModel } from '../../../../models/financev2/account.model';
import { RouteHelper } from '../../../../helpers/route.helper';
import { WithdrawFundsForm } from '../../forms/withdraw-funds.form';
import { DecorateUntilDestroy } from '../../../../helpers/rxjs/take-until-destroyed';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

enum WithdrawStateEnum {
  Initial,
  Success,
  Error,
}

@DecorateUntilDestroy()
@Component({
  selector: 'tpt-withdraw-funds-dialog',
  templateUrl: './withdraw-funds-dialog.component.html',
  styleUrls: [ './withdraw-funds-dialog.component.scss' ],
})
export class WithdrawFundsDialogComponent implements OnDestroy {

  @Output()
  public readonly dialogClose = new EventEmitter<void>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public accounts: AccountModel[];

  public usdAccount: AccountModel;

  public withdrawFundsForm: WithdrawFundsForm;

  public withdrawStateEnum = WithdrawStateEnum;

  public svgIconsEnum = SvgIconsEnum;

  public withdrawState: WithdrawStateEnum = WithdrawStateEnum.Initial;

  private className = 'tpt-withdraw-funds-dialog';

  private config: MatDialogConfig = {
    width: '520px',
    height: '360px',
  };

  constructor(
    public routeHelper: RouteHelper,
    private financeServiceV2: FinanceServiceV2,
    private spinnerService: SpinnerService,
  ) { }

  ngOnDestroy(): void {
  }

  public open(): void {
    if (!this.financeServiceV2.accounts) {
      this.spinnerService.startSpinner();
      this.financeServiceV2.getAccount().subscribe(this.handleAccounts);
    } else {
      this.accounts = this.financeServiceV2.accounts;
      this.usdAccount = this.financeServiceV2.usdAccount;
      this.withdrawFundsForm = WithdrawFundsForm.createForm(this.usdAccount.id);
      this.dialog.config = this.config;
      this.dialog.open(this.className);
    }
    this.withdrawState = WithdrawStateEnum.Initial;
  }

  public close = (): void => {
    this.dialogClose.emit();
    this.dialog.close();
  }

  public withdrawFunds(): void {
    if (!this.withdrawFundsForm.validate()) {
      return;
    }

    this.spinnerService.startSpinner();
    this.financeServiceV2.withdrawFunds(this.withdrawFundsForm)
      .subscribe(this.handleWithdrawSuccess, this.handleWithdrawFailure);
  }

  private handleAccounts = (accounts: AccountModel[]): void => {
    this.accounts = accounts;
    this.withdrawFundsForm = WithdrawFundsForm.createForm(this.usdAccount.id);
    this.spinnerService.stopSpinner();
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  private handleWithdrawSuccess = (): void => {
    this.spinnerService.stopSpinner();
    this.withdrawState = WithdrawStateEnum.Success;
  }

  private handleWithdrawFailure = (): void => {
    this.spinnerService.stopSpinner();
    this.withdrawState = WithdrawStateEnum.Error;
  }

}
