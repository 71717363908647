import {Type} from 'class-transformer';

export class JobSettings {
  public redmineUrl: string;
  public redmineAPIKey: string;
  public redmineProjectId: number;

  @Type(() => Date)
  public maxIssueUpdatedOn: Date;
}
