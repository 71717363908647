import { Injectable, OnDestroy } from '@angular/core';
import {OpenVidu, Publisher, PublisherProperties, Session, StreamEvent, VideoElementEvent} from 'openvidu-browser';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {
  WebRtcCallState,
  WebRtcParticipantStatus,
  WebRtcRegisterState,
  WebRtcSource,
  WSReadyState
} from '../models/webrtc/enums';
import {ParticipantStatusChangedMessage} from '../models/webrtc/messages/participantStatusChangedMessage';
import {StreamCreatedMessage} from '../models/webrtc/messages/streamCreatedMessage';
import {StreamDestroyedMessage} from '../models/webrtc/messages/streamDestroyedMessage';
import {AlertService} from './alert.service';
import {LogService} from './log.service';
import {BaseProfile} from '../models/profile/baseProfile';
import {plainToClass} from 'class-transformer';
import {WSViduMessage} from '../classes/wsViduMessage';
import {WSViduMessageId} from '../classes/enums';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class WebRtcService implements OnDestroy {
  public static CHROME_EXTENSION_ID = 'accnpgmckgfanildidkipbfpjdnebilp';
  public static CHROME_EXTENSION_HREF = 'https://chrome.google.com/webstore/detail/xlance-screen-sharing-ext/accnpgmckgfanildidkipbfpjdnebilp';

  public registerState: WebRtcRegisterState = WebRtcRegisterState.NOT_REGISTERED;
  public callState: WebRtcCallState = WebRtcCallState.INITIAL;

  public registerStateChanged$: BehaviorSubject<number> = new BehaviorSubject(null);
  public callStateChanged$: BehaviorSubject<number> = new BehaviorSubject(null);
  public incomingCall$: BehaviorSubject<any> = new BehaviorSubject(null);
  public streamCreated$: BehaviorSubject<StreamCreatedMessage> = new BehaviorSubject<StreamCreatedMessage>(null);
  public streamDestroyed$: BehaviorSubject<StreamDestroyedMessage> = new BehaviorSubject<StreamDestroyedMessage>(null);
  public participantStatusChanged$: BehaviorSubject<ParticipantStatusChangedMessage> = new BehaviorSubject<ParticipantStatusChangedMessage>(null);
  public participantStatusChangedOnUserLeave$: BehaviorSubject<ParticipantStatusChangedMessage> = new BehaviorSubject<ParticipantStatusChangedMessage>(null);

  public videoSource: WebRtcSource = WebRtcSource.CAMERA;

  // User and room name that invites me right now
  public incomingPeer: string;
  public incomingRoom: string;
  // User to be automatically invited after room have been created
  private userToCall: BaseProfile;

  private OV: OpenVidu;

  private ws: WebSocket;
  private selfName = '';
// Current user OpenVidu session data
  private ovSessionId: string; // same as room name
  private ovToken: string;
  private ovActiveSession: Session;
  private ovActivePublisher: Publisher;
  private profile;

  constructor(public alertService: AlertService,
              private logService: LogService) {
    this.OV = new OpenVidu();
    // console.log(this.OV);
    this.OV.setAdvancedConfiguration({screenShareChromeExtension: WebRtcService.CHROME_EXTENSION_HREF});
  }

  public setRegisterState(state: WebRtcRegisterState) {
    this.registerState = state;
    this.registerStateChanged$.next(state);
  }

  public setCallState(state: WebRtcCallState) {
    this.callState = state;
    this.callStateChanged$.next(state);
  }

  public ngOnDestroy() {
    if (this.ws) {
      try {
        this.ws.close();
      } catch (error) {
        this.logService.info(error);
      }
    }
  }

  public register() {
    const token = localStorage.getItem('auth-token');
    const currentUser = JSON.parse(localStorage.getItem(USER_KEY));
    if (!this.profile || token !== this.profile.token) {
      this.unregister();
      this.ws = new WebSocket(`${window.location.protocol.endsWith('s:') ? 'wss' : 'ws'}://${environment.MAIN_HOST}/rtc?token=${token}`);
      this.ws.onopen = () => {
        this.setRegisterState(WebRtcRegisterState.REGISTERING);
        const message = new WSViduMessage();
        message.id = WSViduMessageId.REGISTER;
        this.sendMessage(message);
      };

      this.ws.onerror = (error) => this.onError(error);

      this.ws.onmessage = (message: MessageEvent) => {
        const parsedMessage: WSViduMessage = plainToClass(WSViduMessage, JSON.parse(message.data) as WSViduMessage);
        this.logService.info('Received message: ' + message.data);
        switch (parsedMessage.id) {
          case WSViduMessageId.REGISTER_RESPONSE:
            this.onRegisterResponse(parsedMessage);
            break;
          case WSViduMessageId.CREATE_ROOM_RESPONSE:
            this.onCreateRoomResponse(parsedMessage);
            break;
          case WSViduMessageId.INCOMING_CALL:
            this.onIncomingCall(parsedMessage);
            break;
          case WSViduMessageId.WITHDRAW_INCOMING_CALL:
            this.onWithdrawIncomingCall(parsedMessage);
            break;
          case WSViduMessageId.INVITE_RESPONSE:
            this.onInviteResponse(parsedMessage);
            break;
          case WSViduMessageId.JOIN_ROOM_RESPONSE:
            this.onJoinRoomResponse(parsedMessage);
            break;
          case WSViduMessageId.LEAVE_ROOM_RESPONSE:
            this.cleanCommunication();
            break;
          case WSViduMessageId.USER_LEAVE_ROOM:
            this.onUserLeaveRoom(parsedMessage);
            break;
          // case WSViduMessageId."kickOut":
          //   this.onKickOut(parsedMessage);
          //   break;
          case WSViduMessageId.ERROR:
            this.onError(parsedMessage);
            break;
          default:
            console.error('Unrecognized message', parsedMessage);
        }
      };

      this.ws.onclose = (event: CloseEvent) => {
        console.log(event);
        console.log('Try to register');
        this.setRegisterState(WebRtcRegisterState.NOT_REGISTERED);
        this.unregister();
        setTimeout(() => {
          this.register();
        }, 1000);

      };

      this.selfName = '';
      this.profile = currentUser;
    }
  }

  public unregister() {
    if (this.ws && this.ws.readyState === WSReadyState.OPEN) {
      try {
        this.leaveRoom();
        this.ws.close();
      } catch (error) {
        this.logService.info(error);
      }

    }
    this.cleanCommunication();
    this.ws = null;
    this.profile = null;
    this.setRegisterState(WebRtcRegisterState.NOT_REGISTERED);
  }

  public createRoom() {
    this.setCallState(WebRtcCallState.CALL);
    const message = new WSViduMessage();
    message.id = WSViduMessageId.CREATE_ROOM;
    this.sendMessage(message);
  }

  public inviteUser(profile: BaseProfile) {
    const message = new WSViduMessage();
    message.id = WSViduMessageId.INVITE_USER;
    message.to = profile.rabbitmqHash;
    message.room = this.ovSessionId;
    this.sendMessage(message);
    this.participantStatusChanged$.next(new ParticipantStatusChangedMessage(profile.rabbitmqHash, WebRtcParticipantStatus.CALLING, profile.fullName));
  }

  public withdrawInviteUser(hash: number) {
    const message = new WSViduMessage();
    message.id = WSViduMessageId.WITHDRAW_INVITE_USER;
    message.to = hash;
    message.room = this.ovSessionId;
    this.sendMessage(message);
    this.participantStatusChanged$.next(new ParticipantStatusChangedMessage(hash, WebRtcParticipantStatus.NONE, ''));
  }

  public call(profile: BaseProfile) {
    this.userToCall = profile;
    this.createRoom();
  }

  public switchVideoTo(source: WebRtcSource) {
    if (this.ovActivePublisher) {
      this.ovActiveSession.unpublish(this.ovActivePublisher);
    }
    this.videoSource = source;
    this.publishStream();
  }

  public acceptIncomingCall() {
    const response = new WSViduMessage();
    response.id = WSViduMessageId.INCOMING_CALL_RESPONSE;
    response.response = 'accepted';
    response.room = this.incomingRoom;
    response.message = '';
    this.sendMessage(response);
    this.joinRoom(this.incomingRoom);
    this.incomingRoom = null;
    this.incomingPeer = null;
  }

  public rejectIncomingCall() {
    const response = new WSViduMessage();
    response.id = WSViduMessageId.INCOMING_CALL_RESPONSE;
    response.response = 'rejected';
    response.room = this.incomingRoom;
    response.message = 'RTC.USER_DECLINED';
    this.sendMessage(response);
    this.cleanCommunication();
  }

  public leaveRoom() {
    if (this.ovSessionId) {
      const message = new WSViduMessage();
      message.id = WSViduMessageId.LEAVE_ROOM;
      message.room = this.ovSessionId;
      this.sendMessage(message);
      this.cleanCommunication();
    }
  }

  public joinRoom(name) {
    const response = new WSViduMessage();
    response.id = WSViduMessageId.JOIN_ROOM;
    response.room = name;
    this.sendMessage(response);
  }

  private cleanCommunication() {
    if (this.ovActiveSession) {
      this.ovActiveSession.disconnect();
    }
    this.ovSessionId = null;
    this.ovToken = null;
    this.ovActiveSession = null;
    this.incomingPeer = null;
    this.incomingRoom = null;
    this.userToCall = null;
    this.videoSource = WebRtcSource.CAMERA;
    this.setCallState(WebRtcCallState.INITIAL);
  }

  /* ====================================
            RESPONSE FUNCTIONS
     ==================================== */

  // receive incoming call message
  private onIncomingCall(message: WSViduMessage) {
    console.log(message);
    // If busy just reject without disturbing user
    if (this.callState !== WebRtcCallState.INITIAL) {
      const response = new WSViduMessage();
      response.id = WSViduMessageId.INCOMING_CALL_RESPONSE;
      response.response = 'rejected';
      response.room = message.room;
      response.message = 'busy';
      return this.sendMessage(response);
    } else {
      this.incomingPeer = message.fromName;
      this.incomingRoom = message.room;
      this.setCallState(WebRtcCallState.INCOMING_CALL);
      this.incomingCall$.next(message);
    }
  }

  private onWithdrawIncomingCall(message: WSViduMessage) {
    this.incomingPeer = '';
    this.incomingRoom = '';
    this.setCallState(WebRtcCallState.INITIAL);
  }

  // receive response on "register" message
  private onRegisterResponse(message: WSViduMessage) {
    if (message.response === 'accepted') {
      this.setRegisterState(WebRtcRegisterState.REGISTERED);
    } else {
      this.setRegisterState(WebRtcRegisterState.NOT_REGISTERED);
      this.selfName = '';
      const errorMessage = message.message ? message.message
        : 'Unknown reason for register rejection.';
      console.log(errorMessage);
      alert('Error registering user. See console for further information.');
    }
  }

  private onUserLeaveRoom(message: WSViduMessage) {
    this.participantStatusChangedOnUserLeave$.next(new ParticipantStatusChangedMessage(message.user, WebRtcParticipantStatus.NONE, message.fromName));
  }

  private onInviteResponse(message: WSViduMessage) {
    if (message.response === 'accepted') {
      this.participantStatusChanged$.next(new ParticipantStatusChangedMessage(message.from, WebRtcParticipantStatus.CONNECTING, message.fromName));
    } else {
      this.participantStatusChanged$.next(new ParticipantStatusChangedMessage(message.from, WebRtcParticipantStatus.REJECT, message.message));
    }
  }

  private onCreateRoomResponse(message: WSViduMessage) {
    if (message.response === 'accepted') {
      this.ovSessionId = message.ovSessionId;
      this.ovToken = message.ovToken;
      this.initOvSession();
      if (this.userToCall) {
        this.inviteUser(this.userToCall);
        this.userToCall = null;
      }
    } else {
      this.setCallState(WebRtcCallState.INITIAL);
      const errorMessage = message.message ? message.message
        : 'Unknown reason for create room rejection.';
      console.log(errorMessage);
      alert('Error creating room. See console for further information.');
    }
  }

  private onJoinRoomResponse(message: WSViduMessage) {
    if (message.response === 'accepted') {
      this.ovSessionId = message.ovSessionId;
      this.ovToken = message.ovToken;
      this.initOvSession();
    } else {
      this.setCallState(WebRtcCallState.INITIAL);
      const errorMessage = message.message ? message.message
        : 'Unknown reason for join room rejection.';
      console.log(errorMessage);
      alert('Error joining room. See console for further information.');
    }
  }

  // private onKickOut(message) {
  //   this.cleanCommunication();
  // }

  private onError(message) {
    console.log(message);
    this.cleanCommunication();
    console.log('Internal Server Error while processing request: ' + message.request + ' with message ' + message.message);
    if (this.ws && this.ws.readyState === WSReadyState.OPEN) {
      this.alertService.error(message.message);
    }
  }

  /* ====================================
      SESSION AND PUBLISHERS FUNCTIONS
     ==================================== */

  private initOvSession() {
    // this.ovActiveSession = this.OV.initSession(this.ovSessionId);
    this.ovActiveSession = this.OV.initSession();

    this.ovActiveSession.on('streamCreated', (event: StreamEvent) => {
      this.streamCreated$.next(new StreamCreatedMessage(event.stream, this.ovActiveSession));
    });

    this.ovActiveSession.on('streamDestroyed', (event: StreamEvent) => {
      this.streamDestroyed$.next(new StreamDestroyedMessage(event.stream, this.ovActiveSession));
    });

    this.ovActiveSession.connect(this.ovToken)
      .then(() => {
        this.publishStream();
      })
      .catch((error) => {
        this.rejectIncomingCall();
        console.warn('There was an error connecting to the session:', error.code, error.message);
      });

    // this.ovActiveSession.connect(this.ovToken, /*'{"clientData": "' + this.selfName + '"}'*/ '', error => {
    //   if (!error) {
    //     this.publishStream();
    //   } else {
    //     console.warn('There was an error connecting to the session:', error.code, error.message);
    //   }
    // });
  }

  private publishStream() {
    this.setCallState(WebRtcCallState.CALL);
    const options: PublisherProperties = {
      audioSource: undefined, // The source of audio. If undefined default microphone
      videoSource: this.videoSource === WebRtcSource.CAMERA ? undefined : this.videoSource, // this.videoSource == 'screen' // The source of video. If undefined default webcam
      publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: true, // Whether you want to start publishing with your video enabled or not
      frameRate: 30,          // The frame rate of your video
      insertMode: 'APPEND',   // How the video is inserted in the target element 'video-container'
      mirror: false,           // Whether to mirror your local video or not
    };
    this.ovActivePublisher = this.OV.initPublisher('webrtc__local__video', options, ((error) => console.log(error)));
    if (this.ovActivePublisher) {
      this.ovActivePublisher.on('videoElementCreated', (event: VideoElementEvent) => {
        const userData = {
          nickName: this.selfName,
          userName: this.selfName,
        };
        this.initLocalVideo(event.element);
      });

      this.ovActiveSession.publish(this.ovActivePublisher);
    }
  }

  private initLocalVideo(element: HTMLVideoElement) {
    element.addEventListener('loadedmetadata', (e) => {
      const aspectRatio = element.videoHeight / element.videoWidth;
      element.width = Math.min(element.videoWidth, 240);
      element.height = element.width * aspectRatio;
    });
  }

  private sendMessage(message: WSViduMessage) {
    const jsonMessage = JSON.stringify(message);
    console.log('Senging message: ' + jsonMessage);
    if (this.ws) {
      this.ws.send(jsonMessage);
    }
  }
}
