export class SimpleProfile {
  public id: number;
  public nickName: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

}
