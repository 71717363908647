import { Component, HostListener } from '@angular/core';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { isMobileDevice } from '../../helpers/get-mobile.helper';

@Component({
  selector: 'confirm-success',
  templateUrl: './confirm-success.component.html',
  styleUrls: ['./confirm-success.component.scss']
})
export class ConfirmSuccessComponent {

  public isMobile = isMobileDevice();
  public svgIconsEnum = SvgIconsEnum;

  @HostListener('window:resize') onResize() {
    this.isMobile = isMobileDevice();
  }

  public get registerCardIcon(): string {
    return `/assets/icons/${SvgIconsEnum.RegisterCardIcon}.svg`;
  }
}
