import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { JobCardProgressComponent } from './job-card-progress/job-card-progress.component';
import { JobCardDaysRemainingComponent } from './job-card-days-remaining/job-card-days-remaining.component';
import { OwnMessageComponent } from './task-comments-messages/own-message/own-message.component';
import { CompanionMessageComponent } from './task-comments-messages/companion-message/companion-message.component';
import { TptUiModule } from '../tpt-ui/tpt-ui.module';
import { ProjectPageHeaderComponent } from './project-page-header/project-page-header.component';
import { ProjectNoteComponent } from './project-note/project-note.component';
import { CommonDialogsModule } from '../common-dialogs/common-dialogs.module';
import { ProjectNotesDialogComponent } from './project-notes-dialog/project-notes-dialog.component';
import { DeleteNoteDialogComponent } from './delete-note-dialog/delete-note-dialog.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { TptFormsModule } from '../tpt-forms/tpt-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachedFilesDialogComponent } from './attached-files-dialog/attached-files-dialog.component';
import { AttachedFileItemComponent } from './attached-file-item/attached-file-item.component';
import { ProjectDetailsCardComponent } from './project-details-card/project-details-card.component';
import { AvatarModule } from 'ngx-avatar';
import { AssigneePreviewComponent } from './assignee-preview/assignee-preview.component';
import { VacancyCardComponent } from './vacancy-card/vacancy-card.component';
import { RolesComponent } from './access-settings-page/roles/roles.component';
import { UsersComponent } from './access-settings-page/users/users.component';
import { DeadlineStatusComponent } from './deadline-status/deadline-status.component';
import { TaskStateComponent } from './task-state/task-state.component';
import { HeaderLightComponent } from './header-light/header-light.component';
import { FooterLightComponent } from './footer-light/footer-light.component';
import { TextileModule } from '../textile/textile.module';
import { ReviewDaysLeftComponent } from './review-days-left/review-days-left.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatIconModule,
    TranslateModule,
    TptUiModule,
    CommonDialogsModule,
    CommonPipesModule,
    TptFormsModule,
    ReactiveFormsModule,
    RouterModule,
    AvatarModule,
    MatDividerModule,
    MatSlideToggleModule,
    FormsModule,
    TextileModule,
  ],
  declarations: [
    JobCardProgressComponent,
    JobCardDaysRemainingComponent,
    OwnMessageComponent,
    CompanionMessageComponent,
    ProjectPageHeaderComponent,
    ProjectNoteComponent,
    ProjectNotesDialogComponent,
    DeleteNoteDialogComponent,
    AttachedFilesDialogComponent,
    AttachedFileItemComponent,
    ProjectDetailsCardComponent,
    AssigneePreviewComponent,
    VacancyCardComponent,
    RolesComponent,
    UsersComponent,
    DeadlineStatusComponent,
    TaskStateComponent,
    HeaderLightComponent,
    FooterLightComponent,
    ReviewDaysLeftComponent
  ],
  exports: [
    JobCardProgressComponent,
    JobCardDaysRemainingComponent,
    OwnMessageComponent,
    CompanionMessageComponent,
    ProjectPageHeaderComponent,
    ProjectNoteComponent,
    AttachedFilesDialogComponent,
    ProjectDetailsCardComponent,
    AssigneePreviewComponent,
    VacancyCardComponent,
    DeadlineStatusComponent,
    TaskStateComponent,
    HeaderLightComponent,
    FooterLightComponent,
    ReviewDaysLeftComponent
  ],
})
export class CommonComponentsModule {
}
