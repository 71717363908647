import {
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

import { Observable, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { NouisliderComponent } from 'ng2-nouislider';
import { TranslateService } from '@ngx-translate/core';
import { omitBy } from 'lodash';

import { PortalService } from '../../../../services/portal.service';
import { CategoryService } from 'src/app/services/category.service';
import { CountryService } from 'src/app/services/country.service';
import { LanguageService } from 'src/app/services/language.service';
import { SearchService } from 'src/app/services/search.service';
import { SkillService } from 'src/app/services/skill.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { ProjectApiService } from '../../../../services/project.api.service';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { Country } from '../../../../models/country';
import { CategoryV2Model } from '../../../../models/category-v2.model';
import { Language } from '../../../../models/language';
import { MatSelectChange } from '@angular/material/select';
import { DecimalPipe } from '@angular/common';
import { AccountModel } from '../../../../models/financev2/account.model';
import { FinanceServiceV2 } from '../../../common-dialogs/services/finance.service';
import { CurrencyEnum, CurrencySymbol } from '../../../../models/currency.enum';
import { LoadingBarService } from '../../../../services/loading-bar.service';

@Component({
  selector: 'project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./project-search.component.scss'],
})
export class ProjectSearchComponent implements OnInit, OnDestroy {

  public languageLevels: string[] = [
    'BEGINNER',
    'MEDIUM',
    'ADVANCED',
    'NATIVE'
  ];

  public categories: CategoryV2Model[];
  languageLevel = {
    code: null,
    name: null,
    level: null
  };

  languageArray = [];

  separatorKeysCodes: number[] = [ENTER];
  skillCtrl = new FormControl('');
  skills: string[] = [];
  allSkills: any[] = [];
  filteredSkills: Observable<any[]>;
  projects = [];

  public mainAccountLoaded = false;
  allSkillsArray = [];
  svgIconsEnum = SvgIconsEnum;
  filteredItems;
  rubMaxBudget = 3000000;
  usdMaxBudget = 50000;
  rubMaxRate = 10000;
  usdMaxRate = 120;

  filterSlider = {
    budget: [0, this.usdMaxBudget],
    hourlyRate: [0, this.usdMaxRate]
  };

  public hourlyRateSliderConfig = {
    connect: [false, true, false],
    tooltips: [true, true],
    range: {
      min: 1,
      max: this.usdMaxRate,
    },
    format: {
      to: (val) => this._decimalPipe.transform(val),
      from: (value) => Number(value.replace(/\s/g, '')),
    },
    step: 1
  };

  public budgetSliderConfig = {
    connect: [false, true, false],
    tooltips: [true, true],
    range: {
      min: 10,
      max: this.usdMaxBudget,
    },
    format: {
      to: (val) => this._decimalPipe.transform(val),
      from: (value) => Number(value.replace(/\s/g, '')),
    },
    step: 10
  };

  @ViewChild('budgetSlider')
  public budgetSlider: NouisliderComponent;

  @ViewChild('hourlyRateSlider')
  public hourlyRateSlider: NouisliderComponent;

  @ViewChild('top')
  public top: ElementRef;

  public paymentMethod: string[] = [
    'fixed',
    'hourly'
  ];

  public countries: Country[];
  public countriesCount: number;
  public languages: Language[];
  public filter: FormGroup;
  public countryFormControl: FormControl = new FormControl();
  public currencyEnum = CurrencyEnum;
  public currencySymbol = CurrencySymbol;
  public mainAccount = CurrencyEnum.USD;
  public showSkeleton = false;
  public loading: boolean;
  private pageNumber = 0;
  private params;
  private currentPosition = 0;
  private totalPages: number;
  private readonly destroy$ = new Subject();

  constructor(
    private portalService: PortalService,
    private categoryService: CategoryService,
    private countryService: CountryService,
    private languageService: LanguageService,
    private searchService: SearchService,
    private skillService: SkillService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private spinner: SpinnerService,
    private projectService: ProjectApiService,
    private translate: TranslateService,
    private _decimalPipe: DecimalPipe,
    private financeService: FinanceServiceV2,
    private loadingBarService: LoadingBarService
  ) {
  }

  @HostListener('scroll', ['$event']) onScroll(event) {
    const containerHeight = event?.target?.offsetHeight;
    const scrollHeight = event?.target?.scrollHeight;
    const scrollTop = event?.target?.scrollTop;

    if (this.currentPosition > scrollTop) {
      return;
    }
    this.currentPosition = scrollTop;

    const a = scrollHeight - scrollTop;
    const b = containerHeight * 0.25 + containerHeight;

    if (a < b && !this.loading) {
      if (this.pageNumber >= this.totalPages - 1) {
        return;
      }
      this.loading = true;
      this.pageNumber = this.pageNumber + 1;
      this.getListOfProjects(this.params);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getCategories();
    this.getCountries(true);
    this.getLanguages();
    this.getAllSkills();
    this.getFilteredProjects();
    this.loadingBarService.start();

    this.skillCtrl.valueChanges.pipe(startWith('')).subscribe((value) => {
      this.findSkills(value);
    });

    if (!this.financeService.accounts) {
      this.financeService.getAccount().subscribe((accounts: AccountModel[]) => {
        const main = accounts.find(acc => acc.main);
        this.mainAccount = main ? CurrencyEnum[main.currency.code] : CurrencyEnum.USD;
        if (this.mainAccount === CurrencyEnum.RUB) {
          this.setRubConfig();
        }
        this.mainAccountLoaded = true;
      });
    } else {
      const main = this.financeService.accounts.find(acc => acc.main);
      this.mainAccount = main ? CurrencyEnum[main.currency.code] : CurrencyEnum.USD;
      if (this.mainAccount === CurrencyEnum.RUB) {
        this.setRubConfig();
      }
      this.mainAccountLoaded = true;
    }

  }

  public setRubConfig(): void {
    this.budgetSliderConfig.range.max = this.rubMaxBudget;
    this.budgetSliderConfig.step = 1000;
    this.filterSlider.budget = [0, this.rubMaxBudget];

    this.filterSlider.hourlyRate[1] = this.rubMaxRate;
    this.hourlyRateSliderConfig.range.max = this.rubMaxRate;
  }

  getAllComplete(category): boolean {
    return category.children.every(item => item.isSelected);
  }

  getFilteredProjects(): void {
    this.searchService.projects$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.refreshVariables();

      if (!data) {
        this.params = {};
        this.getListOfProjects({});
        return;
      }

      const mappedData = data.value;
      let levelsString = '';
      if (data.value.levels && typeof data.value.levels !== 'string') {
        const levels = data.value.levels;
        const levelsArray = [];
        Object.keys(levels).forEach(key => {
          if (levels[key]) {
            levelsArray.push(key.toUpperCase());
          }
        });
        levelsString = levelsArray.join(',');
        mappedData.levels = levelsString;
      }

      const currencies = [];
      if (mappedData.rub) {
        currencies.push('RUB');
      }

      if (mappedData.usd) {
        currencies.push('USD');
      }

      mappedData.currencies = currencies;
      mappedData.skills = mappedData.skills.map(item => item.id);

      const model = omitBy(mappedData,
        (field: string | number) => !Boolean(field));

      this.params = model;
      this.getListOfProjects(model);
    });
  }

  initForm(): void {
    this.filter = new FormGroup({
      categories: new FormControl([]),
      skills: new FormArray([]),
      levels: new FormGroup({
        beginner: new FormControl(false),
        intermediate: new FormControl(false),
        master: new FormControl(false),
      }),
      fixedProject: new FormControl(false),
      hourlyProject: new FormControl(false),
      usd: new FormControl(false),
      rub: new FormControl(false),
      budgetMin: new FormControl(0),
      budgetMax: new FormControl(3000000),
      rateMin: new FormControl(0),
      rateMax: new FormControl(1000),
      countryCode: new FormControl(''),
      languageLevels: new FormControl([]),
      sortBy: new FormControl('PUBLISH_DATE_DESC'),
      projectOwnerNameFtsQuery: new FormControl('')
    });
    this.searchService.filtersProjectsNext(this.filter);
    this.filter.valueChanges.pipe(takeUntil(this.destroy$)).pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.searchService.filtersProjectsNext(this.filter);
    });
  }

  public updateFilter(event: MatSelectChange) {
    if (!this.filter) {
      return;
    }

    this.filter.controls.countryCode.setValue(event.value);
  }

  getListOfProjects(filter) {
    if (!filter?.currencies?.length) {
      delete filter.currencies;
    }
    const defaultFilter = {pageNumber: this.pageNumber, pageSize: 15};
    this.projectService.getListOfProjects({...defaultFilter, ...filter})
      .subscribe((res) => {
        this.projects = [...this.projects, ...res.content];
        this.loading = false;
        this.totalPages = res.totalPages;
        this.showSkeleton = false;
        this.loadingBarService.complete();
    });
  }

  updateSortOrder(event) {
    this.filter.controls.sortBy.patchValue(event);
  }

  addLang(): void {
    this.languageArray.push({
      code: null,
      level: null
    });
  }

  removeLang(i): void {
    this.languageArray = this.languageArray.filter((item, index) => index !== i);
    this.updateLanguageForm();
  }

  updateLanguageForm() {
    const updatedArr = this.languageArray.filter(item => {
      return item.code && item.level;
    });

    this.filter.controls.languageLevels.patchValue(updatedArr);
  }

  public getAllSkills() {
    this.skillService.getSkillsV2().subscribe(res => {
      this.allSkills = res;
      this.allSkillsArray = this.allSkills;
      this.findSkills('');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setAll(checked: boolean, category) {
    category.children.forEach(item => item.isSelected = checked);
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  async getUpdAllSkills(checked, category, subCategory): Promise<void> {

    if (checked && !subCategory) {
      const skillsId = category.id;
      const res = await this.skillService.getCategorySkillsV2(skillsId).toPromise();
      res.forEach(item => {
        if (this.allSkillsArray.some(skill => skill.id === item.id)) {
          return;
        }
        this.allSkillsArray.push(item);
      });
    }

    if (!checked && !subCategory) {
      const skillId = category.id;
      const res = await this.skillService.getCategorySkillsV2(skillId).toPromise();
      const filterArr = res.map(item => item.id);
      this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
      if (!this.allSkillsArray.length) {
        this.allSkillsArray = this.allSkills;
      }
    }

    if (checked && subCategory) {
      const res = await this.skillService.getCategorySkillsV2(subCategory.id).toPromise();
      res.forEach(skill => this.allSkillsArray.push(skill));
    }

    if (!checked && subCategory) {
      if (category.children.every(child => !child.isSelected)) {
        const res = await this.skillService.getCategorySkillsV2(category.id).toPromise();
        const filterArr = res.map(item => item.id);
        this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
        if (!this.allSkillsArray.length) {
          this.allSkillsArray = this.allSkills;
        }
      }
    }

    this.updateCategoryFormControl();
    this.filteredItems = this.allSkillsArray;
  }

  updateCategoryFormControl(): void {
    if (!this.categories) {
      return;
    }

    const categoriesArray = [];

    this.categories.forEach(item => {
      if (item.isSelected) {
        categoriesArray.push(item.id);
      }
    });

    const notAllSelected = this.categories.filter(item => !item.isSelected);
    notAllSelected.forEach(item => {
      item.children.forEach(child => {
        if (child.isSelected) {
          categoriesArray.push(child.id);
        }
      });
    });

    this.filter.controls.categories.patchValue(categoriesArray);
  }

  public getCategories() {
    this.categoryService.getCategoriesV2().subscribe(res => {
      this.categories = res;
      this.categories.forEach(item => {
        item.isSelected = false;
        item.expanded = false;

        item.children.forEach(child => {
          child.isSelected = false;
        });
      });
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const skills = this.filter.get('skills') as FormArray;
      skills.push(this.fb.control(value.trim()));
    }

    if (input) {
      input.value = '';
    }
  }

  remove(index: number): void {
    const skills = this.filter.get('skills') as FormArray;

    if (index >= 0) {
      skills.removeAt(index);
    }
  }

  removeLanguage(index: any): void {
    (this.filter.controls.language as any).removeAt(index);
  }

  selectedSkill(event: any): void {
    const skills = this.filter.get('skills') as FormArray;

    const alreadyAdded = skills.value.some(item => item.id === event.option.value.id);
    if (alreadyAdded) {
      return;
    }

    skills.push(this.fb.control(this.skillCtrl.value));
    this.skillCtrl.setValue('');
    this.cdr.detectChanges();
  }

  public getViewField(): string {
    if (this.translate.currentLang === 'ru') {
      return 'nameRu';
    }
    return 'name';
  }

  public getCountries(setCount: boolean, name?: string): void {
    if (!name) {
      name = '';
    }

    this.countryService.getCountriesV2(name).subscribe(res => {
      this.countries = res;

      if (setCount) {
        this.countriesCount = res.length;
      }
    });
  }

  getLanguages(): void {
    this.languageService.getLanguagesV2().pipe(
      takeUntil(this.destroy$))
      .subscribe(languages => {
          this.languages = languages;
        }
      );
  }

  budgetChange(val): void {
    this.filter.controls.budgetMin.patchValue(val[0]);
    this.filter.controls.budgetMax.patchValue(val[1]);
    this.filter.controls.fixedProject.setValue(true);
  }

  rateChange(val: number[]) {
    this.filter.controls.rateMin.patchValue(val[0]);
    this.filter.controls.rateMax.patchValue(val[1]);
    this.filter.controls.hourlyProject.setValue(true);
  }

  public setBudgetSliderValue() {
    this.budgetSlider.slider.set(this.filterSlider.budget);
  }

  public setRateSliderValue() {
    this.hourlyRateSlider.slider.set(this.filterSlider.hourlyRate);
  }

  toggleSubcats(category): void {
    category.expanded = !category.expanded;
    if (category.expanded) {
      this.categories.forEach(item => {
        item.expanded = item.id === category.id;
      });
    }
  }

  subCategoryChanged(event, subCat, cat): void {
    subCat.isSelected = !subCat.isSelected;
    cat.isSelected = this.getAllComplete(cat);
  }

  someChecked(category): boolean {
    const countOfChecked = category.children.filter(item => item.isSelected).length;
    return countOfChecked > 0 && countOfChecked < category.children.length;
  }

  public updateProjects(val): void {
    this.filter.controls.projectOwnerNameFtsQuery.patchValue(val);
  }

  private refreshVariables(): void {
    this.pageNumber = 0;
    this.currentPosition = 0;
    this.showSkeleton = true;
    this.projects = [];
    this.top?.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  private async findSkills(val): Promise<void> {
    if (!val) {
      this.filteredItems = this.allSkillsArray;
      return;
    }

    if (typeof val === 'string') {
      this.filteredItems = await this.skillService.getCategorySkillsV2(null, val).toPromise();
    }
  }
}
