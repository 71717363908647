import { Injectable } from '@angular/core';

import { HttpClient, HttpParams as NativeHttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpParams } from '../classes/httpParams';
import { deserializationJacksonKey, serializationJacksonKey } from '../classes/jacksonUtils';
import { JobListView } from '../models/job/jobListView';
import { JobSettings } from '../models/job/jobSettings';
import { MasterJob } from '../models/job/masterJob';
import { RIssue } from '../models/rIssue';
import { RProject } from '../models/rProject';
import { RResource } from '../models/rResource';
import { BaseProjectService } from './base-project.service';
import { AlertService } from './alert.service';
import { InvoiceData } from '../models/invoiceData';
import { ArbitrageCommission } from '../models/arbitrageCommission';
import { EditSimpleProjectModel } from '../modules/employer/models/edit-simple-project.model';
import { SaveToDraftsResponseModel } from '../modules/employer/models/save-to-drafts-response.model';
import { SimpleProjectResponseModel } from '../modules/employer/models/simple-project-response.model';
import { TasksResponseModel } from '../modules/employer/models/tasks-response.model';
import { AddTaskFormModel } from '../modules/employer/models/add-task-form.model';
import { ProjectDetailedTaskModel } from '../modules/employer/models/project-detailed-task.model';
import { ProjectStatusEnum } from '../modules/employer/types/project-status.enum';
import { fromPromise } from 'rxjs/internal-compatibility';
import { DeleteProjectRequestModel } from '../modules/employer/models/delete-project-request.model';
import { PriorityTypeEnum } from '../modules/employer/types/priority-type.enum';
import { FutureExpenseModel } from '../modules/finance/models/future-expense.model';
import {EditMultiProjectModel} from '../modules/employer/models/edit-multi-project.model';
import { NegotiationResponse } from '../modules/contracts/components/parts/contracts-project-card/contracts-project-card.component';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService extends BaseProjectService {

  public activeProjectsCount = 0;

  public draftProjectsCount = 0;

  public completedProjectsCount = 0;

  public currentProject = null;

  constructor(
    http: HttpClient,
    alertService: AlertService,
  ) {
    super(http, alertService);
  }

  public syncMasterJobWithRedmine(masterJobId: number): Promise<number> {
    return this.postWithCheck<number>(`redmine/syncToRedMine`, JSON.stringify(masterJobId));
  }

  public createMasterJob(masterJob: MasterJob): Promise<MasterJob> {
    return this.executePost<MasterJob>(`jobs`, JSON.stringify(serializationJacksonKey(masterJob)))
      .then((data) => deserializationJacksonKey(MasterJob, data));
  }

  public editMasterJob(id: number, masterJob: MasterJob): Observable<MasterJob> {
    return this.executePut<MasterJob>(`jobs/${id}`, JSON.stringify(serializationJacksonKey(masterJob)))
      .pipe(map((data) => deserializationJacksonKey(MasterJob, data)));
  }

  public deleteMasterJob(id: number): Promise<any> {
    return this.executeDelete(`jobs/${id}`, JSON.stringify({}));
  }

  public getUnpaidProjectsCount(): Observable<number> {
    return this.executeGet<number>(`projects/unpaidProjectsCount`);
  }

  public getAllJobsList = (params?): Observable<any> => {
    return this.executeGet<any>('v2/projects/list', {params}).pipe(
      tap(this.countProjectsByType),
      map(res => res.projects),
    );
  }

  public getJobList(): Observable<JobListView[]> {
    return this.executeGet<JobListView[]>(`jobs/list`)
      .pipe(map((data) => deserializationJacksonKey(JobListView, data)));
  }

  public getJobInviteList(): Observable<MasterJob[]> {
    return this.executeGet<MasterJob[]>(`jobs/inviteList`)
      .pipe(map((data) => deserializationJacksonKey(MasterJob, data)));
  }

  public getJobsWithArchivedProjects(): Observable<MasterJob[]> {
    return this.executeGet<MasterJob[]>(`jobs/withArchivedProjects`)
      .pipe(map((data) => deserializationJacksonKey(MasterJob, data)));
  }

  public getJobStartedList(): Observable<MasterJob[]> {
    return this.executeGet<MasterJob[]>(`jobs/startedList`)
      .pipe(map((data) => deserializationJacksonKey(MasterJob, data)));
  }

  public getJobsByIds(ids: string): Observable<MasterJob[]> {
    const url = ids ? `jobs/${ids}` : `jobs`;
    return this.executeGet<MasterJob[]>(url)
      .pipe(map((data) => deserializationJacksonKey(MasterJob, data)));
  }

  public getRedmineProjects(jobSettings: JobSettings): Promise<RProject[]> {
    return this.executePost<RProject[]>('redmine/projects', jobSettings)
      .then((data) => deserializationJacksonKey(RProject, data));
  }

  public getRedmineUsers(jobSettings: JobSettings): Promise<RResource[]> {
    return this.executePost<RResource[]>('redmine/users', jobSettings)
      .then((data) => deserializationJacksonKey(RResource, data));
  }

  public getRedmineIssues(jobSettings: JobSettings): Promise<RIssue[]> {
    return this.executePost<RIssue[]>('redmine/issues', jobSettings)
      .then((data) => deserializationJacksonKey(RIssue, data));
  }

  public getInvoices(projectId: number, suggestionId: number): Observable<InvoiceData[]> {
    const params: HttpParams = {};
    if (suggestionId) {
      params.suggestionId = suggestionId.toString();
    } else if (projectId) {
      params.projectId = projectId.toString();
    }
    return this.executeGet<InvoiceData[]>(`projects/invoices`, {params})
      .pipe(map((data) => deserializationJacksonKey(InvoiceData, data)));
  }

  public cloneProject(projectId: number, jobId: number): Promise<any> {
    const params: HttpParams = jobId ? {toMasterJobId: jobId.toString()} : {};
    return this.executePost<any>(`projects/${projectId}/clone`, null, {params})
      .then((data) => {
        return {
          projectId: data.projectId,
          job: deserializationJacksonKey(MasterJob, data.job),
        };
      });
  }

  public arbitrageCommission(projectId: number): Observable<ArbitrageCommission> {
    return this.executeGet(`projects/${projectId}/arbitrageCommission`);
  }

  public getProjectById = (id: number): Observable<SimpleProjectResponseModel> => {
    if (!id) {
      return of(null);
    }

    return this.executeGet('v2/projects', { params: { id }});
  }

  public getContractById = (chatId: number): Observable<NegotiationResponse> => {
    if (!chatId) {
      return of(null);
    }

    return this.executeGet('v2/negotiation', { params: { chatId }});
  }

  public getProjectPaymentInfoById = (negotiationId: number): Observable<FutureExpenseModel> => {
    if (!negotiationId) {
      return of(null);
    }

    return this.executeGet('v2/projects/paymentInfo', { params: { negotiationId }});
  }

  public editContractV2 = (model: EditSimpleProjectModel, chatId: number): Observable<NegotiationResponse> => {
    return this.executePut(`v2/negotiation/${chatId}`, model);
  }

  public cancelTerms = (chatId: number): Observable<void> => {
    return this.executePut(`v2/negotiation/resetNewTerms`, { id: chatId });
  }

  public changeProjectVisibility = (model: { id: number, visibility: string }): Observable<void> => {
    return this.executePut('v2/projects/visibility', model);
  }

  public saveProjectToDrafts = (model: EditSimpleProjectModel): Observable<SaveToDraftsResponseModel> => {
    return this.executePut('v2/projects', model);
  }

  public saveProjectToDraftsV2 = (model: EditMultiProjectModel): Observable<any> => {
    return this.executePut('v2/projects', model);
  }

  public publishProject = (id: number): Observable<void> => {
    return this.executePut(`v2/projects/${id}/publish`, null);
  }

  public publishComplexProject = (id): Observable<void> => {
    return this.executePut(`v2/projects/${id}/publish`, null);
  }

  public moveProjectToDrafts = (projectId: number): Observable<void> => {
    return this.executePut(`v2/projects/${projectId}/draft`, null);
  }

  public deleteProjectV2 = (model: DeleteProjectRequestModel): Observable<void> => {
    return fromPromise(this.executeDelete('v2/projects', { body: model }));
  }

  public completeProject = (projectId: number): Observable<void> => {
    return this.executePut(`v2/projects/${projectId}/stop`, null);
  }

  public completeJob = (jobId: number): Observable<void> => {
    return this.executePut(`v2/jobs/${jobId}/stop`, null);
  }

  public getProjectTasks = (id: number, freelancerId?: number, group?: string, taskName?: string, allJobs?: boolean): Observable<TasksResponseModel> => {
    const params = {
      id, freelancerId, allJobs, criterion: group, taskName
    };

    if (!freelancerId) { delete params.freelancerId; }
    if (!group) { delete params.criterion; }
    if (!taskName) { delete params.taskName; }
    if (!allJobs) {delete params.allJobs; }

    return this.executeGet('v2/projects/tasks', { params });
  }

  public editProjectTask = (model: AddTaskFormModel): Observable<any> => {
    return this.executePut('v2/tasks', model);
  }

  public getTaskDetails = (id: number): Observable<ProjectDetailedTaskModel> => {
    return this.executeGet('v2/tasks', { params: { id }});
  }

  public changeTaskStatus = (
    model: { id: number, status: ProjectStatusEnum }
  ): Observable<{ id: number, status: ProjectStatusEnum }> => {
    return this.executePut('v2/tasks/status', model);
  }

  public deleteTask = (id: number): Observable<void> => {
    const params = { params: { id }};
    return fromPromise(this.executeDelete('v2/tasks', params));
  }

  public getVacancies = (projectId: number, stateType?: string): Observable<any> => {
    const params = {
      projectId,
      stateType
    };
    if (!stateType) { delete params.stateType; }

    return this.executeGet('v2/jobs/vacancies', {params});
  }

  public changePriority = (model: { id: number, priority: PriorityTypeEnum }): Observable<void> => {
    return this.executePut('v2/tasks/priority', model);
  }

  public makePayment(jobId: number): Observable<any> {
    // return of(true).pipe(delay(3000));
    return this.executePut(`v2/projects/${jobId}/pay`, null);
  }

  public stopAutoPayment(projectId: number): Observable<any> {
    return this.executePut(`v2/projects/${projectId}/stop`, null);
  }

  public acceptTask(id, body = null): Observable<any> {
    if (body) {
      Object.keys(body).forEach(key => {
        if (key !== 'text' && key !== 'jobId') {
          body[key] = body[key] / 100;
        }
      });
    }

    return this.executePut('v2/tasks/accept', body, { params : {id}});
  }

  public rejectTask(id, reason) {
    return this.executePut('v2/tasks/decline', {taskId: id, reason});
  }

  public openDispute(id, reason) {
    return this.executePut('v2/tasks/dispute', { taskId: id, reason });
  }

  public saveJob(job): Observable<any> {
    return this.executePut('v2/jobs', job );
  }

  public getListOfProjects(params: any): Observable<any> {
    const queryString = this.transformParams(params);
    return this.executeGet(`v2/jobs/find?${queryString}`);
  }

  public getMyProjectsList(name: string = '', state: string[] = []): Observable<any> {
    return this.executeGet('v2/projects/myProjects', {params: { name, state }});
  }

  public getMyProjectsWithProposal(name: string = '', state: string[] = []): Observable<any> {
    return this.executeGet('v2/projects/myProjectsWithProposal', {params: { name, state }});
  }

  public getFreelancers(id: number): Observable<any> {
    return this.executeGet('v2/projects/freelancers', {params: {id}});
  }

  public createInvoice = (jobId: number): Observable<any> => {
    return this.executePut(`v2/projects/${jobId}/createInvoice`, null);
  }

  public confirmPayment = (jobId: number): Observable<any> => {
    return this.executePut(`v2/projects/${jobId}/confirmPayment`, null);
  }

  public paymentDocs = (jobId: number): Observable<any> => {
    return this.executeGet(`v2/projects/${jobId}/paymentDocs`);
  }

  public closingDocs = (jobId: number): Observable<any> => {
    return this.executeGet(`v2/projects/${jobId}/closingDocs`);
  }

  public saveCheck = (jobId: number, check): Observable<any> => {
    return this.executePut(`v2/projects/${jobId}/saveCheck`, check);
  }

  public getJob = (jobId: number): Observable<any> => {
    return this.http.get(this.baseUrl + `v2/jobs?id=${jobId}`);
  }

  private transformParams(params): string {
    const languageLevels = params.languageLevels;
    let languageLevelsString = '';

    delete params.languageLevels;
    const checkParamsList = ['levels', 'categories', 'skills'];
    checkParamsList.forEach(item => {
      if (params[item] && !params[item].length) {
        delete params[item];
      }
    });
    if (!params.countryCode) {
      delete params.countryCode;
    }

    let newParams = new NativeHttpParams();
    Object.keys(params).forEach(param => {
      newParams = newParams.append(param, params[param]);
    });

    const queryString = newParams.toString();

    if (languageLevels && languageLevels.length) {
      for (let i = 0; i < languageLevels.length; i++) {
        languageLevels[i].index = i;
      }
      languageLevelsString = languageLevels.map(l => {
        return `&languageLevels%5B${l.index}%5D.code=${l.code}&languageLevels%5B${l.index}%5D.level=${l.level}`;
      }).join('');
    }

    return `${queryString}${languageLevelsString}`;
  }

  private countProjectsByType = (count): void => {
    this.resetProjectsCount();

    this.activeProjectsCount = count.activeCount;
    this.draftProjectsCount = count.draftCount;
    this.completedProjectsCount = count.endedCount;
  }

  private resetProjectsCount(): void {
    this.activeProjectsCount = 0;
    this.draftProjectsCount = 0;
    this.completedProjectsCount = 0;
  }

}
