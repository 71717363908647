import { Injectable, Injector, OnDestroy } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { SignalType } from '../classes/enums';
import { NotificationEvent } from '../classes/notificationEvent';
import { RabbitMQConnection } from '../classes/rabbitMQConnection';
import { Message } from '@stomp/stompjs';
import { IMessage } from '../classes/iMessage';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../helpers/rxjs/take-until-destroyed';

@DecorateUntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SignallingService extends RabbitMQConnection implements OnDestroy {

  public projectsEvent$: BehaviorSubject<NotificationEvent> = new BehaviorSubject(null);
  public suggestionEvent$: BehaviorSubject<NotificationEvent> = new BehaviorSubject(null);
  public notificationChange$: BehaviorSubject<Map<string, IMessage[]>>;
  public statusChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private queueRoutes: Map<SignalType, string>;
  private subscriptionId: number;
  private notificationMessages: Map<string, IMessage[]> = new Map<string, IMessage[]>();

  constructor(injector: Injector) {
    super(injector);
    this.subscriptionId = 0;
    this.notificationChange$ = new BehaviorSubject(this.notificationMessages);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public init(): void {
    this.queueRoutes = new Map<SignalType, string>();
    const rabbitIdentity = this.profile.id + '_' + this.profile.rabbitmqHash;
    this.queueRoutes.set(SignalType.PROJECT, '/queue/projects.signaling.queue_' + rabbitIdentity);
    this.queueRoutes.set(SignalType.SUGGESTION, '/queue/suggestions.signaling.queue_' + rabbitIdentity);
    this.queueRoutes.set(SignalType.MESSAGE, '/queue/notifications.signaling.queue_' + rabbitIdentity);
    this.connect();
  }

  public onConnect(): void {
    this.queueRoutes.forEach((value: string) => {
      this.headers.id = (this.subscriptionId++).toString();
      this.rxStompService.watch(value, Object.assign({}, this.headers)).pipe(
        takeUntilDestroyed(this),
      ).subscribe((m: Message) => {
        this.processMessage(m);
      });
    });
    this.statusChange$.next(true);
  }

  public processMessage(m: IMessage): void {
    this.statusChange$.next(true);
    const notificationEvent = this.processNotificationMessage(m);
    if (notificationEvent.type !== SignalType.MESSAGE) {
      if (notificationEvent.hierarchyReference.suggestionId) {
        this.suggestionEvent$.next(notificationEvent);
      }
      if (notificationEvent.hierarchyReference.jobId) {
        this.projectsEvent$.next(notificationEvent);
      }
    }
  }

  public processNotificationMessage(m: IMessage): NotificationEvent {
    const notificationEvent = plainToClass(NotificationEvent, JSON.parse(m.body) as NotificationEvent);
    m.notification = notificationEvent;
    const targetId = notificationEvent.generateTargetId();
    let messages = this.notificationMessages.get(targetId);
    if (!messages) {
      messages = [];
      this.notificationMessages.set(targetId, messages);
    }
    messages.push(m);
    this.notificationChange$.next(this.notificationMessages);
    return notificationEvent;
  }

  public ackNotificationEvent(event: NotificationEvent): void {
    if (event) {
      const targetId = event.generateTargetId();
      this.ackNotification(targetId);
    }
  }

  public ackNotification(targetId: string): void {
    if (targetId) {
      const messages = this.notificationMessages.get(targetId);
      if (messages) {
        for (const message of messages) {
          message.ack();
        }
        this.notificationMessages.delete(targetId);
        this.notificationChange$.next(this.notificationMessages);
      }
    }
  }

  public ackAll(): void {
    this.notificationMessages.forEach((value: IMessage[]) => {
      for (const message of value) {
        message.ack();
      }
    });
    this.notificationMessages.clear();
    this.notificationChange$.next(this.notificationMessages);
  }

  public onDisconnect(): void {
    if (this.statusChange$) {
      this.statusChange$.next(false);
    }
    if (this.notificationMessages) {
      this.notificationMessages.clear();
      this.notificationChange$.next(this.notificationMessages);
    }
  }

}
