
export class Country {

  public id: number;

  public name = '';

  public code = '';

  public nameRu: string;

}
