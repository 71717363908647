import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { FutureExpenseModel } from '../../../finance/models/future-expense.model';
import { PaymentPeriodEnum } from '../../../employer/types/payment-period.enum';
import { DepositFundsDialogComponent } from '../deposit-funds-dialog/deposit-funds-dialog.component';
import { ProjectApiService } from '../../../../services/project.api.service';
import { EditSimpleProjectForm } from '../../../employer/forms/edit-simple-project.form';
import { FinanceServiceV2 } from '../../services/finance.service';
import { AddPaymentMethodDialogComponent } from '../add-payment-method-dialog/add-payment-method-dialog.component';
import { TxTypeEnum } from '../../../finance/models/transaction.model';
import { InsufficientFundsDialogComponent } from '../insufficient-funds-dialog/insufficient-funds-dialog.component';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../../../helpers/rxjs/take-until-destroyed';
import { NewWsService } from '../../../../services/new-ws.service';

enum PaymentStateEnum {
  Initial,
  PaymentRequestInProgress,
  PaymentInProgress,
  Success,
  Error,
}

@DecorateUntilDestroy()
@Component({
  selector: 'tpt-make-payment-dialog',
  templateUrl: './make-payment-dialog.component.html',
  styleUrls: ['./make-payment-dialog.component.scss']
})
export class MakePaymentDialogComponent {

  public get totalProjectCost(): string {
    if (this.simpleProjectForm.isHourlyPayment) {
        const { hourlyRate, hoursPerWeek, paymentPeriod } = this.simpleProjectForm.hourlyPayment;
        const dayCost = (hourlyRate.value * hoursPerWeek.value) / 5;
        if (paymentPeriod.value === 'P_14DAYS') {
            return Number(dayCost * 5 * 2).toFixed(2);
          } else if (paymentPeriod.value === 'P_30DAYS') {
            return Number(dayCost * 5 * 4).toFixed(2);
          } else {
            return Number(dayCost * 5).toFixed(2);
          }
      }
    return (this.simpleProjectForm.fixedPayment.budget.value || 0).toFixed(2);
  }

  get paymentPeriod() {
    return PaymentPeriodEnum;
  }

  public svgIconsEnum = SvgIconsEnum;
  public info: FutureExpenseModel;
  public canBeClosed: boolean;

  public paymentStateEnum = PaymentStateEnum;

  public paymentState = PaymentStateEnum.Initial;

  @ViewChild(DepositFundsDialogComponent)
  public depositFundsDialog: DepositFundsDialogComponent;

  @ViewChild(DialogComponent) public dialog: DialogComponent;

  @ViewChild(AddPaymentMethodDialogComponent)
  public addPaymentMethodDialog: AddPaymentMethodDialogComponent;

  @ViewChild(InsufficientFundsDialogComponent)
  public insufficientFundsDialog: InsufficientFundsDialogComponent;

  @Output() paymentSuccess: EventEmitter<void> = new EventEmitter<void>();

  @Output() closeAndUpdate: EventEmitter<void> = new EventEmitter<void>();

  public simpleProjectForm: EditSimpleProjectForm;

  public transactionId: number;

  public transactionIdFromSocket: number;

  private className = 'tpt-make-payment-dialog';

  private config: MatDialogConfig = {
    width: '520px',
    height: '480px'
  };

  constructor(public financeService: FinanceServiceV2,
              public projectApiService: ProjectApiService,
              private ws: NewWsService
  ) {
  }

  public open(data): void {
    this.info = data;
    this.paymentState = PaymentStateEnum.Initial;
    this.canBeClosed = false;
    this.dialog.config = this.config;
    this.dialog.open(this.className);

    this.ws.operationSuccess.pipe(takeUntilDestroyed(this)).subscribe((res) => {
      if (res?.length) {
        const tx = res.find(item => item.tx?.id === this.transactionId);

        if (tx?.tx?.status === 'success') {
          this.transactionIdFromSocket = tx.id;
          this.paymentState = PaymentStateEnum.Success;
        }
      }
    });
  }

  public close(): void {
    this.dialog.close();
    this.closeAndUpdate.emit();
  }

  public confirm(): void {
    if (this.info?.total > this.financeService.usdAccount?.balance) {
      const diff = this.info?.total - this.financeService.usdAccount?.balance;
      this.dialog.close();
      this.insufficientFundsDialog.open(this.info, diff);

      return;
    }

    this.paymentState = PaymentStateEnum.PaymentRequestInProgress;
    this.projectApiService.makePayment(this.info.job.id)
      .subscribe(this.handlePaymentSuccessful, this.handlePaymentFailure);
  }

  private handlePaymentSuccessful = (res): void => {
    if (res?.holdTransaction?.status === 'success') {
      this.paymentSuccess.emit();
      this.paymentState = this.paymentStateEnum.Success;
      return;
    }

    if (res?.holdTransaction?.txType === TxTypeEnum.DEPOSIT) {
      this.transactionId = res.holdTransaction?.id;
    }
    this.paymentState = PaymentStateEnum.PaymentInProgress;

    setTimeout(() => {
      if (this.paymentState === PaymentStateEnum.PaymentInProgress) {
        this.canBeClosed = true;

        this.financeService.checkTransaction(res.id).subscribe(response => {
          if (response?.length) {
            const tx = response.find(item => item.tx?.id === this.transactionId);

            if (tx?.tx?.status === 'success') {
              this.transactionIdFromSocket = tx.id;
              this.paymentState = PaymentStateEnum.Success;
            }
          }
        });
      }
    }, 10000);
  }

  private handlePaymentFailure = (): void => {
    this.paymentState = this.paymentStateEnum.Error;
  }
}
