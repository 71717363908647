import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { FinanceServiceV2 } from '../../services/finance.service';
import { FutureExpenseModel } from '../../../finance/models/future-expense.model';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { DepositFundsForm } from '../../forms/deposit-funds.form';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { TxTypeEnum } from '../../../finance/models/transaction.model';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../../../helpers/rxjs/take-until-destroyed';
import { NewWsService } from '../../../../services/new-ws.service';
import { AddPaymentMethodDialogComponent } from '../add-payment-method-dialog/add-payment-method-dialog.component';
import { timer } from 'rxjs';

enum DepositStateEnum {
  Initial,
  RequestInProgress,
  DepositInProgress,
  Success,
  Error,
}

@DecorateUntilDestroy()
@Component({
  selector: 'tpt-insufficient-funds-dialog',
  templateUrl: './insufficient-funds-dialog.component.html',
  styleUrls: ['./insufficient-funds-dialog.component.scss']
})
export class InsufficientFundsDialogComponent {

  @ViewChild(DialogComponent) public dialog: DialogComponent;

  @ViewChild(AddPaymentMethodDialogComponent)
  public addPaymentMethodDialog: AddPaymentMethodDialogComponent;

  @Output() depositIsSuccess: EventEmitter<void> = new EventEmitter();

  public info: FutureExpenseModel;

  public depositState;

  public depositStateEnum = DepositStateEnum;

  public svgIconsEnum = SvgIconsEnum;

  public depositFundsForm: DepositFundsForm;

  public transactionId: number;

  public transactionIdFromSocket: number;

  public canBeClosed = false;

  private className = 'tpt-insufficient-funds-dialog';

  private config: MatDialogConfig = {
    width: '520px',
    height: '540px'
  };

  constructor(public financeService: FinanceServiceV2,
              private ws: NewWsService) {}

  public open(data, amount): void {
    this.info = data;
    this.depositState = DepositStateEnum.Initial;
    this.depositFundsForm = DepositFundsForm.createForm(this.financeService.usdAccount.id);
    this.depositFundsForm.amount.setValue(+amount.toFixed(2));
    this.canBeClosed = false;

    this.dialog.config = this.config;
    this.dialog.open(this.className);

    this.ws.operationSuccess.subscribe((res) => {
      if (res?.length) {
        const tx = res.find(item => item.tx?.id === this.transactionId);

        if (tx?.tx?.status === 'success') {
          this.transactionIdFromSocket = tx.id;
          this.depositState = DepositStateEnum.Success;
          this.financeService.balanceUpdated$.next();
        }
      }
    });
  }

  public depositFunds = (): void => {
    if (!this.depositFundsForm.validate()) {
      return;
    }

    this.depositState = DepositStateEnum.RequestInProgress;

    this.financeService.depositFunds(this.depositFundsForm).subscribe((res) => {
        if (res.txType === TxTypeEnum.DEPOSIT) {
          this.transactionId = res.id;
        }
        this.depositState = DepositStateEnum.DepositInProgress;

        setTimeout(() => {
          if (this.depositState === DepositStateEnum.DepositInProgress) {
            this.canBeClosed = true;

            this.financeService.checkTransaction(res.id).subscribe(response => {
              if (response?.length) {
                const tx = response.find(item => item.tx?.id === this.transactionId);

                if (tx?.tx?.status === 'success') {
                  this.transactionIdFromSocket = tx.id;
                  this.depositState = DepositStateEnum.Success;
                  this.financeService.balanceUpdated$.next();
                }
              }
            });
          }
        }, 10000);
      },
      () => {
        this.depositState = DepositStateEnum.Error;
      });
  };

  public close(): void {
    this.dialog.close();
  }

  public closeAndUpdate = (): void => {
    this.close();
    this.financeService.subject.next(null);
  }

  public handleCardAdded(): void {
    // this timer was added so that the Stripe info could be updated
    timer(2000).subscribe(() => {
      this.financeService.getAccount().subscribe();
    });
  }

}
