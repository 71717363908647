
export enum PaymentPeriodEnum {

  P_7DAYS = 'P_7DAYS',

  P_14DAYS = 'P_14DAYS',

  P_30DAYS = 'P_30DAYS',

}
