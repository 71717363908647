export class Category {
  public static toView(cat: Category): Category {
    const category = new Category();
    category.id = cat.id;
    category.name = cat.name;
    category.code = cat.code;
    category.position = cat.position;
    category.parentId = cat.parentId;
    return category;
  }

  public id: number;
  public name: string;
  public code: string;
  public position: string;
  public parentId: number;

  public toString = (): string => {
    return this.name;
  };
}
