<tpt-label *ngIf="showLabel">
  <ng-content select="[label]"></ng-content>
</tpt-label>

<mat-form-field
  appearance="none"
  [class.-error-state]="errorState"
>
  <mat-select
    [formControl]="control"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [disableOptionCentering]="disableOptionCentering"
    [panelClass]="panelClass"
    (selectionChange)="selectionChange.emit($event)"
  >
    <ng-container *ngFor="let option of options">
      <mat-option
        *ngIf="shouldDisplay(control, option)"
        [disabled]="option.disabled"
        [value]="option.code || option.id || option.key || option.zoneId"
      >
        <span *ngIf="daysSelect">
          <span *ngIf="option.key < 5; else days">
            {{ option.key }} {{'CREATE_PROJECT.STEP4.DAY' | translate}}
          </span>
          <ng-template #days>{{ option.key }} {{'CREATE_PROJECT.STEP4.DAYS' | translate}}</ng-template>
        </span>
        <span *ngIf="needCustomTranslation">{{lang === 'ru' ? option.nameRu : option.name}}</span>
        <span *ngIf="!needCustomTranslation && !daysSelect">{{ (option.value || option.code || option.name || option.label) | translate }}</span>
      </mat-option>
    </ng-container>
  </mat-select>

  <mat-hint [align]="hintAlign">
    <ng-content select="[hint]"></ng-content>
  </mat-hint>

  <mat-error [class.-invisible]="!errorState">
    {{ error | translate}}
  </mat-error>
</mat-form-field>
