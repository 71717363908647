import { ChangeHistory } from './models/changeHistory';
import { IChangeable } from './models/iChangeable';
import { ChangeHistoryStatus } from './classes/enums';
import { assignExisting } from './utils';
import { FileUpload } from './models/fileUpload';
import { DiffKey } from './classes/diffKey';
import { FileUploadHistory } from './models/fileUploadHistory';

export function newValue<T extends ChangeHistory>(iChangeable: IChangeable<T>, suggestionId: number): T {
  for (let i = iChangeable.histories.length - 1; i >= 0; i--) {
    const history = iChangeable.histories[i];
    if (!history.suggestionId || history.suggestionId === suggestionId) {
      return history;
    }
  }
  return iChangeable.histories[iChangeable.histories.length - 1];
}

export function oldValue<T extends ChangeHistory>(iChangeable: IChangeable<T>,
                                                  suggestionId: number,
                                                  packageChangeVersion: number): T {
  for (let i = iChangeable.histories.length - 1; i >= 0; i--) {
    const history = iChangeable.histories[i];
    if ((!history.suggestionId || history.suggestionId === suggestionId) &&
      history.packageVersion < packageChangeVersion) {
      return history;
    }
  }
  return iChangeable.histories[0];
}

export function revertChanges<T extends ChangeHistory>(iChangeables: Array<IChangeable<T>>,
                                                       suggestionId: number,
                                                       packageVersion: number): Array<IChangeable<T>> {
  iChangeables.forEach((iChangeable) => {
    iChangeable.histories = iChangeable.histories.filter((iC) => iC.suggestionId !== suggestionId || iC.packageVersion <= packageVersion);
  });
  const result = iChangeables.filter((iChangeable) => iChangeable.histories.length > 0);
  if (suggestionId) {
    iChangeables.forEach((iChangeable) => {
      iChangeable.histories = iChangeable.histories.filter((iC) => iC.packageVersion > 0);
    });
  }
  return result;
}

export function applyLastChanges<T extends ChangeHistory>(iChangeables: Array<IChangeable<T>>,
                                                          suggestionId: number,
                                                          constructorFn: new () => IChangeable<T>): Array<IChangeable<T>> {
  return iChangeables.filter((iChangeable) => {
    const nValue = newValue(iChangeable, suggestionId);
    return nValue ? nValue.status !== ChangeHistoryStatus.DELETED : true;
  })
    .map((iChangeable) => {
      const result = assignFromHistory(new constructorFn(), iChangeable);
      result.histories = [];
      return result;
    });
}

export function cloneSuggestionChange<T extends ChangeHistory>(iChangeable: IChangeable<T>,
                                                               prevSuggId: number,
                                                               newSuggId: number,
                                                               constructorFn: new () => T) {
  const nValue = newValue(iChangeable, prevSuggId);
  if (nValue && nValue.suggestionId === prevSuggId) {
    const nHistory: T = assignExisting(new constructorFn(), nValue);
    nHistory.suggestionId = newSuggId;
    if (iChangeable.changeVersion === 0) {
      iChangeable.id = null;
      nHistory.status = ChangeHistoryStatus.ADDED;
    }

    // можно использовать 2, если нужно показать предыдущие изменения
    // nHistory.packageVersion = 2;
    nHistory.packageVersion = 2;
    nHistory.id = null;
    iChangeable.histories.push(nHistory);
  }
}

export function assignFromHistory<T extends ChangeHistory>(to: IChangeable<T>, source: IChangeable<T>): IChangeable<T> {
  Object.assign(to, source);
  Object.assign(to, source.histories[source.histories.length - 1]);
  to.id = source.id;
  return to;
}

export function shouldHide(file: FileUpload, diffKey: DiffKey): boolean {
  if (diffKey) {
    const lastHistory: FileUploadHistory = file.histories.length > 0 ?
      newValue(file, diffKey.suggestionId) : null;
    if (lastHistory && lastHistory.status === ChangeHistoryStatus.DELETED
      && lastHistory.packageVersion < diffKey.packageVersion) {
      return true;
    }
  }
  return false;
}

export function generateHistoryItem<T extends ChangeHistory>(constructorFn: new () => T,
                                                             source: IChangeable<T> | T,
                                                             diffKey: DiffKey): T {
  const result = assignExisting(new constructorFn(), source);
  if ('histories' in source) {
    result.parentId = source.id;
  }
  result.id = null;
  result.suggestionId = diffKey.suggestionId;
  result.packageVersion = diffKey.packageVersion;
  if (source instanceof FileUpload && !result.owner) {
    result.owner = (source as FileUpload).uploader;
  }
  return result;
}

export function createDefaultHistory<T extends ChangeHistory>(constructorFn: new () => T,
                                                              source: IChangeable<T>,
                                                              diffKey: DiffKey,
                                                              historySource?: IChangeable<T>) {
  const nValue = newValue(source, diffKey.suggestionId);
  if (nValue.packageVersion > 0) {
    if (nValue.packageVersion === diffKey.packageVersion) {
      source.histories.splice(source.histories.length - 1, 1);
    }
    source.histories.push(generateHistoryItem(constructorFn, historySource ? historySource : source, diffKey));
  }
}
