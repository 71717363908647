export enum PlatformsEnum {
  UPWORK = 'Upwork',

  FIVVER = 'Fivver',

  FREELANCER_RU = 'Freelancer_Ru',

  SIMPLY_HIRED = 'SimplyHired',

  DESIGN99 = 'Design99',

  YA_TOLOKA = 'YaToloka',

  TOPTAL = 'Toptal',

  KWORK = 'Kwork',

  AMAZON_MECHANICAL_TURK = 'AmazonMT',

  PEOPLE_PER_HOUR = 'PeoplePH'
}

export enum PlatformsEnumFrontend {
  UPWORK = 'Upwork',

  FIVVER = 'Fivver',

  FREELANCER_RU = 'Freelancer.ru',

  SIMPLY_HIRED = 'SimplyHired',

  DESIGN99 = '99Design',

  YA_TOLOKA = 'Я.Толока',

  TOPTAL = 'Toptal',

  KWORK = 'Kwork',

  AMAZON_MECHANICAL_TURK = 'Amazon Mechanical Turk',

  PEOPLE_PER_HOUR = 'PeoplePerHour'
}
