import {LanguageLevel} from '../languageLevel';
import {Country} from '../country';
import {Type} from 'class-transformer';
import {Language} from '../language';

export class EditProfileLanguage {
  public id: number;
  public interfaceLanguage: string;
  @Type(() => Country)
  public country: Country;
  public timeZone: string;
  public languages: LanguageLevel[];

  // constructor(
  //   id: number,
  //   interfaceLanguage: string,
  //   country: number | Country,
  //   timeZone: string,
  //   languages: LanguageLevel[]
  // ) {
  //   this.id = id;
  //   this.interfaceLanguage = interfaceLanguage;
  //   this.country = country;
  //   this.timeZone = timeZone;
  //   this.languages = languages;
  // }

}
