<div class="footer">
  <div class="footer-container">
    <div class="top-block">
      <div class="first-column">
        <div>
          <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo"></mat-icon>
          <div class="logo-text">{{'MAIN_PAGE.FOOTER.LOGO_TEXT' | translate}}</div>
        </div>

        <div class="subtitle">
          <div class="follow-us">{{'MAIN_PAGE.FOOTER.FOLLOW_US' | translate}}</div>
          <div class="social-nets">
<!--            <a href="https://www.instagram.com/talentpowered.team/" target="_blank">-->
<!--              <mat-icon [svgIcon]="svgIconsEnum.Instagram" class="social-net"></mat-icon>-->
<!--            </a>-->
<!--            <a href="https://www.facebook.com/Talent-Powered-113816080389192/" target="_blank">-->
<!--              <mat-icon [svgIcon]="svgIconsEnum.Facebook" class="social-net"></mat-icon>-->
<!--            </a>-->
            <a href="https://www.linkedin.com/company/talentpowered/" target="_blank">
              <mat-icon [svgIcon]="svgIconsEnum.Linkedin" class="social-net"></mat-icon>
            </a>
            <a href="https://tpt-blog.medium.com/" target="_blank">
              <mat-icon [svgIcon]="svgIconsEnum.Medium" class="social-net"></mat-icon>
            </a>
            <a href="https://t.me/talentpowered" target="_blank">
              <mat-icon [svgIcon]="svgIconsEnum.Telegram" class="social-net"></mat-icon>
            </a>
          </div>

          <div class="support">
            <mat-icon [svgIcon]="svgIconsEnum.Mail" class="mail-icon"></mat-icon>
            <div><a href="mailto:support@talentpowered.team">support@talentpowered.team</a></div>
          </div>
        </div>
      </div>

      <div class="footer-grid">
        <div class="help">
          <div class="subtitle">{{'MAIN_PAGE.FOOTER.HELP' | translate}}</div>
          <div class="point"><a href="https://talentpowered.team/support">{{'MAIN_PAGE.FOOTER.HELP' | translate}}</a></div>
          <div class="point"><a [routerLink]="['/book-demo']">{{'MAIN_PAGE.FOOTER.BOOK_DEMO_FOOTER' | translate}}</a></div>
          <div class="point"><a href="https://talentpowered.team/blogs">{{'MAIN_PAGE.FOOTER.BLOG' | translate}}</a></div>
        </div>

        <div class="legal">
          <div class="subtitle">{{'MAIN_PAGE.FOOTER.LEGAL' | translate}}</div>
          <div class="point"><a href="https://talentpowered.team/legal/terms">{{'MAIN_PAGE.FOOTER.TERMS_CONDITIONS' | translate}}</a></div>
          <div class="point"><a href="https://talentpowered.team/legal/privacy">{{'MAIN_PAGE.FOOTER.PRIVACY_POLICY' | translate}}</a></div>
          <div class="point"><a href="https://talentpowered.team/legal/cookies">{{'MAIN_PAGE.FOOTER.COOKIE_POLICY' | translate}}</a></div>
        </div>
      </div>
    </div>

    <mat-divider class="divider"></mat-divider>

    <div class="bottom-info">
      <div class="text">© 2023 Talent Powered Team Scandinavia AB</div>

<!--      <button mat-button class="footer-lang" (click)="setLang()">-->
<!--        {{langs[switchTo]}}-->
<!--        <mat-icon [svgIcon]="svgIconsEnum['Flag'+switchTo.toUpperCase()]"></mat-icon>-->
<!--      </button>-->
    </div>
  </div>
</div>
