<tpt-dialog>
  <div class="headline mb-24">{{'ACCESS.USER' | translate}}</div>

  <div class="avatar-wrapper mb-24" *ngIf="user">
    <tpt-avatar [textSizeRatio]="2.5"
                size="28" [user]="user?.profile"></tpt-avatar>

    <div class="profile-name">{{user.profile?.firstName}} {{user.profile?.lastName}}</div>
  </div>

  <div class="roles-title subhead mb-12">
    {{'ACCESS.ROLES' | translate}}
  </div>

  <div class="roles-list mb-20">
    <ng-container *ngIf="userRoles.length; else emptyRoles">
      <tpt-chip class="chip" *ngFor="let role of userRoles">{{role.name}}</tpt-chip>
    </ng-container>
    <ng-template #emptyRoles>
      <span *ngIf="rolesAssigning">{{'ACCESS.CHOOSE_ROLES' | translate}}</span>
    </ng-template>
  </div>

  <mat-divider class="divider"></mat-divider>

  <div *ngIf="rolesAssigning && user?.profile?.id !== currentProfileId">
    <mat-selection-list (selectionChange)="changeSelection($event)">
      <mat-list-option *ngFor="let role of projectRoles" [value]="role" [selected]="isChecked(role.roleId)">
        {{role.name}}
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div class="actions-row mt-36" *ngIf="rolesAssigning && user?.profile?.id !== currentProfileId">
    <tpt-button type="secondary" class="cancel" (click)="close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
    <tpt-button type="accent" (click)="saveRoles()">{{'BUTTON.SAVE' | translate}}</tpt-button>
  </div>

  <ng-container *ngIf="user?.profile?.id === currentProfileId">
    <div class="info">
      <mat-icon class="info-icon" [svgIcon]="svgIconsEnum.InfoIcon"></mat-icon>
      {{'ACCESS.ASSIGN_INFO' | translate}}
    </div>

    <div class="actions-row mt-36">
      <tpt-button type="secondary" class="cancel" (click)="close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
    </div>
  </ng-container>

</tpt-dialog>
