<div class="register-root">
  <div>
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full"></mat-icon>

    <div class="register">

      <div class="register-column" *ngIf="registerForm && !googleSignUpStepTwo">
        <h2 class="register-title">{{ 'REGISTER_COMPONENT.ACCOUNT_CREATION' | translate }}</h2>

        <ng-container *ngIf="!basicSignUpStepTwo">
          <div class="regular-16-24">
            {{'REGISTER_COMPONENT.QUICK_JOIN' | translate}}
          </div>

          <tpt-button type="secondary" size="large" class="mt-24 mb-4 sign-up-google" (onClick)="registerWithGoogle()">
            <mat-icon [svgIcon]="svgIconsEnum.Google" class="mr-4"></mat-icon>
            {{'REGISTER_COMPONENT.SIGN_UP_GOOGlE' | translate}}
          </tpt-button>

          <div class="divider-wrapper my-24">
            <mat-divider></mat-divider>
            <div class="mx-16">{{'REGISTER_COMPONENT.OR' | translate}}</div>
            <mat-divider></mat-divider>
          </div>
        </ng-container>

        <form *ngIf="!registerSuccess">
          <ng-container *ngIf="!basicSignUpStepTwo">
            <div class="columns-row">
              <div class="-left">
                <tpt-input
                  [control]="registerForm.email"
                  placeholder="{{ 'REGISTER_COMPONENT.EMAIL_ADDRESS' | translate }}"
                >
                  <ng-container label>{{ 'REGISTER_COMPONENT.EMAIL' | translate }}</ng-container>
                </tpt-input>
              </div>
            </div>

            <div class="register-actions-row">
              <button
                mat-flat-button
                class="register-btn"
                (click)="basicSignUp()"
              >
                {{'REGISTER_COMPONENT.EMAIL_SIGNUP' | translate}}
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="basicSignUpStepTwo">
            <div class="mb-24 info-text"
                 [innerHTML]="'REGISTER_COMPONENT.EMAIL_SIGNUP_TEXT' | translate: {email: registerForm.email.value}">
            </div>

            <div class="choose-role mb-8">
              {{ 'REGISTER_COMPONENT.CHOOSE_THE_ROLE' | translate }}
            </div>

            <mat-radio-group [formControl]="registerForm.role" class="mb-24">
              <mat-radio-button value="EMPLOYER"
                                labelPosition="before">{{'REGISTER_COMPONENT.I_AM_CLIENT' | translate}}</mat-radio-button>
              <mat-radio-button value="FREELANCER"
                                labelPosition="before">{{'REGISTER_COMPONENT.I_AM_TALENT' | translate}}</mat-radio-button>
            </mat-radio-group>

            <tpt-input
              [control]="registerForm.password"
              [type]="showPass ? 'text' : 'password'"
              placeholder="{{ 'REGISTER_COMPONENT.MINIMUM_8' | translate }}"
              class="password-input"
            >
              <ng-container label>{{ 'REGISTER_COMPONENT.PASSWORD' | translate }}</ng-container>
              <ng-container icon>
                <mat-icon class="input-icon"
                          (click)="showPass = !showPass"
                          [svgIcon]="showPass ? svgIconsEnum.EyeOpen : svgIconsEnum.EyeClose"></mat-icon>
              </ng-container>
            </tpt-input>

            <div class="checkboxes-wrapper">
              <mat-checkbox [formControl]="registerForm.userAgreement" class="purple-checkbox">
                {{ 'REGISTER_COMPONENT.I_ACCEPT' | translate }}
                <a (click)="$event.stopPropagation()" [routerLink]="'/legal/terms'"
                   target="_blank">{{ 'REGISTER_COMPONENT.RULES_OF_USE' | translate }}</a>
              </mat-checkbox>

              <mat-checkbox [formControl]="registerForm.privacyPolicy" class="purple-checkbox">
                {{ 'REGISTER_COMPONENT.I_GIVE_CONSENT' | translate }}
                <a (click)="$event.stopPropagation()" [routerLink]="'/legal/privacy'"
                   target="_blank">{{ 'REGISTER_COMPONENT.PRIVACY_POLICY' | translate }}</a>
              </mat-checkbox>
            </div>

            <tpt-button type="primary" class="mt-32"
                        [disabled]="!registerForm.userAgreement.value || !registerForm.privacyPolicy.value"
                        (onClick)="register()"
            >
              {{'REGISTER_COMPONENT.CREATE_ACCOUNT' | translate}}
            </tpt-button>
          </ng-container>
        </form>

        <div *ngIf="registerSuccess" class="success-wrapper">
          <div class="message-title">{{'REGISTER_COMPONENT.SUCCESS_TITLE' | translate}}</div>
          <div class="message-text">{{'REGISTER_COMPONENT.SUCCESS_TEXT' | translate}}
            <span class="email">{{registerForm.email.value}}</span>
          </div>
        </div>
      </div>

      <div *ngIf="googleSignUpStepTwo" class="register-column">
        <h2 class="register-title">{{ 'REGISTER_COMPONENT.GOOGLE_SIGNUP' | translate}}</h2>

        <div class="mb-24 info-text" [innerHTML]="'REGISTER_COMPONENT.GOOGLE_SIGNUP_TEXT' | translate: {email: user?.email}">
        </div>

        <div class="choose-role mb-8">
          {{ 'REGISTER_COMPONENT.CHOOSE_THE_ROLE' | translate }}
        </div>

        <mat-radio-group [formControl]="roleControl">
          <mat-radio-button value="EMPLOYER"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_CLIENT' | translate}}</mat-radio-button>
          <mat-radio-button value="FREELANCER"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_TALENT' | translate}}</mat-radio-button>
        </mat-radio-group>

        <tpt-button type="primary" class="mt-32" (onClick)="createAccountGoogle()">
          {{'REGISTER_COMPONENT.CREATE_ACCOUNT' | translate}}
        </tpt-button>
      </div>
    </div>

    <div class="have-acc" *ngIf="!isMobile && !googleSignUpStepTwo">{{'REGISTER_COMPONENT.HAVE_AN_ACCOUNT' | translate}}
      <span [routerLink]="['/login']" class="link">{{'REGISTER_COMPONENT.SIGN_IN' | translate}}</span>
    </div>

  </div>

</div>
