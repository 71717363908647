import { ComponentType } from '@angular/cdk/portal';
import { Component, ComponentRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { SignallingService } from '../../services/signalling.service';
import { PopupComponent } from '../common-dialogs/popup/popup.component';
import { NotificationContentComponent } from './notification-content.component';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { NotificationService } from '../../services/notification.service';
import { NewWsService } from '../../services/new-ws.service';
import { NotificationV2 } from '../../models/notification';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tpt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends PopupComponent<NotificationContentComponent> implements OnInit, OnDestroy {

  public oldMessages = [];
  svgIconsEnum = SvgIconsEnum;

  public count = 0;
  public signallingNotifications: NotificationV2[] = [];
  public connected = false;
  private destroy$ = new Subject();

  constructor(
    private signallingService: SignallingService,
    public profileService: ProfileService,
    private notificationService: NotificationService,
    private ws: NewWsService,
    injector: Injector,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.notificationService.getNotifications().subscribe(res => {
      this.oldMessages = res;

      this.fillComponent(this._component);
    });

    this.notificationService.getCountNotifications().subscribe(res => {
      this.count = res;
    });

    this.subscribeToNewMessages();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getComponent(): ComponentType<NotificationContentComponent> {
    return NotificationContentComponent;
  }

  public fillComponent(component: ComponentRef<NotificationContentComponent>): void {
    if (component) {
      component.instance.notifications = this.mapNotifications(this.oldMessages);
      component.instance.clear.pipe(
        takeUntil(this.destroy$),
      ).subscribe(() => {});

      component.instance.ack.pipe(
        takeUntil(this.destroy$),
      ).subscribe((notification) => {
        this.count -= 1;
      });

      component.instance.delete.pipe(
        takeUntil(this.destroy$),
      ).subscribe((notification) => {
        this.oldMessages = this.oldMessages.filter(item => item.id !== notification.id);
        component.instance.notifications = this.mapNotifications(this.oldMessages);
      });

      component.instance.close.pipe(
        takeUntil(this.destroy$),
      ).subscribe((notification) => {
        this.close();
      });

      component.instance.markAllAsReadEvent.pipe(
        takeUntil(this.destroy$),
      ).subscribe(() => this.markAllAsRead());
    }
  }

  public show(ev): void {
    this.open(ev);
  }

  public mapNotifications(notifications) {
    const today = moment().endOf('day');
    const endYesterday = moment().subtract(1, 'day').endOf('day');
    const startYesterday = moment().subtract(1, 'day').startOf('day');

    const mapped = {today: [], yesterday: [], older: []};
    mapped.today = notifications.filter(item => {
      if (moment(item.date) > endYesterday && moment(item.date) < today) {
        return item;
      }
    });

    mapped.yesterday = notifications.filter(item => {
      if (moment(item.date) > startYesterday && moment(item.date) < endYesterday) {
        return item;
      }
    });

    mapped.older = notifications.filter(item => {
      if (moment(item.date) < startYesterday) {
        return item;
      }
    });

    return mapped;
  }

  private markAllAsRead() {
    this.notificationService.markAllAsRead().subscribe(() => {
      this.count = 0;
    }, () => {});
  }

  private subscribeToNewMessages(): void {
    this.ws.newNotification.pipe(
      takeUntil(this.destroy$),
    ).subscribe(msg => {
      if (!msg) { return; }
      if (this.oldMessages.some(item => item.id === msg.id)) {
        return;
      }

      this.oldMessages.unshift(msg);
      this.count += 1;
      this.fillComponent(this._component);
    });
  }
}
