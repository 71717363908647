import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { PaymentDocs } from '../../../contracts/components/parts/project-card-actions/project-card-actions.component';
import { ResourcesService } from '../../../../services/resources.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'tpt-payment-docs-dialog',
  templateUrl: './payment-docs-dialog.component.html',
  styleUrls: ['./payment-docs-dialog.component.scss']
})
export class PaymentDocsDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public docs: PaymentDocs;

  public svgIconsEnum = SvgIconsEnum;

  private config: MatDialogConfig = {
    width: '520px',
  };

  private className = 'tpt-legal-status-dialog';

  constructor(
    public resourcesService: ResourcesService,
  ) { }

  public open(docs: PaymentDocs): void {
    this.docs = docs;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public downloadAll(): void {
    if (!this.docs) {
      return;
    }

    forkJoin([this.resourcesService.getFileByUUID(this.docs.projectAgreement.uuid),
      this.resourcesService.getFileByUUID(this.docs.projectAgreement.uuid)]).subscribe((results: File[]) => {
      const temporaryDownloadLink = document.createElement('a');
      temporaryDownloadLink.style.display = 'none';
      document.body.appendChild( temporaryDownloadLink );

      results.forEach(file => {
        const objectURL = this.resourcesService.createObjectURL(file);
        temporaryDownloadLink.setAttribute( 'href', objectURL );
        temporaryDownloadLink.setAttribute( 'download', file.name );
        temporaryDownloadLink.click();
      });

      document.body.removeChild( temporaryDownloadLink );
    });
  }

}
