<tpt-dialog (dialogClose)="step = 1">
  <div class="dialog-root">
    <div class="step" *ngIf="step === 1">
      <div class="title">{{'SIDEBAR.HELP' | translate}}</div>

      <div>
        <div class="section" (click)="openOnBoardingsStep()">
          <mat-icon class="section-icon" [svgIcon]="svgIconsEnum.OnBoarding"></mat-icon>
          <div class="section-info">
            <div class="subhead-1">
              {{'HELP.ONBOARDING' | translate}}
            </div>
            <div class="caption">
              {{'HELP.ONBOARDING_TEXT' | translate}}
            </div>
          </div>
          <mat-icon class="navigate-icon" [svgIcon]="svgIconsEnum.ArrowRight"></mat-icon>
        </div>

        <a [href]="'HELP.LINK_SERVICES' | translate"
           target="_blank"
           class="link">
          <div class="section">
            <mat-icon class="section-icon" [svgIcon]="svgIconsEnum.LightBulb"></mat-icon>
            <div class="section-info">
              <div class="subhead-1">
                {{'HELP.ADDITIONAL_SERVICES' | translate}}
              </div>
              <div class="caption">
                {{'HELP.ADDITIONAL_SERVICES_TEXT' | translate}}
              </div>
            </div>
            <mat-icon class="navigate-icon" [svgIcon]="svgIconsEnum.Link"></mat-icon>
          </div>
        </a>

        <a href="https://talentpowered.team/legal"
           target="_blank"
           class="link">
          <div class="section">
            <mat-icon class="section-icon" [svgIcon]="svgIconsEnum.LegalInfo"></mat-icon>
            <div class="section-info">
              <div class="subhead-1">
                {{'HELP.LEGAL_INFO' | translate}}
              </div>
              <div class="caption">
                {{'HELP.LEGAL_INFO_TEXT' | translate}}
              </div>
            </div>
            <mat-icon class="navigate-icon" [svgIcon]="svgIconsEnum.Link"></mat-icon>
          </div>
        </a>

        <a href="https://talentpowered.team/support"
           class="link"
           target="_blank">
          <div class="section">
            <mat-icon class="section-icon" [svgIcon]="svgIconsEnum.CaptionsBubble"></mat-icon>
            <div class="section-info">
              <div class="subhead-1">
                {{'HELP.SUPPORT' | translate}}
              </div>
              <div class="caption">
                {{'HELP.SUPPORT_TEXT' | translate}}
              </div>
            </div>
            <mat-icon class="navigate-icon" [svgIcon]="svgIconsEnum.Link"></mat-icon>
          </div>
        </a>
      </div>

      <div class="bottom-section">
        <mat-icon class="section-icon" [svgIcon]="svgIconsEnum.Contact"></mat-icon>
        <div class="section-info">
          <div class="subhead-1">
            {{'HELP.BOTTOM_TITLE' | translate}}
          </div>
          <div class="caption">
            {{'HELP.BOTTOM_TITLE_TEXT' | translate}}
          </div>
        </div>
        <a href="mailto:support@talentpowered.team" class="link contact-link">
          <tpt-button type="accent" size="small" class="contact-btn">{{'HELP.CONTACT' | translate}}</tpt-button>
        </a>
      </div>
    </div>

    <div class="step" *ngIf="step === 2">
      <div class="title">
        <mat-icon [svgIcon]="svgIconsEnum.ArrowBack" (click)="step = 1"></mat-icon>
        {{'HELP.ONBOARDING' | translate}}
      </div>

      <mat-card class="tour-card" (click)="startMainOnBoarding()">
        <img mat-card-image src="assets/images/intro-thumb.png" alt="intro">
        <mat-card-title>{{'HELP.INTRO_TITLE' | translate}}</mat-card-title>
        <mat-card-subtitle class="caption">{{'HELP.INTRO_TEXT' | translate}}</mat-card-subtitle>
      </mat-card>

      <mat-card class="tour-card" (click)="startFinanceTour()">
        <img mat-card-image src="assets/images/finance-thumb.png" alt="finance">
        <mat-card-title>{{'HELP.FINANCE_TITLE' | translate}}</mat-card-title>
        <mat-card-subtitle class="caption">{{'HELP.FINANCE_TEXT' | translate}}</mat-card-subtitle>
      </mat-card>

      <mat-card class="tour-card" (click)="startProfileTour()">
        <img mat-card-image src="assets/images/profile-tour.png" alt="finance">
        <mat-card-title>{{'PROFILE_TOUR.TITLE' | translate}}</mat-card-title>
        <mat-card-subtitle class="caption">{{'PROFILE_TOUR.INFO' | translate}}</mat-card-subtitle>
      </mat-card>
    </div>
  </div>
</tpt-dialog>
