<div class="wrapper">
  <div class="sort">
    <h2 class="sort-title">{{'SEARCH.SORT.SORT_BY' | translate}}</h2>
    <mat-form-field class="filtered-page__filters-field" floatLabel="never" appearance="none">
      <mat-select placeholder="{{'SEARCH.SORT.SORT_BY' | translate }}"
                  (valueChange)="emitSortChange.emit($event)">
        <mat-option *ngFor='let sort of sortList' [value]="sort">
          {{'SEARCH.SORT.' + sort | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="search-row">
    <mat-icon class="search-icon" [svgIcon]="svgIconsEnum.Search"></mat-icon>
    <input [formControl]="searchControl"
           matInput
           class="search-input"
           placeholder="{{'SEARCH.USER_SEARCH_PLACEHOLDER' | translate}}"
           (keyup.enter)="searchUsers()"
    >
    <tpt-button type="primary" class="search-button" (onClick)="searchUsers()">
      {{'BUTTON.SEARCH' | translate}}
    </tpt-button>
  </div>

  <ng-container *ngIf="!showSkeleton; else freelancersLoader">
    <ng-container *ngIf="freelancers.length; else notFound">
      <div *ngFor="let freelancer of freelancers" class="mb-16">
        <tpt-freelancer-search-card [freelancer]="freelancer" [empProjects]="empProjects" [skillsFilter]="skillsFilter">
        </tpt-freelancer-search-card>
      </div>
    </ng-container>

    <ng-template #notFound>
      <div class="not-found">
        <div class="not-found-title">{{'SEARCH.NO_RESULTS_TITLE' | translate}}</div>
        <div class="not-found-subtitle">{{'SEARCH.NO_RESULTS_SUBTITLE' | translate}}</div>
      </div>
    </ng-template>
  </ng-container>


  <ng-template #freelancersLoader>
    <ngx-skeleton-loader
      count="5"
      animation="progress"
      [theme]="{
          'border-radius': '4px',
          height: '264px',
          background: '#eff1f6',
          'box-shadow': '0 4px 4px rgba(0, 0, 0, 0.1)'
        }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>

