import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '../../classes/enums';
import { Category } from '../../models/category';
import { Profile } from '../../models/profile/profile';
import { AlertService } from '../../services/alert.service';
import { ProfileManagementService } from '../../services/profile-management.service';
import { TimezoneService } from '../../services/timezone.service';
import { DataLayerService } from '../../services/data-layer.service';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { RegistrationForm } from '../../forms/registration.form';
import { SpinnerService } from '../../modules/tpt-ui/services/spinner.service';
import { isMobileDevice } from '../../helpers/get-mobile.helper';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarNotificationsService } from '../../services/snackbar-notifications.service';
import { ProfilesApiService } from '../../services/profiles.api.service';
import { ProfileService } from '../../services/profile.service';
import { RouteHelper } from '../../helpers/route.helper';

const TOKEN_KEY = 'auth-token';

@Component({
  selector: 'tpt-register',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {

  public registerForm: RegistrationForm;
  public categories: Category[] = [];
  public Role = Role;

  public registerSuccess = false;
  public svgIconsEnum = SvgIconsEnum;
  public isMobile = isMobileDevice();
  public user: SocialUser;
  public roleOptions = [
    {
      id: Role.EMPLOYER,
      name: 'REGISTER_COMPONENT.EMPLOYER'
    },
    {
      id: Role.FREELANCER,
      name: 'REGISTER_COMPONENT.FREELANCER'
    },
  ];

  public roleControl = new FormControl('', Validators.required);
  public googleSignUpStepTwo = false;
  public basicSignUpStepTwo = false;

  public get registerCardIcon(): string {
    return `/assets/icons/${SvgIconsEnum.RegisterCardIcon}.svg`;
  }

  public showPass = false;

  constructor(
    private router: Router,
    private profileManagementService: ProfileManagementService,
    private profileService: ProfileService,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private timezoneService: TimezoneService,
    public translateService: TranslateService,
    private snack: SnackbarNotificationsService,
    private dataLayerService: DataLayerService,
    private authService: SocialAuthService,
    private spinner: SpinnerService,
    private profileApiService: ProfilesApiService,
    private routeHelper: RouteHelper,
    private readonly meta: Meta
  ) { }

  @HostListener('window:resize') onResize() {
    this.isMobile = isMobileDevice();
  }

  public ngOnInit(): void {
    this.registerForm = RegistrationForm.createForm();

    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=device-width, initial-scale=1') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    }
  }

  public async registerWithGoogle(): Promise<void> {
    const socialUser: SocialUser = await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    const token = socialUser.idToken;

    const tptUser = await this.profileApiService.getUserAuthProfile(token).toPromise();
    console.log(tptUser);

    if (tptUser.role === 'NOT_SELECTED') {
      this.user = socialUser;
      this.googleSignUpStepTwo = true;
      this.cd.detectChanges();
    } else {
      localStorage.setItem(TOKEN_KEY, token);
      await this.profileService.getUserInfo();

      const url = tptUser.role === 'EMPLOYER' ? this.routeHelper.employeerProjectsDashboard :
        this.routeHelper.projectSearch;
      await this.router.navigate([url]);
    }
  }

  ngOnDestroy() {
    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=1200px') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({name: 'viewport', content: 'width=1200px'});
    }
  }

  public createAccountGoogle(): void {
    if (this.roleControl.invalid) {
      this.snack.showNotification('REGISTER_COMPONENT.PLEASE_CHOOSE_THE_ROLE', 'fail');
      return;
    }

    this.spinner.startSpinner();
    this.profileApiService.selectRole(this.roleControl.value, this.user.idToken).subscribe(async () => {
      localStorage.setItem(TOKEN_KEY, this.user.idToken);

      await this.profileService.getUserInfo();
      this.spinner.stopSpinner();

      const url = this.roleControl.value === 'EMPLOYER' ? this.routeHelper.employeerProjectsDashboard :
        this.routeHelper.projectSearch;
      await this.router.navigate([url]);
    }, () => {
      this.spinner.stopSpinner();
    });
  }

  public basicSignUp(): void {
    if (this.registerForm.email.invalid) {
      return;
    }

    // this.pushFirstStepToDataLayer(this.registerForm.email.value);

    this.basicSignUpStepTwo = true;
  }

  public register(): void {
    this.registerForm.password.markAsTouched();
    if (this.registerForm.invalid) {
      return;
    }

    const formData = this.registerForm.getFormData();
    formData.email = formData.email.toLowerCase();

    const profile = {
      ...formData,
      timeZone: this.timezoneService.currentZone,
      interfaceLanguage: this.translateService.currentLang
    };

    this.spinner.startSpinner();

    this.profileManagementService.createProfile(profile).then((data) => {
      this.pushToDataLayer(data);
      this.spinner.stopSpinner();
      this.registerSuccess = true;
      this.cd.markForCheck();
    }).catch((error) => {
      const signUpErrors = [
        'SERVER_ERRORS.PROFILE_WITH_THE_EMAIL_ALREADY_EXISTS', 'SERVER_ERRORS.INVALID_EMAIL'
      ];
      if (signUpErrors.includes(error.errorCode?.message)) {
        this?.alertService.error(error.errorCode.message);
      } else {
        this.alertService.error('SERVER_ERRORS.SIGNUP_ERROR');
      }
      this.spinner.stopSpinner();
    });
  }

  private pushToDataLayer = (data: { id: number, role: Role }): void => {
    this.dataLayerService.pushToDataLayer({
      event: 'registration',
      lang: `${this.translateService.currentLang}`,
      userId: `${data.id}`,
      userType: new Profile().analyticsCustomerTypeByRole(data.role),
    });
  }

  private pushFirstStepToDataLayer = (email: string): void => {
    this.dataLayerService.pushToDataLayer({
      event: 'firstStepEmail',
      email,
    });
  }

}
