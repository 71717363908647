import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LabelComponent } from './components/label/label.component';
import { InputComponent } from './components/input/input.component';
import { TptControlDirective } from './directives/tpt-control.directive';
import { SelectComponent } from './components/select/select.component';
import { MaterialModule } from '../material/material.module';
import { TextareaComponent } from './components/textarea/textarea.component';
import { DatepickerHeaderComponent } from './components/datepicker-header/datepicker-header.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { CardsSelectControlComponent } from './components/cards-select-control/cards-select-control.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectSearchComponent } from './components/select-search/select-search.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
  ],
  declarations: [
    TptControlDirective,
    LabelComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DatepickerHeaderComponent,
    DatepickerComponent,
    CardsSelectControlComponent,
    SelectSearchComponent,
  ],
  exports: [
    LabelComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DatepickerHeaderComponent,
    DatepickerComponent,
    CardsSelectControlComponent,
    SelectSearchComponent,
  ],
})
export class TptFormsModule { }
