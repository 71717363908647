<tpt-dialog>
  <div class="tpt-withdraw-funds-dialog-root">
    <ng-container *ngIf="withdrawState === withdrawStateEnum.Initial">
      <div class="withdraw-funds-title">{{'FINANCE.WITHDRAW' | translate}}</div>

      <ng-container *ngIf="accounts">
        <tpt-input
          [control]="withdrawFundsForm.amount"
          type="number"
          placeholder="$ 00.00"
        >
          <ng-container label>{{'FINANCE.ENTER_AMOUNT' | translate}}</ng-container>
        </tpt-input>
      </ng-container>

      <div class="actions">
        <tpt-button type="secondary" (onClick)="dialog.close()">
          {{'BUTTON.CANCEL' | translate}}
        </tpt-button>

        <tpt-button type="accent" class="ml-12" (onClick)="withdrawFunds()">
          {{'BUTTON.OK' | translate}}
        </tpt-button>
      </div>
    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Success">
      <div class="top-up-completed">
        <div class="amount success">
          {{withdrawFundsForm.amount.value | number:'.2'}}$
        </div>

        <div class="headline">
          {{'FINANCE.FUNDS_TRANSFERRED' | translate}}
        </div>
        <div class="regular">
          {{'FINANCE.TRANSFERRED_TO_YOUR_ACCOUNT' | translate}}
          <span class="bold">{{ accounts ? usdAccount.payoneer.payee_id : '' }}</span>
        </div>
        <tpt-button type="secondary" class="full-width" (onClick)="close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
      </div>

    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Error">
      <div class="top-up-completed">
        <div class="amount error">
          <mat-icon [svgIcon]="svgIconsEnum.Cancel" class="mr-4"></mat-icon>
          {{withdrawFundsForm.amount.value | number:'.2'}}$
        </div>

        <div class="headline">
          {{'FINANCE.WITHDRAW_FAILED'| translate}}
        </div>

        <tpt-button type="secondary" class="full-width" (onClick)="dialog.close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
      </div>
    </ng-container>

  </div>
</tpt-dialog>

<tpt-success-dialog>
  <ng-container mainText>
    {{'FINANCE.FUNDS_TRANSFERRED' | translate}}
  </ng-container>
  <ng-container secondaryText>
    <span class="bold">${{ withdrawFundsForm?.amount?.value }}</span>
    {{'FINANCE.TRANSFERRED_TO_YOUR_ACCOUNT' | translate}}
    <span class="bold">{{ accounts ? usdAccount.payoneer.payee_id : '' }}</span>
  </ng-container>
</tpt-success-dialog>

<tpt-failure-dialog>
  <ng-container mainText>
    {{'FINANCE.PAYMENT_NOT_CONNECTED' | translate}}
  </ng-container>
  <ng-container secondaryText>
    {{'FINANCE.PLEASE_CONNECT_METHOD' | translate}}
    <span class="link" [routerLink]="routeHelper.financesPaymentDetails">{{'FINANCE.PAYMENT_DETAILS' | translate}}</span>
  </ng-container>
</tpt-failure-dialog>
