import {Skill} from '../skill';
import { ExpertLevel } from '../../classes/enums';

export class EditProfileBasic {
  public id: number;
  public nickName: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public birthDate: Date;
  public male: boolean;
  public description: string;
  public skills: Skill[];
  public expertLevel: ExpertLevel;
}
