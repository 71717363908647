import {Type} from 'class-transformer';
import {ChangeHistoryStatus} from '../classes/enums';
import {SimpleProfile} from './profile/simpleProfile';

export class ChangeHistory {
  public id: number = null;

  @Type(() => SimpleProfile)
  public owner: SimpleProfile = null;

  public changeVersion = 0;
  public comment = '';

  public status: ChangeHistoryStatus = ChangeHistoryStatus.CHANGED;

  @Type(() => Date)
  public modifiedAt: Date = null;

  public parentId = 0;
  public selfDeclined = false;

  public modify = false;

  public packageVersion = 0;

  public suggestionId = 0;

  public isModify(): boolean {
    return this.status === ChangeHistoryStatus.DELETED || this.status === ChangeHistoryStatus.ADDED || this.status === ChangeHistoryStatus.CHANGED;
  }
}
