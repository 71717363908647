<div class="vacancy-card" [class.expanded]="expanded" (click)="changeState()">
  <div class="not-expanded" *ngIf="!expanded; else expandedTemplate">
    <ng-container *ngIf="vacancy?.parentCategoryId; else emptyCandidateBlock">
      <div class="freelancer-data">
        <div class="avatar-wrapper" *ngIf="tab !== 'DRAFT' && vacancy?.freelancer">
          <tpt-avatar size="32" [textSizeRatio]="2.5" [user]="vacancy.freelancer"></tpt-avatar>
        </div>

        <div class="category-wrapper">
          <div class="subcategory headline" *ngIf="tab === 'VACANT' || tab === 'DRAFT'">
            {{subcategory(vacancy.parentCategoryId, vacancy.subCategoryId) | translate}}</div>
          <ng-container *ngIf="tab === 'ACTIVE' || tab === 'ENDED'">
            <div class="category caption">{{subcategory(vacancy.parentCategoryId, vacancy.subCategoryId) | translate}}</div>
            <div class="subcategory headline">{{vacancy?.freelancer?.firstName}} {{vacancy?.freelancer?.lastName}}</div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #emptyCandidateBlock>
      <div class="empty-block-not-expanded candidate-empty">
        <mat-icon [svgIcon]="svgIconsEnum.Candidate"></mat-icon>
        {{'TEAM.NO_CANDIDATE' | translate}}
      </div>
    </ng-template>

    <div class="flex">
      <ng-container *ngIf="vacancy?.parentCategoryId">
        <div class="expert-level-wrapper">
          <div class="caption">{{'CONTRACTS.SKILLS' | translate}}</div>
          <ng-container *ngIf="vacancy?.expertLevels.length; else notSelected">
            <div *ngFor="let lvl of vacancy?.expertLevels" class="regular">
              {{ 'SEARCH.LEVEL.' + lvl | translate}}
            </div>
          </ng-container>
        </div>

        <div class="location-wrapper">
          <div class="caption">{{'SETTINGS.LOCATION' | translate}}</div>
          <div class="regular" *ngIf="vacancy?.country; else notSelected">
            {{ getTranslation(vacancy?.country)}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="vacancy?.details?.budget || vacancy?.details?.hourlyRate; else emptyBudgetBlock">
        <div class="budget-wrapper">
          <div class="caption">{{'TEAM.BUDGET_SUM' | translate}}</div>
          <div class="regular" *ngIf="vacancy?.details?.paymentMethod === 'FIXED'; else hourlyBudget">
            {{vacancy?.details?.budget | number}}{{currencySymbol[vacancy?.currency?.code]}}
          </div>
          <ng-template #hourlyBudget>
            <div class="regular">
              {{ getHourlyProjectCost() | number}} {{currencySymbol[vacancy?.currency?.code]}}
            </div>
          </ng-template>
        </div>

        <div class="deadline-rate-column">
          <div *ngIf="vacancy?.details?.paymentMethod === 'FIXED'; else hourlyTemplate">
            <div class="caption">{{'FINANCE.COLUMN.DEADLINE' | translate}}</div>
            <div class="regular" *ngIf="vacancy?.details?.deadline; else notSelected">
              {{vacancy?.details?.deadline | localDate: 'dd.MM.yyyy'}}
            </div>
          </div>
          <ng-template #hourlyTemplate>
            <div class="caption">{{'SEARCH.OPTIONS.HOURLY_RATE' | translate}}</div>
            <div class="regular" *ngIf="vacancy?.details?.hourlyRate; else notSelected">
              {{vacancy?.details?.hourlyRate}} {{currencySymbol[vacancy?.currency?.code]}}
            </div>
          </ng-template>
        </div>
      </ng-container>

      <ng-template #emptyBudgetBlock>
        <div class="empty-block-not-expanded budget-empty">
          <mat-icon [svgIcon]="svgIconsEnum.Budget"></mat-icon>
          {{'TEAM.NO_BUDGET' | translate}}
        </div>
      </ng-template>

      <ng-container *ngIf="vacancy?.tasks?.length; else emptyTasksBlock">
        <div class="task-count-wrapper">
          <div class="caption">{{'TEAM.TASKS_COUNT' | translate}}</div>
          <div class="regular" *ngIf="vacancy?.tasks?.length">{{vacancy?.tasks?.length}}</div>
        </div>

        <div class="button-container" *ngIf="tab === 'ACTIVE'">
          <ng-container *ngIf="vacancy.canBeStopped && isEmployer()">
            <button
              class="dots-more"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu
              class="tpt-more-menu"
              #menu="matMenu"
            >
              <button
                mat-menu-item
                (click)="completeJob.emit(vacancy.id)"
              >
                {{'PROJECTS.COMPLETE' | translate}}
              </button>
            </mat-menu>
          </ng-container>
        </div>

        <div class="button-container" *ngIf="tab === 'VACANT'">
          <ng-container>
            <button
              class="dots-more"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon [svgIcon]="svgIconsEnum.MoreHoriz"></mat-icon>
            </button>
            <mat-menu
              class="tpt-more-menu"
              #menu="matMenu"
            >
              <button
                mat-menu-item
                (click)="copyJobLink()"
              >
                {{'SETTINGS.COPY_LINK' | translate}}
              </button>
            </mat-menu>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #emptyTasksBlock>
        <div class="empty-block-not-expanded tasks-empty">
          <mat-icon [svgIcon]="svgIconsEnum.TaskList"></mat-icon>
          {{'TEAM.NO_TASKS' | translate}}
        </div>
      </ng-template>
      <ng-container *ngIf="tab==='DRAFT'">
        <button
          class="dots-more"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu
          class="tpt-more-menu"
          #menu="matMenu"
        >
          <button
            mat-menu-item
            class="delete-menu-btn"
            (click)="openDeleteJobDialog()"
          >
            {{'BUTTON.DELETE' | translate}}
          </button>
          <button
            mat-menu-item
            (click)="publishDraft()"
            *ngIf="showPublishBtn"
          >
            {{'BUTTON.PUBLISH' | translate}}
          </button>
        </mat-menu>
      </ng-container>
    </div>
  </div>

  <ng-template #expandedTemplate>
    <div class="expanded-wrapper">
      <div class="right-top-actions" *ngIf="tab === 'VACANT' && isEmployer()">
        <tpt-button type="secondary" size="small" class="edit-button regular"
                    (click)="$event.stopPropagation(); openEditJobDialog(vacancy)">
          {{'BUTTON.EDIT' | translate}}
          <mat-icon [svgIcon]="svgIconsEnum.Edit"></mat-icon>
        </tpt-button>
        <button mat-icon-button class="copy-btn"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()">
          <mat-icon [svgIcon]="svgIconsEnum.MoreHoriz"></mat-icon>
        </button>
        <mat-menu
          class="tpt-more-menu"
          #menu="matMenu"
          xPosition="before"
        >
          <button
            mat-menu-item
            (click)="copyJobLink()"
          >
            {{'SETTINGS.COPY_LINK' | translate}}
          </button>
        </mat-menu>
      </div>

      <span class="ended-chip regular" *ngIf="tab === 'ENDED'">
        {{'PROJECTS.ENDED' | translate}}
        <mat-icon [svgIcon]="svgIconsEnum.Check" class="check"></mat-icon>
      </span>
      <div class="buttons-wrapper" *ngIf="tab === 'DRAFT'">
        <button mat-icon-button class="delete-btn" (click)="$event.stopPropagation(); openDeleteJobDialog()">
          <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
        </button>
        <button class="publish-btn"
                color="primary"
                mat-flat-button
                *ngIf="showPublishBtn"
                (click)="$event.stopPropagation(); publishDraft()"
        >{{'BUTTON.PUBLISH' | translate}}</button>
      </div>
      <div class="card-grid">
        <div class="left">
          <div class="candidate">
            <div class="caption margin-16 block-name-wrapper">
              <span class="block-inline-flex" [ngClass]="{'filled-block': vacancy?.parentCategoryId}">
                <mat-icon [svgIcon]="svgIconsEnum.Candidate"></mat-icon>
                {{'CREATE_PROJECT.STEP2.CANDIDATE' | translate}}
              </span>
              <mat-icon *ngIf="vacancy?.parentCategoryId && tab === 'DRAFT'"
                        [svgIcon]="svgIconsEnum.Edit"
                        class="edit-block-button edit-block-icon"
                        (click)="$event.stopPropagation(); openCandidatePage.emit({job: vacancy, edit: true})"
              ></mat-icon>
            </div>
            <div *ngIf="vacancy?.parentCategoryId; else EmptyCandidate">
              <div class="assignee-info">
                <div>
                  <div class="caption">{{ category(vacancy?.parentCategoryId) | translate}}</div>
                  <div class="headline margin-16">
                    {{subcategory(vacancy.parentCategoryId, vacancy.subCategoryId) | translate}}
                  </div>
                </div>
                <div class="ml-40" *ngIf="tab !== 'DRAFT'">
                  <div class="caption">{{'TRACKER.ASSIGNEE' | translate}}</div>
                  <div class="headline margin-16" *ngIf="vacancy?.freelancer; else notFound">
                    {{vacancy?.freelancer?.firstName}} {{vacancy?.freelancer?.lastName}}
                  </div>
                  <ng-template #notFound>
                    <div class="not-found caption">
                      {{'PROJECTS.NOT_FOUND' | translate}}
                    </div>
                  </ng-template>
                </div>
              </div>

              <div class="candidate-row mb-20">
                <div>
                  <div class="caption">{{'CREATE_PROJECT.STEP2.SKILLS_LEVEL' | translate}}</div>
                  <ng-container *ngIf="vacancy?.expertLevels.length; else notSelected">
                    <div *ngFor="let lvl of vacancy?.expertLevels" class="value">
                      {{ 'SEARCH.LEVEL.' + lvl | translate}}
                    </div>
                  </ng-container>
                </div>
                <div>
                  <div class="caption">{{'CREATE_PROJECT.STEP2.LANG_LVL' | translate}}</div>
                  <ng-container *ngIf="vacancy?.languages.length; else notSelected">
                    <div *ngFor="let lang of vacancy?.languages" class="value">
                      <span *ngIf="lang?.code && lang?.level; else notSelected">
                        <span class="language">{{getLangByCode(lang)}} </span>
                        <span class="level">{{'CREATE_PROJECT.STEP3.' + lang.level | translate}}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div>
                  <div class="caption">{{'CREATE_PROJECT.STEP2.LOCATION' | translate}}</div>
                  <span *ngIf="vacancy?.country; else notSelected" class="value">
                    {{getTranslation(vacancy?.country) || ('POST_CREATE.NOT_SPECIFIED' | translate)}}
                  </span>
                </div>
              </div>
              <div class="pseudo-chips" *ngIf="getAllSkills().length">
                <tpt-chip class="chip" type="outline" size="small" *ngFor="let skill of getAllSkills()">
                  <span>{{getTranslation(skill) || skill}}</span>
                </tpt-chip>
              </div>
            </div>

            <ng-template #EmptyCandidate>
              <div class="block-name">{{'CREATE_PROJECT.STEP2.CANDIDATE_REQS' | translate}}</div>
              <div class="block-text regular mb-16">{{'CREATE_PROJECT.STEP2.CANDIDATE_REQS_TEXT' | translate}}</div>
              <tpt-button type="info" (click)="$event.stopPropagation(); openCandidatePage.emit({job: vacancy, edit: false})"
              >{{'CREATE_PROJECT.STEP2.CANDIDATE_PAGE_TITLE' | translate}}</tpt-button>
            </ng-template>
          </div>
        </div>
        <div class="right">
          <div class="budget">
            <div class="caption margin-16 block-name-wrapper">
              <span class="block-inline-flex" [ngClass]="{'filled-block': vacancy?.details?.budget ||
               vacancy?.details?.hourlyRate, 'invalid-block': budgetInvalid}">
                <mat-icon [svgIcon]="svgIconsEnum.Budget"></mat-icon>
                {{'CREATE_PROJECT.STEP2.BUDGET' | translate}}
              </span>
              <mat-icon *ngIf="(vacancy?.details?.budget || vacancy?.details?.hourlyRate) && tab === 'DRAFT'"
                        [svgIcon]="svgIconsEnum.Edit"
                        class="edit-block-button edit-block-icon"
                        (click)="$event.stopPropagation(); openBudgetPage.emit({job: vacancy, edit: true})"
              ></mat-icon>
            </div>

            <div *ngIf="vacancy?.details?.budget || vacancy?.details?.hourlyRate; else emptyBudget">
              <div *ngIf="vacancy?.details?.paymentMethod === 'FIXED'" class="budget-info">
                <div>
                  <div class="caption">{{'CREATE_PROJECT.STEP2.TOTAL_BUDGET' | translate}}</div>
                  <div class="total-budget headline">
                    {{vacancy?.details?.budget | number}} {{currencySymbol[vacancy?.currency?.code]}}
                  </div>
                </div>
                <div class="fixed-grid">
                  <div>
                    <div class="caption">{{'FINANCE.COLUMN.PAYMENT_TYPE' | translate}}</div>
                    <div class="budget-value">{{'FINANCE.COLUMN.FIXED' | translate}}</div>
                  </div>
                  <div>
                    <div class="caption">{{ 'FINANCE.COLUMN.DEADLINE' | translate}}</div>
                    <div class="budget-value">{{vacancy?.details?.deadline | localDate: 'dd.MM.yyyy'}}</div>
                  </div>
                </div>
                <div>
                  <div class="caption">{{'CREATE_PROJECT.STEP4.PERIOD_OF_CONSIDERATION_OF_THE_PROBLEM' | translate}}</div>
                  <div class="budget-value">
                    <ng-container *ngIf="vacancy?.details?.reviewPeriod as value; else notSelected">
                          <span *ngIf="value < 5; else days">
                            {{ value }} {{'CREATE_PROJECT.STEP4.DAY' | translate}}
                          </span>
                      <ng-template #days>{{ value }} {{'CREATE_PROJECT.STEP4.DAYS' | translate}}</ng-template>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div *ngIf="vacancy?.details?.paymentMethod === 'HOURLY'" class="budget-info">
                <div class="hourly-grid">
                  <div>
                    <div class="caption">{{'CREATE_PROJECT.STEP2.TOTAL_BUDGET' | translate}}</div>
                    <div class="total-budget headline">{{getHourlyProjectCost() | number}} {{currencySymbol[vacancy?.currency?.code]}}</div>
                  </div>
                  <div>
                    <div class="caption">{{'FINANCE.COLUMN.PAYMENT_TYPE' | translate}}</div>
                    <div class="budget-value">{{'FINANCE.COLUMN.HOURLY' | translate}}</div>
                  </div>
                  <div>
                    <div class="caption">{{'CREATE_PROJECT.STEP4.RATE_PER_HOUR' | translate}}</div>
                    <div class="budget-value">{{vacancy?.details?.hourlyRate }}{{currencySymbol[vacancy?.currency?.code]}}/{{'FINANCE.HOUR' | translate}}</div>
                  </div>
                  <div>
                    <div class="caption">{{'SEARCH.HOURS_PER_WEEK' | translate}}</div>
                    <div class="budget-value">{{vacancy?.details?.hoursPerWeek}}</div>
                  </div>
                  <div>
                    <div class="caption">{{'SEARCH.PAYMENT_PERIOD' | translate}}</div>
                    <div class="budget-value">{{vacancy?.details?.paymentPeriod | translate}}</div>
                  </div>
                  <div *ngIf="vacancy?.details?.deadline">
                    <div class="caption">{{ 'FINANCE.COLUMN.DEADLINE' | translate}}</div>
                    <div class="budget-value">{{vacancy?.details?.deadline | localDate: 'dd.MM.yyyy'}}</div>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #emptyBudget>
              <div class="block-name headline">{{'CREATE_PROJECT.STEP2.VACANCY_BUDGET' | translate}}</div>
              <div class="block-text mb-16 regular">{{'CREATE_PROJECT.STEP2.VACANCY_BUDGET_TEXT' | translate}}</div>
              <tpt-button type="info" (click)="$event.stopPropagation(); openBudgetPage.emit({job: vacancy, edit: true})"
              >{{'CREATE_PROJECT.STEP2.VACANCY_BUDGET_BUTTON' | translate}}</tpt-button>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="tasks">
        <div class="caption margin-16 mt-28 block-name-wrapper">
          <span class="block-inline-flex" [ngClass]="{'filled-block': vacancy?.tasks?.length}">
            <mat-icon [svgIcon]="svgIconsEnum.TaskList"></mat-icon>
            {{'CREATE_PROJECT.STEP2.TASKS' | translate}}
          </span>
        </div>
        <div class="tasks-list" *ngIf="vacancy?.tasks?.length; else emptyTasks">
          <div *ngFor="let task of vacancy?.tasks; let taskIndex = index">
            <div class="task-wrapper">
              <div class="task-row">
                <div class="task-row-title">{{task?.taskTitle}}</div>
                <div class="task-row-deadline">{{task?.deadline | localDate: 'dd.MM.yyyy'}}</div>
              </div>
              <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="task-menu" *ngIf="tab==='DRAFT'">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="$event.stopPropagation(); openTasksPage.emit({job: vacancy, task: task, edit: true})">
                  <span>{{ 'BUTTON.EDIT' | translate }}</span>
                </button>
                <button mat-menu-item (click)="removeTask.emit({job: vacancy, task: task})">
                  <span class="red-text">{{ 'TRACKER.DELETE_TASK' | translate }}</span>
                </button>
              </mat-menu>
            </div>
            <mat-divider class="divider"></mat-divider>
          </div>
          <div class="add-new-task" *ngIf="tab === 'DRAFT'">
            <button mat-button (click)="$event.stopPropagation(); openTasksPage.emit({job: vacancy, edit: false})">
              + {{'CREATE_PROJECT.ADD_TASK' | translate}}
            </button>
          </div>
        </div>
        <ng-template #emptyTasks>
          <div class="block-name headline">{{'CREATE_PROJECT.STEP2.PROJECT_TASKS' | translate}}</div>
          <div class="block-text regular mb-16">{{'CREATE_PROJECT.STEP2.PROJECT_TASKS_TEXT' | translate}}</div>
          <tpt-button type="info" (click)="$event.stopPropagation(); openTasksPage.emit({job: vacancy, edit: false})">
            {{'CREATE_PROJECT.STEP2.PROJECT_TASKS_BUTTON' | translate}}
          </tpt-button>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #notSelected>
  <div class="regular">
    {{'POST_CREATE.NOT_SPECIFIED' | translate}}
  </div>
</ng-template>

<tpt-confirm-destruction-dialog (actionApprove)="deleteJob()"></tpt-confirm-destruction-dialog>
