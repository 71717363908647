import { Injectable } from '@angular/core';
import { ChatApiService } from '../../../services/chat.api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContractEventModel } from '../models/contract-event.model';
import { ChatItemModel } from '../models/chat-item.model';
import { ChatListItemModel } from '../models/chat-list-item.model';
import { ProjectApiService } from '../../../services/project.api.service';
import { EditSimpleProjectModel } from '../../employer/models/edit-simple-project.model';
import { SkillV2Model } from '../../../models/skill-v2.model';
import * as moment from 'moment';
import { FileUpload } from '../../../models/fileUpload';
import { NegotiationResponse } from '../components/parts/contracts-project-card/contracts-project-card.component';
import { SearchService } from '../../../services/search.service';

export interface ChatFilter {
  pageSize?: number;
  pageNumber?: number;
  searchQuery?: string;
  projectId?: number;
  categories?: number[];
  skills?: number[];
  levels?: string[];
  rateMin?: number;
  rateMax?: number;
  budgetMin?: number;
  budgetMax?: number;
  countryCode?: string;
  languageLevels?:
    {
      code: string,
      level: string
    }[];
  fixedProject?: boolean;
  hourlyProject?: boolean;
  ratingLevel?: string;
  provenRecord?: true;
}

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  public proposalsCount = 0;

  public chatsCount = 0;

  public selectedProject: ChatListItemModel;

  public selectedChat: ChatItemModel;

  public refreshAllChats: Subject<void>;

  public findChats: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public findProposals: BehaviorSubject<ChatFilter> = new BehaviorSubject<ChatFilter>({searchQuery: ''});

  public contractEditMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public contractEditMode$ = this.contractEditMode.asObservable();

  public termsChanged: BehaviorSubject<any> = new BehaviorSubject(null);

  public selectedSidebarTab = new Subject<string>();

  private allChats: ChatListItemModel[];
  private allProposals: ChatListItemModel[];

  constructor(
    private chatApiService: ChatApiService,
    private projectApiService: ProjectApiService,
  ) {
    this.refreshAllChats = new Subject<void>();
  }

  public getAllChats = (search: string = ''): Observable<{chats: ChatListItemModel[]}> => {
    return this.chatApiService.getAllChats(search).pipe(
      tap(this.handleAllChats),
    );
  }

  public getUnreadCount = (): Observable<any> => {
    return this.chatApiService.getUnreadCount();
  }

  public getAllProposals = (query: ChatFilter): Observable<{ proposals: ChatListItemModel[] }> => {
    const params = SearchService.transformParams(query);
    return this.chatApiService.getAllProposals(params).pipe(
      tap(this.handleAllProposals),
    );
  }

  public getChatEvents = (chatId: number): Observable<ContractEventModel[]> => {
    return this.chatApiService.getChatEvents(chatId).pipe(
      tap(() => this.findSelectedProject(chatId)),
      tap(() => this.findSelectedChat(chatId)),
    );
  }

  public getContractById = (chatId: number): Observable<NegotiationResponse> => {
    return this.projectApiService.getContractById(chatId);
  }

  public declineFreelancer = (): Observable<void> => {
    return this.chatApiService.declineFreelancer(this.selectedChat.id);
  }

  public acceptFreelancer = (version: number): Observable<void> => {
    return this.chatApiService.acceptFreelancer(this.selectedChat.id, version);
  }

  public declineInvite = (): Observable<void> => {
    return this.chatApiService.declineInvite(this.selectedChat.id);
  }

  public acceptInvite = (version: number): Observable<void> => {
    return this.chatApiService.acceptInvite(this.selectedChat.id, version);
  }

  public cancel = (): Observable<void> => {
    return this.chatApiService.decline(this.selectedChat.id);
  }

  public cancelTerms = (): Observable<void> => {
    return this.projectApiService.cancelTerms(this.selectedChat.id);
  }

  public dispose(): void {
    this.selectedChat = null;
    this.selectedProject = null;
    this.allChats = null;

    // if (this.refreshAllChats) {
    //   this.refreshAllChats.complete();
    //   this.refreshAllChats = null;
    // }
  }

  public mapProjectInfoSave(modelToSend: EditSimpleProjectModel): EditSimpleProjectModel {
    modelToSend.skillIds = (modelToSend.skillIds as any).map((skill: SkillV2Model) => skill.id);
    if (modelToSend.subCategoryId) {
      modelToSend.categoryId = modelToSend.subCategoryId;
    }

    modelToSend.tasks.forEach(task => {
      if (task.fileIds && typeof task.fileIds[0] !== 'number') {
        task.fileIds = (task.fileIds as FileUpload[]).map(file => file.id);
      }

      task.deadline = moment(task.deadline).format('YYYY-MM-DD');
    });

    if ((modelToSend.details as any).deadline ) {
      (modelToSend.details as any).deadline = moment((modelToSend as any).deadline).format('YYYY-MM-DD');
    }

    if (modelToSend.languages.length) {
      modelToSend.languages = modelToSend.languages.filter(lang => lang.code && lang.level);
    }

    return modelToSend;
  }

  public mapProjectBudgetSave(modelToSend: EditSimpleProjectModel): EditSimpleProjectModel {
    modelToSend.skillIds = (modelToSend.skillIds as any).map((skill: SkillV2Model) => skill?.id || skill);

    const milestones = (modelToSend.details as any).milestones;
    for (let i = 0; i < milestones.length; ++i) {
      const value = milestones[i];
      milestones[i].deadline = moment(milestones[i].deadline).format('YYYY-MM-DD');
      milestones[i].budget = + milestones[i].budget;
    }

    if (modelToSend.details?.milestones?.length === 1) {
      modelToSend.details.milestones[0].deadline = modelToSend.details.deadline;
      modelToSend.details.milestones[0].budget = (modelToSend.details as any)?.budget;
    }

    const dates = modelToSend.tasks?.filter(task => task.deadline).map(task => moment(task.deadline));
    const maxDate = moment.max(dates).format('YYYY-MM-DD');

    if (modelToSend.details.milestones?.some(item => !item.deadline || (moment(item.deadline) < moment(maxDate)))) {
      modelToSend.details.milestones.forEach(item => item.deadline = maxDate);
    }

    if (moment(modelToSend.details.deadline) < moment(maxDate)) {
      modelToSend.details.deadline = maxDate;
    }

    return modelToSend;
  }

  public saveProjectAfterEdit = (model: EditSimpleProjectModel): Observable<NegotiationResponse> => {
    const chatId = this.selectedChat.id;
    return this.projectApiService.editContractV2(model, chatId);
  }

  public findSelectedProject = (chatId: number): void => {
    const chats = this.allChats;
    const proposals = this.allProposals;
    this.selectedProject = proposals.concat(chats)
      .find((chatList: ChatListItemModel) => {
          if (chatList) {
            return chatList.chats.some((chat: ChatItemModel) => chat.id === chatId);
          }
        }
      );
  }

  private handleAllChats = (response: {chats: ChatListItemModel[]}): void => {
    this.allChats = response.chats;
    this.chatsCount = response.chats.length;
  }

  private handleAllProposals = (response: {proposals: ChatListItemModel[]}): void => {
    this.allProposals = response.proposals;
    this.proposalsCount = response.proposals.length;
  }

  private findSelectedChat = (chatId: number): void => {
    if (!this.selectedProject) { return; }
    this.selectedChat = this.selectedProject.chats.find((chat: ChatItemModel) => chat.id === chatId);
  }

}
