import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FileUpload } from '../../../models/fileUpload';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { ResourcesService } from '../../../services/resources.service';

@Component({
  selector: 'emp-attached-file-item',
  template: `
    <div
      class="attached-file"
      (click)="getFile(file.uuid)"
    >
      <mat-icon [svgIcon]="svgIconsEnum.AttachedFile">
        <span class="file-size">{{ getFileSize(file.size) }}</span>
      </mat-icon>
      <span class="filename">{{ file.name }}</span>
    </div>
  `,
  styleUrls: [ './attached-file-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedFileItemComponent {

  @Input()
  public file: FileUpload;

  public svgIconsEnum = SvgIconsEnum;

  constructor(
    public resourcesService: ResourcesService,
  ) { }

  public getFile(uuid: string): void {
    this.resourcesService.getFileByUUID(uuid).subscribe(this.handleFile);
  }

  public getFileSize(size: number): string {
    const sizes = ['КБ', 'МБ'];
    const index = Math.floor(Math.log(size) / Math.log(1024 * 1024));
    return Math.round(size / Math.pow(1024, index + 1)) + ' ' + sizes[index];
  }

  public handleFile = (file: File): void => {
    const objectURL = this.resourcesService.createObjectURL(file);
    const link = document.createElement('a');
    link.download = file.name;
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }

}
