import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import moment from 'moment';

import { CategoryV2Model } from '../../../models/category-v2.model';
import { CategoryService } from '../../../services/category.service';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { ProjectJobModel } from '../../employer/models/project-job.model';
import { SimpleProjectHourlyPaymentModel } from '../../employer/models/simple-project-hourly-payment.model';
import { ProfileService } from '../../../services/profile.service';
import { SimpleProjectFixedPaymentModel } from '../../employer/models/simple-project-fixed-payment.model';
import { SpinnerService } from '../../tpt-ui/services/spinner.service';
import { JobService } from '../../employer/services/job.service';
import { SnackbarNotificationsService } from '../../../services/snackbar-notifications.service';
import { ConfirmDestructionDialogComponent } from '../../common-dialogs/components/confirm-destruction-dialog/confirm-destruction-dialog.component';
import { CurrencySymbol } from '../../../models/currency.enum';
import { RouteHelper } from '../../../helpers/route.helper';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { takeUntil } from 'rxjs/operators';
import { Language, LanguageOption } from '../../../models/language';
import { LanguageService } from '../../../services/language.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'vacancy-card',
  templateUrl: './vacancy-card.component.html',
  styleUrls: ['./vacancy-card.component.scss']
})
export class VacancyCardComponent implements OnInit, OnDestroy {

  public get showPublishBtn(): boolean {
    if (this.vacancy.details.paymentMethod === 'FIXED') {
      const deadline = this.vacancy.details.deadline;

      if (moment(deadline) < moment().add(2, 'days')) {
        return false;
      }

      if (this.vacancy.details.milestones.length) {
        const invalidMilestone = this.vacancy.details.milestones
          .some(item => moment(item.deadline) < moment().add(2, 'days'));

        if (invalidMilestone) { return false; }
      }
    }

    const budgetIsValid = (this.vacancy.details as SimpleProjectFixedPaymentModel).budget ||
      (this.vacancy.details as SimpleProjectHourlyPaymentModel).hourlyRate;

    return this.vacancy.parentCategoryId && budgetIsValid && !!this.vacancy.tasks.length;
  }

  public get budgetInvalid(): boolean {
    if (this.vacancy.details.paymentMethod === 'FIXED') {
      const jobDeadline = this.vacancy.details.deadline;

      if (moment(jobDeadline) < moment().add(2, 'days')) {
        return true;
      }

      if (this.vacancy.details.milestones.length) {
        const invalidMilestone = this.vacancy.details.milestones
          .some(item => moment(item.deadline) < moment().add(2, 'days'));

        if (invalidMilestone) {
          return true;
        }
      }
    }
    return false;
  }

  @Input() vacancy: ProjectJobModel;

  @Input() tab: string;

  @Output() openEditDialogEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() openBudgetPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() openCandidatePage: EventEmitter<any> = new EventEmitter<any>();
  @Output() openTasksPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeTask: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public readonly completeJob = new EventEmitter<number>();

  @Output() updateVacancies: EventEmitter<any> = new EventEmitter<any>();

  public expanded = false;

  @Input() isOpened: number;

  @ViewChild(ConfirmDestructionDialogComponent)
  public destructionDialog: ConfirmDestructionDialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public categoriesArray: CategoryV2Model[];

  public currencySymbol = CurrencySymbol;

  public languagesArray: Array<LanguageOption> = [];

  private readonly destroy$ = new Subject();

  constructor(private categoryService: CategoryService,
              private profileService: ProfileService,
              private spinnerService: SpinnerService,
              private jobService: JobService,
              private snack: SnackbarNotificationsService,
              public routeHelper: RouteHelper,
              private router: Router,
              private clipboard: Clipboard,
              private languageService: LanguageService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.categoryService.getCategoriesV2().pipe(takeUntil(this.destroy$)).subscribe(categories => {
      this.categoriesArray = categories;
    });

    this.languageService.getLanguagesV2().pipe(takeUntil(this.destroy$))
      .subscribe((langs: Language[]) => this.languagesArray = langs);

    if (this.isOpened === this.vacancy.id) {
      this.expanded = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  public category(id): string {
    const category: CategoryV2Model = find(this.categoriesArray, (_: CategoryV2Model) => _.id === id);
    if (category) {
      return this.translate.currentLang === 'ru' ? category.nameRu : category.name;
    }
    return 'CREATE_PROJECT.STEP5.CATEGORY_NOT_SELECTED';
  }

  public subcategory(id, subId?: number): string {
    const category: CategoryV2Model = find(this.categoriesArray, (_: CategoryV2Model) => _.id === id);
    if (!category) {
      return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
    }

    const subCategory: CategoryV2Model = find(category.children, (_: CategoryV2Model) => _.id === subId);
    if (subCategory) {
      return this.translate.currentLang === 'ru' ? subCategory.nameRu : subCategory.name;
    }
    return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public getLangByCode(lang) {
    const language = this.languagesArray?.find(item => item.code === lang.code);
    return this.getTranslation(language);
  }

  public getHourlyProjectCost(): string {
    const {hourlyRate, hoursPerWeek, paymentPeriod} = this.vacancy.details as SimpleProjectHourlyPaymentModel;
    const dayCost = (hourlyRate * hoursPerWeek) / 5;
    if (paymentPeriod === 'P_14DAYS') {
      return Number(dayCost * 5 * 2).toFixed(2);
    } else if (paymentPeriod === 'P_30DAYS') {
      return Number(dayCost * 5 * 4).toFixed(2);
    } else {
      return Number(dayCost * 5).toFixed(2);
    }
  }

  public openEditJobDialog(vacancy): void {
    this.openEditDialogEvent.emit(vacancy);
  }

  public changeState(): void {
    this.expanded = !this.expanded;
  }

  public getAllSkills() {
    const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
    return concat(this.vacancy.skills, this.vacancy.customSkills);
  }

  public publishDraft(): void {
    this.spinnerService.startSpinner();
    this.jobService.publishJob(this.vacancy.id).subscribe(
      this.handleSuccess.bind(this),
      this.handleError.bind(this)
    );
  }

  public deleteJob(): void {
    this.spinnerService.startSpinner();
    this.jobService.deleteJob(this.vacancy.id).subscribe(
      () => {
        this.spinnerService.stopSpinner();
        this.updateVacancies.emit(2);
      },
      this.handleError.bind(this)
    );
  }

  public openDeleteJobDialog(): void {
    this.destructionDialog.open('DELETE_JOB', 'TEAM.DELETE_JOB_TITLE', 'TEAM.DELETE_JOB_TEXT',
      'warn', 'BUTTON.DELETE');
  }

  public copyJobLink(): void {
    let baseUrl = window.location.href;
    baseUrl = baseUrl.split('/').slice(0, 3).join('/');

    const userUrl = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.projectPage(this.vacancy.id))
    );

    const fullUrl = `${baseUrl}${userUrl}`;

    this.clipboard.copy(fullUrl);
    this.snack.showNotification('SETTINGS.JOB_LINK_COPIED', 'success');
  }

  private handleSuccess(): void {
    this.spinnerService.stopSpinner();
    this.snack.showNotification('SNACKBAR.JOB_PUBLISHED', 'success');
    this.updateVacancies.emit(1);
  }

  private handleError(): void {
    this.spinnerService.stopSpinner();
    this.snack.showNotification('SNACKBAR.SOMETHING_WENT_WRONG', 'fail');
  }
}
