<div class="tpt-cards-select-control-root">
  <tpt-label>
    <ng-content select="[label]"></ng-content>
  </tpt-label>

  <mat-form-field
    appearance="none"
    [class.-error-state]="errorState"
  >
    <mat-select
      [formControl]="control"
      [placeholder]="placeholder"
      disableOptionCentering
      panelClass="select-panel-custom"
    >
      <mat-select-trigger>
        <ng-container *ngIf="getSelectedCard() as stripeCard; else nothing">
          <div class="card-view">
            <mat-icon class="stripe-icon" [svgIcon]="svgIconsEnum.StripePurple"></mat-icon>
            <span class="number-dots">****</span>
            <span class="number-dots">****</span>
            <span class="number-dots">****</span>
            <span class="card-number">{{ stripeCard.card.lastFour }}</span>
            <span class="card-expiration">{{ stripeCard.card.expMonth}}/{{stripeCard.card.expYear}}</span>
            <mat-icon class="brand-icon" [svgIcon]="getCardBrandIcon(stripeCard)"></mat-icon>
          </div>
        </ng-container>

        <ng-template #nothing></ng-template>
      </mat-select-trigger>

      <ng-container *ngFor="let stripeCard of stripeCards">
        <mat-option
          [value]="stripeCard.id"
        >
          <div class="card-view">
            <mat-icon class="stripe-icon" [svgIcon]="svgIconsEnum.StripeGrey"></mat-icon>
            <span class="number-dots">****</span>
            <span class="number-dots">****</span>
            <span class="number-dots">****</span>
            <span class="card-number">{{ stripeCard.card.lastFour }}</span>
            <span class="card-expiration">{{ stripeCard.card.expMonth}}/{{stripeCard.card.expYear}}</span>
            <mat-icon class="brand-icon" [svgIcon]="getCardBrandIcon(stripeCard)"></mat-icon>
          </div>
        </mat-option>
      </ng-container>
      <mat-option class="add-payment" (click)="addPaymentMethod.emit()">{{'FINANCE.ADD_PAYMENT_METHOD' | translate}}</mat-option>
    </mat-select>

    <mat-hint [align]="hintAlign">
      <ng-content select="[hint]"></ng-content>
    </mat-hint>

    <mat-error [class.-invisible]="!errorState">
      {{ error | translate}}
    </mat-error>
  </mat-form-field>
</div>
