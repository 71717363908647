
export class UserLoggedInModel {

  constructor(
    public userId: string,
    public userType: 'customer' | 'freelancer' | 'admin' | 'judge',
    public lang: 'en' | 'ru',
  ) { }

}
