import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { omitBy, find } from 'lodash';

import { DialogComponent } from '../../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { ProfileModel } from '../../../../../models/profile.model';
import { ProfileService } from '../../../../../services/profile.service';
import { SpinnerService } from '../../../../tpt-ui/services/spinner.service';
import { SearchService } from '../../../../../services/search.service';
import { ProjectApiService } from '../../../../../services/project.api.service';
import { ProjectSearchItemModel } from '../../../../../models/search/projectSearchItem.model';
import { DataLayerService } from '../../../../../services/data-layer.service';
import { RouteHelper } from '../../../../../helpers/route.helper';
import { CategoryService } from '../../../../../services/category.service';
import { CategoryV2Model } from '../../../../../models/category-v2.model';
import { CurrencyEnum } from '../../../../../models/currency.enum';
import { SelfEmployedWarningDialogComponent } from '../../../../common-dialogs/components/self-employed-warning-dialog/self-employed-warning-dialog.component';
import { FreelancerLegalStatusEnum } from '../../../../../models/legalStatuses.enum';

@Component({
  selector: 'hire-freelancer-dialog',
  templateUrl: './hire-freelancer-dialog.component.html',
  styleUrls: ['./hire-freelancer-dialog.component.scss']
})
export class HireFreelancerDialogComponent implements OnInit {

  get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(SelfEmployedWarningDialogComponent, { static: false })
  public warningDialog: SelfEmployedWarningDialogComponent;

  @Output() successfullyHired = new EventEmitter();

  public freelancer: ProfileModel;

  public svgIconsEnum = SvgIconsEnum;

  public vacancies: ProjectSearchItemModel[] = [];

  public currentProject;

  public detailsOpened = false;

  public categories: CategoryV2Model[];

  private className = 'hire-freelancer-dialog';

  private config: MatDialogConfig = {
    width: '729px',
    minHeight: '200px',
    maxHeight: '100vh',
  };

  private currencyEnum = CurrencyEnum;

  constructor(private profileService: ProfileService,
              private spinner: SpinnerService,
              private searchService: SearchService,
              public projectService: ProjectApiService,
              public routeHelper: RouteHelper,
              private dataLayerService: DataLayerService,
              private categoryService: CategoryService,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.categoryService.getCategoriesV2().subscribe(categories => {
      this.categories = categories;
    });
  }

  public open(freelancer, vacancies): void {
    this.vacancies = vacancies;
    this.freelancer = freelancer;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public clearCurrentProject() {
    this.currentProject = null;
  }

  public availableVacancies(): boolean {
    if (!this.vacancies?.length) {
      return false;
    }

    const allTalentJobs = [...this.freelancer.activeProjects, ...this.freelancer.invites, ...this.freelancer.proposals];

    return !this.vacancies.every(job => {
      return allTalentJobs.some(item => job.id === item);
    });
  }

  public hireFreelancer(): void {
    if (this.currentProject.currency.code === this.currencyEnum.RUB &&
      this.freelancer.legalStatus !== FreelancerLegalStatusEnum.SELF_EMPLOYED) {
      this.warningDialog.open();
      return;
    }

    this.spinner.startSpinner();
    this.searchService.sendInvite(this.freelancer.id, this.currentProject.id)
      .then(res => {
        if (res) {
          this.freelancer.invites.push(this.currentProject.id);
          this.successfullyHired.emit(this.freelancer.id);
          this.pushEmployeerSendRequestToDataLayer(this.currentProject);
          this.close();
          this.spinner.stopSpinner();
        }
      })
      .catch(this.spinner.stopSpinner);
  }

  public category(id): string {
    const category: CategoryV2Model = find(this.categories, (_: CategoryV2Model) => _.id === id);
    if (category) {
      return this.translate.currentLang === 'ru' ? category.nameRu : category.name;
    }
    return 'CREATE_PROJECT.STEP5.CATEGORY_NOT_SELECTED';
  }

  public subcategory(id, subId?: number): string {
    const category: CategoryV2Model = find(this.categories, (_: CategoryV2Model) => _.id === id);
    if (!category) {
      return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
    }

    const subCategory: CategoryV2Model = find(category.children, (_: CategoryV2Model) => _.id === subId);
    if (subCategory) {
      return this.translate.currentLang === 'ru' ? subCategory.nameRu : subCategory.name;
    }
    return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
  }

  public disabledJob(job) {
    const allTalentJobs = [...this.freelancer.activeProjects, ...this.freelancer.invites, ...this.freelancer.proposals];

    return allTalentJobs.some(item => job.id === item);
  }

  private pushEmployeerSendRequestToDataLayer = (job: ProjectSearchItemModel): void => {
    const event = 'employeerSendRequest';
    const visible = this.transformVisibilityToAnalyticsForm(job.projectInfo.visibility);
    const level = this.transformExpertLevelsToAnalyticsForm(job.expertLevels);
    const paymentType = job.details.paymentMethod.toLocaleLowerCase();
    const budget = parseFloat(job.details.paymentMethod);
    const currency = job.currency.code.toLowerCase();
    const projectType = job.projectInfo.type === 'COMPLEX' ? 'complex' : 'single';

    const model = omitBy(
      { event, visible, level, paymentType, budget, currency, projectId: `${job.projectId}`, jobId: `${job.id}`, projectType},
      (field: string | number) => !Boolean(field),
    );
    this.dataLayerService.pushToDataLayer(model);
  }

  private transformExpertLevelsToAnalyticsForm(levels: Array<'BEGINNER' | 'INTERMEDIATE' | 'MASTER'>): string {
    return levels.map((value: 'BEGINNER' | 'INTERMEDIATE' | 'MASTER', index: number) => {
      return value === 'BEGINNER' ? 'junior' : value === 'INTERMEDIATE' ? 'middle' : 'expert';
    }).join(',');
  }

  private transformVisibilityToAnalyticsForm(visibility: 'PRIVATE' | 'INTERNALLY' | 'PUBLIC'): string {
    if (visibility === 'PRIVATE') {
      return 'private';
    } else if (visibility === 'INTERNALLY') {
      return 'inside';
    } else {
      return 'public';
    }
  }
}
