<mat-chip-list
    #chipList
    class="chips-autocomplete"
    [ngClass]="(chipsBelow ? 'chips-autocomplete_below ' : 'chips-autocomplete_above ') + cssChipsClass"
>
    <ng-container *ngIf="!chipsBelow" [ngTemplateOutlet]="chips"></ng-container>
    <mat-form-field class="chips-autocomplete__field" floatLabel="always" [appearance]="appearance">
        <mat-label></mat-label>
        <input
            matInput
            class="chips-autocomplete__input"
            name="chips-autocomplete"
            [placeholder]="title"
            #itemInput
            [formControl]="itemCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="false"
            (matChipInputTokenEnd)="addItem($event)"
        />
    </mat-form-field>
    <ng-container *ngIf="chipsBelow" [ngTemplateOutlet]="chips"></ng-container>
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems" [value]="item">
        {{ item[viewField] || item | translate }}
    </mat-option>
</mat-autocomplete>

<ng-template #chips>
    <mat-chip
        *ngFor="let item of selectedItems"
        [selectable]="false"
        [removable]="true"
        (removed)="removeItem(item)"
        [ngClass]="{ blue: blue }"
        class="chips-autocomplete__list-item"
    >
        {{ (item[viewField] || item) | translate }}
        <mat-icon matChipRemove class="chips-autocomplete__list-icon">clear</mat-icon>
    </mat-chip>
</ng-template>
