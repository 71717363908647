import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import * as _textile from 'textile-js/lib/textile.js';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import { default as _rollupTextile } from 'textile-js/lib/textile.js';

const textile = _rollupTextile || _textile;

@Component({
  selector: 'xln-textile',
  templateUrl: './textile.component.html',
  styleUrls: ['./textile.component.scss']
})
export class TextileComponent implements OnChanges {
  @Input() public text = '';
  public result = '';

  public ngOnChanges(changes: SimpleChanges): void {
    this.result = this.text ? textile(this.text) : '';
  }

}
