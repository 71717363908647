<div class="notification-content">
  <div class="notification-content__title headline">
    {{'NOTIFICATIONS' | translate}}
  </div>
  <mat-divider class="divider"></mat-divider>
  <div *ngIf="notifications">
    <div class="notification-content__container">
      <div class="mark-all caption" (click)="markAllAsRead()"
           *ngIf="showMarkAll">{{ 'NOTIFICATION_CONTENT_COMPONENT.MARK_ALL_AS_READ' | translate }}</div>
      <div class="notification-content__wrapper">
        <div *ngIf="notifications.today?.length" class="caption day">
          {{ 'NOTIFICATION_CONTENT_COMPONENT.TODAY' | translate }}
        </div>
        <div *ngFor="let notification of notifications.today"
             class="notification-content__notification"
             [ngClass]="{'active': isActiveRow(notification) || !notification.viewed}"
             (click)="redirectTo(notification)"
        >
          <div class="notification-image">
            <ng-container *ngIf="showFreelancerAvatar.includes(notification?.code)">
              <ngx-avatar name="{{notification.data.freelancer.firstName}} {{notification.data.freelancer.lastName}}"
                          size="32"
              ></ngx-avatar>
            </ng-container>
            <ng-container *ngIf="showEmployerAvatar.includes(notification?.code)">
              <ngx-avatar name="{{notification.data.owner.firstName}} {{notification.data.owner.lastName}}"
                          size="32"
              ></ngx-avatar>
            </ng-container>
            <ng-container *ngIf="showErrorIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="error"></mat-icon>
            </ng-container>
            <ng-container *ngIf="showSuccessIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="success"></mat-icon>
            </ng-container>
            <ng-container *ngIf="infoIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="info"></mat-icon>
            </ng-container>
          </div>
          <div class="notification-content__content">
            <div class="actions">
              <div class="delete" (click)="$event.stopPropagation(); deleteNotification(notification)">
                <mat-icon [svgIcon]="svgIconsEnum.Cross"></mat-icon>
              </div>
              <div class="view-point" *ngIf="!notification?.viewed"></div>
            </div>
            <div class="notification-content__sign">
              <div *ngIf="notification?.date" class="notification-content__date">
                {{notification?.date | timeAgo}}
              </div>
            </div>
            <div class="notification-content__notification-text">
              <span [innerHTML]="translate(notification) | safeHtml"></span>
            </div>
          </div>
        </div>
        <div *ngIf="notifications.yesterday?.length" class="caption day">
          {{ 'NOTIFICATION_CONTENT_COMPONENT.YESTERDAY' | translate }}
        </div>
        <div *ngFor="let notification of notifications.yesterday"
             class="notification-content__notification"
             [ngClass]="{'active': isActiveRow(notification) || !notification.viewed}"
             (click)="redirectTo(notification)"
        >
          <div class="notification-image">
            <ng-container *ngIf="showFreelancerAvatar.includes(notification?.code)">
              <ngx-avatar name="{{notification.data.freelancer.firstName}} {{notification.data.freelancer.lastName}}"
                          size="32"
              ></ngx-avatar>
            </ng-container>
            <ng-container *ngIf="showEmployerAvatar.includes(notification?.code)">
              <ngx-avatar name="{{notification.data.owner.firstName}} {{notification.data.owner.lastName}}"
                          size="32"
              ></ngx-avatar>
            </ng-container>
            <ng-container *ngIf="showErrorIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="error"></mat-icon>
            </ng-container>
            <ng-container *ngIf="showSuccessIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="success"></mat-icon>
            </ng-container>
            <ng-container *ngIf="infoIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="info"></mat-icon>
            </ng-container>
          </div>
          <div class="notification-content__content">
            <div class="actions">
              <div class="delete" (click)="$event.stopPropagation(); deleteNotification(notification)">
                <mat-icon [svgIcon]="svgIconsEnum.Cross"></mat-icon>
              </div>
              <div class="view-point" *ngIf="!notification?.viewed"></div>
            </div>
            <div class="notification-content__sign">
              <div *ngIf="notification?.date" class="notification-content__date">
                {{notification?.date | timeAgo}}
              </div>
            </div>
            <div class="notification-content__notification-text">
              <span [innerHTML]="translate(notification) | safeHtml"></span>
            </div>
          </div>
        </div>
        <div *ngIf="notifications.older?.length" class="caption day">
          {{ 'NOTIFICATION_CONTENT_COMPONENT.OLDER' | translate }}
        </div>
        <div *ngFor="let notification of notifications.older"
             class="notification-content__notification"
             [ngClass]="{'active': isActiveRow(notification) || !notification.viewed}"
             (click)="redirectTo(notification)"
        >
          <div class="notification-image">
            <ng-container *ngIf="showFreelancerAvatar.includes(notification?.code)">
              <ngx-avatar name="{{notification.data.freelancer.firstName}} {{notification.data.freelancer.lastName}}"
                          size="32"
              ></ngx-avatar>
            </ng-container>
            <ng-container *ngIf="showEmployerAvatar.includes(notification?.code)">
              <ngx-avatar name="{{notification.data.owner.firstName}} {{notification.data.owner.lastName}}"
                          size="32"
              ></ngx-avatar>
            </ng-container>
            <ng-container *ngIf="showErrorIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="error"></mat-icon>
            </ng-container>
            <ng-container *ngIf="showSuccessIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="success"></mat-icon>
            </ng-container>
            <ng-container *ngIf="infoIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.Success" class="info"></mat-icon>
            </ng-container>
          </div>
          <div class="notification-content__content">
            <div class="actions">
              <div class="delete" (click)="$event.stopPropagation(); deleteNotification(notification)">
                <mat-icon [svgIcon]="svgIconsEnum.Cross"></mat-icon>
              </div>
              <div class="view-point" *ngIf="!notification?.viewed"></div>
            </div>
            <div class="notification-content__sign">
              <div *ngIf="notification?.date" class="notification-content__date">
                {{notification?.date | timeAgo}}
              </div>
            </div>
            <div class="notification-content__notification-text">
              <span [innerHTML]="translate(notification) | safeHtml"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-bar">
<!--      <span class="show-all" [routerLink]="routeHelper.notifications" (click)="close.emit()">-->
<!--        {{'NOTIFICATION_CONTENT_COMPONENT.SHOW_ALL' | translate}}</span>-->
    </div>
  </div>
  <div *ngIf="!notifications || noNotifications"
       class="notification-content__empty">
    {{ 'NOTIFICATION_CONTENT_COMPONENT.EMPTY' | translate }}
  </div>
</div>
