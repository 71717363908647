import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StompRService } from '@stomp/ng2-stompjs';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { environment } from '../environments/environment';
import { BdcWalkModule, BdcWalkService } from 'bdc-walkthrough';
import { AvatarModule } from 'ngx-avatar';
import { RecaptchaModule } from 'ng-recaptcha';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertDialog } from './components/common-dialogs/alert-dialog/alert-dialog.component';
import { RenameDialog } from './components/common-dialogs/rename-dialog/rename-dialog.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HeaderComponent } from './components/head/header.component';
import { LoginComponent } from './components/login/login.component';
import { NotificationContentComponent } from './components/notification/notification-content.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SubscriptionDialogComponent } from './components/subscription/dialog/subscription-dialog.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { RegisterComponent } from './components/register/register.component';

import { RtcComponent } from './components/webrtc/rtc.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { MainPageGuard } from './guards/main-page.guard';
import { RedirectGuard } from './guards/redirect.guard';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { lazyModules } from './manual-lazy-modules';
import { LocalCurrencyPipe } from './modules/common-pipes/pipes/local-currency.pipe';
import { CommonShareModule } from './modules/common-share.module';
import { SearchModule } from './modules/search/search.module';
import { TextileModule } from './modules/textile/textile.module';
import { PaginatorIntlTranslate } from './paginator-intl-translate';
import { MANUAL_LAZY_MODULES } from './tokens';
import { CustomBreakPointsProvider } from './custom-breakpoints';
import { TptUiModule } from './modules/tpt-ui/tpt-ui.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PortalService } from './services/portal.service';
import { TptFormsModule } from './modules/tpt-forms/tpt-forms.module';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { CommonDialogsModule } from './modules/common-dialogs/common-dialogs.module';
import { SnackbarComponent } from './components/snackbar-notifications/snackbar/snackbar.component';
import { SnackbarNotificationsComponent } from './components/snackbar-notifications/snackbar-notifications.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { CommonComponentsModule } from './modules/common-components/common-components.module';
import { LoadingBarService } from './services/loading-bar.service';

const CLIENT_ID = environment.google_client_id;

export function createHomeTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        `./assets/translations/`,
        '.json?' + environment.timeStamp,
    );
}

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AlertDialog,
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SubscriptionComponent,
    SubscriptionDialogComponent,

    NotificationComponent,
    NotificationContentComponent,
    RegisterComponent,
    ConfirmComponent,
    RenameDialog,

    RtcComponent,
    ConfirmSuccessComponent,
    SnackbarComponent,
    SnackbarNotificationsComponent,
    CheckListComponent,
  ],
  entryComponents: [
    AlertDialog,
    NotificationContentComponent,
    RenameDialog,
    SubscriptionDialogComponent,
    SnackbarComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonShareModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createHomeTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    NgIdleModule.forRoot(),
    MatCardModule,
    TextileModule,
    MatChipsModule,
    MatToolbarModule,
    MatBadgeModule,
    MatRadioModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatCheckboxModule,
    SearchModule,
    TptUiModule,
    TptFormsModule,
    MatExpansionModule,
    RecaptchaModule,
    CommonDialogsModule,
    MatDividerModule,
    BdcWalkModule,
    AvatarModule,
    CommonComponentsModule,
    SocialLoginModule
  ],
  providers: [
    PortalService,
    DatePipe,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD.MM.YYYY',
        },
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY',
        },
      },
    },
    {provide: LOCALE_ID, useValue: 'ru'},
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {disableClose: true},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const paginator = new PaginatorIntlTranslate();
        paginator.injectTranslateService(translate);
        return paginator;
      },
      deps: [TranslateService],
    },
    LocalCurrencyPipe,
    StompRService,
    {
      provide: MANUAL_LAZY_MODULES,
      useValue: lazyModules,
    },
    AuthGuard,
    RoleGuard,
    MainPageGuard,
    RedirectGuard,
    CustomBreakPointsProvider,
    BdcWalkService,
    LoadingBarService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              CLIENT_ID
            )
          }
        ]
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
