import { NgModuleFactory, Type } from '@angular/core';
import { Route } from '@angular/router';

export declare type LoadChildrenPromiseCallback = () => Promise<NgModuleFactory<any> | Type<any>>;

export enum ModuleName {
  ResourceDiff = 'resource-diff',
  DraftedTask  = 'drafted-task',
}

export interface ILazyModule extends Route {
  name: ModuleName;
  loadChildren: LoadChildrenPromiseCallback;
}

export const lazyModules: ILazyModule[] = [
];
