import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss']
})
export class DragNDropComponent implements OnInit{

  @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;
  @Input() files: any[] = [];
  svgIconsEnum = SvgIconsEnum;
  @Input() size: 'standard' | 'small' = 'standard';
  @Input() invisible = false;

  @Output() public uploadFileChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public deleteFileEvent: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (!this.files) {
      this.files = [];
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * emit file browsing
   */
  openFilePicker() {
    this.fileDropEl?.nativeElement.click();
  }

  /**
   * Delete file from files list
   * @param index (File index)
   * @param fileId (File id)
   */
  deleteFile(index: number, fileId: number) {
    if (this.files[index].progress < 100) {
      console.log('Upload in progress.');
      return;
    }
    // this.files.splice(index, 1);
    this.deleteFileEvent.emit(fileId);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    if (index === this.files.length) {
      return;
    } else {
      const progressInterval = setInterval(() => {
        if (this.files[index]?.uuid) {
          this.files[index].progress = 100;
        }
        if (this.files[index]?.progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator(index + 1);
        } else {
          this.files[index].progress += 5;
        }
      }, 10);
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      if (this.files.length >= 10) {
        return;
      }

      item.progress = 0;
      this.files.push(item);
      this.uploadFileChange.emit(item);
    }

    this.fileDropEl.nativeElement.value = '';
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
