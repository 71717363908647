<mat-card (click)="openCardDetails(freelancer)" class="freelancer-card">
  <div class="first-row">
    <div class="left">
      <div class="freel-info">
        <tpt-avatar [textSizeRatio]="3"
                    [user]="freelancer"
                    size="48"
                    class="mr-8"></tpt-avatar>
        <div>
          <div class="freelancer-name">{{freelancer.firstName}} {{freelancer.lastName}}</div>
          <div class="freelancer-category">
            <span class="legal-status" *ngIf="freelancer?.legalStatus === freelancerLegalStatusEnum.SELF_EMPLOYED">
                  {{('SETTINGS.'+freelancer?.legalStatus) | translate}}
                </span>
            <span class="rating">{{'SETTINGS.RATING' | translate}} {{ freelancer.rating | percent}}</span>
          </div>
        </div>
      </div>

      <div class="description">
        <span>{{freelancer?.description | tptTruncate: [130]}}</span>
      </div>

      <div class="categories">
        <mat-tab-group mat-align-tabs="start" #matTabGroup>
          <mat-tab *ngFor="let spec of freelancer.specializations; let i = index">
            <ng-template mat-tab-label>
              <div class="tab-label"
                   (click)="$event.stopPropagation(); matTabGroup.selectedIndex = i">
                {{spec.subCategory?.id ? getTranslation(spec.subCategory) :
                spec.parentCategory?.id ? getTranslation(spec.parentCategory) : ('CONTRACTS.NOT_SPECIFIED' | translate)}}
              </div>
            </ng-template>
            <div class="category-row">
              <div class="">
                <div class="caption">{{'SETTINGS.CATEGORY' | translate}}</div>
                <div class="regular-14-20">{{spec.parentCategory ? getTranslation(spec.parentCategory) : '-'}}</div>
              </div>

              <div class="">
                <div class="caption">{{'SEARCH.OPTIONS.SPECIALIST_LEVEL' | translate}}</div>
                <div class="regular-14-20">{{spec?.expertLevel ? ('SEARCH.LEVEL.'+spec?.expertLevel | translate) : '-'}}</div>
              </div>

              <div class="">
                <div class="caption">{{'SETTINGS.RATE' | translate}}</div>
                <div class="regular-14-20">{{ spec.hourlyRate ? '$' + spec.hourlyRate + '/' + ('FINANCE.HOUR' | translate) : '-'}}</div>
              </div>
            </div>

            <div class="skills-wrap">
              <div class="pseudo-chips" *ngFor="let skill of specSkills(spec)">
                {{getTranslation(skill) || skill}}
              </div>
              <div *ngIf="spec.moreSkillsCount" class="more-skills" (click)="$event.stopPropagation(); showAllSkills(spec)">
                +{{spec.moreSkillsCount}}
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <div class="right">
      <div class="button-block">
        <ng-container *ngIf="isEmployer">
            <button mat-button class="action-button"
                    (click)="$event.stopPropagation(); hireFreelancer(freelancer)"
            >{{'SEARCH.HIRE' | translate}}</button>
        </ng-container>
        <button mat-icon-button class="more-horiz-btn"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()">
          <mat-icon [svgIcon]="svgIconsEnum.MoreHoriz"></mat-icon>
        </button>

        <mat-menu
          class="tpt-more-menu"
          #menu="matMenu"
          xPosition="before"
        >
          <button
            mat-menu-item
            (click)="openProfilePage(freelancer)"
          >
            {{'SETTINGS.COPY_LINK' | translate}}
          </button>
        </mat-menu>
      </div>

      <div *ngIf="freelancer.expertLevel" class="sub-main-info">
        <p class="main-info-category">{{'SEARCH.OPTIONS.SPECIALIST_LEVEL' | translate}}</p>
        <p class="main-info-value">{{ 'SEARCH.LEVEL.'+freelancer?.expertLevel | translate}}</p>
      </div>
      <div *ngIf="freelancer.country" class="sub-main-info">
        <p class="main-info-category">{{'SEARCH.OPTIONS.FREELANCERS_LOCATION' | translate}}</p>
        <p class="main-info-value">{{getTranslation(freelancer?.country)}}</p>
      </div>
      <div *ngIf="freelancer?.experience as experience" class="sub-main-info">
        <p class="main-info-category">{{'SEARCH.OPTIONS.WORK_EXP' | translate}}</p>
        <div class="main-info-value platform-wrap">
          <div *ngFor="let item of experience" class="platform">
            <mat-icon [svgIcon]="svgIconsEnum[platformsEnum[item.code]]"
                      (click)="$event.stopPropagation()"
                      [matMenuTriggerFor]="menu"></mat-icon>
            <mat-menu #menu="matMenu" class="platform-panel" xPosition="before">
              <div class="platform-menu">
                <div class="main-info-category">{{'SEARCH.PLATFORM' | translate}}</div>
                <div class="main-info-value">{{platformsEnumFe[item.code]}}</div>
                <div class="main-info-category">{{'SEARCH.OPTIONS.WORK_EXP' | translate}}</div>
                <div class="main-info-value">
                      <span *ngIf="getYears(item.duration) as years">
                        {{'SEARCH.OPTIONS.YEARS' | translate : { years: years } }}
                      </span>

                  <span *ngIf="getMonths(item.duration) as months">
                        {{'SEARCH.OPTIONS.MONTHS' | translate : { months: months } }}
                      </span>
                </div>
                <div class="main-info-category">{{'SEARCH.RATING_PLATFORM' | translate}}</div>
                <div class="main-info-value rating">{{item.rating}}%
                  <div *ngIf="item.rating" class="progress-bar">
                    <mat-progress-bar [value]="item.rating" color="primary" mode="determinate"></mat-progress-bar>
                  </div>
                </div>
                <div *ngIf="item?.file" class="reviews-link">
                  <a target="_blank" [href]="getLink(item.file.uuid)">
                    {{ 'CONTRACTS.REVIEWS' | translate }}
                    <mat-icon [svgIcon]="svgIconsEnum.Link" class="link-icon"></mat-icon>
                  </a>
                </div>
                <div class="main-info-category">
                  {{'SEARCH.CHECK_DATE' | translate}} {{item.checkingDate | localDate: 'dd.MM.yy'}}
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<hire-freelancer-dialog></hire-freelancer-dialog>
