import { CurrencyPipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '../../../models/currency';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'localCurrency',
  pure: false, // required to update the value when the promise is resolved
})
export class LocalCurrencyPipe implements PipeTransform, OnDestroy {

  protected currencyPipe: CurrencyPipe;

  protected result: string | null = null;

  private subscription: Subscription;

  private value: any = null;

  private locale: string = null;

  private markForChanges = false;

  private currency: Currency = null;

  constructor(protected translateService: TranslateService) {
    this.currencyPipe = new CurrencyPipe(this.translateService.currentLang);
    this.subscription = this.translateService.onLangChange
      .asObservable()
      .subscribe((value) => {
        this.currencyPipe = new CurrencyPipe(value.lang);
        this.markForChanges = true;
      });
  }

  public transform(value: any, currency?: Currency): string | null {
    if (!this.hasChanges(value, currency)) {
      return this.result;
    }
    this.storeValues(value, currency);
    this.calcResult();
    this.markForChanges = false;
    return this.result;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected storeValues(value: any, currency?: Currency) {
    this.value = value;
    this.currency = currency;
  }

  protected hasChanges(value: any, currency?: Currency): boolean {
    return this.markForChanges || this.value !== value || this.currency !== currency ||
      (this.currency && currency && this.currency.code !== currency.code);
  }

  protected calcResult() {
    // console.log(this.currency);
    // console.log(this.value);
    // console.log(Currency.SYMBOLS[this.currency.code]);
    // console.log(this.locale);

    if (this.currency && this.currency.fiat) {
      this.result = this.currencyPipe.transform(this.value, this.currency.code, 'symbol-narrow');
    } else {
      const digits = this.currency ? this.currency.fraction : 2;
      this.result = this.currencyPipe.transform(this.value,
        Currency.SYMBOLS[this.currency.code],
        'symbol-narrow',
        `1.${digits}-${digits}`,
        this.locale);
    }
  }
}
