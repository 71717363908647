import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../../services/profile.service';
import { BdcWalkService } from 'bdc-walkthrough';
import { RouteHelper } from '../../../../helpers/route.helper';

@Component({
  selector: 'help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent {

  get isRu() {
    return this.translate.currentLang === 'ru';
  }

  get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  private config: MatDialogConfig = {
    maxWidth: '729px',
  };

  private className = 'help-dialog';

  constructor(private translate: TranslateService,
              private profileService: ProfileService,
              private router: Router,
              private routeHelper: RouteHelper,
              private bdcWalkService: BdcWalkService) {
  }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public reset() {
    this.profileService.startOnBoardingSubject.next();
    this.bdcWalkService.reset(this.isEmployer ? 'onBoardingEmployer' : 'onBoardingFreelancer');
    this.bdcWalkService.setTaskCompleted('dialogWelcome', true);
    this.router.navigate(this.isEmployer ? this.routeHelper.employeerProjectsDashboard :
      this.routeHelper.freelancerProjectsDashboard);
    this.close();
  }

}
