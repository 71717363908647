<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="dialog-title">
      {{'CONTRACTS.DOCS' | translate}}
    </div>

    <div class="dialog-text">
      {{'CONTRACTS.PAYMENT_DOCS_INFO' | translate}}
    </div>

    <div class="docs-title">
      {{'CONTRACTS.DOCS_LIST' | translate}}
    </div>

    <ng-container *ngIf="docs">
      <div class="mb-8" *ngIf="docs?.advancePaymentInvoice">
        <tpt-uploaded-file [file]="docs.advancePaymentInvoice"></tpt-uploaded-file>
      </div>
      <tpt-uploaded-file [file]="docs.projectAgreement"></tpt-uploaded-file>
    </ng-container>

    <div class="actions-row mt-40">
      <tpt-button type="secondary" size="medium" (onClick)="close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
      <tpt-button type="primary" size="medium" class="dwnld-button" (onClick)="downloadAll()">
        {{'CONTRACTS.DOWNLOAD_DOCS' | translate}}
        <mat-icon [svgIcon]="svgIconsEnum.Download" class="docs-icon"></mat-icon>
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
