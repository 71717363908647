import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getNotifications = (): Observable<any> => {
    return this.executeGet('v2/notifications');
  }

  public getCountNotifications = (): Observable<any> => {
    return this.executeGet('v2/notifications/unreadCount');
  }

  public markAllAsRead = (): Observable<any> => {
    return this.executePut('v2/notifications/all', null);
  }

  public markOneAsRead = (id): Observable<any> => {
    return this.executePut('v2/notifications', null, { params: { id }});
  }

  public deleteNotification = (id: number): Observable<any> => {
    return fromPromise(this.executeDelete('v2/notifications', { params: { id } }));
  }
}
