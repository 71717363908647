import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { AccountModel } from '../models/financev2/account.model';
import { fromPromise } from 'rxjs/internal-compatibility';
import { SetupIntentResponseModel } from '../models/financev2/setup-intent-response.model';
import { ProviderEnum } from '../types/provider.enum';
import { ProviderInfoModel } from '../models/financev2/provider-info.model';
import { HttpClient } from '@angular/common/http';
import { FinanceHistoryListItemModel } from '../modules/finance/models/finance-history-list-item.model';
import { FinanceHistoryListFilterModel } from '../modules/finance/models/finance-history-list-filter.model';
import { DepositFundsModel } from '../modules/common-dialogs/models/deposit-funds.model';
import { FinanceCommonInfoModel } from '../modules/finance/models/finance-common-info.model';
import { WithdrawFundsModel } from '../modules/common-dialogs/models/withdraw-funds.model';
import { omitBy } from 'lodash';
import { IPayoneerReleasePayee } from '../models/payoneer/payoneer-release-payee.interface';
import { CardDetachRequestModel } from '../modules/finance/models/card-detach-request.model';
import { IPayoneerRegLink } from '../models/payoneer/payoneer-reg-link.interface';
import { TransactionModel } from '../modules/finance/models/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class FinancesApiService extends BaseService {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getFinances = (): Observable<AccountModel[]> => {
    return this.executeGet('v2/finances');
  }

  public getCommonInfo = (): Observable<FinanceCommonInfoModel[]> => {
    return this.executeGet('v2/finances/common');
  }

  public getProviderInfo = (providerId: ProviderEnum): Observable<ProviderInfoModel> => {
    return this.executeGet(`v2/finances/provider/${providerId}`);
  }

  public setupIntent = (accountId: number): Observable<SetupIntentResponseModel> => {
    return this.executeGet(`v2/finances/setupIntent/${accountId}`);
  }

  public depositFunds = (model: DepositFundsModel): Observable<TransactionModel> => {
    return fromPromise(this.executePost('v2/finances/depositWithCard', model));
  }

  public withdrawFunds = (model: WithdrawFundsModel): Observable<any> => {
    return fromPromise(this.executePost('v2/finances/requestWithdraw', model));
  }

  public getHistoryOperations = (model?: FinanceHistoryListFilterModel): Observable<FinanceHistoryListItemModel[]> => {
    if (model) {
      return this.executeGet('v2/finances/operations', { params: omitBy(model, (value: any) => !Boolean(value)) } );
    }
    return this.executeGet('v2/finances/operations');
  }

  public releasePayoneerPayee(accountId: number): Observable<IPayoneerReleasePayee> {
    return fromPromise(this.executeDelete<IPayoneerReleasePayee>(`v2/finances/payoneer/releasePayee/${accountId}`));
  }

  public cardDetach(model: CardDetachRequestModel): Observable<SetupIntentResponseModel> {
    return fromPromise(this.executePost<any>(`v2/finances/cardDetach`, model));
  }

  public cardAdded(): Observable<any> {
    return fromPromise(this.executePost<any>(`v2/finances/onCardAttached`, null));
  }

  public requestPayoneerRegLink(accountId: number): Observable<IPayoneerRegLink> {
    return this.executeGet<IPayoneerRegLink>(`v2/finances/requestPayoneerRegLink/${accountId}`);
  }

  public checkTransaction(txId: number): Observable<IPayoneerRegLink> {
    return this.executeGet(`v2/finances/transaction/${txId}`);
  }

  public setMainAccount(accountId: number): Observable<any> {
    return this.executePut(`v2/finances/mainAccount/${accountId}`, null);
  }
}
