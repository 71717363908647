import { FormContext } from '../modules/tpt-forms/base/form.context';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class ContactForm extends FormContext
  <{firstName: string, lastName: string; email: string, company: string, message: string}> {

  static createForm(): ContactForm {
    const form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      company: new FormControl(''),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$'),
        Validators.maxLength(50),
      ]),
      message: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(500)
      ]),
    });

    return new ContactForm(form);
  }

  public get firstName(): AbstractControl {
    return this.getControl('firstName');
  }

  public get lastName(): AbstractControl {
    return this.getControl('lastName');
  }

  public get email(): AbstractControl {
    return this.getControl('email');
  }

  public get phone(): AbstractControl {
    return this.getControl('phone');
  }

  public get company(): AbstractControl {
    return this.getControl('company');
  }

  public get message(): AbstractControl {
    return this.getControl('message');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): {firstName: string, lastName: string; email: string, company: string, message: string} {
    return this.form.value;
  }

}
