import {Type} from 'class-transformer';
import {ProjectProblem, JobVisibility} from '../../classes/enums';
import {IDateRange} from '../../classes/iDateRange';

export class JobListView implements IDateRange {
  public id: number;
  public name: string;
  public checked = true;

  @Type(() => Date)
  public createdAt: Date = new Date();

  public resourcesTotal = 0;
  public resourcesFilled = 0;
  public resourcesArchived = 0;
  public archived = false;
  public visibility: JobVisibility = JobVisibility.PUBLIC;
  public state: ProjectProblem = ProjectProblem.NO;

  public tasksTotal = 0;
  public tasksCompleted = 0;
  public tasksDrafted = 0;

  @Type(() => Date)
  public startDate: Date = null;

  @Type(() => Date)
  public tillDate: Date = null;
}
