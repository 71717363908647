<tpt-dialog>
  <div class="remove-dialog-root">
    <div class="dialog-title">{{'FINANCE.REMOVE_STRIPE' | translate}}</div>
    <div class="dialog-text">{{'FINANCE.REMOVE_STRIPE_TEXT' | translate}}</div>
    <div class="actions-row">
      <button mat-button class="cancel" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
      <button mat-button class="confirm" (click)="confirmDelete()">{{'BUTTON.DELETE' | translate}}</button>
    </div>
  </div>
</tpt-dialog>
