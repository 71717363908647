<header class="header" [ngClass]="{'dark': isDark}">
  <div class="header__wrapper">
    <mat-toolbar class="header__toolbar">
      <ng-template [cdkPortalOutlet]="headerPortal"></ng-template>
      <ng-container [ngTemplateOutlet]="menuCollapsable"></ng-container>
    </mat-toolbar>
  </div>
</header>

<ng-template #menuCollapsable>
  <ng-container *ngIf="profileService.currentProfile == null">
    <mat-icon [svgIcon]="svgIconsEnum.ExpandedLogo" class="logo" [routerLink]="['/']"></mat-icon>
  </ng-container>

  <div class="header__actions-block" *ngIf="profileService.currentProfile">
    <button mat-icon-button class="help-btn" (click)="helpDialog.open()"
            [bdcWalkTriggerFor]="isEmployer ? onBoardingEmployerStep6 : onBoardingFreelancerStep5">
      <mat-icon [svgIcon]="svgIconsEnum.HelpOutline" class="icon"></mat-icon>
    </button>

    <tpt-notification *ngIf="profileService.currentProfile"
                      class="icon"></tpt-notification>

    <ng-container *ngIf="profileService.currentProfile == null">
      <a mat-button [routerLink]="['/login']"
         class="header__menu-link link-small white">{{ 'HEADER_COMPONENT.LOGIN' | translate }}</a>
      <a mat-button [routerLink]="['/register']"
         class="header__menu-link link-small white">{{ 'HEADER_COMPONENT.REGISTER' | translate }}</a>
    </ng-container>

    <ng-container *ngIf="(profileService.newProfileObject | async) as profile">
      <div class="header__profile" [matMenuTriggerFor]="profileMenu" (click)="$event.stopPropagation()">
        <div class="avatar-wrapper" [bdcWalkTriggerFor]="isEmployer ? onBoardingEmployerStep5 : onBoardingFreelancerStep1">
          <tpt-avatar size="24" [textSizeRatio]="2.5" [user]="profile"></tpt-avatar>
        </div>
      </div>
      <mat-menu #profileMenu="matMenu" class="header__menu">
          <div class="profile-info">
            <tpt-avatar class="mr-8" size="32" [textSizeRatio]="2.5" [user]="profile"></tpt-avatar>
            <div>
              <div class="profile-name">
                {{profile?.firstName}} {{profile?.lastName}}
              </div>
              <div class="account-balance">
                {{currencySymbol[account()?.currency?.code]}} {{account()?.balance | number}}
              </div>
            </div>
          </div>

<!--        <button mat-menu-item class="header__lang" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">-->
<!--          {{'HEADER_COMPONENT.LANGUAGE' | translate}}: {{langs[translate.currentLang]}}-->
<!--        </button>-->
<!--        <mat-menu #menu="matMenu" class="header__lang-menu">-->
<!--          <button class="header__menu-item" mat-menu-item *ngFor="let lang of ['en', 'ru']"-->
<!--                  (click)="setCurrentLang(lang)">-->
<!--            <span [class.selected]="lang === translate.currentLang">{{ langs[lang] }}</span>-->
<!--          </button>-->
<!--        </mat-menu>-->
        <button mat-menu-item [routerLink]="['/settings']">{{'HEADER_COMPONENT.EDIT_PROFILE' | translate}}</button>
        <button mat-menu-item (click)="logout()">{{ 'HEADER_COMPONENT.EXIT' | translate }}</button>
      </mat-menu>
    </ng-container>
  </div>
</ng-template>

<bdc-walk-popup #onBoardingEmployerStep5
                name="onBoardingEmployerStep5" yPosition="below"
                sideNoteText="5/6"
                [showButton]="true"
                [header]="'TOUR.EMPLOYER.5_TITLE' | translate"
                [buttonText]="'BUTTON.NEXT' | translate"
                [mustCompleted]="{onBoardingEmployerNext: 4}"
                [onButtonCompleteTask]="{onBoardingEmployerNext: 5}">
  <div class="tour-text" [innerHTML]="'TOUR.EMPLOYER.5_TEXT' | translate"></div>
</bdc-walk-popup>

<bdc-walk-popup #onBoardingFreelancerStep1
                name="onBoardingFreelancerStep1" yPosition="below"
                sideNoteText="1/5"
                [showButton]="true"
                [header]="'TOUR.FREELANCER.1_TITLE' | translate"
                [buttonText]="'BUTTON.NEXT' | translate"
                [mustCompleted]="{dialogWelcome: true}"
                [onButtonCompleteTask]="{onBoardingFreelancerNext: 1}">
  <div class="tour-text">
    {{'TOUR.FREELANCER.1_TEXT' | translate}} 😉
  </div>
</bdc-walk-popup>

<bdc-walk-popup #onBoardingFreelancerStep5
                name="onBoardingFreelancerStep5"
                sideNoteText="5/5"
                [header]="'TOUR.FREELANCER.5_TITLE' | translate"
                yPosition="below"
                [showButton]="true"
                [buttonText]="'BUTTON.FINISH' | translate"
                [mustCompleted]="{onBoardingFreelancerNext: 4}"
                [onButtonCompleteTask]="{onBoardingFreelancerNext: 5}">
  <div class="tour-text">
    <div [innerHTML]="'TOUR.FREELANCER.5_TEXT' | translate"></div>
    <button mat-stroked-button (click)="reset()" class="restart-btn">{{'TOUR.RESTART_TOUR' | translate}}</button>
  </div>
</bdc-walk-popup>

<bdc-walk-popup #onBoardingEmployerStep6
                name="onBoardingEmployerStep6"
                [header]="'TOUR.EMPLOYER.6_TITLE' | translate"
                yPosition="below"
                sideNoteText="6/6"
                [showButton]="true"
                [buttonText]="'BUTTON.FINISH' | translate"
                [mustCompleted]="{onBoardingEmployerNext: 5}"
                [onButtonCompleteTask]="{onBoardingEmployerNext: 6}">
  <div class="tour-text">
    <div [innerHTML]="'TOUR.EMPLOYER.6_TEXT' | translate"></div>
    <button mat-stroked-button (click)="reset()" class="restart-btn">{{'TOUR.RESTART_TOUR' | translate}}</button>
  </div>
</bdc-walk-popup>


<tpt-help-center></tpt-help-center>
