import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { environment } from '../../../environments/environment';
import Cookies from 'js-cookie';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionDialogComponent, DialogType } from '../subscription/dialog/subscription-dialog.component';
import { DataLayerService } from '../../services/data-layer.service';

@Component({
    selector: 'xln-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    public contactMail;
    public isCookieAccepted = false;
    public subscribeEmail = '';
    private COOKIE_ACCEPTED = 'accepted';

    constructor(
        public profileService: ProfileService,
        public subscriptionDialog: MatDialog,
        private router: Router,
        private subscriptionService: SubscriptionService,
        private dataLayerService: DataLayerService,
    ) {
        this.contactMail = environment.contactMail;

        // Cookies.remove('isCookieAccepted');
        if (Cookies.get('isCookieAccepted') === this.COOKIE_ACCEPTED) {
            this.isCookieAccepted = true;
        }
    }

    public acceptCookie() {
        Cookies.set('isCookieAccepted', this.COOKIE_ACCEPTED);
        this.isCookieAccepted = true;
    }

    subscribe() {
        this.subscribeEmail = this.subscribeEmail.trim();
        const valid = this.subscribeEmail ? this.subscriptionService.isValidEmail(this.subscribeEmail) : false;

        if (valid) {
            this.subscriptionService.subscribeEmail(this.subscribeEmail).subscribe(
                (data) => {
                  this.dataLayerService.pushToDataLayer({ event: 'subscribe' });
                  this.subscribeEmail = '';
                  this.subscriptionDialog.open(SubscriptionDialogComponent, {
                        data: {
                            message: 'Thank you for subscribing!',
                            type: DialogType.success,
                        },
                    });
                },
                (err) => {
                    console.log(err);
                    this.subscribeEmail = '';
                    this.subscriptionDialog.open(SubscriptionDialogComponent, {
                        data: {
                            message: err.message,
                            type: DialogType.error,
                        },
                    });
                },
            );
        } else {
            this.subscriptionDialog.open(SubscriptionDialogComponent, {
                data: {
                    message: 'Please enter a valid email address',
                    type: DialogType.error,
                },
            });
        }
    }
}
