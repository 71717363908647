import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export enum DialogType {
  error   = 'ERROR',
  success = 'SUCCESS',
  warning = 'WARNING',
}

@Component({
  selector: 'subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss'],
})
export class SubscriptionDialogComponent {
  public message: string;
  public title: string;
  public params: any = {};
  public type: DialogType;

  constructor(public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private translateService: TranslateService, ) {
    this.message = 'Message unavaliable';
    this.title = data.title || '';
    this.params = data.params;
    this.type = data.type;

    if (data.type === DialogType.success) {
      this.message = data.message;
    }

    if (data.type === DialogType.error) {

      this.translateService.get(['SUBSCRIPTION.ALREADY_SUBSCRIBED', 'SUBSCRIPTION.INVALID_EMAIL'])
        .subscribe((result: string[]) => {
          switch (data.message) {
            case 'SERVER_ERROR.SUBSCRIPTION_EMAIL_ALREADY_SUBSCRIBED':
              this.message =
                result['SUBSCRIPTION.ALREADY_SUBSCRIBED'];
              break;
            case 'SERVER_ERROR.SUBSCRIPTION_INVALID_EMAIL':
              this.message =  result['SUBSCRIPTION.INVALID_EMAIL'];
              break;
            default:
              this.message = data.message;
              break;
          }
        });
    }
  }
}
