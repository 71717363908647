<tpt-dialog (dialogClose)="clearCurrentProject()">
  <div class="title">{{'SEARCH.HIRE' | translate}}</div>

  <ng-container *ngIf="availableVacancies(); else emptyProjects">
    <div class="select-title subhead">{{'SEARCH.CHOOSE_PROJECT' | translate}}:</div>
    <mat-select class="select" [(value)]="currentProject" panelClass="project-select"
                [disableOptionCentering]="true">
      <mat-select-trigger *ngIf="currentProject">
        <div class="job caption">{{category(currentProject.parentCategoryId) | translate}} /
          {{subcategory(currentProject.parentCategoryId, currentProject.subCategoryId) | translate}}</div>
        <div class="project-name"> {{currentProject.projectInfo.name}}</div>
      </mat-select-trigger>
      <mat-option *ngFor='let vacancy of vacancies' [value]="vacancy" [disabled]="disabledJob(vacancy)">
        <div class="job caption">{{category(vacancy.parentCategoryId) | translate}} /
          {{subcategory(vacancy.parentCategoryId, vacancy.subCategoryId) | translate}}</div>
        <div class="project-name"> {{vacancy.projectInfo.name}}</div>
      </mat-option>
    </mat-select>

    <ng-container *ngIf="currentProject && detailsOpened">
      <project-details-card [project]="currentProject"></project-details-card>
    </ng-container>

    <div class="show-details" (click)="detailsOpened = !detailsOpened" *ngIf="currentProject">
      {{(!detailsOpened ? 'SEARCH.VIEW_DETAILS' : 'SEARCH.HIDE_CONTRACT_DETAILS') | translate}}
    </div>

    <div class="actions-row">
      <tpt-button type="secondary" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button class="ml-12" type="primary" [disabled]="!currentProject" (click)="hireFreelancer()">
        {{'BUTTON.HIRE' | translate}}
      </tpt-button>
    </div>
  </ng-container>

  <ng-template #emptyProjects>
    <div class="empty-text">{{'SEARCH.NO_PROJECT_TEXT' | translate}}
      <span class="link" (click)="close()" [routerLink]="routeHelper.employeerProjectsDrafts">{{'SEARCH.DRAFTS' | translate}}</span>
    </div>

    <div class="actions-row">
      <tpt-button type="secondary" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button class="ml-12" type="primary" (onClick)="close()" [routerLink]="routeHelper.employeerCreateProject">
        {{'CREATE_PROJECT.STEP1.CREATE_A_PROJECT' | translate}}
      </tpt-button>
    </div>
  </ng-template>
</tpt-dialog>

<tpt-self-employed-warning-dialog></tpt-self-employed-warning-dialog>
