import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { StripeCardModel } from '../../../../models/financev2/stripe-card.model';

@Component({
  selector: 'tpt-remove-stripe-account-dialog',
  templateUrl: './remove-stripe-account-dialog.component.html',
  styleUrls: [ './remove-stripe-account-dialog.component.scss' ],
})
export class RemoveStripeAccountDialogComponent {

  @Output()
  public readonly cancel = new EventEmitter<void>();

  @Output()
  public readonly confirm = new EventEmitter<StripeCardModel>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private className = 'tpt-remove-stripe-account-dialog';

  private card: StripeCardModel;

  public open(card: StripeCardModel): void {
    this.card = card;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public confirmDelete(): void {
    this.confirm.emit(this.card);
    this.close();
  }

}
