<ng-container *ngIf="!isMobile; else Mobile">
  <tpt-spinner></tpt-spinner>
  <tpt-loading-bar></tpt-loading-bar>
  <ng-container *ngIf="loaded">
    <div class="xln body" [ngClass]="{'is-employer': isEmployer}">
      <tpt-sidebar *ngIf="!hideSidebar"></tpt-sidebar>
      <div class="app-content">
        <xln-header
          [ngClass]="{'loaded': loaded, 'hide-header': hideHeader}"
          class="app-header"
          [class.loaded]="loaded"
          *ngIf="profileService?.currentProfile"
        >
        </xln-header>
        <tpt-header-light *ngIf="!profileService?.currentProfile"
                          class="app-header"
                          [ngClass]="{'hide-on-main-page': hideHeaderOnMainPage}">
        </tpt-header-light>

        <div class="body-content" [ngClass]="{'body-content_main': hideHeader}">
          <router-outlet></router-outlet>
          <tpt-snackbar-notifications></tpt-snackbar-notifications>
          <bdc-walk-dialog #dialogWelcome name="dialogWelcome"
                           width="720px"
                           *ngIf="profileService.currentProfile && !(profileService.mainOnBoardingViewed | async)">

            <div class="top-block" *ngIf="getImage as image">
              <img class="bckg-image" [src]="image"/>
            </div>

            <div class="bottom-block">
              <div class="title" [innerHTML]="'TOUR.WELCOME_TITLE' | translate"></div>
              <div class="text" [innerHTML]="'TOUR.WELCOME_TEXT' | translate"></div>
            </div>

            <div class="buttons">
              <button mat-stroked-button (click)="skipTour()"
                      class="skip-btn"
              >
                {{'BUTTON.SKIP' | translate}}
              </button>
              <button mat-flat-button (click)="startTour()"
                      class="start-btn" color="accent"
              >
                {{'BUTTON.START_TOUR' | translate}}
              </button>
            </div>
          </bdc-walk-dialog>
        </div>
      </div>
    </div>

    <!--  <rtc class="webrtc-wrapper" *ngIf="!adminHeader"></rtc>-->
    <xln-footer *ngIf="!loaded" class="app-footer"></xln-footer>
  </ng-container>
</ng-container>

<ng-template #Mobile>
  <mobile-layout></mobile-layout>
</ng-template>
