import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { mergeMap, take } from 'rxjs/operators';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { FinanceServiceV2 } from '../../services/finance.service';
import { AccountModel } from '../../../../models/financev2/account.model';
import { ProviderEnum } from '../../../../types/provider.enum';
import { ProviderInfoModel } from '../../../../models/financev2/provider-info.model';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { AddPaymentMethodForm } from '../../forms/add-payment-method.form';
import { SetupIntentResponseModel } from '../../../../models/financev2/setup-intent-response.model';
import { stripeCardStyleConfig } from '../../stripe-card-style.config';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../../../helpers/rxjs/take-until-destroyed';
import Element = stripe.elements.Element;
import Stripe = stripe.Stripe;
import { SnackbarNotificationsService } from '../../../../services/snackbar-notifications.service';
import SetupIntentResponse = stripe.SetupIntentResponse;

declare var Stripe: stripe.StripeStatic;

@DecorateUntilDestroy()
@Component({
  selector: 'tpt-add-payment-method-dialog',
  templateUrl: './add-payment-method-dialog.component.html',
  styleUrls: [ './add-payment-method-dialog.component.scss' ],
})
export class AddPaymentMethodDialogComponent implements OnDestroy {

  @Output()
  public readonly cardAdded = new EventEmitter<void>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild('cardElement')
  public cardElementRef: ElementRef;

  public form: AddPaymentMethodForm;

  public stripe: Stripe;

  private className = 'tpt-add-payment-method-dialog';

  private config: MatDialogConfig = {
    width: '620px'
  };

  private accounts: AccountModel[];

  private privateKey: string;

  private card: Element;

  constructor(
    private financeServiceV2: FinanceServiceV2,
    private spinnerService: SpinnerService,
    private snack: SnackbarNotificationsService,
  ) { }

  public open(): void {
    this.spinnerService.startSpinner();
    if (!this.financeServiceV2.accounts) {
      this.financeServiceV2.getAccount().subscribe((accounts: AccountModel[]) => this.accounts = accounts);
    }

    this.financeServiceV2.getProviderInfo(ProviderEnum.Stripe).pipe(
      take(1),
    ).subscribe(this.handleProviderInfo, this.spinnerService.stopSpinner);
  }

  public close(): void {
    this.dialog.close();
  }

  public cancel(): void {
    this.close();
  }

  public saveCard(): void {
    if (!this.form.validate()) {
      return;
    }

    this.spinnerService.startSpinner();
    const name = this.form.getFormData().name.toUpperCase();
    const options = {
      payment_method: {
        card: this.card,
        billing_details: {
          name,
        },
      }
    };
    this.financeServiceV2.setupIntent(this.financeServiceV2.usdAccount.id).pipe(
      take(1),
      mergeMap((response: SetupIntentResponseModel) => this.stripe.confirmCardSetup(response.clientSecret, options)),
    ).subscribe(this.handleCardAdded, this.spinnerService.stopSpinner);
  }

  ngOnDestroy() {}

  private handleProviderInfo = (providerInfo: ProviderInfoModel): void => {
    this.privateKey = providerInfo.stripeParam.pkey;
    this.setupStripeElement(providerInfo.stripeParam.pkey);
    this.form = AddPaymentMethodForm.createForm();
    this.spinnerService.stopSpinner();
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  private setupStripeElement(privateKey: string): void {
    this.stripe = Stripe(privateKey);
    const elements = this.stripe.elements();
    this.card = elements.create('card', stripeCardStyleConfig as any);
    this.card.mount(this.cardElementRef.nativeElement);
  }

  private handleCardAdded = (info: SetupIntentResponse): void => {
    if (info?.error) {
      this.snack.showNotification('FINANCE.CARD_NOT_ADDED', 'fail');
      this.spinnerService.stopSpinner();
      this.close();
      return;
    }

    this.spinnerService.stopSpinner();
    this.financeServiceV2.cardAdded().pipe(takeUntilDestroyed(this)).subscribe(
      () => {
        this.cardAdded.emit();
        this.snack.showNotification('FINANCE.CARD_ADDED', 'success');
      }
    );
    this.close();
  }
}
