<tpt-dialog>
  <div class="tpt-add-payment-method-dialog-root">
    <div class="add-payment-method-title">{{'FINANCE.ADD_PAYMENT_METHOD' | translate}}</div>

    <div *ngIf="form" class="add-payment-method-holder-name">
      <tpt-input
        [control]="form.name"
        [uppercase]="true"
        [placeholder]="'FINANCE.USE_LATIN' | translate"
      >
        <ng-container label>{{'FINANCE.CARD_HOLDER_NAME' | translate}}</ng-container>
      </tpt-input>
    </div>

    <div class="card-label">{{'FINANCE.CARD_NUMBER' | translate}}</div>

    <div class="add-payment-method-card-fields-row" #cardElement></div>

    <div class="add-payment-method-actions">
      <tpt-button
        type="secondary"
        (onClick)="cancel()"
      >
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button
        type="accent"
        class="ml-12"
        (onClick)="saveCard()"
      >
        {{'BUTTON.OK' | translate}}
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
