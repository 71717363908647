import { Injectable, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Cookies from 'js-cookie';
import { BdcWalkService } from 'bdc-walkthrough';
import { deserializationJacksonKey } from '../classes/jacksonUtils';
import { WebRtcService } from './webrtc.service';
import { ProfileManagementService } from './profile-management.service';
import { LogoutService } from './logout.service';
import { IdleService } from './idle.service';
import { NewWsService } from './new-ws.service';
import { FinanceServiceV2 } from '../modules/common-dialogs/services/finance.service';
import { ProfilesApiService } from './profiles.api.service';
import { ProfileModel } from '../models/profile.model';
import { SpinnerService } from '../modules/tpt-ui/services/spinner.service';
import { LogService } from './log.service';
import { AuthService, TokenData } from './auth.service';
import { SocialAuthService } from 'angularx-social-login';

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})

export class ProfileService {

  public startOnBoardingSubject = new Subject<void>();

  public switchToChats = new Subject<void>();

  public openInfo = new Subject<void>();

  public mainOnBoardingViewed = new BehaviorSubject<boolean>(true);

  public onBoardingInfo$ = new BehaviorSubject<any>(null);

  public onBoardingInfo = {
    isIncoming: false,
    isOutgoing: false,
    chatId: null,
    launched: false
  };

  get currentProfile(): ProfileModel {
    return this._currentProfile;
  }

  set currentProfile(value: ProfileModel) {
    this._currentProfile = value;
    this.currentProfile$.next(value);
  }

  public newProfileObject = new BehaviorSubject<ProfileModel>(null);

  public get isLoggedIn(): boolean {
    return Boolean(localStorage.getItem(TOKEN_KEY));
  }

  public currentProfile$: BehaviorSubject<ProfileModel> = new BehaviorSubject<ProfileModel>(this.currentProfile);

  @Input() public _currentProfile: ProfileModel;

  constructor(private logService: LogService,
              public profileManagementService: ProfileManagementService,
              public profileApiService: ProfilesApiService,
              private webRtcService: WebRtcService,
              private webSocket: NewWsService,
              private router: Router,
              private dialog: MatDialog,
              private idleService: IdleService,
              private translate: TranslateService,
              private auth: AuthService,
              private socialAuthService: SocialAuthService,
              private bdcService: BdcWalkService,
              private spinner: SpinnerService,
              private financeService: FinanceServiceV2) {
    LogoutService.registerService(this);

    const user: ProfileModel = JSON.parse(localStorage.getItem(USER_KEY));
    this._currentProfile = deserializationJacksonKey(ProfileModel, user);
  }

  public async refreshCurrentProfile() {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      return;
    }

    await this.getUserInfo();
    this.afterLogin();
  }

  public afterLogin(returnUrl?: string): void {
    const currentLocation = window.location.pathname + window.location.search + window.location.hash;
    returnUrl = returnUrl || currentLocation;
    if (this.currentProfile) {
      this.financeService.balanceUpdated$.next();
      this.webSocket.updateUnreadCount.next();
    }

    this.getChatOnBoardingInfo();

    this.webSocket.chatOnBoardingWs.subscribe((info) => {
        if (!info) {
          return;
        }
        if ((info.showFirstInviteChatOnBoarding && !this.currentProfile.isEmployer()) ||
          (info.showFirstProposalChatOnBoarding && this.currentProfile.isEmployer())) {

          const status = this.bdcService.getTaskCompleted('empInStepNext');
          if (!status) {
            this.bdcService.setTaskCompleted('empInStepNext', 1);
          }

          this.onBoardingInfo.isIncoming = true;
          this.onBoardingInfo.chatId = this.currentProfile.isEmployer() ?
            info.firstProposalChatId :
            info.firstInviteChatId;
        } else {
          if ((info.showFirstInviteChatOnBoarding && this.currentProfile.isEmployer()) ||
            (info.showFirstProposalChatOnBoarding && !this.currentProfile.isEmployer())) {

            const status = this.bdcService.getTaskCompleted('empOutStepNext');
            if (!status) {
              this.bdcService.setTaskCompleted('empOutStepNext', 1);
            }

            this.onBoardingInfo.isOutgoing = true;
            this.onBoardingInfo.chatId = this.currentProfile.isEmployer() ?
              info.firstInviteChatId :
              info.firstProposalChatId;
          }
        }
        this.onBoardingInfo.launched = true;
      }
    );

    this.webSocket.connect();

    if (returnUrl !== currentLocation) {
      this.router.navigateByUrl(returnUrl);
    }
  }

  async getUserInfo(): Promise<void> {
    try {
      const user = await this.profileApiService.getUserProfile().toPromise();

      this._currentProfile = deserializationJacksonKey(ProfileModel, user);
      localStorage.setItem(USER_KEY, JSON.stringify(user));

      this.newProfileObject.next(user);

      // if (['en', 'ru'].includes(user.interfaceLanguage)) {
      //   this.translate.use(user.interfaceLanguage);
      // } else {
      //   this.translate.use(localStorage.getItem('currentLang') || Cookies.get('lang') || 'en');
      // }
    } catch (err) {
      console.log(err);
    }
  }

  public getChatOnBoardingInfo(): void {
    if (!this.currentProfile) {
      return;
    }
    this.profileApiService.getOnBoardings().subscribe((response) => {
      this.onBoardingInfo$.next(response);

      this.mainOnBoardingViewed.next(response.onBoardingViewed);

      if (!response?.onBoardings?.PROFILE) {
        this.bdcService.setTaskCompleted('startProfileTour', true);
      }

      if (response.onBoardingViewed) {
        this.bdcService.setTaskCompleted('dialogWelcome', false);
      }

      if ((response.showFirstInviteChatOnBoarding && !this.currentProfile.isEmployer()) ||
        (response.showFirstProposalChatOnBoarding && this.currentProfile.isEmployer())) {
        const status = this.bdcService.getTaskCompleted('empInStepNext');
        if (!status) {
          this.bdcService.setTaskCompleted('empInStepNext', 1);
        }

        this.onBoardingInfo.isIncoming = true;
        this.onBoardingInfo.chatId = this.currentProfile.isEmployer() ?
          response.firstProposalChatId :
          response.firstInviteChatId;
        this.onBoardingInfo.launched = true;
      } else {
        if ((response.showFirstInviteChatOnBoarding && this.currentProfile.isEmployer()) ||
          (response.showFirstProposalChatOnBoarding && !this.currentProfile.isEmployer())) {
          const status = this.bdcService.getTaskCompleted('empOutStepNext');
          if (!status) {
            this.bdcService.setTaskCompleted('empOutStepNext', 1);
          }

          this.onBoardingInfo.isOutgoing = true;
          this.onBoardingInfo.chatId = this.currentProfile.isEmployer() ?
            response.firstInviteChatId :
            response.firstProposalChatId;
          this.onBoardingInfo.launched = true;
        }
      }
    });
  }

  public logout(retURL?: boolean) {
    this.webSocket.disconnect();
    this.currentProfile = null;
    this.newProfileObject.next(null);
    this.auth.deleteRefreshToken().subscribe();
    this.socialAuthService.signOut().then();
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESHTOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    this.dialog.closeAll();
    if (!this.router.url.startsWith('/login')) {
      this.router.navigate(['/login'], (retURL ? {queryParams: {returnUrl: this.router.url}} : undefined));
    }
    this.spinner.stopSpinner();
  }

  public updateToken(data: TokenData): void {
    localStorage.setItem(TOKEN_KEY, data.access_token);
    localStorage.setItem(REFRESHTOKEN_KEY, data.refresh_token);
  }
}
