
export class LanguageChangeEventModel {

  public event = 'language';

  constructor(
    public lang: 'ru' | 'en' | string,
  ) { }

}
