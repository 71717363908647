import { Observable, Subject, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDestroyedStreamOptions } from './interfaces';
import {
  ɵComponentType as ComponentType,
  ɵDirectiveType as DirectiveType,
  ɵComponentDef as ComponentDef,
  ɵDirectiveDef as DirectiveDef,
  InjectableType,
} from '@angular/core';

const DESTROYED_STREAM_DEFAULT_NAME = '_destroyed_';
const DEFAULT_DESTROY_METHOD_NAME = 'ngOnDestroy';
const DECORATOR_APPLIED = Symbol('UntilDestroy');

/**
 * Decorate component or directive that uses `takeUntilDestroyed` operator. Required for proper operator work
 */
export function DecorateUntilDestroy(): ClassDecorator {
  return (target: any) => {
    const destroyedStreamName = getDestroyStreamName(DEFAULT_DESTROY_METHOD_NAME);

    if (isInjectableType(target)) {
      target.prototype.ngOnDestroy = getNewDestroyMethod(target.prototype.ngOnDestroy, destroyedStreamName);
    } else {
      const def = getDef(target);

      // non-ivy mode
      if (!def) {
        return;
      }

      (def as any).onDestroy = getNewDestroyMethod(def.prototype.onDestroy, destroyedStreamName);

      (def as any)[DECORATOR_APPLIED] = true;
    }
  };
}

export function takeUntilDestroyed<T>(target: any, options: IDestroyedStreamOptions = {}): MonoTypeOperatorFunction<T> {
  const destroyMethodName = options.destroyMethod
    ? getClassMethodName(target, options.destroyMethod) || DEFAULT_DESTROY_METHOD_NAME
    : DEFAULT_DESTROY_METHOD_NAME;
  // NOTE: we have to separate destroy streams based on used destroy method
  const destroyedStreamName = getDestroyStreamName(destroyMethodName);

  const def = getDef(target.constructor);

  if (!options.destroyMethod && def) {

    if (!def[DECORATOR_APPLIED]) {
      throwError(target, `Missed '@DecorateUntilDestroy' decorator usage`);
    }
  }

  if ((options.destroyMethod || !def) && !target[destroyedStreamName]) {
    if (!target[destroyMethodName]) {
      throwError(target, `Missed destroy method '${destroyMethodName}'`);
    }

    target[destroyMethodName] = getNewDestroyMethod(target[destroyMethodName], destroyedStreamName);
  }

  if (!target[destroyedStreamName]) {
    target[destroyedStreamName] = new Subject<void>();
  }

  return (source: Observable<T>) => {
    return source.pipe(takeUntil(target[destroyedStreamName]));
  };
}

function throwError(target: any, textPart: string): void {
  throw new Error(`takeUntilDestroyed: ${textPart} in '${target.constructor.name}'`);
}

export function getClassMethodName(classObj: any, method: Function): string | null {
  const methodName = Object.getOwnPropertyNames(classObj).find(prop => classObj[prop] === method);

  if (methodName) {
    return methodName;
  }

  const proto = Object.getPrototypeOf(classObj);
  if (proto) {
    return getClassMethodName(proto, method);
  }

  return null;
}

function getNewDestroyMethod(
  originalDestroy: ((...args: any[]) => any) | null | undefined,
  destroyedStreamName: string,
): () => any {
  return function(this: any, ...args: any[]): any {
    let result: any | undefined;

    if (originalDestroy) {
      result = originalDestroy.call(this, ...args);
    }

    if (this[destroyedStreamName]) {
      this[destroyedStreamName].next();
    }

    return result;
  };
}

function getDestroyStreamName(destroyMethodName: string): string {
  return DESTROYED_STREAM_DEFAULT_NAME + destroyMethodName;
}

export function getDef<T>(type: DirectiveType<T> | ComponentType<T>): DirectiveType<T> | ComponentType<T> {
  return type;
}

export function isInjectableType(target: any): target is InjectableType<unknown> {
  return !!target.ngInjectableDef;
}
