import {Type} from 'class-transformer';
import {JobState, JobVisibility} from '../../classes/enums';
import {SimpleJob} from './simpleJob';

export class MasterJob extends SimpleJob {
  public status: JobState;

  @Type(() => Date)
  public modifiedAt: Date;

  public jobVisibility: JobVisibility;
}
