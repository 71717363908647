import { Injectable, Injector, OnDestroy } from '@angular/core';
import {RxStompService, StompHeaders, StompRService} from '@stomp/ng2-stompjs';
import {environment} from '../../environments/environment';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../helpers/rxjs/take-until-destroyed';
import {Profile} from '../models/profile/profile';
import {LogService} from '../services/log.service';
import {ProfileService} from '../services/profile.service';
import {Role} from './enums';

@DecorateUntilDestroy()
@Injectable()
export abstract class RabbitMQConnection implements OnDestroy {

  public profileService: ProfileService;
  public rxStompService: RxStompService;
  public profile: Profile;

  protected logService: LogService;
  protected headers: StompHeaders = {
    ack: 'client-individual',
  };
  protected wss;
  protected host = 'chatRooms';

  constructor(injector: Injector) {
    this.profileService = injector.get(ProfileService);
    this.rxStompService = injector.get(StompRService);
    this.logService = injector.get(LogService);

    // this.profileService.currentProfile$.pipe(
    //   takeUntilDestroyed(this)
    // ).subscribe((profile) => {
    //   if (profile && profile.rabbitmqHash && profile.role !== Role.ADMIN) {
    //     if (!this.profile || this.profile.id !== profile.id || this.profile.rabbitmqHash !== profile.rabbitmqHash) {
    //       this.profile = profile;
    //       this.init();
    //     }
    //   } else {
    //     this.profile = null;
    //     this.disconnect();
    //   }
    // });

    this.rxStompService.connected$.pipe(
      takeUntilDestroyed(this),
    ).subscribe((_) => {
      this.onConnect();
    });
  }

  public ngOnDestroy(): void {
    this.disconnect();
  }

  public abstract onConnect(): void;

  public abstract onDisconnect(): void;

  public abstract init(): void;

  protected disconnect() {
    if (this.rxStompService.connected) {
      this.rxStompService.deactivate();
    }
    this.onDisconnect();
  }

  protected connect(): void {
    this.disconnect();
    if (this.profile) {
      this.rxStompService.configure({
        brokerURL: `${environment.WEB_SOCKET_URL}:${environment.STOMP_PORT}/ws`,
        connectHeaders: {
          login: this.profile.email,
          passcode: this.profile.rabbitmqKey,
          host: this.host,
        },
        // How often to heartbeat?
        // Interval in milliseconds, set to 0 to disable
        heartbeatIncoming: 0, // Typical value 0 - disabled
        heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

        // Wait in milliseconds before attempting auto reconnect
        // Set to 0 to disable
        // Typical value 5000 (5 seconds)
        reconnectDelay: 5000,

        // Will log diagnostics on console
        // It can be quite verbose, not recommended in production
        // Skip this key to stop logging to console
        debug: (str) => {
          // console.log(new Date(), str);
        }
      });
      this.rxStompService.activate();
    }
  }

}
