import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { LogService } from './services/log.service';
import { ProfileService } from './services/profile.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DateAdapter } from '@angular/material/core';
import { DataLayerService } from './services/data-layer.service';
import { filter, take } from 'rxjs/operators';
import { Profile } from './models/profile/profile';
import { UserLoggedInModel } from './models/data-layer/user-logged-in.model';
import { IconsService } from './services/icons.service';
import { RouteHelper } from './helpers/route.helper';
import { BdcWalkDialogComponent } from 'bdc-walkthrough';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfilesApiService } from './services/profiles.api.service';
import { Title, Meta } from '@angular/platform-browser';
import Cookies from 'js-cookie';
import moment from 'moment';

@Component({
  selector: 'tpt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public isEmployer = false;

  public loaded = false;

  public blogs = false;

  public hideHeader = true;

  public hideSidebar = false;

  public hideHeaderOnMainPage = true;

  public isMobile = false;

  @ViewChild(BdcWalkDialogComponent) bdcDialog: BdcWalkDialogComponent;

  @ViewChild(SidebarComponent) sidebar: SidebarComponent;

  constructor(
    public profileService: ProfileService,
    private router: Router,
    private translate: TranslateService,
    private logService: LogService,
    private overlayContainer: OverlayContainer,
    private adapter: DateAdapter<any>,
    private titleService: Title,
    private meta: Meta,
    private dataLayerService: DataLayerService,
    private iconsService: IconsService,
    private profileApiService: ProfilesApiService,
  ) {
    iconsService.registerIcons();
    translate.addLangs(['en', 'se']);
    translate.setDefaultLang('en');
    translate.use('en');

    translate.onLangChange
      .subscribe((value) => {
        this.adapter.setLocale(value.lang);
        // this.titleService.setTitle(value.lang === 'ru' ?
        //   'Talent Powered Team | Биржа фриланса с самой низкой комиссией в России' :
        //   'Talent Powered Team | Freelancers search and payment service');
        // this.meta.updateTag({ name: 'description', content: value.lang === 'ru' ?
        // 'Безопасная оплата фрилансерам во все страны, гарантия результата. Бесплатная платформа для фрилансеров. Самая низкая комиссия для заказчиков. Инструменты для работы над проектами' :
        // 'No fees for freelancers. Secure payment in all countries. Guaranteed refunds. Hire experts form 1000+ professional fields. Build remote teams and execute the projects together'
        // });
        Cookies.set('lang', value.lang);
      });

    if (environment.MAIN_HOST.indexOf('localhost') > -1) {
      logService.warn(environment.MAIN_HOST + ' valid only for DEVELOPMENT');
    }

    if (environment.STOMP_PORT === 10678) {
      logService.warn(environment.STOMP_PORT + ' valid only for DEVELOPMENT');
    }

    this.overlayContainer.getContainerElement().classList.add('xln');
    profileService.currentProfile$.subscribe((profile) => {
        this.isEmployer = profile && profile.isEmployer();
        if (this.isEmployer) {
          this.overlayContainer.getContainerElement().classList.add('is-employer');
        } else {
          this.overlayContainer.getContainerElement().classList.remove('is-employer');
        }
        // if (!this.profileService.currentProfile) {
        //   const lang = localStorage.getItem('currentLang') || Cookies.get('lang');
        //   if (lang) {
        //     translate.use(lang);
        //   } else {
        //     const browserLang = translate.getBrowserLang();
        //     translate.use(browserLang.match(/ru|en/) ? browserLang : 'en');
        //   }
        // }
        this.loaded = true;
      });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.hideHeader = val.url === '/' ||
          val.url.startsWith('/login') ||
          val.url.startsWith('/forgotPassword') ||
          val.url.includes('/book-demo') ||
          val.url.includes('/confirm-success') ||
          val.url.includes('/check-list') ||
          val.url.includes('/rsp') ||
          val.url.includes('/gp-landing') ||
          (val.url.includes('/profiles') && !this.profileService?.currentProfile) ||
          (val.url.includes('/projects') && !this.profileService?.currentProfile) ||
          val.url.includes('/register');

        this.hideHeaderOnMainPage = val.url.includes('/rsp') || val.url.includes('/check-list') ||
          val.url.includes('/gp-landing') || val.url === '/';

        this.hideSidebar = val.url === '/';
      }

      if (val instanceof NavigationEnd && val.urlAfterRedirects === '/') {
        this.hideHeader = true;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.profileService.refreshCurrentProfile();

    if (this.profileService.isLoggedIn) {
      this.profileService.currentProfile$.pipe(
        filter(Boolean),
        take(1),
      ).subscribe(this.handleUserProfile);

    } else {
      this.dataLayerService.pushToDataLayer({ lang: `${this.translate.currentLang}` });
    }

    console.log(`Application version is: ${environment.timeStamp || moment().format('DD.MM HH:mm')}`);
  }

  public goToProjects(): void {
    if (this.profileService.currentProfile.isEmployer()) {
      this.router.navigate([RouteHelper.PROJECTS_DASHBOARD_PATH]);
    } else {
      this.router.navigate([RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH]);
    }
  }

  public skipTour(): void {
    if (this.profileService.currentProfile.isEmployer()) {
      this.bdcDialog.close({
        onBoardingEmployerNext: 6,
        onBoardingEmployerStep1: true,
        onBoardingEmployerStep2: true,
        onBoardingEmployerStep3: true,
        onBoardingEmployerStep4: true,
        onBoardingEmployerStep5: true,
        onBoardingEmployerStep6: true,
      });
    } else {
      this.bdcDialog.close({
        onBoardingFreelancerNext: 5,
        onBoardingFreelancerStep1: true,
        onBoardingFreelancerStep2: true,
        onBoardingFreelancerStep3: true,
        onBoardingFreelancerStep4: true,
        onBoardingFreelancerStep5: true,
      });
    }
    this.profileApiService.viewOnBoardings('MAIN').subscribe();

    this.goToProjects();
  }

  public startTour(): void {
    this.bdcDialog.close();
    this.sidebar.expanded = true;
    this.goToProjects();
  }

  get getImage(): string {
    const lang = this.translate.currentLang;
    if (!lang) { return; }
    return `assets/images/onboarding-${lang}.png`;
  }

  private handleUserProfile = (profile: Profile): void => {
    const { interfaceLanguage, id, role } = profile;
    const userType = profile.analyticsCustomerTypeByRole(role);
    const model = new UserLoggedInModel(String(id), userType, interfaceLanguage as 'en' | 'ru' || 'en');
    this.dataLayerService.pushToDataLayer(model);
  }

}
