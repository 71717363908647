<div class="note" [class.-active]="activeNote">
  <div class="note-header">
    <span class="time">
      {{ noteCreateTime }}
    </span>
    <mat-icon
      *ngIf="activeNote"
      [svgIcon]="svgIconsEnum.Cross"
      (click)="deleteNote.emit(note)"
    ></mat-icon>
  </div>
  <div class="note-text">
    {{ note?.text | tptTruncate: [109] }}
  </div>
</div>
