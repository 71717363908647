<tpt-dialog>
  <div class="dialog-root">
    <div class="headline">
      {{'TRACKER.OPENING_ARBITRATION' | translate}}
    </div>
    <mat-stepper #stepper (selectionChange)="updateIndex($event)">
      <mat-step>
        <ng-template matStepLabel>{{'TRACKER.INFORMATION' | translate}}</ng-template>
        <div class="info-text regular mt-16">
          {{(isEmployer ? 'TRACKER.EMP_ARBITRATION_INFO' : 'TRACKER.FR_ARBITRATION_INFO') | translate}}
        </div>
        <div class="info-grid">
          <div>
            <div class="label">{{'TRACKER.ARB_DURATION_LABEL' | translate}}</div>
            {{'TRACKER.ARB_DURATION' | translate}}
          </div>
          <div>
            <div class="label">{{'TRACKER.DISPUTE_PERIOD_LABEL' | translate}}</div>
            {{'TRACKER.DISPUTE_PERIOD' | translate}}
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>{{'TRACKER.CAUSE' | translate}}</ng-template>
        <div class="description-block">
          <div>
            <div class="regular cause-label">
              {{'TRACKER.SITUATION_DESCRIPTION' | translate}}
            </div>
            <mat-form-field [class.error]="reasonError && !reason.trim()"
                            floatLabel="never" appearance="outline" class="mb-8">
            <textarea
              matInput
              class="textarea-control"
              [(ngModel)]="reason"
              [placeholder]="'TRACKER.PLACEHOLDER_DESCRIPTION' | translate"
            ></textarea>
            </mat-form-field>
          </div>

          <tpt-drag-n-drop [files]="files"
                           size="small"
                           (deleteFileEvent)="deleteFile($event)"
                           (uploadFileChange)="uploadFile($event)"
          >
          </tpt-drag-n-drop>
        </div>

      </mat-step>
      <mat-step>
        <ng-template matStepLabel>{{'TRACKER.PAYMENT' | translate}}</ng-template>
        <div>
          <div class="paid-status" *ngIf="paymentInfo?.prepaid; else notPaid">
            {{'TRACKER.MEDIATION_PAID' | translate}} <mat-icon [svgIcon]="svgIconsEnum.ConfirmSuccess"></mat-icon>
          </div>
          <ng-template #notPaid>
            <div class="paid-status">{{'TRACKER.MEDIATION_NOT_PAID' | translate}}</div>
          </ng-template>

          <div class="amount-wrapper">
            <div class="job-cost">
              {{'TRACKER.JOB_COST' | translate}}
              <div>{{currencySymbol[paymentInfo?.currency?.code]}}{{paymentInfo?.jobAmount}}</div>
            </div>
            <div class="mediation-cost">
              {{'TRACKER.MEDIATION_COST' | translate}}
              <div>{{currencySymbol[paymentInfo?.currency?.code]}}{{paymentInfo?.arbitrageAmount}}</div>
            </div>
          </div>

          <div class="caption mb-20">
            {{'TRACKER.MEDIATION_INFO' | translate}}
          </div>
        </div>
      </mat-step>
    </mat-stepper>

    <div class="actions-row" [ngSwitch]="activeStep">
      <ng-container *ngSwitchCase="0">
        <tpt-button type="secondary" class="cancel" (onClick)="close()">
          {{'BUTTON.CANCEL' | translate}}
        </tpt-button>
        <tpt-button type="primary" (onClick)="stepper.next()">
          {{'BUTTON.NEXT' | translate}}
        </tpt-button>
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <tpt-button type="secondary" class="cancel" (onClick)="stepper.previous()">
          {{'BUTTON.BACK' | translate}}
        </tpt-button>
        <tpt-button type="primary" (onClick)="stepper.next()">
          {{'BUTTON.NEXT' | translate}}
        </tpt-button>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <tpt-button type="secondary" class="cancel" (onClick)="stepper.previous()">
          {{'BUTTON.BACK' | translate}}
        </tpt-button>
        <tpt-button type="primary" (onClick)="openMediation()">
          {{(paymentInfo?.prepaid ? 'TRACKER.OPEN_MEDIATION' : 'TRACKER.PAY_AND_OPEN_MEDIATION') | translate}}
        </tpt-button>
      </ng-container>
    </div>
  </div>
</tpt-dialog>

<tpt-failure-dialog>
  <ng-container mainText>
    {{ 'FINANCE.MAKE_PAYMENT_DIALOG.PAYMENT_FAIL_TITLE' | translate }}
  </ng-container>
  <ng-container secondaryText>
    {{ 'FINANCE.PLEASE' | translate }},
    <span class="top-up-balance" (click)="openDepositFundsDialog()">{{ 'FINANCE.MAKE_PAYMENT_DIALOG.PAYMENT_FAIL_TEXT' | translate }}</span>
  </ng-container>
</tpt-failure-dialog>

<tpt-deposit-funds-dialog [isPaymentFlow]="true" (depositIsSuccess)="depositIsSuccess()"></tpt-deposit-funds-dialog>
