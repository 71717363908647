<ng-container *ngIf="file">
  <div [matMenuTriggerFor]="menu"
       #trigger="matMenuTrigger"
       class="file"
       [ngClass]="{
                    '_added': status == ChangeHistoryStatus.ADDED,
                    '_changed': status == ChangeHistoryStatus.CHANGED,
                    '_deleted': status == ChangeHistoryStatus.DELETED,
                    '_force_deleted': forceDeleted
                  }">

    <div class="file__icon-wrapper">
      <mat-icon class="file__icon">get_app</mat-icon>
    </div>
    <div class="file__name">{{filename}}</div>
  </div>

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item (click)="viewFile()" [disabled]="true">
        <span>{{ 'FILE_VIEWER_COMPONENT.VIEW' | translate }}</span>
      </button>
      <button mat-menu-item (click)="download()">
        <span>{{ 'FILE_VIEWER_COMPONENT.DOWNLOAD' | translate }}</span>
      </button>
      <ng-container *ngIf="status != ChangeHistoryStatus.DELETED">
        <button *ngIf="shouldAllowAddVersion" mat-menu-item class="file-viewer-v2__menu-upload">
          <xln-file-uploader [contentId]="file.contentId"
                             [contentType]="file.contentType"
                             [onlyButton]="true"
                             [icon]="null"
                             [title]="'FILE_VIEWER_COMPONENT.UPDATE' | translate"
                             [documentId]="file.id"
                             [diffKey]="diffKey"
                             (uploadedFilesChange)="closeMenu($event)">
          </xln-file-uploader>
        </button>
        <button mat-menu-item *ngIf="allowRename" (click)="rename()">
          <span>{{ 'FILE_VIEWER_COMPONENT.RENAME' | translate }}</span>
        </button>
        <button mat-menu-item *ngIf="shouldShowHistory" (click)="toggleHistory()">
          <span>{{file.isHistoryShown ? ('FILE_VIEWER_COMPONENT.HISTORY_HIDE' | translate) :
            ('FILE_VIEWER_COMPONENT.HISTORY_SHOW' | translate) }}</span>
        </button>
        <button mat-menu-item *ngIf="allowDelete" (click)="deleteUpload()">
          <span>{{ 'COMMON.DELETE' | translate }}</span>
        </button>
      </ng-container>
      <button mat-menu-item *ngIf="status == ChangeHistoryStatus.DELETED && allowDelete"
              (click)="restoreUpload()">
        <span>{{ 'COMMON.RESTORE' | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-container>
