<tpt-dialog>
  <div class="remove-dialog-root">
    <div class="dialog-title">{{'FINANCE.DISCONNECT_PAYONEER' | translate}}</div>
    <div class="dialog-text" [innerHTML]="'FINANCE.DISCONNECT_PAYONEER_TEXT' | translate"></div>
    <div class="actions-row">
      <button mat-button class="cancel" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
      <button mat-button class="confirm" (click)="confirmDelete()">{{'BUTTON.DISCONNECT' | translate}}</button>
    </div>
  </div>
</tpt-dialog>
