<div class="rename-dialog">
  <h1 class="rename-dialog__title">{{title | translate}}</h1>
  <button mat-icon-button class="close-btn close-dialog" [mat-dialog-close]="null" [disabled]="loading">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
  <form class="rename-dialog__form" name="form"
        (ngSubmit)="rename(f.form.valid)" #f="ngForm" novalidate>
    <mat-form-field class="rename-dialog__input-container">
      <input matInput class="rename-dialog__input" data-placeholder="{{ 'RENAME_DIALOG_COMPONENT.NAME' | translate }}"

             name="name" [maxlength]="maxLength > 0 ? maxLength : 524288" #nameInput
             [(ngModel)]="name" [required]="!allowEmpty"/>
        <mat-hint *ngIf="maxLength > 0" align="start"><strong>{{ 'COMMON.MAX_CHARACTERS' | translate }}</strong></mat-hint>
        <mat-hint *ngIf="maxLength > 0" align="end">{{nameInput.value.length}} / 100</mat-hint>
    </mat-form-field>

    <div class="rename-dialog__action">
      <button mat-flat-button color="primary" class="rename-dialog__action-btn">{{ 'COMMON.SAVE' | translate }}</button>
    </div>
  </form>
</div>
