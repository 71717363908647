import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniquePipe } from './pipes/unique-pipe';
import { DateRangePipe } from './pipes/date-range.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { LocalCurrencyPipe } from './pipes/local-currency.pipe';
import { TimeAgoPipe } from './pipes/time-ago-pipe';
import { EndingsPipe } from './pipes/endings.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DetectUrlPipe } from './pipes/detect-url.pipe';

@NgModule({
  declarations: [
    DateRangePipe,
    LocalDatePipe,
    LocalCurrencyPipe,
    TimeAgoPipe,
    UniquePipe,
    EndingsPipe,
    TruncatePipe,
    DetectUrlPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DateRangePipe,
    LocalDatePipe,
    LocalCurrencyPipe,
    TimeAgoPipe,
    UniquePipe,
    EndingsPipe,
    TruncatePipe,
    DetectUrlPipe,
  ]
})
export class CommonPipesModule {
}
