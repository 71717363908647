import {WebRtcParticipantStatus} from '../enums';

export class ParticipantStatusChangedMessage {
  public userHash: number;
  public status: WebRtcParticipantStatus;
  public message = '';
  public userFullName = '';

  constructor(userHash: number, status: WebRtcParticipantStatus, userFullName, message: string = '') {
    this.userHash = userHash;
    this.userFullName = userFullName;
    this.message = message;
    this.status = status;
  }
}
