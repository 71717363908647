import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { CommonShareModule } from '../common-share.module';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { FileComponent } from './components/file/file.component';

@NgModule({
  declarations: [
    FileComponent,
    FileViewerComponent,
    FileUploaderComponent,
  ],
  imports: [
    CommonModule,
    CommonShareModule,
    NgxUploaderModule,
  ],
  exports: [
    FileComponent,
    FileViewerComponent,
    FileUploaderComponent,
  ],
})
export class FileModule {
}
