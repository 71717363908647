<div class="assignee">
  <div class="user-avatar" *ngIf="task?.assignee">
    <tpt-avatar [textSizeRatio]="2.5" size="32" [user]="task?.assignee"></tpt-avatar>
  </div>

  <div class="name" [class.wide]="!task.assignee">
    {{ task?.assignee?.firstName }} {{ task?.assignee?.lastName }}
    <div class="category" *ngIf="task?.categoryInfo">{{getCategory()}}</div>
  </div>

</div>
