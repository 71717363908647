import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { fromPromise } from 'rxjs/internal-compatibility';
import { delay } from 'rxjs/operators';

export interface MediationModel {
  reason?: string;
  reopenReason?: string;
  taskId: number;
  fileIds?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class ArbitrationService extends BaseService {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public startArbitrage = (body: MediationModel): Observable<any> => {
    return fromPromise(this.executePost<any>(`v2/arbitrage`, body));
  }

  public getPaymentInfo = (taskId: number): Observable<any> => {
    return this.executeGet<any>(`v2/arbitrage/payment`, {params: {taskId}});
  }

  public reopenArbitrage = (body: MediationModel): Observable<any> => {
    return fromPromise(this.executePost<any>(`v2/arbitrage/reopen`, body));
  }

}
