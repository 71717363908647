<footer class="footer">
    <div class="footer__container">
        <div class="footer__logo">
            <a class="footer-top__logo" href="/">
                <img src="../../../assets/logo/logo_footer.svg " alt="" />
            </a>
        </div>
        <!--    <div class="footer__links">-->
        <!--      <a href="https://talentpowered.team/browse-projects">{{ 'FOOTER.BROWSE_PROJECTS' | translate }}</a>-->
        <!--      <a href="https://talentpowered.team/browse-freelancers">{{ 'FOOTER.BROWSE_FREELANCERS' | translate }}</a>-->
        <!--    </div>-->
        <div class="footer__links">
            <a href="/legal/terms">{{ 'FOOTER.TERMS_AND_COND' | translate }}</a>
            <a href="/legal/privacy">{{ 'FOOTER.PRIVACY_POLICY' | translate }}</a>
        </div>
        <div class="footer__links">
            <a href="mailto:{{ 'FOOTER.SUPPORT_MAIL' | translate | lowercase }}">{{
                'FOOTER.CONTACT_US' | translate
            }}</a>
            <a href="/blogs">{{ 'FOOTER.BLOGS' | translate }}</a>
        </div>
        <div class="footer__subscribe">
            <form action="" class="footer-form">
                <input
                    type="email"
                    class="footer-form__input"
                    placeholder="{{ 'FOOTER.SUBSCRIBE_EMAIL' | translate }}"
                    name="subscribeEmail"
                    [(ngModel)]="subscribeEmail"
                />
                <button type="submit" class="footer-form__submit" (click)="subscribe()">
                    {{ 'FOOTER.SUBSCRIBE' | translate }}
                </button>
            </form>
            <div class="footer-form__unsubscribe">
                <a routerLink="/subscription/unsubscribe">{{ 'FOOTER.UNSUBSCRIBE' | translate }}</a>
            </div>
        </div>
    </div>
    <div class="footer-copy">
        <div class="footer-copy__text">
            <p>
                {{ 'FOOTER.COPYRIGHT' | translate }}
            </p>
        </div>
        <div class="footer-copy__social">
            <span>{{ 'FOOTER.FOLLOW' | translate }}</span>
            <ul>
<!--                <li class="instagramm">-->
<!--                    <a href="https://www.instagram.com/talentpowered.team/" target="_blank">-->
<!--                        <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <g>-->
<!--                                <g>-->
<!--                                    <path-->
<!--                                        d="M10.733 2.411c.467 0 .856.389.856.856a.863.863 0 0 1-.856.855.863.863 0 0 1-.855-.855c0-.467.389-.856.855-.856M7 9.333c1.322 0 2.333-1.01 2.333-2.333 0-1.322-1.01-2.333-2.333-2.333-1.322 0-2.333 1.01-2.333 2.333 0 1.322 1.01 2.333 2.333 2.333m0-5.91A3.568 3.568 0 0 1 10.578 7 3.568 3.568 0 0 1 7 10.578 3.568 3.568 0 0 1 3.422 7c0-2.022 1.556-3.578 3.578-3.578M7 0c1.867 0 2.178 0 2.878.078.778 0 1.244.155 1.71.31.468.156.856.39 1.245.779.39.389.623.777.778 1.244.156.467.311.933.311 1.711C14 4.822 14 5.133 14 7c0 1.867-.078 2.1-.078 2.878s-.155 1.244-.31 1.71a3.045 3.045 0 0 1-.779 1.245 3.045 3.045 0 0 1-1.244.778c-.467.156-.933.311-1.711.311C9.178 14 8.867 14 7 14c-1.867 0-2.178 0-2.878-.078-.778 0-1.244-.155-1.71-.31a3.045 3.045 0 0 1-1.245-.779 3.045 3.045 0 0 1-.778-1.244c-.156-.467-.311-.933-.311-1.711C0 9.178 0 8.867 0 7c0-1.867 0-2.178.078-2.878 0-.778.078-1.244.31-1.71.156-.468.39-.856.779-1.245A3.045 3.045 0 0 1 2.41.389c.467-.156.933-.311 1.711-.311C4.822 0 5.133 0 7 0m0 1.244c-1.867 0-2.1 0-2.8.078-.7 0-1.089.156-1.322.234-.311.077-.545.233-.778.544a2.794 2.794 0 0 0-.544.778c-.078.233-.234.622-.234 1.322-.078.7-.078.933-.078 2.8 0 1.867.078 2.1.078 2.8 0 .7.156 1.089.234 1.322.155.311.31.545.544.778.233.233.467.389.778.544.233.078.622.234 1.322.234.7.078.933.078 2.8.078 1.867 0 2.1-.078 2.8-.078.7 0 1.089-.156 1.322-.234.311-.155.545-.31.778-.544.233-.233.389-.467.544-.778.078-.233.234-.622.234-1.322.078-.7.078-.933.078-2.8 0-1.867-.078-2.1-.078-2.8 0-.7-.156-1.089-.234-1.322A2.794 2.794 0 0 0 11.9 2.1a2.794 2.794 0 0 0-.778-.544c-.233-.078-.622-.234-1.322-.234-.7-.078-.933-.078-2.8-.078"-->
<!--                                        fill="#fff"-->
<!--                                    />-->
<!--                                </g>-->
<!--                            </g>-->
<!--                        </svg>-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li class="facebook">-->
<!--                    <a href="https://www.facebook.com/Talent-Powered-113816080389192/" target="_blank">-->
<!--                        <svg height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <g>-->
<!--                                <g>-->
<!--                                    <path-->
<!--                                        d="M4.744 14V7.622h2.178l.311-2.489H4.744V3.578c0-.7.234-1.245 1.245-1.245H7.31V.078C7 .078 6.222 0 5.367 0 3.5 0 2.177 1.167 2.177 3.267v1.866H0v2.49h2.178V14z"-->
<!--                                        fill="#fff"-->
<!--                                    />-->
<!--                                </g>-->
<!--                            </g>-->
<!--                        </svg>-->
<!--                    </a>-->
<!--                </li>-->
                <li class="linkedin">
                    <a href="https://www.linkedin.com/company/talentpowered/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                                fill="#fff"
                            />
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <!--  <div class="container">-->
    <!--          <a class="footer-top__logo" href="/">-->
    <!--              <img src="../../../assets/logo/logo_footer.svg" alt="">-->
    <!--          </a>-->
    <!--          <div class="footer-nav">-->
    <!--              <ul>-->
    <!--                  <li><a routerLink="/browse-projects">Browse Projects</a></li>-->
    <!--                  <li><a routerLink="/browse-freelancers">Browse Freelancers</a></li>-->
    <!--                  <li><a href="">Privacy & Policy</a></li>-->
    <!--                  <li><a href="/blogs">Blogs</a></li>-->
    <!--              </ul>-->
    <!--          </div>-->
    <!--          <form  action="" class="footer-form">-->
    <!--              <input type="email" class="footer-form__input" placeholder="Your email">-->
    <!--              <button type="submit" class="footer-form__submit">Subscribe</button>-->
    <!--          </form>-->
    <!--          <div class="footer__unsubscribe">-->
    <!--            <a routerLink="/subscription/unsubscribe">Unsubscribe</a>-->
    <!--          </div>-->
    <!--          <div class="footer-social">-->
    <!--              <span>Follow Us</span>-->
    <!--              <ul>-->
    <!--                  <li>-->
    <!--                      <a href="" target="_blank">-->
    <!--                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">-->
    <!--                              <g>-->
    <!--                                  <g>-->
    <!--                                      <path fill="#fff"-->
    <!--                                            d="M10.733 2.411c.467 0 .856.389.856.856a.863.863 0 0 1-.856.855.863.863 0 0 1-.855-.855c0-.467.389-.856.855-.856M7 9.333c1.322 0 2.333-1.01 2.333-2.333 0-1.322-1.01-2.333-2.333-2.333-1.322 0-2.333 1.01-2.333 2.333 0 1.322 1.01 2.333 2.333 2.333m0-5.91A3.568 3.568 0 0 1 10.578 7 3.568 3.568 0 0 1 7 10.578 3.568 3.568 0 0 1 3.422 7c0-2.022 1.556-3.578 3.578-3.578M7 0c1.867 0 2.178 0 2.878.078.778 0 1.244.155 1.71.31.468.156.856.39 1.245.779.39.389.623.777.778 1.244.156.467.311.933.311 1.711C14 4.822 14 5.133 14 7c0 1.867-.078 2.1-.078 2.878s-.155 1.244-.31 1.71a3.045 3.045 0 0 1-.779 1.245 3.045 3.045 0 0 1-1.244.778c-.467.156-.933.311-1.711.311C9.178 14 8.867 14 7 14c-1.867 0-2.178 0-2.878-.078-.778 0-1.244-.155-1.71-.31a3.045 3.045 0 0 1-1.245-.779 3.045 3.045 0 0 1-.778-1.244c-.156-.467-.311-.933-.311-1.711C0 9.178 0 8.867 0 7c0-1.867 0-2.178.078-2.878 0-.778.078-1.244.31-1.71.156-.468.39-.856.779-1.245A3.045 3.045 0 0 1 2.41.389c.467-.156.933-.311 1.711-.311C4.822 0 5.133 0 7 0m0 1.244c-1.867 0-2.1 0-2.8.078-.7 0-1.089.156-1.322.234-.311.077-.545.233-.778.544a2.794 2.794 0 0 0-.544.778c-.078.233-.234.622-.234 1.322-.078.7-.078.933-.078 2.8 0 1.867.078 2.1.078 2.8 0 .7.156 1.089.234 1.322.155.311.31.545.544.778.233.233.467.389.778.544.233.078.622.234 1.322.234.7.078.933.078 2.8.078 1.867 0 2.1-.078 2.8-.078.7 0 1.089-.156 1.322-.234.311-.155.545-.31.778-.544.233-.233.389-.467.544-.778.078-.233.234-.622.234-1.322.078-.7.078-.933.078-2.8 0-1.867-.078-2.1-.078-2.8 0-.7-.156-1.089-.234-1.322A2.794 2.794 0 0 0 11.9 2.1a2.794 2.794 0 0 0-.778-.544c-.233-.078-.622-.234-1.322-.234-.7-.078-.933-.078-2.8-.078" />-->
    <!--                                  </g>-->
    <!--                              </g>-->
    <!--                          </svg>-->
    <!--                      </a>-->
    <!--                  </li>-->
    <!--                  <li>-->
    <!--                      <a href="" target="_blank">-->
    <!--                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">-->
    <!--                              <g>-->
    <!--                                  <g>-->
    <!--                                      <path fill="#fff"-->
    <!--                                            d="M4.433 11.356c5.29 0 8.167-4.356 8.167-8.167V2.8c.544-.389 1.011-.933 1.4-1.478a6.457 6.457 0 0 1-1.633.467c.622-.389 1.01-.933 1.244-1.556a7.134 7.134 0 0 1-1.789.7A2.778 2.778 0 0 0 9.722 0C8.167 0 6.844 1.322 6.844 2.878c0 .233 0 .466.078.622-2.41-.156-4.51-1.244-5.91-3.033a2.979 2.979 0 0 0-.39 1.477c0 1.012.545 1.867 1.245 2.412-.467 0-.934-.156-1.323-.39 0 1.4 1.012 2.567 2.334 2.8-.234.078-.467.078-.778.078-.156 0-.389 0-.544-.077.388 1.166 1.4 1.944 2.722 2.022-1.011.778-2.256 1.244-3.578 1.244-.233 0-.467 0-.7-.077 1.244.933 2.8 1.4 4.433 1.4" />-->
    <!--                                  </g>-->
    <!--                              </g>-->
    <!--                          </svg>-->
    <!--                      </a>-->
    <!--                  </li>-->
    <!--                  <li>-->
    <!--                      <a href="" target="_blank">-->
    <!--                          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">-->
    <!--                              <g>-->
    <!--                                  <g>-->
    <!--                                      <path fill="#fff"-->
    <!--                                            d="M4.744 14V7.622h2.178l.311-2.489H4.744V3.578c0-.7.234-1.245 1.245-1.245H7.31V.078C7 .078 6.222 0 5.367 0 3.5 0 2.177 1.167 2.177 3.267v1.866H0v2.49h2.178V14z" />-->
    <!--                                  </g>-->
    <!--                              </g>-->
    <!--                          </svg>-->
    <!--                      </a>-->
    <!--                  </li>-->
    <!--              </ul>-->
    <!--          </div>-->
    <!--          <div class="footer-copy">-->
    <!--              <p>Copyright 2020 Talent Powered Team.<a-->
    <!--                      href="mailto:support@talentpowered.team">Support@TalentPowered.Team</a> </p>-->
    <!--          </div>-->
    <!--  </div>-->

    <div class="footer-cookie-alert" *ngIf="!isCookieAccepted">
        <div>
            {{ 'FOOTER.COOKIE_INFO' | translate }} <a href="legal/privacy">{{ 'FOOTER.COOKIE_POLICY_LINK' | translate }}</a
            >.
        </div>
        <div class="footer-cookie-alert__bottom">
            <button class="footer-cookie-alert__accept" (click)="acceptCookie()">
                {{ 'FOOTER.COOKIE_ACCEPT' | translate }}
            </button>
        </div>
    </div>
</footer>
