import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from './classes/enums';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { RoleGuard } from './guards/role.guard';
import { RouteHelper } from './helpers/route.helper';
import { MainPageGuard } from './guards/main-page.guard';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { RedirectGuard } from './guards/redirect.guard';
import { CheckListComponent } from './components/check-list/check-list.component';

const routes: Routes = [
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  {
    path: RouteHelper.SETTINGS_PATH,
    loadChildren: () => import('./modules/edit-profile/edit-profile.module').then((m) => m.EditProfileModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER, Role.EMPLOYER] },
  },
  { path: 'login', component: LoginComponent, canActivate: [MainPageGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [MainPageGuard] },
  { path: 'confirm', component: ConfirmComponent },
  { path: 'confirm-success', component: ConfirmSuccessComponent },
  { path: 'confirm/connect', component: ConfirmComponent },
  { path: 'subscription/:mode', component: SubscriptionComponent },
  {
    path: RouteHelper.FREELANCER_PROJECTS_DASHBOARD_PATH,
    loadChildren: () => import('./modules/freelancer/freelancer.module').then((m) => m.FreelancerModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER] },
  },
  {
    path: RouteHelper.PROJECTS_DASHBOARD_PATH,
    loadChildren: () => import('./modules/employer/employer.module').then((m) => m.EmployerModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER] },
  },
  {
    path: RouteHelper.CONTRACTS_PATH,
    loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER] },
  },
  {
    path: 'finance',
    loadChildren: () => import('./modules/finance/finance.module').then((m) => m.FinanceModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER, Role.EMPLOYER] },
  },
  // {
  //   path: 'notifications',
  //   loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule)
  // },
  {
    path: 'search',
    loadChildren: () =>
    import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER] },
  },
  {
    path: 'profiles',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule)
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./modules/projects/projects.module').then((m) => m.ProjectsModule)
  },

  { path: 'check-list', component: CheckListComponent, canActivate: [MainPageGuard] },
  // { path: 'rsp', loadChildren: () => import('./modules/rsp-landing/rsp-landing.module').then(m => m.RspLandingModule), canActivate: [MainPageGuard] },
  { path: 'book-demo', loadChildren: () => import('./modules/book-demo/book-demo.module').then(m => m.BookDemoModule) },
  // { path: 'home-page', loadChildren: () => import('./components/home-page/home-page.module').then(m => m.HomePageModule), canActivate: [MainPageGuard] },
  // { path: '', component: RedirectGuard, canActivate: [RedirectGuard], data: { externalUrl: '/landing' } },
  { path: '', loadChildren: () => import('./modules/gp-landing/gp-landing.module').then(m => m.GpLandingModule) },
  // { path: '',
  //   loadChildren: () => import('./components/home-page/home-page.module').then(m => m.HomePageModule),
  //   canActivate: [MainPageGuard]},
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
