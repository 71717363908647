import { Compiler, Inject, Injectable, Injector, NgModuleFactory, Type } from '@angular/core';
import { ILazyModule, LoadChildrenPromiseCallback, ModuleName } from '../manual-lazy-modules';
import { LazyDialogHelperComponent } from '../modules/lazy-dialog-helper/lazy-dialog-helper.component';
import { MANUAL_LAZY_MODULES } from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadService {
  private modules: Map<ModuleName, LoadChildrenPromiseCallback>;
  private loadedModules: Map<ModuleName, Promise<LazyDialogHelperComponent>> = new Map<ModuleName, Promise<LazyDialogHelperComponent>>();

  constructor(private compiler: Compiler,
              private injector: Injector,
              @Inject(MANUAL_LAZY_MODULES) lazyModules: ILazyModule[]) {
    this.modules = new Map<ModuleName, LoadChildrenPromiseCallback>(
      lazyModules.map((m) => [m.name, m.loadChildren])
    );
  }

  public preloadModule(name: ModuleName) {
    this.loadModuleWithHelper(name);
  }

  public loadModuleWithHelper(name: ModuleName): Promise<LazyDialogHelperComponent> {
    let promise: Promise<LazyDialogHelperComponent> = this.loadedModules.get(name);
    if (!promise) {
      promise = (this.modules.get(name)() as Promise<NgModuleFactory<any> | Type<any>>)
        .then((moduleOrFactory) => {
          console.log(moduleOrFactory);
          if (moduleOrFactory instanceof NgModuleFactory) {
            return moduleOrFactory;
          }
          return this.compiler.compileModuleAsync(moduleOrFactory);
        })
        .then((moduleFactory) => {
          const moduleRef = moduleFactory.create(this.injector);
          const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(LazyDialogHelperComponent);
          const component = componentFactory.create(moduleRef.injector);
          return component.instance;
        });
      this.loadedModules.set(name, promise);
    }
    return promise;
  }

}
