<tpt-dialog>
  <div class="heading">
    {{'TRACKER.DELETE_NOTE_CONFIRM' | translate}}
  </div>
  <div class="description">
    {{'TRACKER.DELETE_NOTE_TEXT' | translate}}
  </div>
  <div class="actions-row">
    <button class="cancel" (click)="close()">
      {{'BUTTON.CANCEL' | translate}}
    </button>
    <button class="confirm" (click)="confirmDelete()">
      {{'BUTTON.DELETE' | translate}}
    </button>
  </div>
</tpt-dialog>
