import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export class PaginatorIntlTranslate extends MatPaginatorIntl {
  translate: TranslateService;
  public subscription: Subscription;
  public ofLabel: string;
  private labels = ['PAGINATOR.PER_PAGE',
                    'PAGINATOR.NEXT',
                    'PAGINATOR.PREVIOUS',
                    'PAGINATOR.OF',
                    'PAGINATOR.FIRST',
                    'PAGINATOR.LAST'];

  public injectTranslateService(translate: TranslateService) {
    this.translate = translate;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.translate.onLangChange
      .subscribe(() => {
        this.updateTranslation();
      });
    this.updateTranslation();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };

  private updateTranslation() {
    this.translate.get(this.labels)
      .pipe(take(1))
      .subscribe((result) => {
        this.itemsPerPageLabel = result[this.labels[0]];
        this.nextPageLabel = result[this.labels[1]];
        this.previousPageLabel = result[this.labels[2]];
        this.ofLabel = result[this.labels[3]];
        this.firstPageLabel = result[this.labels[4]];
        this.lastPageLabel = result[this.labels[5]];
        this.changes.next();
      });
  }
}
