import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { TasksResponseModel } from '../../employer/models/tasks-response.model';
import { ProjectNotesDialogComponent } from '../project-notes-dialog/project-notes-dialog.component';
import { ProjectApiService } from '../../../services/project.api.service';
import { RouteHelper } from '../../../helpers/route.helper';
import { ProfileService } from '../../../services/profile.service';
import { ProjectBoardService } from '../../employer/services/project-board.service';
import { SimpleProjectResponseModel } from '../../employer/models/simple-project-response.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'tpt-project-page-header',
  templateUrl: './project-page-header.component.html',
  styleUrls: [ './project-page-header.component.scss' ],
})
export class ProjectPageHeaderComponent implements OnInit, OnDestroy {

  @Input()
  public task: TasksResponseModel;

  @Input()
  public project: SimpleProjectResponseModel;

  @ViewChild(ProjectNotesDialogComponent)
  public projectNotesDialog: ProjectNotesDialogComponent;

  @Output() tasksFilterEvent: EventEmitter<any> = new EventEmitter<any>();

  public svgIconsEnum = SvgIconsEnum;

  public dropdownItems;
  public currentProject;
  public id;

  public activeProjects = ['ACTIVE'];
  public completedProject = ['ENDED'];
  public draftProjects = ['DRAFT'];

  public groupingTypes = [
    {
      id: '',
      value: 'TRACKER.GROUP.NO_GROUP'
    },
    {
      id: 'ASSIGNEE',
      value: 'TRACKER.GROUP.ASSIGNEE',
    },
    {
      id: 'JOB',
      value: 'TRACKER.GROUP.JOBS',
    },
    {
      id: 'PRIORITY',
      value: 'TRACKER.GROUP.PRIORITY',
    },
  ];

  public filterFreel = 0;
  public groupBy = '';
  public taskName = '';

  public taskNameControl: FormControl = new FormControl();

  public showArchive = false;

  public currentProjectControl = new FormControl();

  private readonly destroy$ = new Subject();

  constructor(
    private router: Router,
    public routeHelper: RouteHelper,
    private activateRoute: ActivatedRoute,
    public projectService: ProjectApiService,
    private cd: ChangeDetectorRef,
    private profile: ProfileService,
    private projectBoardService: ProjectBoardService,
  ) {
    this.id = this.activateRoute.parent.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getCurrentTabProjects();
    const { id, name } = this.project;
    this.currentProjectControl.setValue({ id, name });

    this.projectBoardService.projectUpdated.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      if ((this.project && this.project.id) !== (project && project.id)) {
        this.clearForm();
      }

      this.project = project;
      if (this.project && this.project.type === 'SIMPLE') {
        this.filterFreel = 0;
      }

      this.getCurrentTabProjects();
    });

    this.taskNameControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((value: string) => {
      this.searchTask(value.trim());
    });

    this.currentProjectControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
      (val) => {
        this.goToProject(val.id);
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public clearForm(): void {
    this.taskNameControl.setValue('');
  }

  public openNotes(): void {
    this.projectNotesDialog.open(this.project);
  }

  public filterFreelancers(id): void {
    this.filterFreel = id;
    this.tasksFilterEvent.emit({
      id: this.filterFreel,
      group: this.groupBy,
      taskName: this.taskName,
      showArchive: this.showArchive
    });
  }

  public group(type): void {
    this.groupBy = type;
    this.tasksFilterEvent.emit({
      id: this.filterFreel,
      group: this.groupBy,
      taskName: this.taskName,
      showArchive: this.showArchive
    });
  }

  public toggleArchive(event: boolean): void {
    this.showArchive = event;
    this.tasksFilterEvent.emit({
      id: this.filterFreel,
      group: this.groupBy,
      taskName: this.taskName,
      showArchive: this.showArchive
    });
  }

  public getRoute(id): any[] {
    return this.profile.currentProfile.isEmployer() ? this.routeHelper.employerProjectTaskTracker(id) :
      this.routeHelper.freelancerProjectTaskTracker(id);
  }

  public goToProject(id): void {
    this.filterFreel = 0;
    this.groupBy = '';
    this.taskName = '';
    this.showArchive = false;
    this.router.navigate(this.getRoute(id));
    this.tasksFilterEvent.emit({
      id: this.filterFreel,
      group: this.groupBy,
      taskName: this.taskName,
      showArchive: this.showArchive
    });
  }

  public clearFilter(): void {
    this.showArchive = false;
    this.filterFreel = 0;
    this.tasksFilterEvent.emit({
      id: this.filterFreel,
      group: this.groupBy,
      taskName: this.taskName,
      showArchive: this.showArchive
    });
  }

  public compareFn = (opt, select) => {
    return opt && select && opt?.id === select?.id;
  }

  private searchTask(name: string): void {
    this.taskName = name;
    this.tasksFilterEvent.emit({
      id: this.filterFreel,
      group: this.groupBy,
      taskName: this.taskName,
      showArchive: this.showArchive
    });
  }

  private getCurrentTabProjects(): void {
    if (!this.project || this.dropdownItems) { return; }

    const state = ['ACTIVE', 'ENDED', 'DRAFT'].includes(this.project.state) ? this.project.state : 'ACTIVE';

    this.projectService.getAllJobsList({state}).pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.dropdownItems = res.map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
      }
    );
  }
}
