import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable, Injector, NgZone, OnDestroy, ViewContainerRef } from '@angular/core';
import { DecorateUntilDestroy, takeUntilDestroyed } from '../../../helpers/rxjs/take-until-destroyed';

@DecorateUntilDestroy()
@Injectable()
export abstract class PopupComponent<T> implements OnDestroy {
  public opened = false;

  protected _component: ComponentRef<T>;
  protected _overlay: Overlay;
  protected _ngZone: NgZone;
  protected _viewContainerRef: ViewContainerRef;
  protected _elementRef: ElementRef;
  private _popupRef: OverlayRef;
  private _contentPortal: ComponentPortal<T>;

  constructor(injector: Injector) {
    this._overlay = injector.get(Overlay);
    this._ngZone = injector.get(NgZone);
    this._viewContainerRef = injector.get(ViewContainerRef);
    this._elementRef = injector.get(ElementRef);
  }

  public abstract getComponent(): ComponentType<T>;

  public abstract fillComponent(component: ComponentRef<T>);

  public ngOnDestroy(): void {
    this.close();
    if (this._popupRef) {
      this._popupRef.dispose();
    }
  }

  public open(ev): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.opened) {
      return;
    }

    if (!this._contentPortal) {
      this._contentPortal = new ComponentPortal(this.getComponent(), this._viewContainerRef);
    }

    if (!this._popupRef) {
      this._createPopup();
    }

    if (!this._popupRef.hasAttached()) {
      this._component = this._popupRef.attach(this._contentPortal);
      this.fillComponent(this._component);

      // Update the position once the info has rendered.
      this._ngZone.onStable.asObservable().pipe(
        takeUntilDestroyed(this),
      ).subscribe(() => {
        this._popupRef.updatePosition();
      });
    }

    this._popupRef.backdropClick().pipe(
      takeUntilDestroyed(this),
    ).subscribe(() => this.close());

    this.opened = true;
  }

  public close(): void {
    if (!this.opened) {
      return;
    }
    if (this._popupRef && this._popupRef.hasAttached()) {
      this._popupRef.detach();
    }
    this.opened = false;
  }

  /** Create the popup. */
  private _createPopup(): void {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this._createPopupPositionStrategy(),
      hasBackdrop: true,
      direction: 'ltr',
      scrollStrategy: this._overlay.scrollStrategies.block(),
    });

    this._popupRef = this._overlay.create(overlayConfig);
  }

  /** Create the popup PositionStrategy. */
  private _createPopupPositionStrategy(): PositionStrategy {
    return this._overlay.position()
      .flexibleConnectedTo(this._elementRef)
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withPush(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);
  }

}
