<tpt-dialog>
  <div class="content">

    <div class="heading">{{'CONTRACTS.ALL_ATTACHMENTS' | translate}} </div>

    <div class="files-list">
      <emp-attached-file-item
        *ngFor="let file of files"
        [file]="file"
      ></emp-attached-file-item>
    </div>

  </div>
</tpt-dialog>
