export class EditProfileContacts {
  public id: number;
  public contactEmail: string;
  public skype: string;
  public phoneNumber: string;
  public website: string;
  public facebook: string;
  public twitter: string;
  public linkedin: string;
}
