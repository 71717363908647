import {Type} from 'class-transformer';
import {RIssue} from './rIssue';
import {RUser} from './rUser';

export class RResource {
  public id: number;

  @Type(() => RUser)
  public user: RUser;

  @Type(() => RIssue)
  public issues: RIssue[];

}
