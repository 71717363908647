<div class="header-container">
  <nav class="home-header">
    <a href="/landing">
      <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo" class="logo"></mat-icon>
    </a>

    <div class="buttons">
<!--      <button mat-button class="header-lang" (click)="setLang()" >-->
<!--        {{langFE[switchTo] | uppercase}}-->
<!--      </button>-->
      <button mat-button class="login-btn"
              [routerLink]="['/login']">{{'MAIN_PAGE.SIGN_IN' | translate}}</button>
      <button mat-button class="register-btn" [routerLink]="['/register']">{{'MAIN_PAGE.SIGN_UP' | translate}}</button>
    </div>
  </nav>
</div>
