import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MainPageService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public contactUs(form): Observable<any> {
    return fromPromise(this.executePost<any>(`v2/feedback`, form));
  }

  public bookDemo(form): Observable<any> {
    return fromPromise(this.executePost<any>(`v2/demo`, form));
  }

  public sendCv(form): Observable<any> {
    return fromPromise(this.executePost<any>(`v2/demo/cv`, form));
  }

  public subscribeEmail(email: string): Observable<any> {
    return this.http.post(this.baseUrl + 'v2/subscribe', { email }, {})
      .pipe(catchError(this.errorHandler));
  }

  public unsubscribeEmail(email: string, reason: string): Observable<any> {
    return this.http.put(this.baseUrl + 'v2/subscribe', { email, reason }, {})
      .pipe(catchError(this.errorHandler));
  }
}
