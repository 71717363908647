import {Language} from './language';
import {EnglishLevel} from '../classes/enums';
import { Type } from 'class-transformer';

export class LanguageLevel {
  public id: number;

  @Type(() => Language)
  public language: Language;

  public level: EnglishLevel;
}
