import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { EmployerLegalStatusEnum, FreelancerLegalStatusEnum } from '../../../../models/legalStatuses.enum';

@Component({
  selector: 'tpt-legal-status-dialog',
  templateUrl: './legal-status-dialog.component.html',
  styleUrls: ['./legal-status-dialog.component.scss']
})
export class LegalStatusDialogComponent implements OnInit {
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() statusChanged: EventEmitter<any> = new EventEmitter<any>();

  public svgIconsEnum = SvgIconsEnum;

  public entityType: EmployerLegalStatusEnum | FreelancerLegalStatusEnum;

  public role: string;

  public cards;

  public employerCards = [
    {
      id: EmployerLegalStatusEnum.INDIVIDUAL,
      icon: SvgIconsEnum.Individual,
      name: 'INDIVIDUAL',
      description: 'INDIVIDUAL_TEXT',
      currency: 'USD'
    },
    {
      id: EmployerLegalStatusEnum.INDIVIDUAL_ENTREPRENEUR,
      icon: SvgIconsEnum.IndividualEntrepreneur,
      name: 'INDIVIDUAL_ENTREPRENEUR_SHORT',
      description: 'INDIVIDUAL_ENTREPRENEUR_TEXT',
      currency: 'RUB, USD'
    },
    {
      id: EmployerLegalStatusEnum.LEGAL_ENTITY,
      icon: SvgIconsEnum.LegalEntity,
      name: 'LEGAL_ENTITY',
      description: 'LEGAL_ENTITY_TEXT',
      currency: 'RUB, USD'
    },
  ];

  public freelancerCards = [
    {
      id: FreelancerLegalStatusEnum.INDIVIDUAL,
      icon: SvgIconsEnum.Individual,
      name: 'INDIVIDUAL',
      description: 'INDIVIDUAL_TEXT',
      currency: 'USD'
    },
    {
      id: FreelancerLegalStatusEnum.SELF_EMPLOYED,
      icon: SvgIconsEnum.LegalEntity,
      name: 'SELF_EMPLOYED',
      description: 'SELF_EMPLOYED_TEXT',
      currency: 'RUB, USD'
    },
  ];

  private config: MatDialogConfig = {
    width: '720px',
  };

  private className = 'tpt-legal-status-dialog';

  constructor() { }

  ngOnInit() {
    this.cards = this.employerCards;
  }

  public open(legalType: (EmployerLegalStatusEnum | FreelancerLegalStatusEnum), role: string): void {
    this.entityType = legalType || EmployerLegalStatusEnum.INDIVIDUAL;
    this.role = role;
    if (this.role === 'freelancer') {
      this.config.width = '520px';
      this.cards = this.freelancerCards;
    }
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public selectType(type: EmployerLegalStatusEnum): void {
    this.entityType = type;
  }

  public changeType(): void {
    this.statusChanged.emit(this.entityType);
    this.close();
  }
}
