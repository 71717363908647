
export class Language {

  public id?: number;

  public name = '';

  public code = '';

  public level: string;

}

export class LanguageOption extends Language {
  public disabled?: boolean;
}
