export class Button {

  public static CancelButton(result: any = false) {
    return new Button('BUTTON.CANCEL', 'default', result);
  }

  public static DeleteButton(result: any = true) {
    return new Button('BUTTON.DELETE', 'warn', result);
  }

  public static OkButton(result: any = true) {
    return new Button('BUTTON.OK', 'primary', result);
  }

  public static CloseButton(result: any = true) {
    return new Button('BUTTON.CLOSE', 'warn', result);
  }

  public title: string;
  public color: string;
  public cssClass = '';
  public result: any;

  constructor(title: string, color: string, result: any, cssClass: string = '') {
    this.title = title;
    this.color = color;
    this.result = result;
    this.cssClass = cssClass;
  }
}
