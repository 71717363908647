import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ProfileService } from '../../../../services/profile.service';
import { ContentType } from '../../../../classes/enums';
import { MasterJob } from '../../../../models/job/masterJob';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { CurrencySymbol } from '../../../../models/currency.enum';
import { ArbitrationService, MediationModel } from '../../../../services/arbitration.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { CurrencyModel } from '../../../../models/financev2/currency.model';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { SnackbarNotificationsService } from '../../../../services/snackbar-notifications.service';
import { FailureDialogComponent } from '../failure-dialog/failure-dialog.component';
import { DepositFundsDialogComponent } from '../deposit-funds-dialog/deposit-funds-dialog.component';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FinanceServiceV2 } from '../../services/finance.service';
import { FileUpload } from '../../../../models/fileUpload';
import { FileUploadService } from '../../../../services/file-upload.service';

@Component({
  selector: 'open-arbitration-dialog',
  templateUrl: './open-arbitration-dialog.component.html',
  styleUrls: ['./open-arbitration-dialog.component.scss']
})
export class OpenArbitrationDialogComponent implements AfterViewInit {

  @Output()
  public readonly openArbitrationEvent = new EventEmitter<any>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(FailureDialogComponent, { static: false })
  public failureDialog: FailureDialogComponent;

  @ViewChild(DepositFundsDialogComponent, { static: false })
  public depositFundsDialog: DepositFundsDialogComponent;

  @ViewChild('stepper') public stepper: MatStepper;

  public reason = '';

  public contentType = ContentType;

  public svgIconsEnum = SvgIconsEnum;

  public files = [];

  public currencySymbol = CurrencySymbol;

  public activeStep: number;

  public reasonError;

  public taskId: number;

  public paymentInfo: {
    prepaid: boolean,
    jobId: number,
    jobAmount: number,
    arbitrageAmount: number,
    currency: CurrencyModel
  };

  private className = 'open-arbitration-dialog';

  private config: MatDialogConfig = {
    width: '460px',
    minHeight: '500px',
    panelClass: 'arbitration-panel'
  };

  constructor(private profile: ProfileService,
              private arbitrationService: ArbitrationService,
              private filesService: FileUploadService,
              private snack: SnackbarNotificationsService,
              private financeService: FinanceServiceV2,
              private spinner: SpinnerService) {
  }

  get isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => 'number';
  }

  public open(taskId): void {
    this.dialog.config = this.config;
    this.taskId = taskId;
    this.getPaymentInfo(taskId);
    this.dialog.open(this.className);
    this.activeStep = 0;
    this.stepper.selectedIndex = 0;
    this.files = new MasterJob().fileUploads;
  }

  public close(): void {
    this.dialog.close();
  }

  public uploadFile(file: File): void {
    this.filesService.uploadFile(file).subscribe((fileInfo: FileUpload) => {
      const filesArray = this.files.filter(file => file.uuid) || [];
      filesArray.push(fileInfo);
      this.files = filesArray;
    });
  }

  public deleteFile(fileId): void {
    this.files = this.files.filter(file => file.id !== fileId);
  }

  public getPaymentInfo(taskId) {
    this.arbitrationService.getPaymentInfo(taskId).subscribe((res) => {
      this.paymentInfo = res;
    });
  }

  public updateIndex(event: StepperSelectionEvent): void {
    this.activeStep = event.selectedIndex;
  }

  public openMediation(): void {
    if (!this.reason.trim()) {
      this.stepper.selectedIndex = 1;
      this.reasonError = true;
      return;
    }

    const fileIds = this.files.map(file => file.id);

    const body: MediationModel = {
      reason: this.reason.trim(),
      reopenReason: null,
      fileIds,
      taskId: this.taskId,
    };

    this.spinner.startSpinner();
    this.arbitrationService.startArbitrage(body).subscribe(() => {
      this.spinner.stopSpinner();
      this.reason = '';
      this.close();
      this.openArbitrationEvent.emit(this.taskId);
    }, this.handlePaymentFailure);
  }

  public depositIsSuccess(): void {
    timer(2500).pipe(tap(() => {
      this.financeService.balanceUpdated$.next();
    })).subscribe(() => {
      this.depositFundsDialog.close();
      this.snack.showNotification('FINANCE.TOP_UP_SUCCESS', 'success');
      this.spinner.stopSpinner();
    });
  }

  public openDepositFundsDialog(): void {
    this.depositFundsDialog.open();
    this.failureDialog.close();
  }

  private handlePaymentFailure = (err): void => {
    if (err.errorCode && err.errorCode.message === 'SERVER_ERRORS.ARBITRAGE_INSUFFICIENT_FUNDS') {
      this.failureDialog.open();
    } else {
      this.snack.showSmthWentWrong();
    }
    this.spinner.stopSpinner();
  }
}
