import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

import { IBaseFilters } from '../../../../../classes/iBaseFilters';
import { DecorateUntilDestroy } from '../../../../../helpers/rxjs/take-until-destroyed';
import { FreelancerSearchSorting } from '../../../../../classes/enums';
import { ProfileModel } from '../../../../../models/profile.model';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';

@DecorateUntilDestroy()
@Component({
  selector: 'freelancer-search-page',
  templateUrl: './freelancer-search-page.component.html',
  styleUrls: ['./freelancer-search-page.component.scss'],
})
export class FreelancerSearchPageComponent implements OnInit {
  @Output()emitSortChange = new EventEmitter();
  @Input() freelancers: ProfileModel[];
  @Output()searchEvent = new EventEmitter();
  @Input() empProjects = [];
  @Input() skillsFilter: FormArray;
  @Input() showSkeleton: boolean;

  public svgIconsEnum = SvgIconsEnum;

  public filters: Partial<IBaseFilters> = {
    sort: FreelancerSearchSorting.RATE_ASC,
    skills: [],
    expertLevel: null,
    location: null,
    searchText: '',
    pageIndex: 0,
    pageSize: 20,
    hourlyRate: [0, 1000],
  };

  public searchControl: FormControl;

  get sortList() {
    return ['RATE_ASC', 'RATE_DESC'];
  }

  ngOnInit(): void {
    this.searchControl = new FormControl('');
  }

  public searchUsers(): void {
    const searchValue = this.searchControl.value.trim();
    this.searchEvent.emit(searchValue);
  }
}
