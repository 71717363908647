<div class="page-wrapper">
  <div class="header-page">
    <span class="page-title">{{'ACCESS.USERS' | translate}}</span>
    <div class="tabs" *ngIf="showRolesTab">
      <span class="tab regular active">{{'ACCESS.USERS' | translate}}</span>
      <span class="tab regular not-active" routerLink="../roles">{{'ACCESS.ROLES' | translate}}</span>
    </div>
  </div>

  <div class="users-text">{{'ACCESS.USERS_TEXT' | translate}}</div>

  <div class="subhead table-title">{{'ACCESS.USERS_TABLE_TITLE' | translate}}</div>

  <div class="list-wrapper">
    <div class="list-item" *ngFor="let user of users">
      <div class="profile-info">
        <div class="avatar-wrapper">
          <tpt-avatar size="28" [textSizeRatio]="2.5" [user]="user?.profile"></tpt-avatar>


          <div class="profile-name">{{user.profile?.firstName}} {{user.profile?.lastName}}
            <span *ngIf="user.profile?.id === currentProfileId">({{'ACCESS.ITS_YOU' | translate}})</span>
          </div>
        </div>
        <div class="roles">
          <div class="pseudo-chips">
            <ng-container *ngIf="splitArray(user.roles).firstPart?.length; else addRoleChip">
              <tpt-chip class="chip" type="outline" size="small" *ngFor="let role of splitArray(user.roles).firstPart">
                <span class="text">{{role.name}}</span>
              </tpt-chip>
              <div *ngIf="splitArray(user.roles).secondPartLength"
                   (click)="openUserRolesDialog(user)"
                   class="count-roles">
                + {{splitArray(user.roles).secondPartLength}}
              </div>
            </ng-container>
          </div>
        </div>
        <ng-template #addRoleChip>
          <tpt-chip class="chip-add" type="outline" size="small"
                    (click)="openUserRolesDialog(user)">
            <span class="text">+{{'ACCESS.ADD_ROLE' | translate}}</span>
          </tpt-chip>
        </ng-template>
        <div class="action" *ngIf="splitArray(user.roles).firstPart.length">
          <button mat-icon-button class="edit-roles" (click)="openUserRolesDialog(user)">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>

<user-roles-dialog (userRolesUpdated)="updateUsers()"></user-roles-dialog>
