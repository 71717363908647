import { Injectable, isDevMode } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ErrorCodeEnum } from '../types/error-code.enum';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationErrorService {

  constructor(private translate: TranslateService) {}

  public getValidationErrorMessage(control: AbstractControl): string {
    if (!control.errors) {
      return null;
    }

    if (control.hasError(ErrorCodeEnum.serverError)) {
      return control.getError(ErrorCodeEnum.serverError);
    }

    if (control.hasError(ErrorCodeEnum.required)) {
      return 'VALIDATION_COMPONENT.REQUIRED_FIELD';
    }

    if (control.hasError(ErrorCodeEnum.passwordConfirmation)) {
      return 'VALIDATION_COMPONENT.PASSWORDS_MUST_MATCH';
    }

    if (control.hasError(ErrorCodeEnum.email)) {
      return 'VALIDATION_COMPONENT.FIELD_MUST_VALID_EMAIL';
    }

    if (control.hasError(ErrorCodeEnum.minLength)) {
      const error = control.getError(ErrorCodeEnum.minLength);

      return `${this.translate.instant('VALIDATION_COMPONENT.FIELD_CANNOT_LESS_THAN')}
      ${error.requiredLength} ${this.translate.instant('VALIDATION_COMPONENT.CHARACTERS')}`;
    }

    if (control.hasError(ErrorCodeEnum.maxlength)) {
      const error = control.getError(ErrorCodeEnum.maxlength);
      return `${this.translate.instant('VALIDATION_COMPONENT.FIELD_CANNOT_MORE_THAN')}
      ${error.requiredLength} ${this.translate.instant('VALIDATION_COMPONENT.CHARACTERS')}`;
    }

    if (control.hasError(ErrorCodeEnum.min)) {
      const error = control.getError(ErrorCodeEnum.min);
      return `${this.translate.instant('VALIDATION_COMPONENT.MINIMUM_VALUE')} ${get(error, ErrorCodeEnum.min)}`;
    }

    if (control.hasError(ErrorCodeEnum.max)) {
      const error = control.getError(ErrorCodeEnum.max);
      return `${this.translate.instant('VALIDATION_COMPONENT.MAXIMUM_VALUE')} ${get(error, ErrorCodeEnum.max)}`;
    }

    if (control.hasError(ErrorCodeEnum.matDatepickerMin)) {
      const error = control.getError(ErrorCodeEnum.matDatepickerMin);
      return `${this.translate.instant('VALIDATION_COMPONENT.ENTERED_DATE_MUST_BE_NO_EARLIER')} ${error.min.format('DD.MM.YYYY')}`;
    }

    if (control.hasError(ErrorCodeEnum.matDatepickerFilter)) {
      return 'VALIDATION_COMPONENT.EXPIRATION_DATE_MUST_BE_NO_EARLIER';
    }

    if (control.hasError(ErrorCodeEnum.validatePattern)) {
      if (control.errors.validatePattern.errorCode === 'exactLengthValidation') {
        return `${this.translate.instant('VALIDATION_COMPONENT.LENGTH_VALIDATION',
          { count: control.errors.validatePattern.number})}`;
      }
    }

    if (control.hasError(ErrorCodeEnum.passwordLettersDigits)) {
        return 'VALIDATION_COMPONENT.PASSWORD_LETTERS_DIGITS';
    }

    if (control.hasError(ErrorCodeEnum.sameEmail)) {
        return 'VALIDATION_COMPONENT.SAME_EMAIL';
    }

    if (control.hasError(ErrorCodeEnum.safePassword)) {
        return 'VALIDATION_COMPONENT.SAFE_PASSWORD_ERROR';
    }

    if (control.hasError(ErrorCodeEnum.pattern)) {
      if (control.errors.pattern.requiredPattern === '^[а-яА-ЯЁё-\\s]+$') {
        return this.translate.instant('VALIDATION_COMPONENT.CYRILLIC_ONLY');
      }

      if (control.errors.pattern.requiredPattern === '^[a-zA-Zа-яА-ЯЁё-\\s]+$') {
        return this.translate.instant('VALIDATION_COMPONENT.LATIN_CYRILLIC_ONLY');
      }

      if (control.errors.pattern.requiredPattern === '^(https?://)?([\\da-zа-я.-]+)\\.([a-zа-я.]{2,6})[/\\w .-]*/?$') {
        return this.translate.instant('VALIDATION_COMPONENT.ENTER_VALID_URL');
      }

      if (control.errors.pattern.requiredPattern === '^[a-zA-Z][a-zA-Z0-9.,_:-]+$') {
        return this.translate.instant('VALIDATION_COMPONENT.SKYPE_ERROR');
      }

      if (control.errors.pattern.requiredPattern === '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$') {
        return this.translate.instant('VALIDATION_COMPONENT.FIELD_MUST_VALID_EMAIL');
      }
    }

    if (isDevMode()) {
      console.warn(this.translate.instant('VALIDATION_COMPONENT.UNKNOWN_VALIDATION_ERROR'), control.errors);
    }

    return null;
  }

}
