<tpt-dialog>
  <div class="tpt-deposit-funds-dialog-root">

    <ng-container *ngIf="paymentState === paymentStateEnum.Initial">
      <div class="deposit-funds-title">{{'FINANCE.TOP_UP_BALANCE'| translate}}</div>

      <ng-container *ngIf="accounts">
        <tpt-cards-select
          [placeholder]="'FINANCE.SELECT_CARD' | translate"
          [control]="depositFundsForm.cardId"
          [stripeCards]="usdAccount.stripeCustomer?.cards"
          (addPaymentMethod)="openAddPaymentMethod()"
        >
          <ng-container label>{{'FINANCE.SELECT_PAYMENT_METHOD'| translate}}</ng-container>
        </tpt-cards-select>

        <tpt-input
          [control]="depositFundsForm.amount"
          type="number"
          placeholder="$ 00.00"
        >
          <ng-container label>{{'FINANCE.TOP_UP_AMOUNT'| translate}}</ng-container>
        </tpt-input>
      </ng-container>

      <div class="actions">
        <tpt-button
          type="secondary"
          (onClick)="dialog.close()"
        >
          {{'BUTTON.CANCEL'| translate}}
        </tpt-button>

        <tpt-button
          type="accent"
          class="ml-12"
          (onClick)="depositFunds()"
        >
          {{'BUTTON.OK'| translate}}
        </tpt-button>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.RequestInProgress">
      <div class="step-wrapper">
        <div class="step-title">{{'FINANCE.REQUEST_IN_PROGRESS' | translate}}</div>

        <div class="step-text">{{'FINANCE.PLEASE_WAIT' | translate}}</div>

        <mat-spinner [diameter]="32"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.DepositInProgress">
      <div class="step-wrapper" [class.small]="canBeClosed">
        <div class="step-title">{{'FINANCE.DEPOSIT_IN_PROGRESS' | translate}}</div>

        <div class="step-text">{{'FINANCE.PLEASE_WAIT' | translate}}</div>

        <mat-spinner [diameter]="32"></mat-spinner>

        <div *ngIf="canBeClosed" class="in-progress">
          <div class="info-message mt-24" [innerHTML]="'FINANCE.TOP_UP_INFO_MESSAGE' | translate"></div>

          <div class="actions">
            <tpt-button
              type="accent"
              (onClick)="close()"
            >
              {{'FINANCE.BACK_TO_WORK'| translate}}
            </tpt-button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.Success">
      <div class="top-up-completed">
        <div class="amount success">
          +{{depositFundsForm.amount.value | number:'.2'}}$
        </div>

        <div class="headline">
          {{'FINANCE.TOP_UP_SUCCESS'| translate}}
        </div>
        <div class="regular">
          {{'FINANCE.TOP_UP_SUCCESS_TEXT'| translate}}
        </div>
        <tpt-button type="secondary" class="full-width" (onClick)="closeIfSuccess()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
      </div>
    </ng-container>

    <ng-container *ngIf="paymentState === paymentStateEnum.Error">
      <div class="top-up-completed">
        <div class="amount error">
          <mat-icon [svgIcon]="svgIconsEnum.Cancel" class="mr-4"></mat-icon>
          {{depositFundsForm.amount.value | number:'.2'}}$
        </div>

        <div class="headline">
          {{'FINANCE.TOP_UP_FAILED'| translate}}
        </div>
        <div class="regular" [innerHTML]="'FINANCE.TOP_UP_ERROR_TEXT'| translate"></div>

        <tpt-button type="secondary" class="full-width" (onClick)="dialog.close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
      </div>
    </ng-container>
  </div>
</tpt-dialog>

<tpt-add-payment-method-dialog (cardAdded)="handleCardAdded()"></tpt-add-payment-method-dialog>

