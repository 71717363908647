<tpt-dialog>
  <div class="menu-wrapper" (click)="$event.stopPropagation()">

    <div class="gradient-header">
      <div class="menu-title">
        {{'MAIN_PAGE.CONTACT_US_TITLE' | translate}}
      </div>

      <div class="menu-text">
        {{'MAIN_PAGE.CONTACT_US_TEXT' | translate}}
      </div>
    </div>

    <form *ngIf="form" (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()">
      <div class="field-with-error">
        <label>{{'MAIN_PAGE.CONTACT_FORM.FIRST_NAME' | translate}}</label>
        <input matInput [formControl]="form.firstName"/>
        <div class="error-wrap">
          <mat-error [class.-invisible]="!nameIsInvalid">
            {{ nameValidationError | translate }}
          </mat-error>
        </div>
      </div>

      <div class="field-with-error">
        <label>{{'MAIN_PAGE.CONTACT_FORM.LAST_NAME' | translate}}</label>
        <input matInput [formControl]="form.lastName"/>
        <div class="error-wrap">
          <mat-error [class.-invisible]="!lastNameIsInvalid">
            {{ lastNameValidationError | translate }}
          </mat-error>
        </div>
      </div>

      <div class="field-with-error">
        <label>{{'MAIN_PAGE.CONTACT_FORM.EMAIL' | translate}}</label>
        <input matInput [formControl]="form.email"/>
        <div class="error-wrap">
          <mat-error [class.-invisible]="!emailIsInvalid">
            {{ emailValidationError | translate }}
          </mat-error>
        </div>
      </div>

      <div class="field-with-error">
        <label>{{'MAIN_PAGE.CONTACT_FORM.MESSAGE' | translate}}</label>
        <textarea
          matInput
          rows="5"
          class="message"
          maxlength="500"
          [formControl]="form.message"
        ></textarea>
        <div class="error-wrap">
          <mat-error [class.-invisible]="!messageIsInvalid">
            {{ messageValidationError | translate }}
          </mat-error>
        </div>
      </div>

      <ng-container *ngIf="loading">
        <mat-spinner diameter="32" color="primary" class="spinner-loader"></mat-spinner>
      </ng-container>

      <ng-container *ngIf="!loading">
        <ng-container *ngIf="!showSuccess">
          <div class="section-contact-check">
            <mat-checkbox labelPosition="after"
                          class="purple-checkbox"
                          [checked]="acceptTerms"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="acceptTerms">
              {{'MAIN_PAGE.CONTACT_FORM.I_ACCEPT' | translate}}
              <a class="link"
                 (click)="$event.stopPropagation()"
                 href="https://talentpowered.team/legal/terms"
              >{{'MAIN_PAGE.CONTACT_FORM.TERMS' | translate}}</a>
            </mat-checkbox><br/>

            <mat-checkbox labelPosition="after"
                          class="purple-checkbox"
                          [checked]="acceptPrivacy"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="acceptPrivacy">
              {{'MAIN_PAGE.CONTACT_FORM.I_AGREE' | translate}}
              <a class="link"
                 (click)="$event.stopPropagation()"
                 href="https://talentpowered.team/legal/privacy "
              >{{'MAIN_PAGE.CONTACT_FORM.PRIVACY_POLICY' | translate}}</a>
            </mat-checkbox>
          </div>

          <button mat-flat-button color="accent" class="green-btn"
                  [disabled]="!acceptTerms || !acceptPrivacy"
                  (click)="submit()">{{'MAIN_PAGE.CONTACT_FORM.SUBMIT' | translate}}</button>
        </ng-container>

        <ng-container *ngIf="showSuccess">
          <div class="sent-button">{{'MAIN_PAGE.CONTACT_FORM.SENT' | translate}}
            <mat-icon [svgIcon]="svgIconsEnum.Check"></mat-icon>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>

</tpt-dialog>
