<div class="card-wrapper" *ngIf="feedback">
  <div class="stats-block" [class.stats-expanded]="feedback?.statsExpanded">
    <div class="main-rating-wrapper" [class.stats-expanded]="feedback?.statsExpanded">
      <div class="main-rating">
        <div class="main-rating-text">{{'SETTINGS.rating' | translate}}</div>
        <div class="progress-row">
          <div class="progress-container">
            <mat-progress-bar [value]="feedback?.generalAssessment * 100" color="accent" mode="determinate"></mat-progress-bar>
          </div>
          <span class="main-rating-value">{{feedback?.generalAssessment | percent}}</span>
        </div>
      </div>
      <div class="expand-icon">
        <mat-icon [svgIcon]="svgIconsEnum.Expand" (click)="feedback.statsExpanded = !feedback.statsExpanded"></mat-icon>
      </div>
    </div>

    <div *ngIf="feedback?.statsExpanded" class="stats-card">
      <div class="main-stats-wrapper" *ngFor="let stat of isEmployer ? employerStats : freelancerStats">
        <div class="main-rating">
          <div class="main-rating-text">{{'SETTINGS.' + stat.name | translate}}</div>
          <div class="progress-row">
            <div class="progress-container">
              <mat-progress-bar [value]="feedback[stat.field] * 100" color="accent" mode="determinate"></mat-progress-bar>
            </div>
            <span class="main-rating-value">{{feedback[stat.field] | percent}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="left-block" [class.stats-expanded]="feedback?.statsExpanded">
    <div class="post-date" *ngIf="feedback?.created">{{feedback?.created | localDate: 'dd.MM.yyyy'}}</div>
    <div class="title">
      <div class="project-name">
        {{feedback.jobInfo?.projectName}}
      </div>
      <div class="category">
        / {{feedback?.jobInfo?.subCategory?.id ?
        getTranslation(feedback?.jobInfo?.subCategory) :
        getTranslation(feedback?.jobInfo?.parentCategory)}}
      </div>
    </div>
    <div class="message">{{feedback.text | tptTruncate: 340}}</div>

    <div class="sender">
      <tpt-avatar [textSizeRatio]="2" size="14" [user]="feedback?.estimator"></tpt-avatar>
      <span class="name">
      {{feedback?.estimator?.firstName}} {{feedback?.estimator?.lastName}}
    </span>
    </div>
  </div>
</div>
