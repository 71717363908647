<tpt-dialog>
  <div class="tpt-stop-autopay-dialog-root">
    <div class="dialog-title">{{ 'FINANCE.STOP_AUTO_PAYMENT.TITLE' | translate }}</div>

    <div class="dialog-text" [innerHTML]="'FINANCE.STOP_AUTO_PAYMENT.TEXT' | translate"></div>

    <div class="info">
      <div class="first-row">
        <div class="freelancer-info border-right">
          <img [src]="info?.freelancer.avatarUUID | tptAvatar : 'empty'" class="freelancer-avatar" alt="freelancer-avatar"/>
          <div>
            <div class="freelancer-category">{{info?.freelancer?.category?.name}}</div>
            <div class="freelancer-name">{{info?.freelancer.firstName}} {{info?.freelancer.lastName}}</div>
          </div>
        </div>
        <div class="project-info column">
          <div class="column-name">{{ 'FINANCE.COLUMN.PROJECT_NAME' | translate }}</div>
          <span class="dark-text">{{info?.job?.name}}</span>
        </div>
      </div>

      <div class="second-row" [ngClass]="{'fixed-type': info?.paymentMethod === 'FIXED'}">
        <div class="border-right"></div>
        <div class="payment-column border-right column">
          <div class="column-name">{{ 'FINANCE.COLUMN.PAYMENT_TYPE' | translate }}</div>
          <div class="dark-text" *ngIf="info?.paymentMethod === 'FIXED'">{{ 'FINANCE.COLUMN.FIXED' | translate }}</div>
          <div class="dark-text" *ngIf="info?.paymentMethod === 'HOURLY'">{{ 'FINANCE.COLUMN.HOURLY' | translate }}</div>
        </div>
        <div [ngSwitch]="info?.paymentMethod" class="border-right column deadline">
          <div *ngSwitchCase="'HOURLY'">
            <div class="column-name">{{ 'FINANCE.COLUMN.PAYMENT_PERIOD' | translate }}</div>
            <span class="dark-text">{{paymentPeriod[info?.paymentPeriod] | translate}}</span>
          </div>
          <div *ngSwitchCase="'FIXED'">
            <div class="column-name">{{ 'FINANCE.COLUMN.DEADLINE' | translate }}</div>
            <div class="dark-text datepicker-row">
              <mat-icon class="calendar-icon" [svgIcon]="svgIconsEnum.DatepickerCalendar"></mat-icon>
              {{info?.deadline | localDate: 'dd.MM.yyyy'}}
            </div>
          </div>
        </div>
        <div class="hourly-rate column">
          <ng-container *ngIf="info?.paymentMethod === 'HOURLY'">
            <div class="column-name">{{ 'FINANCE.COLUMN.RATE' | translate }}</div>
            <div class="dark-text">${{info?.freelancer?.hourlyRate}}
              /{{ 'FINANCE.HOUR' | translate }}</div>
          </ng-container>
        </div>
      </div>

      <div class="amount-block">
        <div class="first-payment-text">{{ 'FINANCE.STOP_AUTO_PAYMENT.AMOUNT' | translate }}</div>
        <div class="first-payment-amount">${{ info?.amountIfStopNow }}</div>
      </div>

      <div class="actions-row">
        <button mat-button class="cancel" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
        <button mat-button class="confirm" (click)="confirm()">{{'BUTTON.OK' | translate}}</button>
      </div>
    </div>
  </div>
</tpt-dialog>

<tpt-failure-dialog>
  <ng-container mainText>
    {{ 'FINANCE.MAKE_PAYMENT_DIALOG.PAYMENT_FAIL_TITLE' | translate }}
  </ng-container>
  <ng-container secondaryText>
    {{ 'FINANCE.PLEASE' | translate }},
    <span class="top-up-balance" (click)="openDepositFundsDialog()">{{ 'FINANCE.MAKE_PAYMENT_DIALOG.PAYMENT_FAIL_TEXT' | translate }}</span>
  </ng-container>
</tpt-failure-dialog>

<tpt-success-dialog [closeAndUpdateFinanceInfo]="true">
  <ng-container mainText>
    {{ 'FINANCE.STOP_AUTO_PAYMENT.PAYMENT_SUCCESS_TITLE' | translate }}
  </ng-container>
  <ng-container secondaryText>
    <b>${{ info?.amountIfStopNow }}</b> {{ 'FINANCE.STOP_AUTO_PAYMENT.PAYMENT_SUCCESS_TEXT' | translate }}
  </ng-container>
</tpt-success-dialog>

<tpt-deposit-funds-dialog (depositIsSuccess)="depositIsSuccess()" [isPaymentFlow]="true"></tpt-deposit-funds-dialog>
