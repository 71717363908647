import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from '../../services/subscription.service';
import { map } from 'rxjs/operators';
import { MainPageService } from '../../services/main-page.service';

export enum SubscriptionMode {
    SUBSCRIBE_START = 'SUBSCRIBE',
    SUBSCRIBE_SUCCESS = 'SUBSCRIBED',
    UNSUBSCRIBE_REASON = 'UNSUBSCRIBE',
    UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBED',
    ALREADY_UNSUBSCRIBED = 'ALREADY_UNSUBSCRIBED',
}

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
    public mode: SubscriptionMode;
    public SubscriptionMode = SubscriptionMode;

    public subscriptionForm: FormGroup;
    public loading = false;
    private email: string;

    constructor(
        private subscriptionService: SubscriptionService,
        private mainPageService: MainPageService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        router.events.subscribe((val) => {
            this.init();
            if (this.mode === SubscriptionMode.SUBSCRIBE_SUCCESS) {
                this.loading = false;
            }
        });
    }

    ngOnInit() {
        this.init();
    }

    init() {
        if (this.loading) {
            return;
        }

        this.route.params.subscribe((params) => {
            this.mode = this.getMode(params.mode);
        });

        this.route.queryParams.subscribe((params) => {
            this.email = params.email;
            this.subscribeEmail();
        });

        this.subscriptionForm = this.formBuilder.group({
            email: new FormControl(null, [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$'),
                Validators.maxLength(50),
            ]),
            unsubscribe: new FormControl(false, Validators.required),
            reason: new FormControl(null, []),
        });
    }

    subscribeEmail() {
        if (this.mode === SubscriptionMode.SUBSCRIBE_START && this.email) {
            this.loading = true;
            const email = this.email.trim().toLowerCase();
            if (!this.subscriptionService.isValidEmail(this.email)) {
                return;
            }
            this.subscriptionService
                .subscribeEmail(email)
                .pipe(
                    map((data) => {
                        this.router.navigate(['/subscription/subscribed']);
                    }),
                )
                .subscribe();
        }
    }

    getMode(mode): SubscriptionMode {
        if (!mode) {
            return null;
        }
        let result = null;
        switch (mode) {
            case 'subscribe':
                result = SubscriptionMode.SUBSCRIBE_START;
                break;
            case 'subscribed':
                result = SubscriptionMode.SUBSCRIBE_SUCCESS;
                break;
            case 'unsubscribe':
                result = SubscriptionMode.UNSUBSCRIBE_REASON;
                break;
            case 'unsubscribed':
                result = SubscriptionMode.UNSUBSCRIBE_SUCCESS;
                break;
            case 'already-unsubscribed':
                result = SubscriptionMode.ALREADY_UNSUBSCRIBED;
                break;
        }
        return result;
    }

    onClickSubmit() {
        const email = this.subscriptionForm.controls.email.value.trim().toLowerCase();
        const reasonControl = this.subscriptionForm.controls.reason;
        const reason = reasonControl.value ? this.subscriptionForm.controls.reason.value.trim() : '';

        this.mainPageService
            .unsubscribeEmail(email, reason)
            .pipe(
                map((data) => {
                    this.subscriptionForm.reset();
                }),
            )
            .subscribe(
                (data) => {
                    this.subscriptionForm.reset();
                    this.router.navigate(['/subscription/unsubscribed']);
                },
                (err) => {
                    if ((err.message = 'SERVER_ERROR.SUBSCRIPTION_EMAIL_ALREADY_UNSUBSCRIBED')) {
                        this.router.navigate(['/subscription/already-unsubscribed']);
                    }
                },
            );
    }
}
