<div *ngIf="!onlyButton"
     class="file-uploader" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
     [uploadInput]="uploadInput" [ngClass]="dragOver ? '_drag-over' : ''">
  <ng-content class="file-uploader__divider"></ng-content>
  <mat-progress-bar mode="indeterminate" *ngIf="isFileUploading"></mat-progress-bar>
  <div class="file-uploader__buttons-area">
    <button mat-stroked-button color="primary" class="file-uploader__button" type="button">
      <label class="file-uploader__button-label">
        <input class="file-uploader__upload-input"
               type="file" #fileInput ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)"
               [uploadInput]="uploadInput"/>
        <mat-icon class="file-uploader__button-icon">{{ uploaderIcon }}</mat-icon>
        {{ (customTitle || title) | translate }}
      </label>
    </button>
  </div>
  <div *ngIf="files.length != 0" class="file-uploader__current-files-area">
    <h3 class="file-uploader__caption">{{ 'FILE_UPLOADER_COMPONENT.UPLOADING_FILES' | translate }}</h3>
    <div *ngFor="let file of files" class="file-uploader__file">
      <div class="file-uploader__file-body">
        <img *ngIf="!isUploadDone(file)" class="file-uploader__file-spinner"
             src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
        <i *ngIf="uploadError(file)" class="material-icons file-uploader__error-icon">error</i>
        <div *ngIf="!isUploadDone(file) || uploadError(file)" class="file-uploader__file-name">{{file.name}}</div>
        <div *ngIf="isUploadDone(file) && !uploadError(file)" class="file-uploader__file-info">
          <i *ngIf="!uploadError(file)" class="material-icons file-uploader__done-icon">check_circle</i>
          <a target="_blank" href="{{uploadedFileUrl(file)}}">{{file.name}}</a>
        </div>
        <i *ngIf="!isUploadDone(file) || uploadError(file)" class="material-icons file-uploader__remove-icon"
           (click)="removeUpload(file.id)">close</i>
      </div>
      <div *ngIf="uploadError(file)" class="file-uploader__error-label">{{uploadError(file)}}</div>
    </div>
  </div>
</div>

<label *ngIf="onlyButton" class="file-uploader__only-btn"
       [ngClass]="customButton ? 'file-uploader__only-btn_custom': ''" (click)="stopProp($event)">
  <input class="file-uploader__upload-input"
         type="file" #fileInput ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)"
         [uploadInput]="uploadInput"/>
  <span *ngIf="!customButton" class="file-uploader__only-btn-title">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>{{(customTitle || title) | translate}}

  </span>
</label>

