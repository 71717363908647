import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import {BaseService} from './base.service';
import {Profile} from '../models/profile/profile';
import {deserializationJacksonKey, serializationJacksonKey} from '../classes/jacksonUtils';
import {HttpParams} from '../classes/httpParams';
import {ProfileStatus} from '../models/profileStatus';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EditProfileBasic} from '../models/profile/editProfileBasic';
import {EditProfileContacts} from '../models/profile/editProfileContacts';
import {EditProfileLanguage} from '../models/profile/editProfileLanguage';
import {EditProfileEmail} from '../models/profile/editProfileEmail';
import {EditProfilePassword} from '../models/profile/editProfilePassword';
import {EditProfileSettings} from '../models/profile/editProfileSettings';
import { BaseProfile } from '../models/profile/baseProfile';
import { ProfileModel } from '../models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileManagementService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getProfile(id: number): Observable<BaseProfile | Profile> {
    const path = `profiles/${id}`;
    return this.executeGet<BaseProfile | Profile>(path).pipe(map((data) => deserializationJacksonKey(Profile, data)));
  }

  public exportProfileTrackedTime(id: number): any {
    const path = `profiles/${id}/export/trackedTime`;
    return this.executeGet<any>(path, {
      responseType: 'blob',
    }).pipe(map((response) => {
      const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      this.downloadFile('export.xls', blob);
    })).subscribe();
  }

  public issueToken(): Observable<string> {
    return this.executeGet<string>('profiles/actions/issueToken', {responseType: 'text'});
  }

  public createProfile(profile: Partial<ProfileModel>): Promise<any> {
    return this.executePost<any>('v2/users/register', profile);
  }

  public confirmRegistration(token: string): Promise<any> {
    return this.executePost<any>('v2/users/registrationConfirm', token);
  }

  public confirmConnect(code: string, state: string): Promise<any> {
    return this.executePost<any>(`finances/confirmConnect/${code}/${state}`, {});
  }

  public editProfile(profile: Profile): Observable<Profile> {
    return this.executePut<Profile>('profiles/update', serializationJacksonKey(profile))
      .pipe(map((data) => deserializationJacksonKey(Profile, data)));
  }

  public editProfileBasic(profile: EditProfileBasic): Observable<EditProfileBasic> {
    return this.executePut<EditProfileBasic>('profiles/update/basic', serializationJacksonKey(profile))
      .pipe(map((data) => deserializationJacksonKey(EditProfileBasic, data)));
  }

  public editProfileContacts(profile: EditProfileContacts): Observable<EditProfileContacts> {
    return this.executePut<EditProfileContacts>('profiles/update/contacts', serializationJacksonKey(profile))
      .pipe(map((data) => deserializationJacksonKey(EditProfileContacts, data)));
  }

  public editProfileLanguages(profile: EditProfileLanguage): Observable<EditProfileLanguage> {
    return this.executePut<EditProfileLanguage>('profiles/update/languages', serializationJacksonKey(profile))
      .pipe(map((data) => deserializationJacksonKey(EditProfileLanguage, data)));
  }

  public editProfileEmail(profile: EditProfileEmail): Observable<EditProfileEmail> {
    return this.executePut<EditProfileEmail>('profiles/update/email', serializationJacksonKey(profile))
      .pipe(map((data) => deserializationJacksonKey(EditProfileEmail, data)));
  }

  public editProfilePassword(profile: EditProfilePassword): Observable<EditProfilePassword> {
    return this.executePut<EditProfilePassword>('profiles/update/changePassword', serializationJacksonKey(profile))
      .pipe(map((data) => deserializationJacksonKey(EditProfilePassword, data)));
  }

  public editProfileContactEmailVerify(profile: Profile, email: string): Observable<any> {
    return this.executePut<string>('profiles/update/verifyContactEmail', serializationJacksonKey({
      profile,
      email
    })).pipe(map((data) => data));
  }

  public validateContactEmail(token: string): Promise<any> {
    return this.executePost<any>('profiles/validateContactEmail', token);
  }

  public editProfileEmailVerify(profile: Profile, editProfileEmail: EditProfileEmail): Observable<any> {
    return this.executePut<string>('profiles/update/verifyEmail', serializationJacksonKey({
      profile,
      newEmail: editProfileEmail.email,
      password: editProfileEmail.password
    })).pipe(map((data) => data));
  }

  public validateEmail(token: string): Promise<any> {
    return this.executePost<any>('v2/users/validateEmail', token);
  }

  public changePassword(userId: number, current: string, newPassword: string): Observable<any> {
    return this.executePut<any>('profiles/' + userId + '/changePassword', serializationJacksonKey({
      current,
      new: newPassword,
    }));
  }

  public editProfileNotifications(profile: EditProfileSettings): Observable<EditProfileSettings> {
    console.log(profile);
    return this.executePut<EditProfileSettings>('profiles/update/notifications', serializationJacksonKey(profile))
      .pipe(map((data) => deserializationJacksonKey(EditProfileSettings, data)));
  }

  public getFilteredProfiles(filters: any): Observable<any> {
    return this.executeGet<any>(`profiles/filtered`, {params: filters})
      .pipe(map((data) => {
        return {
          total: data.total,
          profiles: deserializationJacksonKey(Profile, data.profiles),
        };
      }));
  }

  public getProfilesStatus(profiles: HttpParams): Observable<ProfileStatus[]> {
    return this.executeGet<ProfileStatus[]>(`profiles/statuses`, {params: profiles});
  }

  public logout(): Promise<void> {
    return this.executePost<void>('profiles/logout', null);
  }

  public refreshLowBalance(): Observable<void> {
    return this.executeGet<void>('profiles/refreshLowBalance');
  }

  public updateAvailability(available: boolean): Promise<boolean> {
    return this.executePost<boolean>('profiles/actions/updateAvailability', available);
  }

  public editProfileInterfaceLanguage(lang: string): Observable<void> {
    return this.executePut('profiles/update/interfaceLang', lang);
  }

  public checkNickName(name: string): Observable<boolean> {
    return this.executeGet<boolean>('profiles/existNickName/' + name);
  }

  public checkEmail(email: string): Observable<boolean> {
    return this.executeGet<boolean>('profiles/existEmail/' + email);
  }

}
