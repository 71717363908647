import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../classes/enums';
import { ProfileService } from '../services/profile.service';
import {RouteHelper} from '../helpers/route.helper';

const USER_KEY = 'auth-user';

@Injectable()
export class MainPageGuard implements CanActivate {
  roleEnum = Role;

  constructor(private profileService: ProfileService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem(USER_KEY));

    const role = currentUser?.role || null;

    if (!currentUser) {
      // not authorized so return true
      return true;
    }

    if (currentUser && role === Role.EMPLOYER) {
      this.router.navigate([RouteHelper.PROJECTS_DASHBOARD_PATH]);
      return false;
    }

    if (currentUser && role === Role.FREELANCER) {
      this.router.navigate([RouteHelper.SEARCH_PATH]);
      return false;
    }
  }
}
