import {Session, Stream} from 'openvidu-browser';

export class StreamCreatedMessage {
  public stream: Stream;
  public session: Session;

  constructor(stream: Stream, session: Session) {
    this.stream = stream;
    this.session = session;
  }
}
