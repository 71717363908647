import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { DataLayerService } from '../../services/data-layer.service';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { LoginForm } from '../../forms/login.form';
import { SpinnerService } from '../../modules/tpt-ui/services/spinner.service';
import { AuthErrorDialogComponent } from '../../modules/common-dialogs/components/auth-error-dialog/auth-error-dialog.component';
import { RouteHelper } from '../../helpers/route.helper';
import { ProfileModel } from '../../models/profile.model';
import { Role } from '../../classes/enums';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { ProfilesApiService } from '../../services/profiles.api.service';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarNotificationsService } from '../../services/snackbar-notifications.service';

const TOKEN_KEY = 'auth-token';

@Component({
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
})
export class LoginComponent implements OnInit, OnDestroy {

  public form: LoginForm;

  public loading = false;

  public svgIconsEnum = SvgIconsEnum;

  public googleSignUpStepTwo = false;

  public roleControl = new FormControl('', Validators.required);

  public user: SocialUser;

  @ViewChild(AuthErrorDialogComponent, {static: false}) authErrorDialog: AuthErrorDialogComponent;

  public get loginCardIcon(): string {
    return `/assets/icons/${SvgIconsEnum.LoginCardIcon}.svg`;
  }

  constructor(
    private auth: AuthService,
    private profileService: ProfileService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private routeHelper: RouteHelper,
    private authService: SocialAuthService,
    private profileApiService: ProfilesApiService,
    private dataLayerService: DataLayerService,
    private spinnerService: SpinnerService,
    public translate: TranslateService,
    private readonly meta: Meta,
    private cd: ChangeDetectorRef,
    private snack: SnackbarNotificationsService,
  ) { }

  ngOnInit(): void {
    this.form = LoginForm.createForm();

    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=device-width, initial-scale=1') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    }
  }

  ngOnDestroy() {
    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=1200px') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=1200px' });
    }
  }

  public async googleSignIn(): Promise<void> {
    const socialUser: SocialUser = await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    const token = socialUser.idToken;
    const tptUser = await this.profileApiService.getUserAuthProfile(token).toPromise();

    if (tptUser.role === 'NOT_SELECTED') {
      this.user = socialUser;
      this.googleSignUpStepTwo = true;
      this.cd.detectChanges();
    } else {
      localStorage.setItem(TOKEN_KEY, token);
      await this.profileService.getUserInfo();

      const url = tptUser.role === 'EMPLOYER' ? this.routeHelper.employeerProjectsDashboard :
        this.routeHelper.projectSearch;
      await this.router.navigate([url]);
    }
  }

  public createAccountGoogle(): void {
    if (this.roleControl.invalid) {
      this.snack.showNotification('REGISTER_COMPONENT.PLEASE_CHOOSE_THE_ROLE', 'fail');
      return;
    }

    this.spinnerService.startSpinner();
    this.profileApiService.selectRole(this.roleControl.value, this.user.idToken).subscribe(async () => {
      localStorage.setItem(TOKEN_KEY, this.user.idToken);
      await this.profileService.getUserInfo();
      this.spinnerService.stopSpinner();

      const url = this.roleControl.value === 'EMPLOYER' ? this.routeHelper.employeerProjectsDashboard :
        this.routeHelper.projectSearch;
      await this.router.navigate([url]);
    }, () => {
      this.spinnerService.stopSpinner();
    });
  }

  public login(): void {
    if (!this.form.validate()) {
      return;
    }

    this.loading = true;
    this.spinnerService.startSpinner();
    const data = this.form.getFormData();

    this.auth.loginV2(data.username, data.password)
      .subscribe(async (response) => {
          this.profileService.updateToken(response);

          await this.profileService.refreshCurrentProfile();

          const url = this.profileService.currentProfile.isEmployer() ? this.routeHelper.employeerProjectsDashboard :
            this.routeHelper.projectSearch;
          this.router.navigate([url]);
          this.pushToDataLayer(this.profileService.currentProfile);
          this.spinnerService.stopSpinner();
          this.loading = false;
        },
      (error: { error: string, error_description: string }) => {
        const newErrors = ['Неверные учетные данные пользователя', 'Пользователь отключен',
          'Bad credentials', 'User is locked', 'User is disabled'];

        let errorText;
        switch (true) {
          case ['Bad credentials', 'Неверные учетные данные пользователя'].includes(error?.error_description):
            errorText = 'SERVER_ERRORS.AUTHENTICATION_FAILED';
            break;
          case ['User is disabled', 'Пользователь отключен'].includes(error?.error_description) :
            errorText = 'SERVER_ERRORS.ACCOUNT_DISABLED';
            break;
          case ['User is locked', 'Пользователь заблокирован'].includes(error?.error_description) :
            errorText = 'SERVER_ERRORS.ACCOUNT_LOCKED';
            break;
          default: errorText = 'SERVER_ERRORS.LOGIN_ERROR';
        }

        if (error && newErrors.includes(error.error_description)) {
          this.authErrorDialog.open('AUTH_ERROR', errorText, data.username);
        } else {
          this.authErrorDialog.open('AUTH_ERROR', 'SERVER_ERRORS.LOGIN_ERROR', data.username);
        }
        this.loading = false;
        this.spinnerService.stopSpinner();
      }
    );
  }

  private pushToDataLayer = (profile: ProfileModel): void => {
    this.dataLayerService.pushToDataLayer({
      event: 'login',
      userId: `${profile.id}`,
      userType: profile.role === Role.EMPLOYER ? 'customer' : 'freelancer'
    });
  }

}
