import {Type} from 'class-transformer';
import {RIssueStatus} from '../classes/enums';
import {RResource} from './rResource';

export class RIssue {

  public id: number;

  public estimatedHours: number;
  public doneRation: number;
  public status: RIssueStatus;
  public assignedTo: RResource;

  public subject: string;
  public description: string;

  @Type(() => Date)
  public createdOn: Date;

  @Type(() => Date)
  public updatedOn: Date;
}
