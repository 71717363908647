import { Type } from 'class-transformer';
import { BaseProfile } from './baseProfile';
import { ProfileSettings } from './profileSettings';
import { Role } from '../../classes/enums';

export class Profile extends BaseProfile {
  public password: string;
  public email: string;
  public lowBalance: boolean;

  public rabbitmqKey: string;
  public sessionExpirationTimeout: number;

  @Type(() => ProfileSettings)
  public settings: ProfileSettings = new ProfileSettings();

  public analyticsCustomerTypeByRole(role: Role): 'customer' | 'freelancer' | 'admin' | 'judge' {
    return role === Role.EMPLOYER
      ? 'customer'
      : role === Role.FREELANCER
        ? 'freelancer'
        : role === Role.ADMIN
          ? 'admin'
          : 'judge';
  }

}
