import {
  ChangeDetectorRef,
  Component, ElementRef, HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';
import { MatSelectChange } from '@angular/material/select';

import { Observable, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NouisliderComponent } from 'ng2-nouislider';
import { omitBy } from 'lodash';

import { SkillService } from 'src/app/services/skill.service';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { PortalService } from '../../../../services/portal.service';
import { CategoryService } from '../../../../services/category.service';
import { CountryService } from '../../../../services/country.service';
import { LanguageService } from '../../../../services/language.service';
import { SearchService } from '../../../../services/search.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { ProjectApiService } from '../../../../services/project.api.service';
import { ProfileService } from '../../../../services/profile.service';
import { Language } from '../../../../models/language';
import { ProfileModel } from '../../../../models/profile.model';
import { Country } from '../../../../models/country';
import { FreelancerLegalStatusEnum } from '../../../../models/legalStatuses.enum';
import { LoadingBarService } from '../../../../services/loading-bar.service';

const BUDGET_MAX = 1000;

@Component({
  selector: 'freelancer-search',
  templateUrl: './freelancer-search.component.html',
  styleUrls: ['./freelancer-search.component.scss'],
})
export class FreelancerSearchComponent implements OnInit, OnDestroy {

  @ViewChild('hourlyRateSlider')
  public hourlyRateSlider: NouisliderComponent;

  @ViewChild('top')
  public top: ElementRef;

  languageLevels = [
    'BEGINNER',
    'MEDIUM',
    'ADVANCED',
    'NATIVE'
  ];

  categories: any;
  languageLevel = {
    code: null,
    name: null,
    level: null
  };

  languageArray = [];

  filterSlider = {
    budget: [0, 3000],
    hourlyRate: [0, 120]
  };

  separatorKeysCodes: number[] = [ENTER];
  skillCtrl = new FormControl('');
  skills: string[] = [];
  allSkills: any[] = [];
  filteredSkills: Observable<any[]>;
  freelancers: ProfileModel[] = [];
  employersProjects = [];
  filteredItems;
  allSkillsArray = [];
  svgIconsEnum = SvgIconsEnum;

  public hourlyRateSliderConfig = {
    connect: [false, true, false],
    tooltips: [true, true],
    range: {
      min: 1,
      max: 120,
    },
    format: {
      to: (value) => value === BUDGET_MAX ? `> ${BUDGET_MAX}` : Math.round(value),
      from: (value) => Number(`${value}`.replace('> ', '')),
    },
    step: 1
  };

  public countries: Country[];
  public countriesCount: number;
  public languages: Language[];
  public filter: FormGroup;
  public countryFormControl: FormControl = new FormControl();
  public talentLegalStatus = FreelancerLegalStatusEnum;
  public showSkeleton = false;
  public loading: boolean;
  private pageNumber = 0;
  private params;
  private currentPosition = 0;
  private totalPages: number;
  private readonly destroy$ = new Subject();

  constructor(
    private portalService: PortalService,
    private categoryService: CategoryService,
    private countryService: CountryService,
    private languageService: LanguageService,
    private searchService: SearchService,
    private skillService: SkillService,
    private fb: FormBuilder,
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private spinner: SpinnerService,
    private projectService: ProjectApiService,
    private translate: TranslateService,
    private profile: ProfileService,
    private loadingBarService: LoadingBarService
  ) {}

  @HostListener('scroll', ['$event']) onScroll(event) {
    const containerHeight = event?.target?.offsetHeight;
    const scrollHeight = event?.target?.scrollHeight;
    const scrollTop = event?.target?.scrollTop;

    if (this.currentPosition > scrollTop) {
      return;
    }
    this.currentPosition = scrollTop;

    const a = scrollHeight - scrollTop;
    const b = containerHeight * 0.25 + containerHeight;

    if (a < b && !this.loading) {
      if (this.pageNumber >= this.totalPages - 1) {
        return;
      }
      this.loading = true;
      this.pageNumber = this.pageNumber + 1;
      this.getListOfFreelancers(this.params);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getCategories();
    this.getCountries(true);
    this.getLanguages();
    this.getAllSkills();
    this.getCurrentEmployersProjects();
    this.getFilteredFreelancers();
    this.loadingBarService.start();

    this.skillCtrl.valueChanges.pipe(startWith('')).subscribe((value) => {
      this.findSkills(value);
    });
  }

  public updateFilter(event: MatSelectChange): void {
    if (!this.filter) {
      return;
    }

    this.filter.controls.countryCode.setValue(event.value);
  }

  public updateSortOrder(event): void {
    this.filter.controls.sortBy.patchValue(event);
  }

  public addLang(): void {
    this.languageArray.push({
      code: null,
      level: null
    });
  }

  public removeLang(i): void {
    this.languageArray = this.languageArray.filter((item, index) => index !== i);
    this.updateLanguageForm();
  }

  public updateLanguageForm(): void {
    const updatedArr = this.languageArray.filter(item => {
      return item.code && item.level;
    });

    this.filter.controls.languageLevels.patchValue(updatedArr);
  }

  public getAllSkills(): void {
    this.skillService.getSkillsV2().subscribe(res => {
      this.allSkills = res;
      this.allSkillsArray = this.allSkills;
      this.findSkills('');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setAll(checked: boolean, category) {
    category.children.forEach(item => item.isSelected = checked);
  }

  public getTranslation(item): string {
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  async getUpdAllSkills(checked, category, subCategory): Promise<void> {

    if (checked && !subCategory) {
      const skillsId = category.id;
      const res = await this.skillService.getCategorySkillsV2(skillsId).toPromise();
      res.forEach(item => {
        if (this.allSkillsArray.some(skill => skill.id === item.id)) {
          return;
        }
        this.allSkillsArray.push(item);
      });
    }

    if (!checked && !subCategory) {
      const skillId = category.id;
      const res = await this.skillService.getCategorySkillsV2(skillId).toPromise();
      const filterArr = res.map(item => item.id);
      this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
      if (!this.allSkillsArray.length) {
        this.allSkillsArray = this.allSkills;
      }
    }

    if (checked && subCategory) {
      const res = await this.skillService.getCategorySkillsV2(category.id).toPromise();
      res.forEach(skill => this.allSkillsArray.push(skill));
    }

    if (!checked && subCategory) {
      if (category.children.every(child => !child.isSelected)) {
        const res = await this.skillService.getCategorySkillsV2(category.id).toPromise();
        const filterArr = res.map(item => item.id);
        this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
        if (!this.allSkillsArray.length) {
          this.allSkillsArray = this.allSkills;
        }
      }
    }

    this.updateCategoryFormControl();
    this.filteredItems = this.allSkillsArray;
  }

  public updateCategoryFormControl(): void {
    if (!this.categories) { return; }

    const categoriesArray = [];

    this.categories.forEach(item => {
      if (item.isSelected) {
        categoriesArray.push(item.id);
      }
    });

    const notAllSelected = this.categories.filter(item => !item.isSelected);
    notAllSelected.forEach(item => {
      item.children.forEach(child => {
        if (child.isSelected) {
          categoriesArray.push(child.id);
        }
      });
    });

    this.filter.controls.categories.patchValue(categoriesArray);
  }

  public getCategories(): void {
    this.categoryService.getCategoriesV2().subscribe(res => {
      this.categories = res;
      this.categories.forEach(item => {
        item.isSelected = false;
        item.expanded = false;

        item.children.forEach(child => {
          child.isSelected = false;
        });
      });
    });
  }

  public removeSkill(index: number): void {
    const skills = this.filter.get('skills') as FormArray;

    if (index >= 0) {
      skills.removeAt(index);
    }
  }

  public selectedSkill(event: any): void {
    const skills = this.filter.get('skills') as FormArray;

    const alreadyAdded = skills.value.some(item => item.id === event.option.value.id);
    if (alreadyAdded) { return; }

    skills.push(this.fb.control(this.skillCtrl.value));
    this.skillCtrl.setValue('');
    this.cdr.detectChanges();
  }

  public rateChange(val: number[]): void {
    this.filter.controls.rateMin.patchValue(val[0]);
    this.filter.controls.rateMax.patchValue(val[1]);
  }

  public toggleSubcats(category): void {
    category.expanded = !category.expanded;

    if (category.expanded) {
      this.categories.forEach(item => {
        item.expanded = item.id === category.id;
      });
    }
  }

  public subCategoryChanged(event, subCat, cat): void {
    subCat.isSelected = !subCat.isSelected;
    cat.isSelected = this.getAllComplete(cat);
  }

  public someChecked(category): boolean {
    const countOfChecked = category.children.filter(item => item.isSelected).length;
    return countOfChecked > 0 && countOfChecked < category.children.length;
  }

  public updateUsers(val): void {
    this.filter.controls.name.patchValue(val);
  }

  public getViewField(): string {
    if (this.translate.currentLang === 'ru') {
      return 'nameRu';
    }
    return 'name';
  }

  public getCountries(setCount: boolean, name?: string): void {
    if (!name) {
      name = '';
    }

    this.countryService.getCountriesV2(name).subscribe(res => {
      this.countries = res;

      if (setCount) {
        this.countriesCount = res.length;
      }
    });
  }

  private getListOfFreelancers(filter): void {
    const defaultFilter = {pageNumber: this.pageNumber, pageSize: 15, rateMin: 0, rateMax: 1000};
    this.searchService.getListOfFreelancers({...defaultFilter, ...filter}).subscribe((res) => {
      this.freelancers = [...this.freelancers, ...res.content];
      this.totalPages = res.totalPages;
      this.loading = false;
      this.showSkeleton = false;
      this.loadingBarService.complete();
    });
  }

  private getLanguages(): void {
    this.languageService.getLanguagesV2().pipe(
      takeUntil(this.destroy$))
      .subscribe(languages => {
          this.languages = languages;
        }
      );
  }

  private getCurrentEmployersProjects(): void {
    if (!this.profile.currentProfile.isEmployer()) { return; }

    const employerId = this.profile.currentProfile.id;

    const filter = {pageNumber: 0, pageSize: 300, employerId, states: ['INIT'] };

    this.projectService.getListOfProjects(filter).subscribe(res => {
      this.employersProjects = res.content;
    });
  }

  private getAllComplete(category): boolean {
    return category.children.every(item => item.isSelected);
  }

  private getFilteredFreelancers(): void {
    this.searchService.freelancers$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.refreshVariables();

      if (!data) {
        this.params = {};
        this.getListOfFreelancers({});
        return;
      }

      let levelsString = '';
      if (data.value?.levels) {
        const levels = data.value.levels;
        const levelsArray = [];
        Object.keys(levels).forEach(key => {
          if (levels[key]) {
            levelsArray.push(key.toUpperCase());
          }
        });
        levelsString = levelsArray.join(',');
      }

      let legalStatus = '';
      if (data.value?.legalStatus) {
        const statusArray = [];
        const legalStatuses = data.value.legalStatus;
        Object.keys(legalStatuses).forEach(key => {
          if (legalStatuses[key]) {
            statusArray.push(key.toUpperCase());
          }
        });

        if (statusArray.length === 1) {
          legalStatus = statusArray[0];
        }
      }

      const mappedData = data.value;
      mappedData.levels = levelsString;
      mappedData.legalStatus = legalStatus;
      mappedData.skills = mappedData.skills.map(item => item.id);

      const model = omitBy(mappedData,
        (field: string | number) => !Boolean(field));

      this.params = model;
      this.getListOfFreelancers(model);
    });
  }

  private refreshVariables(): void {
    this.pageNumber = 0;
    this.currentPosition = 0;
    this.showSkeleton = true;
    this.freelancers = [];
    this.top?.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  private initForm(): void {
    this.filter = new FormGroup({
      categories: new FormControl([]),
      skills: new FormArray([]),
      levels: new FormGroup({
        beginner: new FormControl(false),
        intermediate: new FormControl(false),
        master: new FormControl(false),
      }),
      rateMin: new FormControl(0),
      rateMax: new FormControl(1000),
      countryCode: new FormControl(''),
      languageLevels: new FormControl([]),
      sortBy: new FormControl('RATE_ASC'),
      name: new FormControl(''),
      legalStatus: new FormGroup({
        individual: new FormControl(false),
        self_employed: new FormControl(false),
      })
    });

    this.searchService.filtersFreelancersNext(this.filter);
    this.filter.valueChanges.pipe(takeUntil(this.destroy$)).pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.searchService.filtersFreelancersNext(this.filter);
    });
  }

  private async findSkills(val): Promise<void> {
    if (!val) {
      this.filteredItems = this.allSkillsArray;
      return;
    }

    if (typeof val === 'string') {
      this.filteredItems = await this.skillService.getCategorySkillsV2(null, val).toPromise();
    }
  }
}
