<tpt-dialog>
  <ng-container *ngIf="freelancer">
    <mat-icon [svgIcon]="svgIconsEnum.MoreHoriz" class="cross-icon"
              [matMenuTriggerFor]="menu">
    </mat-icon>

    <mat-menu
      class="tpt-more-menu"
      #menu="matMenu"
      xPosition="before"
    >
      <button
        mat-menu-item
        (click)="openProfilePage()"
      >
        {{'SETTINGS.USER_PROFILE' | translate}}
      </button>
    </mat-menu>

    <div class="header-row">
      <tpt-avatar size="56" class="mr-20" [user]="freelancer"></tpt-avatar>

      <div>
        <div class="freelancer-name headline">{{ freelancer.firstName }} {{ freelancer.lastName }}</div>

        <div class="legal-category-row">
          <div class="freelancer-position" *ngIf="mainSpec">
            {{getTranslation(mainSpec?.parentCategory) }}
          </div>

          <div class="legal-status" *ngIf="freelancer?.legalStatus === freelancerLegalStatusEnum.SELF_EMPLOYED">
            {{('SETTINGS.' + freelancer?.legalStatus) | translate}}
          </div>
        </div>

        <span class="freelancer-rate caption">
          {{ freelancer.hourlyRate ? '$' + freelancer.hourlyRate + '/' + ('FINANCE.HOUR' | translate) : '-' }}
        </span>

        <span class="caption freelancer-rating">{{'SETTINGS.RATING' | translate}} {{ freelancer.rating | percent }}</span>
      </div>
    </div>

    <mat-tab-group>
      <mat-tab label="{{'CONTRACTS.ABOUT_FREELANCER' | translate}}">
        <div class="about-container">
          <div class="subhead-1 mb-8 mt-24">{{'CONTRACTS.ABOUT' | translate}}</div>
          <div class="freelancer-about caption mb-20" *ngIf="freelancer?.description; else emptyTemplate">
            {{ freelancer.description }}
          </div>

          <div class="about-grid">
            <div>
              <div class="subhead-1 mb-8 section-title">{{'CONTRACTS.LINKS' | translate}}</div>
              <div class="section-content" *ngIf="freelancer?.links?.length; else emptyTemplate">
                <a *ngFor="let link of freelancer?.links" [href]="link" target="_blank"
                   class="freelancer-link">{{link}}</a>
              </div>
            </div>
            <div>
              <div class="subhead-1 mb-8">{{'SETTINGS.LOCATION' | translate}}</div>
              <div class="freelancer-location caption" *ngIf="freelancer.country">
                <mat-icon [svgIcon]="svgIconsEnum.Location" class="location-icon"></mat-icon>
                {{ getTranslation(freelancer.country) }}
              </div>
              <div *ngIf="freelancer?.timeZone"
                class="freelancer-local-time caption">{{'CONTRACTS.LOCAL_TIME' | translate}} {{ getTime(freelancer.timeZone)}}</div>
            </div>
          </div>

        </div>

      </mat-tab>
      <mat-tab label="{{'CONTRACTS.SKILLS_FREELANCER' | translate}}">
        <div class="about-container">
          <div class="subhead-1 mb-8 mt-20">{{'SETTINGS.SPECIALIZATION' | translate}}</div>
          <ng-container *ngIf="mainSpec || freelancer?.expertLevel; else emptyTemplate">
            <div class="regular mb-8 specialisation">{{getTranslation(mainSpec?.parentCategory)}}</div>
            <div class="regular specialisation" *ngIf="freelancer?.expertLevel">
              {{'CONTRACTS.EXPERT_LEVEL' | translate}} - {{ 'SEARCH.LEVEL.' + freelancer?.expertLevel | translate}}
            </div>
          </ng-container>

          <div class="subhead-1 mb-8 mt-20" *ngIf="freelancer?.experience">
            {{'SEARCH.OPTIONS.WORK_EXP' | translate}}
          </div>

          <div *ngFor="let item of freelancer?.experience" class="exp-item">
            <mat-icon [svgIcon]="svgIconsEnum[platformsEnum[item.code]]"></mat-icon>
            <div>
              <div class="platform-name mb-4">
                {{platformsEnumFe[item.code]}}
                <div *ngIf="item.rating" class="progress-bar">
                  <span class="progress-bar-value">{{item.rating}}%</span>
                  <mat-progress-bar [value]="item.rating" color="primary" mode="determinate"></mat-progress-bar>
                </div>
              </div>
              <div class="mb-4">
                <span class="info-category">{{'SEARCH.OPTIONS.WORK_EXP' | translate}}:</span>
                <span *ngIf="getYears(item.duration) as years" class="info-value">
                  {{'SEARCH.OPTIONS.YEARS' | translate : { years: years } }}
                </span>
                <span *ngIf="getMonths(item.duration) as months" class="info-value">
                  {{'SEARCH.OPTIONS.MONTHS' | translate : { months: months } }}
                </span>
              </div>
              <div>
                <span class="info-category">{{'SEARCH.CHECK_DATE' | translate}}: </span>
                <span class="info-value">{{item.checkingDate | localDate: 'dd.MM.yy'}}</span>
              </div>

              <div *ngIf="item?.file" class="reviews-link">
                <a target="_blank" [href]="getLink(item.file.uuid)">
                  {{ 'CONTRACTS.REVIEWS' | translate }}
                  <mat-icon [svgIcon]="svgIconsEnum.Link" class="link-icon"></mat-icon>
                </a>
              </div>
            </div>
          </div>

          <div class="subhead-1 mb-8 mt-20">{{'CONTRACTS.SKILLS' | translate}}</div>
          <div class="freelancer-about caption mb-20">
            <div class="pseudo-chips" *ngIf="getAllSkills().length; else emptyTemplate">
              <tpt-chip class="chip mr-8 mb-8" type="outline" size="small"
                        *ngFor="let skill of getAllSkills()">
                <span>{{getTranslation(skill) || skill}}</span>
              </tpt-chip>
            </div>
          </div>

          <div>
            <div class="subhead-1 mb-8">{{'CONTRACTS.LANGUAGES' | translate}}</div>
            <ng-container *ngIf="freelancer?.languages?.length; else emptyTemplate">
              <div
                class="mb-8"
                *ngFor="let language of freelancer.languages"
              >
                <span class="key regular">{{getLangByCode(language)}}:</span>
                <span class="value regular" *ngIf="language.level">
                {{ 'CREATE_PROJECT.STEP3.' + language.level | translate }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'CONTRACTS.REVIEWS' | translate}}">
        <div class="reviews">
          <ng-container *ngIf="(reviews$ | async)?.length; else emptyReviews">
            <div *ngFor="let review of reviews$ | async" class="card-wrapper">
              <tpt-review-card [feedback]="review" [isEmployer]="false"></tpt-review-card>
            </div>
          </ng-container>
          <ng-template #emptyReviews>
            <div class="no-reviews">
              <img src="assets/images/empty-feedbacks.png" class="empty-feedbacks">

              <div class="title">{{'CONTRACTS.NO_REVIEWS' | translate}}</div>
              <div class="text">{{'CONTRACTS.NO_REVIEWS_FREEL' | translate}}</div>
            </div>
          </ng-template>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-template #emptyTemplate>
    <span class="not-specified">{{'CONTRACTS.NOT_SPECIFIED' | translate}}</span>
  </ng-template>
</tpt-dialog>
