<tpt-dialog>
  <div class="deposit-funds-title">{{'FINANCE.TOP_UP_BALANCE' | translate}}</div>

  <div class="info">
    <div class="title-info">{{'CONTRACTS.GENERATE_INVOICE_INFO_TITLE' | translate}}</div>
    {{'CONTRACTS.GENERATE_INVOICE_INFO_TEXT' | translate}}
  </div>

  <div class="top-up-amount">
    {{'CONTRACTS.TOPUP_AMOUNT' | translate}}
  </div>

  <div class="amount-container" *ngIf="project">
    <div class="amount-text">{{'CONTRACTS.AMOUNT_TEXT' | translate}}</div>
    <div class="amount">{{currencySymbol[project?.currency?.code]}}{{project?.details?.budgetWithCommission | json}}</div>
  </div>

  <div class="actions-row">
    <tpt-button (onClick)="close()" type="secondary" size="medium">
      {{'BUTTON.CANCEL' | translate}}
    </tpt-button>
    <tpt-button type="accent" (onClick)="generateInvoice()" size="medium">
      {{'CONTRACTS.GENERATE_BUTTON' | translate}}
    </tpt-button>

  </div>
</tpt-dialog>
